import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { firestoreConnect } from 'react-redux-firebase';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { getUnreadNotificationsForCompanyQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Notifications';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { get } from 'lodash-es';

export default compose(
  connect((state, ownProps) => {
    const openCompany = ownProps.companyId || getOpenCompanyId(state);
    const profile = state.firebase.profile;

    const loadingNotifications =
      get(profile, 'uid') && openCompany
        ? !hasLoaded(
          [getUnreadNotificationsForCompanyQuery(profile.uid, openCompany)],
          state,
        )
        : false;

    return {
      openCompany,
      profile,
      loadingNotifications,
    };
  }),
  firestoreConnect(props => {
    const { profile, openCompany } = props;

    if (get(profile, 'uid') && openCompany) {
      return [
        getUnreadNotificationsForCompanyQuery(
          props.profile.uid,
          props.openCompany,
        ),
      ];
    }
    return [];
  }),
);
