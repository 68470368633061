import React from 'react';
import { get } from 'lodash-es';

import { isAdmin } from 'farmerjoe-common/lib/utils/User';

import NoFields from './NoFields';
import NoResultsResetFilter from './NoResultsResetFilter';
import FieldsListSectionHeader from './FieldsListSectionHeader';
import CropFieldsListItem from './CropFieldsListItem';
import NoResults from '../Common/NoResults';
import SectionList from '../Common/SectionList';
import { Loading } from '../Loading/Loading';

import './style.css';
import I18n from '../../language/i18n';
import type { Field, WaitTime } from '../../flowTypes';

type Props = {
  fields?: Array<any> | null;
  onClick?: (...args: Array<any>) => any;
  filter: Record<string, any>;
  waitTimes?: any[];
  myCompanyProfile?: any;
  renderField?: (arg0: {
    item: Field;
    waitTimes: Record<string, WaitTime>;
  }) => {};
  ListHeaderComponent: (...args: Array<any>) => any;
  onScroll?: (...args: Array<any>) => any;
  openFieldPath?: Record<string, any>;
  allFieldsOpen?: boolean;
  isMapPage?: boolean;
  tabLabel?: string;
  loading?: boolean;
  extraData?: any;
  isActiveTab?: boolean;
};
type State = {
  open: any;
};
export default class FieldsList extends React.PureComponent<Props, State> {
  state: State = { open: {} };

  static defaultProps = {
    ListHeaderComponent: null,
  };

  isSectionExpanded({ title }: Record<string, any>) {
    return this.state.open[title] != null ? this.state.open[title] : true;
  }

  fieldListItem = (item: { section: any }) => {
    const {
      onClick,
      filter,
      waitTimes,
      myCompanyProfile,
      openFieldPath,
      renderField,
      allFieldsOpen,
    } = this.props;
    const search = get(filter, 'search');

    if (!this.isSectionExpanded(item.section)) {
      return null;
    }

    return (
      <CropFieldsListItem
        expanded={this.isSectionExpanded(item.section)}
        rowData={item}
        search={search}
        waitTimes={waitTimes}
        onClick={onClick}
        renderField={renderField}
        myCompanyProfile={myCompanyProfile}
        openFieldPath={openFieldPath}
        allFieldsOpen={allFieldsOpen}
      />
    );
  };

  sectionHeader = ({ section }: any) => {
    return (
      <FieldsListSectionHeader
        sectionId={section.title}
        onClick={sectionId => {
          this.setState({
            open: {
              ...this.state.open,
              [sectionId]: !this.isSectionExpanded(section),
            },
          });
        }}
        expanded={this.isSectionExpanded(section)}
      />
    );
  };

  render() {
    const { fields } = this.props;

    if (!fields) {
      return <Loading />;
    }

    return (
      <SectionList
        className="crop-field-list"
        ListHeaderComponent={this.props.ListHeaderComponent}
        renderItem={this.fieldListItem as any}
        renderSectionHeader={this.sectionHeader}
        sections={fields}
        ListEmptyComponent={this.emptyView()}
        SectionSeparatorComponent={sep => {
          // Don't display this with top separators
          if (sep.trailingItem) {
            return null;
          }
          return (
            <div style={{ height: 15, backgroundColor: 'transparent' }}></div>
          );
        }}
        stickySectionHeadersEnabled={true}
        keyExtractor={(item, index) => {
          return item.crop + index;
        }}
        onScroll={this.props.onScroll}
      />
    );
  }

  emptyView = () => {
    const { myCompanyProfile, filter, fields, isMapPage } = this.props;

    const showCrops = get(filter, 'showCrops');
    const search = get(filter, 'search');

    if (!isMapPage && fields && fields.length === 0 && search) {
      return (
        <div style={{ flex: 1 }} /* tabLabel={I18n.t('current')} */>
          <NoResults text={I18n.t('noSearchResults')} />
        </div>
      );
    } else if (search || (showCrops && showCrops.length !== 3)) {
      return <NoResultsResetFilter tabLabel={I18n.t('current')} />;
    } else {
      return (
        <NoFields
          showCreate={isAdmin(myCompanyProfile)}
        />
      );
    }
  };
}
