import React, { createContext, useState } from 'react';


const DataContext = createContext<any>({});

const DataProvider = ({ children }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const refreshData = () => setRefreshTrigger(prev => prev + 1);

  return (
    <DataContext.Provider value={{ refreshTrigger, refreshData }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
