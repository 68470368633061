import { useState, useEffect } from 'react';
import { getPaymentMethod } from 'farmerjoe-common/lib/requests/billing';

import { getUidToken } from '../../utils/auth';

type Response = {
  card?: {
    brand: string;
    last4: string;
    exp_month: number;
    exp_year: number;
  };
};

const usePaymentMethod = (companyId: string, stripeCustomerId: string) : [Response | null, boolean] => {
  const [paymentMethod, setPaymentMethod] = useState<Response|null>(null);
  const [ apiLoading, setApiLoading ] = useState(false);

  useEffect(() => {
    const getData = () => {
      setApiLoading(true);
      return getUidToken()
        .then((uidToken) => {
          return getPaymentMethod(companyId, uidToken)
            .then((response) => {
              return response.json().then((result: Response) => {
                if (response.status === 200) {
                  setPaymentMethod(result);
                }
              });
            });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setApiLoading(false);
        });
    };
    if (companyId && stripeCustomerId) {
      getData();
    }
  }, [companyId, stripeCustomerId]);

  return [paymentMethod, apiLoading];
};

export default usePaymentMethod;
