import React from 'react';
import { SUBSCRIPTION_FEATURES } from './constants';
import I18n from '../../language/i18n';

const isFreePlan = (currentPlan) => {
  return currentPlan.type === 'free';
};

const CurrentPlan = (props) => {
  const { currentPlan, currentUsage, onBuySeats, onReduceSeats, currentSubscriptionInReview } = props;
  const isCurrentPlanEnterprise = currentPlan.type === 'enterprise';
  return (
    <div style={{
      margin: '1em 2em',
    }}>
      <div style= {{
        fontSize: '32px',
        borderBottom: '1px solid grey',
        margin: '1em 0',
      }}>
        {I18n.t('billing.currentPlan')}
      </div>
      <div style={{
        border: '1px solid rgb(138, 180, 88)',
        borderRadius: '15px',
        padding: '15px',
      }}>
        <div style={{
          fontSize: '22px',
          fontWeight: 'bold',
        }}>
          {currentPlan.name}
        </div>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <ul style={{
            margin: 0,
            padding: 0,
            listStyleType: 'none',
          }}>
            {SUBSCRIPTION_FEATURES[currentPlan.key].map((f: string, idx: number) => {
              return (
                <li key={idx}>
                  <span
                    style={{
                      color: 'rgb(138,180,88)',
                      marginRight: '20px',
                    }}
                  >&#10004;</span>
                  <span>{f}</span>
                </li>
              );
            })
            }
          </ul>
        </div>

        {isCurrentPlanEnterprise ? null : (
          <div style={{ marginTop: '10px' }}>
            <strong>{currentPlan.price}&#8364;</strong> {I18n.t('billing.perSeatPerMonth')}
          </div>
        )}

        <div style={{
          margin: '15px 0',
        }}>
          {isCurrentPlanEnterprise ? (
            <>
              <strong>{I18n.t('billing.seats')}: </strong>
              <span style={{color: '#7EB549', fontWeight: 'bold' }}>
                {currentUsage.totalUsers}
              </span>
            </>
          ) : (
            <>
              <div>
                <strong>{I18n.t('billing.seats')}: </strong>
                <span style={{color: currentUsage.totalUsers >= currentUsage.maxAllowedUsers ? 'rgb(197,53,32)' : '#7EB549', fontWeight: 'bold' }}>
                  {currentUsage.totalUsers}
                </span>
                {'/'}
                <span style={{fontWeight: 'bold'}}>
                  {currentUsage.maxAllowedUsers}
                </span>
                <div style={{display: 'flex'}}>
                  {isFreePlan(currentPlan) || currentSubscriptionInReview ? null : (
                    <div style={{
                      margin: '0.5em 0 0 0',
                    }}>
                      <button className={'btn btn-primary'} onClick={onBuySeats} style={{textTransform: 'uppercase'}}>{I18n.t('billing.buyMoreSeats')}</button>
                    </div>
                  )}
                  {(currentUsage.totalUsers < currentUsage.maxAllowedUsers) && !isFreePlan(currentPlan) && !currentSubscriptionInReview ? (
                    <div style={{
                      margin: '0.5em 0 0 0.5em',
                    }}>
                      <button className={'btn btn-outline-secondary'} onClick={onReduceSeats} style={{textTransform: 'uppercase'}}>{I18n.t('billing.removeUnusedSeats')}</button>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentPlan;
