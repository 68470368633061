import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash-es';

import { filters } from 'farmerjoe-common/lib/actions/actions';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import {
  getGroupFieldsByOptions,
  getFertilizationOptions,
  getSortFieldsByOptions,
  getShowProducerNamesOptions,
  getSharedByCompanyOptions,
  getOptionsForShowCropsWith,
} from 'farmerjoe-common/lib/utils/Filter';
import {
  DISPLAY_SETTINGS_SORT_FIELDS_BY,
  DISPLAY_SETTINGS_GROUP_FIELDS_BY,
  DISPLAY_SETTINGS_SHOW_SHARED_BY_COMPANY,
  DISPLAY_SETTINGS_SHOW_PRODUCER_NAMES,
  DISPLAY_SETTINGS_SHOW_FERTILIZATION_RESULT,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
  DISPLAY_SETTINGS_SORT_FIELDS_BY_FIELD_NAME,
  DISPLAY_SETTINGS_GROUP_FIELDS_BY_CROP_NAME,
} from 'farmerjoe-common/lib/constants/filters';

import Icon from '../Common/Icon';
import { FilterList, ListItemCheck, ListItemToggle, ListSection } from '../Modal/FilterList';
import I18n from '../../language/i18n';
import * as constants from '../../styles/style';
import './style.css';

type FiltersProps = {
  openCompany: string;

  displaySettings: {
    fertilizing: boolean;
  };

  cropState: 'active' | 'harvested';
  sortFieldsBy: string;
  groupFieldsBy: string;
  showFertilizationResult: boolean;
  showProducerNames: boolean;
  showSharedByCompany: boolean;
  showCropsWith: string;

  actions: {
    filters: any; // typeof filters
  };
};

type FiltersState = {
  openFilterList: boolean;
  sortFieldsBy: string;
  groupFieldsBy: string;
  showCropsWith: string;
  showFertilizationResult: boolean;
  showProducerNames: boolean;
  showSharedByCompany: boolean;
};

const Filters = (props: FiltersProps) => {
  const {
    cropState,
    sortFieldsBy,
    groupFieldsBy,
    showFertilizationResult,
    showProducerNames,
    showSharedByCompany,
    openCompany,
    displaySettings,
    showCropsWith,
  } = props;

  const [state, setState] = useState<FiltersState>({
    openFilterList: false,
    sortFieldsBy: props.sortFieldsBy ?? DISPLAY_SETTINGS_SORT_FIELDS_BY_FIELD_NAME,
    groupFieldsBy: props.groupFieldsBy ?? DISPLAY_SETTINGS_GROUP_FIELDS_BY_CROP_NAME,
    showCropsWith: props.showCropsWith ?? DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
    showFertilizationResult: props.showFertilizationResult ?? false,
    showProducerNames: props.showProducerNames ?? false,
    showSharedByCompany: props.showSharedByCompany ?? false,
  });

  const updateFilterState = (key: keyof FiltersState, value: string | boolean) => {
    const updatedState: FiltersState = {
      ...state,
      [key]: value,
    };
    setState({ ...updatedState });
    const updatedDisplaySettings = {
      displaySettings: {
        ...displaySettings,
        [cropState]: {
          ...updatedState,
        },
      },
    };
    props.actions.filters(openCompany, updatedDisplaySettings);
  };

  const sortFieldsByOptions = getSortFieldsByOptions(cropState) || [];
  const groupFieldsByOptions = getGroupFieldsByOptions(cropState) || [];
  const showCropsWithOptions = getOptionsForShowCropsWith() || [];
  const fertilizingOptions = getFertilizationOptions() || [];
  const showProducerNamesOptions = getShowProducerNamesOptions() || [];
  const showSharedByCompanyOptions = getSharedByCompanyOptions() || [];

  return (
    <FilterList
      open={state.openFilterList}
      onClose={() => setState({...state, openFilterList: false })}
      button={<button
        className="sort-filter btn btn-secondary"
        onClick={() => setState({...state, openFilterList: !state.openFilterList })}>
        <Icon
          name="sort"
          iconType={'fj'}
          style={{
            color: constants.FJMUTEDDARK,
          }}
        />
      </button>}
    >
      <ListSection headline={I18n.t('sortFieldsBy')}>
        {sortFieldsByOptions.map(option => (
          <ListItemCheck
            key={option.label}
            option={option}
            selected={option.value === sortFieldsBy}
            onChange={(value) => updateFilterState(DISPLAY_SETTINGS_SORT_FIELDS_BY, value)}
          />
        ))}
      </ListSection>

      <ListSection headline={I18n.t('groupFieldsBy')}>
        {groupFieldsByOptions.map(option => (
          <ListItemCheck
            key={option.label}
            option={option}
            selected={option.value === groupFieldsBy}
            onChange={(value) => updateFilterState(DISPLAY_SETTINGS_GROUP_FIELDS_BY, value)}
          />
        ))}
      </ListSection>

      <ListSection headline={I18n.t('showCropsWith')}>
        {showCropsWithOptions.map(option => (
          <ListItemCheck
            key={option.label}
            option={option}
            selected={option.value === showCropsWith}
            onChange={(value) => updateFilterState(DISPLAY_SETTINGS_SHOW_CROPS_WITH, value)}
          />
        ))}
      </ListSection>

      <div>
        <ListSection>
          {showSharedByCompanyOptions.map(option => (
            <ListItemToggle
              key={option.label}
              option={option}
              checked={showSharedByCompany}
              onChange={(checked) => updateFilterState(DISPLAY_SETTINGS_SHOW_SHARED_BY_COMPANY, checked)} />
          ))}
        </ListSection>

        <ListSection>
          {showProducerNamesOptions.map(option => (
            <ListItemToggle
              key={option.label}
              option={option}
              checked={showProducerNames}
              onChange={(checked) => updateFilterState(DISPLAY_SETTINGS_SHOW_PRODUCER_NAMES, checked)} />
          ))}
        </ListSection>

        <ListSection>
          {fertilizingOptions.map(option => (
            <ListItemToggle
              key={option.label}
              option={option}
              checked={showFertilizationResult}
              onChange={(checked) => updateFilterState(DISPLAY_SETTINGS_SHOW_FERTILIZATION_RESULT, checked)} />
          ))}
        </ListSection>
      </div>
    </FilterList>
  );
};

const selector = (state, ownProps) => {
  const cropState = ownProps.cropState || 'active';
  const openCompany = selectors.getOpenCompanyId(state);
  const settings = get(
    state,
    ['filtersByCompany', openCompany, 'displaySettings', cropState],
  );
  const sortFieldsBy = settings?.sortFieldsBy ?? DISPLAY_SETTINGS_SORT_FIELDS_BY_FIELD_NAME;
  const groupFieldsBy = settings?.groupFieldsBy ?? DISPLAY_SETTINGS_GROUP_FIELDS_BY_CROP_NAME;
  const showFertilizationResult = settings?.showFertilizationResult ?? false;
  const showProducerNames = settings?.showProducerNames ?? false;
  const showSharedByCompany = settings?.showSharedByCompany ?? false;
  const displaySettings = state.filtersByCompany[openCompany]
    ? state.filtersByCompany[openCompany].displaySettings
    : {};
  const showCropsWith = settings?.showCropsWith ?? DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA;

  return {
    openCompany,
    cropState,
    displaySettings,
    sortFieldsBy,
    groupFieldsBy,
    showFertilizationResult,
    showProducerNames,
    showSharedByCompany,
    showCropsWith,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          filters,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(Filters as any);
