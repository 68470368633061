import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import '@fortawesome/fontawesome-pro/css/all.css';
import { Redirect } from 'react-router';

import { getFieldsView } from 'farmerjoe-common/lib/selectors/fields';

import { getEmployeesView } from './actions/employeesTable';
import { classes } from './utils/dom';
import { isMapPage } from './utils/page';
import { TableListViewState } from './flowTypes';

import CompanyLoader from './containers/CompanyLoader';
import Employees from './containers/Employees/Employees';
import InviteLoader from './containers/InviteLoader';
import LatestActivitiesContainer from './components/LatestActivities/LatestActivitiesContainer';
import Profile from './containers/Profile/index';
import SingleGroup from './containers/Groups/SingleGroup';
import fieldsContainer from './containers/Fields';

import ActivityTracker from './components/Users/ActivityTracker';
import Analyses from './components/Analysis/Analyses';
import Analysis from './components/Analysis/Analysis';
import ApiKeys from './components/ApiKeys/ApiKeys';
import ApiLog from './components/ApiKeys/ApiLog';
import Archive from './components/Archive/Archive';
import AuthAction from './components/LoginAndRegistration/AuthAction';
import Billing from './components/Billing';
import Bonitur from './components/Bonitur/Bonitur';
import Bonituren from './components/Bonitur/Bonituren';
import CachingPrivateRoute from './components/Router/CachingPrivateRoute';
import CachingSwitch from './components/Router/CachingSwitch';
import Card from './components/Page/Card';
import ColumnLayoutPage from './components/Page/ColumnLayoutPage';
import Company from './components/Company/Company';
import EmployeesTable from './components/Employees/Table/EmployeesTable';
import Field from './components/Field/Field';
import FieldCollaborator from './components/FieldCollaborator';
import FieldSharingRequest from './components/FieldsSharing/FieldSharingRequest';
import FieldSharingRequests from './components/FieldsSharing/FieldSharingRequests';
import FieldTable from './components/Fields/Table/FieldTable';
import Fields from './components/Fields/Fields';
import FieldsMap from './components/Map/FieldsMap';
import Invites from './components/Companies/Invites';
import LocaleLoader from './language/LocaleLoader';
import Login from './components/LoginAndRegistration/Login';
import NoCompanySelected from './components/Companies/NoCompanySelected';
import Notifications from './components/Notifications/NotificationList';
import PrivateRoute from './components/Router/PrivateRoute';
import ProducersTable from './components/Groups/Table/ProducersTable';
import RecoverEmail from './components/LoginAndRegistration/RecoverEmail';
import Register from './components/LoginAndRegistration/Register';
import RequestPassword from './components/LoginAndRegistration/RequestPassword';
import ResetPassword from './components/LoginAndRegistration/ResetPassword';
import TradersTable from './components/Groups/Table/TradersTable';
import VerifyEmail from './components/LoginAndRegistration/VerifyEmail';
import { useLoadGoogleMapsApiScript } from './components/Map/GoogleMapWithLoader';
import FailedPaymentPage from './pages/FailedPayment.page';
import { DataProvider as CompanyEmployeesContext } from './contexts/CompanyEmployeesProvider';
import UnauthorizedAccessToCompanyPage from './pages/UnauthorizedAccessToCompany.page';

const FieldsLoader = fieldsContainer(({ children }) => children);

const FieldComponent = ({ match }) => {
  if (!match) {
    return null;
  }
  const {
    params: { companyKey, fieldKey, cropKey },
  } = match;
  return (
    <Card className="no-padding" bodyProps={{ style: { height: '100%' } }}>
      <Field companyId={companyKey} fieldId={fieldKey} crop_id={cropKey} />
    </Card>
  );
};
let NoFieldSelectedComponent,
  NoEmployeeSelectedComponent,
  NoAnalysisSelectedComponent;
NoFieldSelectedComponent =
  NoEmployeeSelectedComponent =
  NoAnalysisSelectedComponent =
    () => {
      return <div style={{ padding: 0, border: '1px solid transparent' }} />;
    };
const ProfileComponent = ({ match }) => {
  if (!match) {
    return null;
  }
  const {
    params: { employeeKey, groupId },
  } = match;
  return (
    <Card className="no-padding">
      <Profile uid={employeeKey} groupId={groupId} />
    </Card>
  );
};

function FloatingProfileComponent({ match }) {
  if (!match) return null;

  const {
    params: { employeeKey, groupId },
  } = match;
  return (
    <Card className="no-padding">
      <Profile uid={employeeKey} groupId={groupId} isFloating={true} />
    </Card>
  );
}

const FieldArchiveComponent = ({
  match: {
    params: { companyKey, fieldKey },
  },
}) => {
  return (
    <FieldsLoader companyId={companyKey}>
      <Card className="no-padding" bodyProps={{ style: { height: '100%' } }}>
        <Field companyId={companyKey} fieldId={fieldKey} />
      </Card>
    </FieldsLoader>
  );
};
const AnalysisComponent = ({
  match: {
    params: { companyKey, analysisKey },
  },
}) => {
  return (
    <Card className="no-padding">
      <Analysis analysisId={analysisKey} companyId={companyKey} />
    </Card>
  );
};

function FieldsComponent({ location: { pathname } }) {
  return (
    <Card className="no-padding" key={0}>
      <Fields isMapPage={isMapPage(pathname)} />
    </Card>
  );
}

function FieldTableComponent({ location: { pathname } }) {
  return (
    <Card className="no-padding card-flex-column" key={0}>
      <FieldTable isMapPage={isMapPage(pathname)} />
    </Card>
  );
}

function EmployeesTableComponent({ match }) {
  const {
    params: { employeeKey },
  } = match;

  return (
    <Card className="no-padding card-flex-column" key={0}>
      <EmployeesTable selectedEmployee={employeeKey} />
    </Card>
  );
}

const FloatingFieldComponent = ({ match, location: { pathname } }) => {
  if (!match) {
    return null;
  }
  const {
    params: { companyKey, fieldKey, cropKey },
  } = match;
  return (
    <div className="sliding-column-container">
      <Card className="no-padding" bodyProps={{ style: { height: '100%' } }}>
        {fieldKey ? (
          <Field
            companyId={companyKey}
            fieldId={fieldKey}
            crop_id={cropKey}
            isFloating={true}
            isMapPage={isMapPage(pathname)}
          />
        ) : null}
      </Card>
    </div>
  );
};

function FieldsMapComponent({ match, forcererenderprop }) {
  if (!match) {
    return null;
  }
  const {
    params: { companyKey },
  } = match;
  return (
    <FieldsLoader companyId={companyKey}>
      <FieldsMap forceRerenderProp={forcererenderprop} />
    </FieldsLoader>
  );
}

function EmployeesComponent({ match }) {
  if (!match) {
    return null;
  }
  const {
    params: { employeeKey, companyKey },
  } = match;
  return (
    <Card className="no-padding">
      <FieldsLoader companyId={companyKey}>
        <Employees selectedEmployee={employeeKey} />
      </FieldsLoader>
    </Card>
  );
}

function ArchiveComponent({ match: { companyKey } }) {
  return (
    <FieldsLoader companyId={companyKey}>
      <Card className="no-padding">
        <Archive />
      </Card>
    </FieldsLoader>
  );
}

function CompanyInfoComponent() {
  return (
    <Card className="no-padding">
      <Company />
    </Card>
  );
}

function CompanyBillingComponent({ match }) {
  if (!match) {
    return null;
  }

  const {
    params: { companyKey },
  } = match;

  return (
    <Card className="no-padding">
      <Billing companyId={companyKey} />
    </Card>
  );
}

function AnalysesComponent({ match }) {
  if (!match) {
    return null;
  }
  const {
    params: { analysisKey },
  } = match;
  return (
    <Card className="no-padding">
      <Analyses selectedAnalysis={analysisKey} />
    </Card>
  );
}

function LatestActivitiesComponent() {
  return (
    <Card className="no-padding">
      <LatestActivitiesContainer />
    </Card>
  );
}

function ApiKeysComponent() {
  return (
    <div className="api-keys-card-wrapper no-padding">
      <Card className="no-padding">
        <ApiKeys />
      </Card>
      <Card className="no-padding">
        <ApiLog />
      </Card>
    </div>
  );
}

function NotificationsComponent({ match: { companyKey } }) {
  return (
    <Card className="no-padding" key={0}>
      <Notifications openCompany={companyKey} />
    </Card>
  );
}

// XXX: Deprecated
// function ProducersComponent({ match }) {
//   if (!match) {
//     return null;
//   }
//   const {
//     params: { companyKey },
//   } = match;
//   return (
//     <Card className="no-padding">
//       <FieldsLoader companyId={companyKey}>
//         <Groups type={"producer"} />
//       </FieldsLoader>
//     </Card>
//   );
// }

function ProducersTableComponent({ match }) {
  const {
    params: { producerKey },
  } = match;

  return (
    <Card className="no-padding card-flex-column" key={0}>
      <ProducersTable groupId={producerKey} type="producer" />
    </Card>
  );
}

function ProducerComponent({ match }) {
  if (!match) return null;

  const {
    params: { producerKey },
  } = match;
  return (
    <Card className="no-padding">
      <SingleGroup groupId={producerKey} type="producer" />
    </Card>
  );
}

// XXX: Deprecated
// function TradersComponent({ match }) {
//   if (!match) {
//     return null;
//   }
//   const {
//     params: { companyKey },
//   } = match;
//   return (
//     <Card className="no-padding">
//       <FieldsLoader companyId={companyKey}>
//         <Groups type={"trader"} />
//       </FieldsLoader>
//     </Card>
//   );
// }

function TradersTableComponent({ match }) {
  const {
    params: { traderKey },
  } = match;

  return (
    <Card className="no-padding card-flex-column" key={0}>
      <TradersTable groupId={traderKey} type="trader" />
    </Card>
  );
}

function TraderComponent({ match }) {
  if (!match) return null;

  const {
    params: { traderKey },
  } = match;
  return (
    <Card className="no-padding">
      <SingleGroup groupId={traderKey} type="trader" />
    </Card>
  );
}

function FormTableComponent() {
  return (
    <Card className="no-padding card-flex-column crop-rating-table" key={0}>
      <Bonituren />
    </Card>
  );
}

const FloatingFormComponent = ({ match }) => {
  if (!match) {
    return null;
  }
  const {
    params: { companyKey, formKey },
  } = match;
  return (
    <div className="sliding-column-container">
      <Card className="no-padding" bodyProps={{ style: { height: '100%' } }}>
        {formKey ? <Bonitur companyId={companyKey} formId={formKey} /> : null}
      </Card>
    </div>
  );
};

function FieldSharingRequestsComponent() {
  return (
    <Card className="no-padding">
      <FieldSharingRequests />
    </Card>
  );
}

function FieldSharingRequestComponent({ match }) {
  if (!match) {
    return null;
  }
  const {
    params: { companyKey, fieldSharingRequestId },
  } = match;

  if (!fieldSharingRequestId) {
    return null;
  }

  return (
    <Card className="no-padding">
      <FieldSharingRequest fieldSharingRequestKey={fieldSharingRequestId} />
    </Card>
  );
}

function FieldCollaboratorComponent({ match }) {
  if (!match) {
    return null;
  }

  const {
    params: { companyKey, fieldKey, collaboratorKey },
  } = match;

  if (!collaboratorKey) {
    return null;
  }
  return (
    <Card className="no-padding">
      <FieldCollaborator
        companyId={companyKey}
        fieldId={fieldKey}
        collaboratorId={collaboratorKey}
        key="floating-collaborator"
      />
    </Card>
  );
}

const CompanyPage = compose(
  connect((state: any, ownProps: any) => {
    const {
      params: { companyKey },
    } = ownProps.match;
    return {
      companyKey,
      match: ownProps.match,
      fieldsView: getFieldsView(state, companyKey),
      employeesView: getEmployeesView(state, companyKey),
      showMapFields: state.showMapFields,
    };
  }),
)(function CompanyPage(props) {
  const {
    companyKey,
    fieldsView,
    showMapFields,
    employeesView,
  } = props;

  return (
    <CompanyEmployeesContext>
      <ColumnLayoutPage>
        <CachingSwitch>
          {fieldsView === TableListViewState.Table ? (
            <CachingPrivateRoute
              path="/company/:companyKey/field/:fieldKey?"
              render={FieldTableComponent}
              className="unlimited-column"
              key="field-table"
            />
          ) : (
            <CachingPrivateRoute
              path="/company/:companyKey/field/:fieldKey?"
              render={FieldsComponent}
              key="fields"
              className="fields-wrapper"
            />
          )}
          {fieldsView === TableListViewState.Classic ? (
            <CachingPrivateRoute
              path="/company/:companyKey/field"
              exact
              component={NoFieldSelectedComponent}
              className="wide-column"
              key="no-field"
            />
          ) : null}
          {fieldsView === TableListViewState.Table ? (
            <CachingPrivateRoute
              path="/company/:companyKey/field/:fieldKey?/:cropKey?"
              exact
              component={FloatingFieldComponent}
              className="sliding-column"
              classNameBasedOnProps={({ match }) =>
                match && match.params.fieldKey ? 'open' : ''
              }
              key="floating-field"
            />
          ) : (
            <CachingPrivateRoute
              path="/company/:companyKey/field/:fieldKey/:cropKey?"
              exact
              component={FieldComponent}
              className="wide-column"
              key="field"
            />
          )}

          <CachingPrivateRoute
            path="/company/:companyKey/producer/:producerKey?"
            render={ProducersTableComponent}
            className="unlimited-column"
            renderFresh={true}
          />

          <CachingPrivateRoute
            path="/company/:companyKey/producer/:producerKey"
            exact
            component={ProducerComponent}
            className="wide-column"
            renderFresh={true}
          />

          <CachingPrivateRoute
            path="/company/:companyKey/trader/:traderKey?"
            render={TradersTableComponent}
            className="unlimited-column"
            renderFresh={true}
          />

          <CachingPrivateRoute
            path="/company/:companyKey/trader/:traderKey"
            exact
            component={TraderComponent}
            className="wide-column"
            renderFresh={true}
          />

          {employeesView === TableListViewState.Table ? (
            <CachingPrivateRoute
              path={'/company/:companyKey/employee/:employeeKey?'}
              render={EmployeesTableComponent}
              className="unlimited-column"
            />
          ) : (
            <CachingPrivateRoute
              path="/company/:companyKey/employee/:employeeKey?"
              render={EmployeesComponent}
              className="medium-column"
            />
          )}

          {employeesView === TableListViewState.Classic ? (
            <CachingPrivateRoute
              path="/company/:companyKey/employee"
              exact
              component={NoEmployeeSelectedComponent}
              className="wide-column"
            />
          ) : null}

          {employeesView === TableListViewState.Table ? (
            <CachingPrivateRoute
              path="/company/:companyKey/employee/:employeeKey/:groupId"
              exact
              component={FloatingProfileComponent}
              className="wide-column"
            />
          ) : (
            <CachingPrivateRoute
              path="/company/:companyKey/employee/:employeeKey/:groupId"
              exact
              component={ProfileComponent}
              className="wide-column"
            />
          )}

          <CachingPrivateRoute
            path="/company/:companyKey/archive/:fieldKey?"
            component={ArchiveComponent}
          />
          <CachingPrivateRoute
            path="/company/:companyKey/archive"
            exact
            component={NoFieldSelectedComponent}
            className="wide-column"
          />
          <CachingPrivateRoute
            path="/company/:companyKey/archive/:fieldKey"
            exact
            component={FieldArchiveComponent}
            className="wide-column"
          />

          <CachingPrivateRoute
            path="/company/:companyKey/info"
            exact
            component={CompanyInfoComponent}
          />

          <CachingPrivateRoute
            path="/company/:companyKey/billing"
            exact
            component={CompanyBillingComponent}
            className="wide-column"
          />

          <CachingPrivateRoute
            path="/company/:companyKey/analysis/:analysisKey?"
            component={AnalysesComponent}
          />
          <CachingPrivateRoute
            path="/company/:companyKey/analysis"
            exact
            component={NoAnalysisSelectedComponent}
            className="wide-column"
          />
          <CachingPrivateRoute
            path="/company/:companyKey/analysis/:analysisKey"
            exact
            component={AnalysisComponent}
            className="wide-column"
          />

          {fieldsView === TableListViewState.Table ? (
            <CachingPrivateRoute
              path={'/company/:companyKey/map/:fieldKey?'}
              render={FieldTableComponent}
              className={classes('map-fields-column', showMapFields && 'open')}
              key="field-table-map"
            />
          ) : (
            <CachingPrivateRoute
              path={'/company/:companyKey/map/:fieldKey?'}
              render={FieldsComponent}
              className={classes(
                'map-fields-column',
                'map-fields-column-field-list',
                showMapFields && 'open',
              )}
              key="fields-map"
            />
          )}

          <CachingPrivateRoute
            path="/company/:companyKey/map"
            component={FieldsMapComponent}
            className="map-column"
          />

          <CachingPrivateRoute
            path={'/company/:companyKey/map/:fieldKey?/:cropKey?'}
            exact
            component={FloatingFieldComponent}
            className="map-field-column sliding-column"
            classNameBasedOnProps={({ match }) =>
              match && match.params.fieldKey ? 'open' : ''
            }
            key="field-map"
          />

          <CachingPrivateRoute
            path="/company/:companyKey/latest/:fieldKey?"
            component={LatestActivitiesComponent}
          />
          <CachingPrivateRoute
            path="/company/:companyKey/latest"
            exact
            component={NoFieldSelectedComponent}
            className="wide-column"
          />
          <CachingPrivateRoute
            path="/company/:companyKey/latest/:fieldKey"
            exact
            component={FieldComponent}
            className="wide-column"
          />

          <CachingPrivateRoute
            path="/company/:companyKey/form/:formKey?"
            render={FormTableComponent}
            className="unlimited-column"
          />
          <CachingPrivateRoute
            path="/company/:companyKey/form/:formKey?"
            exact
            component={FloatingFormComponent}
            className="sliding-column"
            classNameBasedOnProps={({ match }) =>
              match && match.params.formKey ? 'open' : ''
            }
          />

          <CachingPrivateRoute
            path="/company/:companyKey/apikeys"
            component={ApiKeysComponent}
            className="api-keys-column unlimited-column"
          />
          <CachingPrivateRoute
            path="/company/:companyKey/notifications"
            component={NotificationsComponent}
          />
          <CachingPrivateRoute
            path="/company/:companyKey/field-sharing-requests"
            component={FieldSharingRequestsComponent}
          />
          <CachingPrivateRoute
            path="/company/:companyKey/field-sharing-requests/:fieldSharingRequestId?"
            exact
            component={FieldSharingRequestComponent}
            className="wide-column"
          />
          <CachingPrivateRoute
            path="/company/:companyKey/field/:fieldKey/collaborators/:collaboratorKey"
            exact
            component={FieldCollaboratorComponent}
            className="wide-column"
          />
          <Redirect to={`/company/${companyKey}/field`} />
        </CachingSwitch>
      </ColumnLayoutPage>
    </CompanyEmployeesContext>
  );
});

class CompanyRoute extends React.PureComponent<{
  match: { params: Record<string, any> };
}> {
  render() {
    const {
      match: {
        params: { companyKey },
      },
      match,
    } = this.props;
    return (
      <CompanyLoader openKey={companyKey}>
        <CompanyPage match={match} />
        <ActivityTracker />
      </CompanyLoader>
    );
  }
}

function App() {
  // Certain parts of the application need access to Google Maps API
  // before the map is rendered, therefore we're loading it at app start
  useLoadGoogleMapsApiScript();

  const render = () => {
    return (
      <React.Fragment>
        <LocaleLoader>
          <Switch>
            <PrivateRoute
              path="/"
              exact
              component={(props) => {
                return [
                  <ColumnLayoutPage key={0}>
                    <div className="show">
                      <NoCompanySelected />
                    </div>
                  </ColumnLayoutPage>,
                  <ActivityTracker key={2} />,
                ];
              }}
            />

            <PrivateRoute
              path="/company/:companyKey/failed-payment"
              exact
              component={(props) => {
                return (
                  <ColumnLayoutPage>
                    <FailedPaymentPage />
                  </ColumnLayoutPage>
                );
              }}
            />

            <PrivateRoute
              path="/invites"
              exact
              component={(props) => {
                return [
                  <InviteLoader key={0}>
                    <ColumnLayoutPage>
                      <Card className="no-padding show">
                        <Invites />
                      </Card>
                    </ColumnLayoutPage>
                  </InviteLoader>,
                  <ActivityTracker key={2} />,
                ];
              }}
            />
            <PrivateRoute
              path="/company/:companyKey"
              component={CompanyRoute}
            />
            <Route path="/(login|logout)" exact component={Login as any} />
            <Route
              path="/forgotpassword"
              exact
              component={RequestPassword as any}
            />
            <Route path="/register" exact component={Register as any} />

            {/* AuthAction redirects to other routes */}
            <Route path="/auth-action" component={AuthAction} />
            <Route
              path="/resetpassword/:actionCode"
              exact
              component={({
                match: {
                  params: { actionCode },
                },
              }) => <ResetPassword actionCode={actionCode} />}
            />
            <Route
              path="/verifyemail/:actionCode"
              exact
              component={({
                match: {
                  params: { actionCode },
                },
              }) => <VerifyEmail actionCode={actionCode} />}
            />
            <Route
              path="/recoveremail/:actionCode"
              exact
              component={({
                match: {
                  params: { actionCode },
                },
              }) => <RecoverEmail actionCode={actionCode} />}
            />
            <Route
              path="/unauthorized"
              exact
              component={(props) => {
                return (
                  <ColumnLayoutPage>
                    <UnauthorizedAccessToCompanyPage />
                  </ColumnLayoutPage>
                );
              }}
            />
            <Redirect to="/" />
          </Switch>
        </LocaleLoader>
      </React.Fragment>
    );
  };

  return render();
}

export default App;
