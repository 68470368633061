import React from 'react';
import Modal from 'react-bootstrap-modal';
import I18n from '../../language/i18n';

const stopPropagation = e => e.stopPropagation();

const PlanUpgradeModal = (props) => {
  const { show, onClose, children, footer, title=I18n.t('billing.upgradePlan') } = props;
  return (
    <Modal
      show={show}
      dialogClassName="billing-upgrade-plan"
      onHide={onClose}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title onClick={stopPropagation}>{title}</Modal.Title>
        <button className="close" onClick={onClose}>
          <i className="fa fa-times" />
        </button>
      </Modal.Header>
      <Modal.Body onClick={(e) => e.stopPropagation()}>
        {children}
      </Modal.Body>
      {footer ? (
        <Modal.Footer onClick={stopPropagation}>{footer}</Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default PlanUpgradeModal;
