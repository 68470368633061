import  React from 'react';
import { compose } from 'redux';
import { isEqual, some } from 'lodash-es';
import MutationObserver from 'mutation-observer';
import { connect } from 'react-redux';

import { getColor } from 'farmerjoe-common/lib/utils/Colors';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { NotACropState } from 'farmerjoe-common/lib/flow/types';

import CurrentLocationMarker from './CurrentLocationMarker';
import GoogleMapWithLoader from './GoogleMapWithLoader';
import MarkerBonitur from './MarkerBonitur';
import MarkerCompany from './MarkerCompany';
import MarkerWithLabel from './MarkerWithLabel';
import MarkerProducer from './MarkerProducer';

import Icon from '../Common/Icon';
import withRouter from '../Router/withRouter';

import {
  appPosToLatLng,
  calcZoom,
  getRegionForCoordinates,
  regionToBounds,
} from '../../utils/Map';
import type { LatLng, MarkerWithClick } from '../../flowTypes';
import { FJNEWGREEN } from '../../styles/style';

type Props = {
  position: LatLng;
  markers: MarkerWithClick[];
  filterNotACrop?: boolean;
  zoomedIn?: boolean;
  onClick?: (...args: Array<any>) => any;
  mapStyles?: any;
  navigate: boolean;
  openCompany?: string;
  history?: Record<string, any>;
  containerStyle?: Record<string, any>;
  mapRef?: (map: google.maps.Map) => any;
  locationPermission?: boolean;
  children?: React.ReactNode;
};
type State = {
  region: LatLng & {
    latitudeDelta: number;
    longitudeDelta: number;
  };
  mapType: string;
  markers: MarkerWithClick[];
};

class Map extends React.PureComponent<Props, State> {
  static defaultProps = {
    filterNotACrop: true,
    zoomedIn: false,
    navigate: false,
  };

  mapRef?: google.maps.Map | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      mapType: 'hybrid',
      ...this.createStateForProps(props),
    };
  }

  createStateForProps(props: Props) {
    let state = {
      region: {
        latitude: props.position.latitude,
        longitude: props.position.longitude,
        latitudeDelta: 0.001244134067952984,
        longitudeDelta: 0.004945160735246645,
      },
      markers: [] as any[],
    };

    if (props.markers.length) {
      let markers = props.markers;

      if (props.filterNotACrop) {
        markers = markers.filter(marker => {
          return marker.activeCrop && marker.activeCrop.not_a_crop !== NotACropState.NotACrop;
        });
      }
      if (markers.length) {
        state = {
          ...state,
          markers: markers,
          region: {
            ...getRegionForCoordinates(markers.map(marker => marker.position)),
          },
        };
        if (props.zoomedIn) {
          state.region = {
            ...state.region,
            latitudeDelta: 0.001244134067952984,
            longitudeDelta: 0.004945160735246645,
          };
        }
      }
    }
    return state;
  }

  componentWillUpdate(nextProps) {
    if (
      !isEqual(this.props.position, nextProps.position) ||
      !isEqual(this.props.zoomedIn, nextProps.zoomedIn) ||
      !isEqual(this.props.markers, nextProps.markers)
    ) {
      this.setState({
        ...this.state,
        ...this.createStateForProps(nextProps),
      });
    }
  }

  render() {
    const { onClick, navigate, containerStyle, mapRef, children } = this.props;
    return (
      <div style={{ height: '100%', ...containerStyle }}>
        <GoogleMap1
          mapRef={ref => {
            if (!this.mapRef) {
              this.mapRef = ref;
              mapRef && mapRef(ref);
              this.forceUpdate();
            }
          }}
          region={this.state.region}
          mapContainerClassName="map-container"
          mapContainerStyle={{ height: '100%' }}
          mapTypeId={this.state.mapType}
          onClick={() => onClick && onClick()}
        >
          {this.renderMarkers()}
          {this.props.locationPermission && <CurrentLocationMarker />}
          {children}
        </GoogleMap1>

        {navigate ? (
          <div
            onClick={() => {
              // const {position, markers} = this.props
              // const latlng              = position.latitude.toFixed(4) + ',' + position.longitude.toFixed(4)
              // const locTitle            = markers.length === 1 && markers[0].title ? markers[0].title : 'Field'
              //
              // if (Platform.OS === 'android') {
              //   Linking.openURL(`geo:${latlng}?q=${latlng}(${locTitle})`)
              // }
            }}
            style={{
              position: 'absolute',
              bottom: 0,
              right: 10,
              zIndex: 20,
              paddingTop: 2,
              marginBottom: 10,
              backgroundColor: 'rgba(255, 255, 255, 1)',
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 20,
            }}>
            <Icon
              name={'ios-navigate-outline'}
              style={{ fontSize: 25, backgroundColor: 'transparent' }}
            />
          </div>
        ) : null}
      </div>
    );
  }

  renderMarkers() {
    // TODO: fix
    // MarkerWithLabel can have MarkerClusterer instead of map
    // In that case, precense of mapRef is not required
    if (!this.mapRef) {
      return [];
    }

    const { markers } = this.state;
    const icons = {
      0: 'leaf',
      1: '',
      2: 'time',
    };
    const mapped = markers.map(marker => {
      if (marker.type === 'company') {
        return (
          <MarkerCompany
            map={this.mapRef as google.maps.Map}
            key={marker.key}
            marker={marker}
            onClick={e => {
              e.stopPropagation();
              this.props.history?.push(`/company/${this.props.openCompany}`);
            }}
          />
        );
      }
      if (marker.type === 'bonitur') {
        return (
          <MarkerBonitur
            map={this.mapRef as google.maps.Map}
            key={marker.key}
            marker={marker}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        );
      }

      if (marker.type === 'producer') {
        return (
          <MarkerProducer
            key={marker.key}
            marker={marker}
            mapRef={this.mapRef as google.maps.Map} />
        );
      }

      const markerColor = getColor(marker.activeCrop?.color);
      return (
        <MarkerWithLabel
          key={marker.key}
          position={appPosToLatLng(marker.position) as any}
          pinColor={markerColor}
          width={17}
          height={16}
          map={this.mapRef as google.maps.Map}
        >
          <div
            style={{
              width: 17,
              height: 17,
              backgroundColor: markerColor,
              borderRadius: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {icons[marker.activeCrop?.not_a_crop as any]
              ? (
                <Icon
                  iconType={'fj'}
                  name={icons[marker.activeCrop?.not_a_crop as any]}
                  style={{
                    fontSize: 11,
                    backgroundColor: 'transparent',
                  }}
                />
              )
              : null}
          </div>
        </MarkerWithLabel>
      );
    });

    return mapped;
  }
}

export default compose<typeof Map>(
  withRouter,
  connect((state: any) => ({
    openCompany: getOpenCompanyId(state),
    locationPermission: state.locationPermission,
  })),
)(Map);

function removeTabIndex(el) {
  ;[].slice
    .apply(el.querySelectorAll('a, button, [tabindex], iframe'))
    .forEach(function(item: any) {
      item.setAttribute('tabIndex', '-1');
    });
}

type MapProps = {
  children: Array<any>;
  mapRef?: (map: google.maps.Map) => any;
  region?: Record<string, any>;
  defaultRegion?: Record<string, any>;
  options?: Record<string, any>;
  noTabIndex?: boolean;
  onMapTypeIdChanged?: (arg0: string) => void;
  mapContainerClassName?: string;
  mapContainerStyle?: React.CSSProperties;
  mapTypeId?: string;
  onClick?: () => any;
  defaultCenter?: any;
  defaultZoom?: number;
  onDrag?: () => any;
  onBoundsChanged?: () => any;
  onUnmount?: (map: google.maps.Map) => any;
};
type MapState = {
  options: Record<string, any>;
};

class GoogleMap1 extends React.Component<MapProps, MapState> {
  firstRegionSet = false;
  map: google.maps.Map | null = null;

  lastMapTypeId: string | null = null;

  state = {
    options: {
      styles: style,
      ...this.props.options,
      fullscreenControl: false,
      mapTypeControlOptions: {
        position: (window.google).maps.ControlPosition.RIGHT_TOP,
        style: (window.google).maps.MapTypeControlStyle.HORIZONTAL_BAR,
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: (window.google).maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: (window.google).maps.ControlPosition.RIGHT_BOTTOM,
      },
      mapTypeId: 'hybrid',
    },
  };

  disableTabIndexListener: any;
  mutationObserver: MutationObserver;

  onUnmount(map) {
    if (this.props.onUnmount) {
      this.props.onUnmount(map);
    }
    this.map = null;
  }

  componentWillUpdate(nextProps) {
    if (nextProps.options !== this.props.options) {
      this.setState({
        options: {
          styles: style,
          ...nextProps.options,
          fullscreenControl: false,
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.disableTabIndexListener) {
      (window as any).google.maps.event.removeListener(this.disableTabIndexListener);
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  render() {
    const {
      children,
      mapRef,
      region,
      defaultRegion,
      options,
      noTabIndex,
      onMapTypeIdChanged,
      ...restProps
    } = this.props;

    return (
      <GoogleMapWithLoader
        onLoad={this.onRef}
        onProjectionChanged={this.onProjectionChanged}
        center={this.props.defaultCenter}
        zoom={this.props.defaultZoom}
        options={this.state.options}
        onMapTypeIdChanged={this.onMapTypeIdChanged}
        {...restProps}>
        {children}
      </GoogleMapWithLoader>
    );
  }

  onRef = (mapReference: google.maps.Map) => {
    const { noTabIndex, mapRef } = this.props;

    if (this.props.mapRef) {
      this.props.mapRef(mapReference);
    }

    if (mapReference) {
      this.map = mapReference;
      this.setRegion();

      if (noTabIndex) {
        this.disableTabIndex();
      }

      if (mapRef) {
        mapRef(mapReference);
      }
    }
  };

  onProjectionChanged = () => {
    this.setRegion();
  };

  setRegion = () => {
    const map = this.map;
    const { region, defaultRegion } = this.props;

    if ((!region && !defaultRegion) || !map || !map.getProjection()) {
      return;
    }

    const projection = map.getProjection();
    if (!projection) {
      throw Error('The map is not initialized yet');
    }
    const bounds = regionToBounds((region || defaultRegion) as any);
    const ne = projection.fromLatLngToPoint(bounds.getNorthEast() as any) as google.maps.Point;
    const sw = projection.fromLatLngToPoint(bounds.getSouthWest() as any) as google.maps.Point;
    const latFraction = Math.abs(ne.y - sw.y) / 256;
    const lngFraction = Math.abs(ne.x - sw.x) / 256;

    const mapHeight = (map.getDiv() as any).offsetHeight;
    const mapWidth = (map.getDiv() as any).offsetWidth;

    const latZoom = calcZoom(mapHeight, 256, latFraction);
    const lngZoom = calcZoom(mapWidth, 256, lngFraction);

    const zoom = Math.min(latZoom, lngZoom, 21);
    const center = bounds.getCenter();

    if (region || (defaultRegion && !this.firstRegionSet)) {
      if (
        zoom !== map.getZoom() ||
        !map.getCenter()?.equals(center as any)
      ) {
        map.setZoom(zoom);
        map.setCenter(center as any);
        // map.panToBounds(bounds)
      }
      this.firstRegionSet = true;
    }
  };

  /**
   * This method sets tabindex attributes to -1 on the map elements
   * Because google maps doesn't seem to have an event when the map is completely ready (including having rendered controls),
   * we use a mutation observer to wait for the controls to render.
   */
  disableTabIndex = () => {
    const map = this.map;
    removeTabIndex(map?.getDiv());
    this.disableTabIndexListener = (window as any).google.maps.event.addListener(
      map,
      'idle',
      () => {
        const parentToControls = map?.getDiv().querySelectorAll('.gm-style')[0];
        if (!parentToControls || this.mutationObserver) {
          return;
        }
        this.mutationObserver = new MutationObserver(
          (mutationList, observer) => {
            for (const mutation of mutationList) {
              if (
                mutation.type === 'childList' &&
                some(mutation.addedNodes, n =>
                  n.classList.contains('gmnoprint'),
                )
              ) {
                setTimeout(removeTabIndex.bind(null, map.getDiv()), 0);
                (window as any).google.maps.event.removeListener(
                  this.disableTabIndexListener,
                );
                observer.disconnect();
                break;
              }
            }
          },
        );
        this.mutationObserver.observe(parentToControls, { childList: true });
      },
    );
  };

  onMapTypeIdChanged = () => {
    if (!this.props.onMapTypeIdChanged) {
      return;
    }
    if (!this.map) {
      // the map has not initialized yet, ignore the call
      return;
    }
    const mapTypeId = this.map.getMapTypeId();
    if (!mapTypeId) {
      return;
    }

    // The Mapping library is firing the onMapTypeIdChanged twice when the mapTypeId is changed
    // To prevent redundant re-rendering and to stay consistent
    // with the before-migration-to-typescript-version behavior,
    // we'll propogate this event only once
    if (this.lastMapTypeId === mapTypeId) {
      return;
    }

    this.props.onMapTypeIdChanged(mapTypeId);
    this.lastMapTypeId = mapTypeId;
  };
}

// const GoogleMap2 = compose<typeof GoogleMap1>(
//   withGoogleMapScriptImport(),
//   // withGoogleMap
// )(GoogleMap1)

export { GoogleMap1 as GoogleMap };

export const style = [
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#ffffff',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#0d0d0d',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
      {
        color: '#d81717',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#151414',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 21,
      },
      {
        color: '#2c2a2a',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 19,
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 17,
      },
    ],
  },
];

export const fieldPolygonOptions = {
  strokeColor: 'red',
  fillColor: 'rgba(0,0,255,0.5)',
  clickable: false,
  strokeWeight: 2,
};

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4,
};
export const measurePolylineOptions = {
  strokeColor: FJNEWGREEN,
  strokeWeight: 4,
  strokeOpacity: 0,
  icons: [
    {
      icon: lineSymbol,
      offset: '0',
      repeat: '20px',
    },
  ],
};
