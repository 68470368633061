import React, { useState, useRef } from 'react';
import { firebaseConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'libphonenumber-js';
import t from 'tcomb-form';
import { SIGNUP_STATUS_DONE } from 'farmerjoe-common/lib/constants/signup';

import { Loading } from '../Loading/Loading';
import SelectLanguage from '../Settings/SelectLanguage';
import CoverImage from '../Settings/CoverImage';
import Avatar from '../Settings/Avatar';
import Dialog, { AlertDialog } from '../Dialog/Dialog';

import ProfileModel from '../../tcomb/models/profile';
import * as constants from '../../styles/style';
import * as profileActions from '../../actions/profile';
import I18n from '../../language/i18n';

import './style.css';


const Form = t.form.Form;

// TODO: Move those interfaces somewhere elsle
interface Props {
  owner?: any; // Profile
  onClose: any;
  actions?: any;
  show: boolean;
};

interface ErrorDialog {
  alertMessage: string | null;
  alertTitle: string | null;
};

interface UserProfile {
  email: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  user_id?: string;
};

interface OnSaveCallbacks {
  displayError: (errorDialog: ErrorDialog) => any;
  storeUser: (userProfile: UserProfile) => any;
  completeProfile: (status: string) => any;
  showForm: (formShown: boolean) => any;
  runLoader: (isRunning: boolean) => any;
};

const ProfileForm = (props: Props) => {
  const { owner, show, onClose, actions } = props;

  const [ userData, setUserData ] = useState(owner);
  const [ formError, setFormError] = useState<ErrorDialog>({
    alertMessage: null,
    alertTitle: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const componentRef = useRef(null);

  const onSaveCbs: OnSaveCallbacks = {
    displayError: setFormError,
    storeUser: actions.accountEdit,
    completeProfile: actions.updateSignupStatus,
    showForm: onClose,
    runLoader: setIsLoading,
  };

  return (
    <>
      <Dialog
        show={show}
        onClose={onClose}
        className={'account-edit'}
        title={I18n.t('account.account')}
        footer={
          <div className="d-flex flex-grow-1">
            <button
              className="ml-auto btn btn-secondary"
              disabled={!owner.isLoaded}
              onClick={onClose}>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              disabled={!owner.isLoaded}
              onClick={() => {
                onSave(componentRef, owner.uid, onSaveCbs);
              }}>
              {I18n.t('save')}
            </button>
          </div>
        }>
        <div className="settings">
          <div className="cover-avatar-container">
            <CoverImage />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: -50,
                flexDirection: 'column',
              }}>
              <Avatar />
            </div>
          </div>
          <div
            className={''}
            style={{
              borderBottomWidth: 1,
              borderBottomColor: constants.FJBORDERCOLOR,
            }}>
            <div
              className={
                'd-flex flex-column align-items-center justify-content-center pb-3'
              }>
              <div style={{ fontSize: 22 }}>{owner.displayName}</div>
              <div className="text-muted">
                {owner.phoneNumber
                  ? format(owner.phoneNumber, 'INTERNATIONAL')
                  : null}
              </div>
              <div className="text-muted">
                {owner.email ? owner.email : null}
              </div>
            </div>
          </div>
          <div>
            <div className={'text-muted'}>{I18n.t('language')}</div>
            <SelectLanguage />
          </div>
          <div style={{ ...styles.box, ...{ padding: 0 } }}>
            <Form
              ref={componentRef}
              type={ProfileModel.model()}
              options={() => ProfileModel.options(this)}
              value={userData}
              onChange={(data) => {
                setUserData({...userData, ...data});
              }}
            />
          </div>
        </div>
        {!owner.isLoaded || isLoading
          ? (
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                position: 'absolute',
                justifyContent: 'center',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Loading />
            </div>
          )
          : null}

        <AlertDialog
          show={!!formError.alertMessage}
          onClose={() =>
            setFormError({ alertMessage: null, alertTitle: null })
          }
          title={formError.alertTitle}
          key="alert"
        >
          {formError.alertMessage}
        </AlertDialog>
      </Dialog>
    </>
  );
};

const onSave = (componentRef, uid, cbs: OnSaveCallbacks) => {
  const formData = componentRef.current.getValue();
  if (formData === null) {
    cbs.displayError({
      alertMessage: I18n.t('please_correct_your_entry'),
      alertTitle: I18n.t('error'),
    });
    return;
  }
  const user = {
    user_id: uid,
    ...formData,
  };

  cbs.runLoader(true);
  cbs.storeUser(user)
    .then(() => {
      cbs.runLoader(false);
      cbs.completeProfile(SIGNUP_STATUS_DONE);
      cbs.showForm(false);
    })
    .catch(e => {
      cbs.runLoader(false);
      cbs.displayError({
        alertMessage: e.message,
        alertTitle: I18n.t('error'),
      });
    });
};

const selector = (state) => {
  let owner = state.firebase.profile;
  const { profile: signupProfile, status: signupStatus } = state.signup;

  if (signupStatus === 'ACTIVE') {
    owner = {...owner, ...signupProfile};
  }

  return {
    owner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...profileActions,
        },
      ),
      dispatch,
    ),
  };
};

export default (connect(selector, mapDispatchToProps)(ProfileForm));

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
  },
  box: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: constants.FJBORDERCOLOR,
    paddingTop: 10,
    paddingBottom: 10,
    padding: 20,
  },
};

