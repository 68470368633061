import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import * as fieldActions from 'farmerjoe-common/lib/actions/field';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getOpenFieldId } from 'farmerjoe-common/lib/selectors/selectors';
import { getArchivedFields } from 'farmerjoe-common/lib/selectors/fields';
import { sortFields } from 'farmerjoe-common/lib/utils/FieldsSorter';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { FieldState } from 'farmerjoe-common/lib/flow/types';

import NoResults from '../Common/NoResults';
import FlatList from '../Common/FlatList';
import NavbarBasic from '../Common/NavbarBasic';
import SingleField from '../Fields/SingleField';
import withRouter from '../Router/withRouter';
import { Loading } from '../Loading/Loading';
import I18n from '../../language/i18n';
import { classes } from '../../utils/dom';
import fieldsContainer from '../../containers/Fields';

import './style.css';

// TODO: improve typings
type Props = any;
type State = any;

const Archive = (props) => {
  const { fields, loading } = props;

  if (loading) {
    return <Loading />;
  }

  const _renderRow = (field) => {
    const selected = props.openFieldId === field.key;
    return (
      <div
        key={'field' + field.field_id}
        onClick={() => {
          props.actions.openField(field.key);
          props.history.push(
            `/company/${props.company.key}/archive/${field.key}`,
          );
        }}
        className={classes('list-item archive-item', selected && 'selected')}>
        <SingleField
          field={field}
          crop={field.activeCrop}
          waitTimes={[]}
          lastComment={field.activeCrop.lastComment}
        />
      </div>
    );
  };

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <NavbarBasic title={I18n.t('archive.archive')} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: 3,
          marginBottom: 3,
        }}>
      </div>
      <FlatList
        className="archive-list"
        initialListSize={19}
        data={fields}
        renderItem={({ item }) => _renderRow(item)}
        ListEmptyComponent={() => (
          <NoResults text={I18n.t('archive.noFieldsInArchive')} />
        )}
      />
    </div>
  );
};


const selector = state => {
  const openCompany = selectors.getOpenCompanyId(state);

  const fields = getArchivedFields(state, openCompany);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  sortFields(fields, 'modified');

  return {
    openCompany,
    company: getCompany(state.firestore.data, openCompany),
    fields,
    openFieldId: getOpenFieldId(state),
    browsingGroup,
    myCompanyProfile,
    fieldState: FieldState.Archived,
    companyId: openCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
        },
      ),
      dispatch,
    ),
  };
};

export default compose<typeof Archive>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  fieldsContainer,
  withRouter,
)(Archive);
