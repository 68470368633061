import React, { useCallback } from 'react';
import { get } from 'lodash-es';
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './style.css';

const createSliderWithTooltip = RCSlider.createSliderWithTooltip;
const SliderWithTooltip = createSliderWithTooltip(RCSlider);

const Slider = ({ config, formik, value = 0, error }) => {
  const { name, attributes, label } = config;
  const { setFieldValue, handleBlur } = formik;

  const min = get(config, 'options.min', 0);
  const max = get(config, 'options.max', 100);
  const valueLabel = get(config, 'options.valueLabel', '');

  const valueForSlider = Number.isNaN(Number(value))
    ? Number(min)
    : Number(value);

  const handleInputOnChange = useCallback(event => {
    setFieldValue(name, event.target.value);
  }, [name, setFieldValue]);

  const handleInputOnBlur = useCallback(event => {
    let value = parseFloat(String(event.target.value.replace(',', '.')));

    if (isNaN(value)) {
      return;
    }
    if (value <= min) {
      value = min;
    } else if (value >= max) {
      value = max;
    }
    setFieldValue(name, value);

    handleBlur();
  }, [handleBlur, max, min, name, setFieldValue]);

  const handleInputOnSlide = useCallback(value => {
    setFieldValue(name, value);
  }, [name, setFieldValue]);

  return (
    <>
      <div className={'fj-slider-header'}>
        <label>{label}</label>
        <div style={{ position: 'relative' }}>
          <span className={'fj-slider-suffix'}>{valueLabel}</span>
          <input
            id={name}
            name={name}
            type={'text'}
            value={value}
            className={'fj-slider-input' + (error ? ' is-invalid ' : '')}
            placeholder={value || 0}
            onChange={handleInputOnChange}
            onBlur={handleInputOnBlur}
            {...attributes}
          />
        </div>
      </div>
      <div
        className={`fj-slider-container flex-row ${
          error ? 'fj-form-error' : ''
        }`}>
        <div className={'fj-slider-component d-flex flex-column'}>
          <SliderWithTooltip
            min={min}
            max={max}
            value={value === 0 ? min : valueForSlider}
            step={1}
            onChange={handleInputOnSlide}
          />
          <div className='fj-slider-footer'>
            <span>{min}</span>
            <span>{max}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Slider);
