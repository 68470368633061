import { useEffect, useState, useContext } from 'react';
import fetch from '../utils/fetch';
import { getUidToken } from '../utils/auth';
import { BASE_API_URL } from '../constants';
import { DataContext } from '../contexts/CompanyEmployeesProvider';

type UserState = 'active' | 'deactivated' | 'all';

const useCompanyEmployees = (companyId: string, groupId: string, userState: UserState) : any => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const { refreshTrigger } = useContext(DataContext);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        const uidToken = await getUidToken();
        const response = await fetch(
          `${BASE_API_URL}/companies/${companyId}/employees`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${uidToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ groupId, userState }),
            signal: controller.signal,
          });

        if (!response.ok) {
          throw new Error('Failed to fetch employees');
        }
        const json = await response.json();
        setEmployees(json);
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      controller.abort();
    };
  }, [companyId, groupId, userState, refreshTrigger]);

  return [employees, loading];
};

export default useCompanyEmployees;
