import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { get } from 'lodash-es';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import {
  getSortedFields,
} from 'farmerjoe-common/lib/selectors/fields';
import { getFieldsQueries } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';
import { getCompanyQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Companies';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { FieldState } from 'farmerjoe-common/lib/flow/types';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { filters } from 'farmerjoe-common/lib/actions/actions';

import FieldsList from '../Fields/CropFieldsList';
import SingleField from '../Fields/SingleField';
import Icon from '../Common/Icon';
import SearchInput from '../Common/SearchInput';

import * as constants from '../../styles/style';

type fieldSelection = Record<string, boolean>;

// TODO: improve typings
type Props = {
  selected: fieldSelection;
  sortedFields: any;
  filter: any;
  onSelectField: (...args: any) => any;
  excludedField?: any;
  showSearchBar?: boolean;
  openCompany?: string;
  actions: {
    filters: (...args: any) => any;
  };
};
type State = any;

class FieldSelector extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.renderField = this.renderField.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  onChangeText(text) {
    const { openCompany } = this.props;
    this.props.actions.filters(openCompany, {
      search: text,
    });
  }

  render() {
    const { sortedFields, filter } = this.props;
    const search = get(filter, 'search', '');

    return (
      <div>
        {this.props.showSearchBar ? (
          <SearchInput
            key={'searchField'}
            onChange={this.onChangeText}
            search={search}
          />
        ) : null }
        <FieldsList
          fields={sortedFields}
          filter={filter}
          renderField={this.renderField}
          allFieldsOpen={true}
          extraData={this.props.selected}
        />
      </div>
    );
  }

  renderField({ item }) {
    const field = item.item;
    const { selected } = this.props;
    return (
      <div key={'field' + field.key}>
        <div
          onClick={() => {
            this.props.onSelectField(field);
          }}
          className={
            'd-flex justify-content-between cursor-pointer list-item crop-fields-item'
          }>
          <div className={'d-flex'} style={{width: '100%'}}>
            <SingleField
              field={field}
              crop={field.activeCrop}
              waitTimes={[]}
              lastComment={field.activeCrop.lastComment}
            />
          </div>
          <div
            style={{ width: 40 }}
            className={'d-flex justify-content-center'}>
            {selected[field.key]
              ? (
                <Icon
                  name={'ios-checkmark-circle'}
                  style={{ fontSize: 25, color: constants.FJAPPLEBLUE }}
                />
              )
              : (
                <Icon
                  name={'ios-radio-button-off'}
                  style={{ fontSize: 25, color: constants.FJAPPLEBLUE }}
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  const filterOptions = ownProps.excludedField
    ? { excludeField: { key: ownProps.excludedField.key } }
    : {};

  const sortedFields = get(
    getSortedFields(
      state,
      openCompany,
      // @ts-ignore
      state.firebase.auth.uid,
      filterOptions,
    ), 'sections', []);

  const loading = !hasLoaded(
    [
      ...getFieldsQueries(openCompany, myCompanyProfile, browsingGroup, FieldState.Active),
      getCompanyQuery(openCompany),
    ],
    state,
  );

  return {
    openCompany,
    sortedFields,
    filter: state.filtersByCompany[openCompany],
    loading,
    browsingGroup,
    myCompanyProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          filters,
        },
      ),
      dispatch,
    ),
  };
};

const wrappedFieldSelector = firestoreConnect(props => {
  const companyId = props.openCompany;
  const { browsingGroup, myCompanyProfile } = props;

  return [
    ...getFieldsQueries(companyId, myCompanyProfile, browsingGroup, FieldState.Active),
    getCompanyQuery(companyId),
  ];
})(FieldSelector);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedFieldSelector);

