import t from 'tcomb-form';

import stylesheet from '../../stylesheets/style';
import { defaultOptions } from '../../utils/options';

import I18n from '../../../language/i18n';
import modalCountry from '../../templates/Country/Country';
import Tel from '../../validators/tel';

const model = t.struct({
  name: t.String,
  street: t.String,
  zip: t.String,
  city: t.String,
  country: t.String,
  email: t.maybe(t.String),
  tel: t.maybe(Tel),
});

const modelBolap = t.struct({
  name: t.String,
  street: t.String,
  zip: t.String,
  city: t.String,
  country: t.String,
  email: t.maybe(t.String),
  tel: t.maybe(Tel),
  bolapClientId: t.maybe(t.String),
});

const options = function(isBolap: boolean) {
  const defaultFields =  {
    stylesheet: stylesheet,
    auto: 'none',
    fields: {
      name: {
        ...defaultOptions(I18n.t('name'), true),
      },
      street: {
        ...defaultOptions(I18n.t('street'), true),
      },
      zip: {
        ...defaultOptions(I18n.t('zip'), true),
      },
      city: {
        ...defaultOptions(I18n.t('city'), true),
      },
      country: {
        ...defaultOptions(I18n.t('country'), true),
        factory: modalCountry,
      },
      email: {
        ...defaultOptions(I18n.t('email')),
      },
      tel: {
        ...defaultOptions(I18n.t('phone')),
      },
    },
  };


  if (isBolap) {
    return {
      ...defaultFields,
      fields: {
        ...defaultFields.fields,
        bolapClientId: {
          ...defaultOptions('BOLAP Kundennummer'),
        },
      },
    };
  }

  return defaultFields;
};

export default { model, modelBolap, options };
