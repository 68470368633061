import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { filters } from 'farmerjoe-common/lib/actions/actions';
import './style.css';
import I18n from '../../language/i18n';
import Icon from '../Common/Icon';
import * as constants from '../../styles/style';

type Props = {
  openCompany: string;
  filter: {
    cropAgeMin?: number | null;
    cropAgeMax?: number | null;
  };
  actions: {
    filters: (...args: Array<any>) => any;
  };
};

const CropAgeFilter = (props: Props) => {
  const { openCompany, filter, actions } = props;
  const [min, setCropAgeMin] = useState(filter.cropAgeMin);
  const [max, setCropAgeMax] = useState(filter.cropAgeMax);

  const onChangeMin = e => {
    const minValue = e.target.value;
    const value = parseInt(minValue);

    if (isNaN(value)) {
      setCropAgeMin(null);
    } else {
      setCropAgeMin(value);
    }
  };

  const onChangeMax = e => {
    const maxValue = e.target.value;
    const value = parseInt(maxValue);

    if (isNaN(value)) {
      setCropAgeMax(null);
    } else {
      setCropAgeMax(value);
    }
  };

  const onClear = () => {
    setCropAgeMin(null);
    setCropAgeMax(null);
  };

  const setFilters = useCallback(
    () => {
      actions.filters(openCompany, {
        cropAgeMin: min !== null ? parseInt(min as any) : null,
        cropAgeMax: max !== null ? parseInt(max as any) : null,
      });
    }, [actions, openCompany, min, max]);

  useEffect(() => {
    setFilters();
  }, [min, max, setFilters]);

  return (
    <div className="crop-age-filter">
      <span>{I18n.t('from')}</span>
      <input
        value={min !== null ? min : ''}
        onChange={onChangeMin}
        className="form-control"
        type="text"
      />
      <span>{I18n.t('to')}</span>
      <input
        value={max !== null ? max : ''}
        onChange={onChangeMax}
        className="form-control"
        type="text"
      />
      {min !== null || max !== null
        ? (
          <span className="clear" onClick={onClear}>
            <Icon
              name="md-close"
              style={{
                color: constants.FJBORDERCOLOR,
                fontSize: '1.4em',
              }}
            />
          </span>
        )
        : null}
    </div>
  );
};

const defaultFilter = {
  cropAgeMin: null,
  cropAgeMax: null,
};

export default compose(
  connect(
    (state: any) => {
      const openCompany = selectors.getOpenCompanyId(state);
      return {
        openCompany,
        filter: state.filtersByCompany[openCompany]
          ? state.filtersByCompany[openCompany]
          : defaultFilter,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          filters,
        },
        dispatch,
      ),
    }),
  ),
)(CropAgeFilter);
