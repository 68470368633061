import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';

import { getFormSchema } from 'farmerjoe-common/lib/selectors/forms';
import { calculate } from 'farmerjoe-common/lib/utils/math';
import {
  NonSupportedRenderer,
  supportedContentRenderers as supportedRenderers,
} from '../../Bonitur/ContentRenderers';
import styles from './DataCell.module.css';
import { FJNEWGREEN, FJORANGE, FJYELLOW, FJBLACK } from '../../../styles/style';
import { classes } from '../../../utils/dom';

type Props = {
  schemaId: string;
  elementId: string;
  values?: Record<string, any>;
  schemaConfig?: any; // FormSchema
  createdDate?: Date;
  renderedBy: 'table' | 'details' | 'comment' | 'bonitur';
};

class BoniturDataCell extends React.PureComponent<Props> {
  render() {
    const { schemaConfig, elementId, values, createdDate, renderedBy } =
      this.props;

    if (!schemaConfig) {
      return null;
    }

    const elements = schemaConfig.schema.elements;

    const element = elements[elementId];
    const renderer = element.renderer;
    const value = get(values, [elementId], '');
    element.className = element[`${renderedBy}Class`];
    const RenderComponent =
      supportedRenderers[renderer] || NonSupportedRenderer;

    const isNitrateCell: boolean = element.name === 'nitrate';
    const isAnerkennungCell: boolean = element.name === 'anerkennungNumber' || element.name === 'anerkennungNote';
    const isBooleanRenderer: boolean = renderer === 'fj-boolean';
    const isTextRenderer: boolean = renderer === 'fj-text';
    const hasColorSchemes: boolean = element.options.colorSchemes;
    const hasCalculationInTable: boolean = !!element.options.calculationInTable;

    let nitrateColorStyle: string = '';

    if (isNitrateCell) {
      switch (true) {
      case value <= 2500:
        nitrateColorStyle = FJNEWGREEN;
        break;
      case value >= 2500 && value <= 2750:
        nitrateColorStyle = FJYELLOW;
        break;
      case value >= 2750:
        nitrateColorStyle = FJORANGE;
        break;
      default:
        nitrateColorStyle = FJBLACK;
        break;
      }
    }

    const calculatedValue = hasCalculationInTable ? calculate(element.options.calculationInTable, values) : value;

    return (
      <div
        className={classes(
          isNitrateCell ? styles.containerNitrate : styles.container,
          isAnerkennungCell || isBooleanRenderer || isTextRenderer ? styles.containerLeftAlignedNormal : '',
        )}
        style={{
          color: isNitrateCell ? nitrateColorStyle : 'black',
        }}
      >
        <RenderComponent
          element={element}
          value={hasCalculationInTable ? calculatedValue : value}
          key={elementId}
          valueOnly={true}
          createdDate={createdDate}
          isBooleanColorSchemes={isBooleanRenderer && hasColorSchemes}
        />
      </div>
    );
  }
}

const selector = (state, ownProps) => {
  const schemaId = ownProps.schemaId;
  const elementId = ownProps.elementId;
  const schemaConfig = getFormSchema(state, schemaId);

  return {
    elementId,
    schemaConfig,
  };
};

export default compose(connect(selector))(BoniturDataCell);
