import React from 'react';
import { get } from 'lodash-es';
import './style.css';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { filters } from 'farmerjoe-common/lib/actions/actions';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import DateRangeFilterBase from '../DateRangeFilter/DateRangeFilter';

import I18n from '../../language/i18n';

type TProps = {
  date: string;
  onSelect: (...args: any) => any;
};

const DateRangeFilter = (props: TProps) => {
  const { date } = props;

  const ranges = [
    { value: 'today', label: I18n.t('moments.today') },
    { value: 'yesterday', label: I18n.t('moments.yesterday') },
    { value: 'last:7', label: I18n.t('lastXDays', { days: 7 }) },
    { value: 'last:30', label: I18n.t('lastXDays', { days: 30 }) },
    { value: 'last:90', label: I18n.t('lastXDays', { days: 90 }) },
    { value: 'last:365', label: I18n.t('lastXDays', { days: 365 }) },
    { value: 'custom', label: I18n.t('customRange') },
  ];

  return (
    <div style={{width: '100%'}}>
      <DateRangeFilterBase
        onSave={props.onSelect}
        date={date}
        ranges={ranges}
      />
    </div>
  );
};

export default compose(
  connect(
    (state: any) => {
      const openCompany = getOpenCompanyId(state);
      return {
        openCompany: openCompany,
        date: get(
          state,
          ['filtersByCompany', openCompany, 'displaySettings', 'latestActivity', 'date'],
          'today',
        ),
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          filters,
        },
        dispatch,
      ),
    }),
  ),
)(DateRangeFilter);
