import React, { useState, useRef } from 'react';
import t from 'tcomb-form';
import moment from 'moment';
import fbase from 'firebase/app';

import { NotACropState , CropType, CropState, FieldState } from 'farmerjoe-common/lib/flow/types';
import { toDate } from 'farmerjoe-common';
import { dummy } from 'farmerjoe-common/lib/utils/Comment';
import { getNoCrop } from 'farmerjoe-common/lib/actions/actionConstants';
import {
  isFieldOwner,
  hasCollaborators,
} from 'farmerjoe-common/lib/utils/Field';

import Dialog, { AlertDialog } from '../../../Dialog/Dialog';
import HarvestForm from './forms/HarvestForm';

import I18n from '../../../../language/i18n';
import * as styleConstants from '../../../../styles/style';

import firebase from '../../../../data/firebase';


type TProps = {
  show: boolean;
  onClose: (...args: any) => void;
  fields: any[];
  selectedFields: string[];
  openCompany: string;
};

const Form = t.form.Form;
const HarvestingForm = (props: TProps) => {
  const { show, onClose, fields, selectedFields, openCompany } = props;

  const [harvested, setHarvested] = useState({ harvestedOn: new Date()});
  const [alert, setAlert] = useState({ message: null, title: null });
  const formRef = useRef(null);

  const targetFields = fields
    .filter((field) =>
      selectedFields.includes(field.key),
    ).filter((field) => field.activeCrop.state === CropState.Planted &&
      field.activeCrop.not_a_crop === NotACropState.HarvestedCrop);

  const onSave = () => {
    const harvestedOn = moment(toDate(harvested.harvestedOn));
    const db = firebase.firestore();
    const batch = db.batch();
    for (const field of targetFields) {
      harvestField(batch, field, harvestedOn, openCompany);
    }
    batch.commit().catch((error) => {
      setAlert({ message: error.message, title: I18n.t('error') });
    });
    onClose();
  };


  return (
    <Dialog
      show={show}
      onClose={onClose}
      title={I18n.t('field.harvested')}
      footer={
        <div className="d-flex flex-grow-1">
          <button className="ml-auto btn btn-secondary" onClick={onClose}>
            {I18n.t('cancel')}
          </button>{' '}
          <button
            className="btn btn-primary"
            onClick={onSave}>
            {I18n.t('save')}
          </button>
        </div>
      }>
      <div style={{ flex: 1 }}>
        <div>
          <span style={{ ...styleConstants.styles.boxHeader }}></span>
          <div
            style={{
              ...styleConstants.styles.box,
              ...{ paddingLeft: 0, paddingRight: 0 },
            }}>
          </div>
        </div>

        <Form
          ref={formRef}
          type={HarvestForm.model()}
          options={() => HarvestForm.options()}
          value={harvested}
          onChange={setHarvested}
        />

        <AlertDialog
          show={!!alert.message}
          onClose={() =>
            setAlert({ message: null, title: null })
          }
          title={alert.title}
        >
          {alert.message}
        </AlertDialog>
      </div>
    </Dialog>
  );
};


const harvestedComment = (batch, field, oldActiveCropUuid, harvestedOn) => {
  const auth = firebase.auth().currentUser;
  const commentRef = firebase
    .firestore()
    .collection('comments')
    .doc();
  const oldCropRef = firebase
    .firestore()
    .collection('crops')
    .doc(oldActiveCropUuid);

  const text = {
    languageConstant: 'field.harvested_on',
    date: harvestedOn.toDate(),
  };

  // Add comment
  const comment = {
    ...dummy(
      commentRef.id,
      field.company_id,
      field,
      field.activeCrop,
        auth as any,
        'system.crop.harvested',
    ),
    text: text,
  };

  batch.set(oldCropRef, { lastComment: comment }, { merge: true });
  batch.set(commentRef, comment);
};

const handleAnnualCrop = (batch, field, harvestedOn) => {
  const auth = firebase.auth().currentUser;
  const db = firebase.firestore();
  const fieldRef = db.collection('fields').doc(field.key);
  const commentRef = db.collection('comments').doc();

  const cropRef = db.collection('crops').doc();

  const newActiveCrop = {
    ...getNoCrop(cropRef.id, auth),
    field_id: field.key,
    company_id: field.company_id,
  };

  // Add comment
  const comment = {
    ...dummy(
      commentRef.id,
      field.company_id,
      field,
      newActiveCrop,
        auth as any,
        'system.crop.withoutSince',
    ),
    text: {
      languageConstant: 'crop.field_without_crop_since',
      date: harvestedOn.toDate(),
    },
  };

  batch.set(cropRef, newActiveCrop);
  batch.set(commentRef, comment);
  batch.update(fieldRef, {
    activeCrop: newActiveCrop,
  });
};

const handlePermanentCrop = (batch, field, oldCrop) => {
  const auth = firebase.auth().currentUser;
  const db = firebase.firestore();
  const cropRef = db.collection('crops').doc();
  const commentRef = db.collection('comments').doc();
  const fieldRef = db.collection('fields').doc(field.key);

  const newActiveCrop = {
    ...oldCrop,
    field_id: field.key,
    company_id: field.company_id,
    sown_on: oldCrop.sown_on,
    last_harvested_on: oldCrop.harvested_on,
    harvested_on: null,
    key: cropRef.id,
    state: CropState.Planted,
    // reset properties
    analysis: null,
    waittimes: null,
    lastComment: null,
  };

  // Add comment
  const comment = {
    ...dummy(
      commentRef.id,
      field.company_id,
      field,
      newActiveCrop,
        auth as any,
        'system.crop.sown',
    ),
    text: { languageConstant: 'crop.isActiveNow' },
  };

  batch.set(cropRef, newActiveCrop);
  batch.set(commentRef, comment);
  batch.update(fieldRef, {
    activeCrop: newActiveCrop,
  });
};

const getAbandonedField = (
  field,
  fieldRefId: string,
  cropRefId: string,
  companyId: string,
  force_create: boolean,
) =>  {
  const { activeCrop, activeCrops, collaborators, key: realFieldId, ...fieldRest } = field;

  const abandonedField = {
    ...fieldRest,
    company_id: companyId,
    state: FieldState.Abandoned,
    realFieldId: realFieldId,
    abandonedCropId: cropRefId,
    key: fieldRefId,
    force_create,
  };

  return abandonedField;
};

const getAbandonedCrop = (
  abandonedField,
  oldActiveCrop,
  fieldRefId: string,
  cropRefId: string,
  companyId: string,
  force_create: boolean,
) => {
  const { activeCrop, abandonedCropId, ...abandonedFieldRest } =
      abandonedField;

  const abandonedCrop = {
    ...oldActiveCrop,
    field_id: fieldRefId,
    field: {
      ...abandonedFieldRest,
    },
    key: cropRefId,
    company_id: companyId,
    state: CropState.Abandoned,
    realCropId: oldActiveCrop.key,
    force_create,
  };

  return abandonedCrop;
};

// XXX: for partially planted crops on field
// This is the sub field, copied from the base field
// with id baseFieldId
// In this case, we don't handle permanentCrop or normalCrop
// We just need to harvest the field and free up the area form the base field
const handleSubField = (batch, field) => {
  const db = firebase.firestore();
  const { key, baseFieldId, activeCrop } = field;
  const fieldRef = db.collection('fields').doc(key);
  const baseFieldRef = db.collection('fields').doc(baseFieldId);
  batch.set(baseFieldRef, {
    usedCropArea: {
      [activeCrop.key]: fbase.firestore.FieldValue.delete(),
    },
  }, { merge: true });
  batch.set(fieldRef, {
    state: FieldState.Abandoned,
  }, { merge: true });
};

const createAbandonedField = (batch, field, companyId, oldActiveCrop, forceCreate) => {
  const db = firebase.firestore();
  const abandonedFieldRef = db.collection('fields').doc();
  const abandonedFieldRefId = abandonedFieldRef.id;
  const abandonedCropRef = db.collection('crops').doc();
  const abandonedCropRefId = abandonedCropRef.id;

  const abandonedField = getAbandonedField(
    field,
    abandonedFieldRefId,
    abandonedCropRefId,
    companyId,
    forceCreate,
  );
  const abandonedCrop = getAbandonedCrop(
    abandonedField,
    oldActiveCrop,
    abandonedFieldRefId,
    abandonedCropRefId,
    companyId,
    forceCreate,
  );
  batch.set(abandonedCropRef, abandonedCrop);

  const {
    field: { f },
    ...abandonedActiveCrop
  } = abandonedCrop;

  batch.set(abandonedFieldRef, {
    ...abandonedField,
    activeCrop: abandonedActiveCrop,
  });
};

const harvestField = (batch, field, harvestedOn, openCompany) => {
  const { activeCrop } = field;
  const { key: oldUuid } = activeCrop;

  const oldActiveCrop = {
    ...activeCrop,
    field_id: field.key,
    harvested_on: harvestedOn.toDate(),
    state: CropState.Harvested,
    yield: {
      total: null,
      perHa: null,
    },
  };

  const db = firebase.firestore();

  const oldCropRef = db.collection('crops').doc(oldUuid);

  batch.update(oldCropRef, oldActiveCrop);

  harvestedComment(batch, field, oldUuid, harvestedOn);

  const isSubField = !!field.baseFieldId;

  if (!isSubField) {
    if (parseInt(oldActiveCrop.type, 10) === parseInt(CropType.Permanent, 10)) {
      handlePermanentCrop(batch, field, oldActiveCrop);
    } else {
      handleAnnualCrop(batch, field, harvestedOn);
    }
  } else {
    handleSubField(batch, field);
  }


  const userOwnsField = isFieldOwner(field.company_id, openCompany);
  if (!userOwnsField) {
    createAbandonedField(batch, field, openCompany, oldActiveCrop, false);
    return;
  }

  if (hasCollaborators(field)) {
    field.collaborators.forEach((collaboratorId) => {
      createAbandonedField(batch, field, collaboratorId, oldActiveCrop, true);
    });
  }
};

export default HarvestingForm;
