import React from 'react';
import * as constants from '../../styles/style';

type Props = {
  title: string | React.ReactNode;
  subtitle?: string;
  rightText?: string | React.ReactNode;
  rightTextStyle?: React.CSSProperties;
  rightButton?: React.ReactNode;
  onRight?: (...args: Array<any>) => any;
  leftButton?: React.ReactNode;
  leftText?: any;
};

const NavbarBasic = (props: Props) => {
  const { title, subtitle, rightText = '', rightButton = null, onRight, leftButton } =
      props;

  return (
    <div
      className="page-card-top-bar-container"
      style={{...constants.styles.navBar}}
    >
      {leftButton}
      <div
        className={['navbar-basic-title', rightButton ? 'navbar-basic-title--left-aligned' : ''].join(' ')}
      >
        <span
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            color: constants.FJMUTEDDARK,
          }}
        >
          {title}
        </span>
        {subtitle ? (
          <span style={{ fontSize: 12, color: constants.FJMUTEDDARK }}>
            {subtitle}
          </span>
        ) : null}
      </div>
      {rightButton}
      {!!onRight && (
        <div className="top-bar-button" onClick={onRight}>
          {rightText}
        </div>
      )}
    </div>
  );
};

export default NavbarBasic;
