import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { clone } from 'ramda';
import { get } from 'lodash-es';
import t from 'tcomb-form';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import shortid from 'shortid32';


import * as cropActions from 'farmerjoe-common/lib/actions/crop';
import { openField, copyField } from 'farmerjoe-common/lib/actions/field';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { searchForFieldAmongTheUniverse } from 'farmerjoe-common/lib/selectors/fields';
import { getColor } from 'farmerjoe-common/lib/utils/Colors';
import { getFavorites } from 'farmerjoe-common/lib/selectors/favorites';
import { getFavoritesQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Favorites';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { NotACropState } from 'farmerjoe-common/lib/flow/types';
import { isFieldOwner } from 'farmerjoe-common/lib/utils/Field';

import './style.css';
import ActiveCropsDropDown from './ActiveCropsDropDown';
import withRouter from '../Router/withRouter';
import Dialog, { AlertDialog } from '../Dialog/Dialog';
import Favorites from '../Common/Favorites';

import I18n from '../../language/i18n';
import CropModel from '../../tcomb/models/crop';
import { dummy } from '../../utils/Comment';
import { getFieldPath } from '../../utils/page';

import CropArea from '../Field/CropArea';
import PrecropsFromField from '../Field/Precrops';
import usePrecrops from '../../hooks/usePrecrops';
import { definePrecrops }  from '../Field/utils';

const Form = t.form.Form;

// TODO: improve typings
type Props = any;
type State = any;
type ClassProps = {
    onClose: (...args) => any;
    show: boolean;
};

class PlanCrop extends Component<Props | ClassProps, State> {
  cropFormRef: any;

  constructor(props, context) {
    super(props, context);

    const crop = clone(this.props.field.activeCrop);
    this.state = {
      crop: {
        ...crop,
        markedArea: crop.markedArea ? { ...crop.markedArea } : { center: null, polygon: [], areaSize: 0 },
      },
      field: this.props.field,
      select: false,
      renderForm: false,
      alertMessage: null,
      alertTitle: null,
      showMapAreaDialog: false,
      precropsToDelete: [],
    };

    // if we currently don't have a crop clear the name field
    if (crop.not_a_crop === NotACropState.NotACrop) {
      this.state.crop.name = '';
      this.state.crop.color = null;
    }

    if (props.plant && this.state.crop.sown_on) {
      this.state.crop.sown_on = new Date();
    }

    this.cropFormRef = React.createRef();
  }

  onSave() {
    const form = this.cropFormRef.current;

    if (!form) { // we don't have any crop yet
      return;
    }

    const formValue = form.getValue();

    // If value is null, then the form has errors
    if (formValue === null) {
      this.setState({
        alertMessage: I18n.t('please_correct_your_entry'),
        alertTitle: I18n.t('error'),
      });
      return;
    }

    const cropValue = JSON.parse(JSON.stringify(formValue));

    if (this.state.crop.markedArea.polygon.length) {
      this.saveCropOnArea(cropValue);
    } else {
      this.saveCrop(cropValue);
    }
    this.props.onClose();
  }

  // XXX: Save the crop as it is when we haven't specified any area for planting
  // XXX: Save the crop planted on the whole area
  saveCrop(formValue) {
    const { field, plant, auth, precropsFromApi } = this.props;
    const { precrops, ...cropValue } = formValue;

    const db = firebase.firestore();
    const fieldRef = db.collection('fields').doc(field.key);
    const cropRef = db.collection('crops').doc(field.activeCrop.key);
    const batch = db.batch();

    let currentCrop = {
      ...cropValue,
      company_id: field.company_id,
      field_id: field.key,
      key: field.activeCrop.key,
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      not_a_crop: NotACropState.PlannedCrop,
    };

    if (plant) {
      currentCrop = {
        ...currentCrop,
        not_a_crop: NotACropState.HarvestedCrop,
        externalCropId: shortid.generate(),
      };

      const commentRef = db.collection('comments').doc();
      // Add comment
      const comment = {
        ...dummy(
          commentRef.id,
          field.company_id,
          field,
          currentCrop,
          auth,
          'system.crop.sown',
        ),
        text: { languageConstant: 'crop.isActiveNow' },
      };

      batch.set(commentRef, comment);
    }

    batch.update(cropRef, currentCrop);
    batch.set(fieldRef, {
      activeCrop: {
        ...currentCrop,
      },
    }, { merge: true });

    if (cropValue.favorite) {
      this.saveFavorite(currentCrop, field, batch);
    }

    if (precrops) {
      this.savePrecrops(precrops, field, batch, precropsFromApi);
    }

    batch.commit();
  }

  saveCropOnArea(formValue) {
    const { field, plant, auth, actions, precropsFromApi } = this.props;
    const { precrops, ...cropValue } = formValue;

    const db = firebase.firestore();
    const baseFieldRef = db.collection('fields').doc(field.key);
    const copyFieldRef = db.collection('fields').doc();
    const copyFieldKey = copyFieldRef.id;
    const cropRef = db.collection('crops').doc();
    const batch = db.batch();

    const currentCrop = {
      ...cropValue,
      company_id: field.company_id,
      field_id: copyFieldKey,
      key: cropRef.id,
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      // 2 is planned crop
      not_a_crop: plant ? NotACropState.HarvestedCrop : NotACropState.PlannedCrop,
      markedArea: this.state.crop.markedArea,
      lastComment: null,
      externalCropId: plant ? shortid.generate() : null,
    };

    batch.set(cropRef, currentCrop);

    // sanitize base field
    const { baseField, usedCropArea, ...fieldRest } = field;

    const updatedField =  {
      ...fieldRest,
      lastComment: null,
      key: copyFieldKey,
      activeCrop: {
        ...currentCrop,
      },
      baseFieldId: field.key,
    };

    if (plant) {
      const commentRef = db.collection('comments').doc();
      // Add comment
      const comment = {
        ...dummy(
          commentRef.id,
          field.company_id,
          updatedField,
          currentCrop,
          auth,
          'system.crop.sown',
        ),
        text: { languageConstant: 'crop.isActiveNow' },
      };

      batch.set(commentRef, comment);
    }

    batch.set(copyFieldRef, updatedField, { merge: true });

    batch.set(baseFieldRef, {
      ...field,
      baseField: true,
      usedCropArea: {
        [cropRef.id]: {
          markedArea: currentCrop.markedArea,
          activeCropId: currentCrop.key,
          fieldId: copyFieldKey,
          color: currentCrop.color,
        },
      },
    }, { merge: true });

    if (cropValue.favorite) {
      this.saveFavorite(currentCrop, updatedField, batch);
    }

    if (precrops) {
      this.savePrecrops(precrops, field, batch, precropsFromApi);
    }

    batch.commit();

    actions?.openField(copyFieldKey);
    this.props.history?.push(getFieldPath(this.props.openCompany as string, copyFieldKey));
  }

  saveFavorite(currentCrop, field, batch) {
    const db = firebase.firestore();
    const favoriteRef = db.collection('favorites').doc();

    const favorite = {
      name: currentCrop.name + (currentCrop.art ? ` - ${currentCrop.art}` : ''),
      replace: {
        name: currentCrop.name,
        color: currentCrop.color,
        art: currentCrop.art,
      },
      company_id: field.company_id,
      type: 'crops',
      key: favoriteRef.id,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };
    batch.set(favoriteRef, favorite);
  }

  savePrecrops(precrops, field, batch, precropsFromApi) {
    const db = firebase.firestore();
    const fieldRef = db.collection('fields').doc(field.key);
    const { extraPrecrops } = precrops;

    const precropsToAdd = definePrecrops({
      fieldPrecrops: field.precrops,
      precropsFromApi,
      precropsFromForm: extraPrecrops,
      precropsToDelete: this.state.precropsToDelete,
    });

    batch.set(fieldRef, {
      precrops: precropsToAdd,
    }, { merge: true});
  }

  onChange(value) {
    this.setState({
      crop: {
        ...value,
      },
    });
  }

  render() {
    const { plant, favorites, show, onClose, isOwnerOfField, precropsFromApi } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Dialog
        show={show}
        onClose={onClose}
        title={I18n.t(`${this.props.plant ? 'crop.plant' : 'crop.plan'}`)}
        footer={
          <div className="d-flex flex-grow-1">
            <button className="ml-auto btn btn-secondary" onClick={onClose}>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}>
              {I18n.t('save')}
            </button>
          </div>
        }
        className={'plan-crop-dialog'}>
        <div className={'d-flex'}>
          <div>
            <div className="d-flex flex-row justify-content-center crop-fast-selection">
              {favorites
                ? (
                  <Favorites
                    favorites={favorites}
                    onClick={rowData => {
                      this.setState({
                        crop: {
                          ...this.state.crop,
                          name: rowData.replace.name,
                          color: rowData.replace.color,
                          art: rowData.replace.art,
                        },
                      });
                    }}
                    simpleContent={false}
                    renderCustomRow={rowData => (
                      <div
                        className="crop-favorite-row"
                        style={{
                          backgroundColor: getColor(rowData.replace.color),
                        }}>
                        <span>{rowData.name}</span>
                      </div>
                    )}
                    getCustomRowText={rowData => rowData.name}
                  />
                )
                : null}

              <div className={'ml-2'}>
                <ActiveCropsDropDown
                  onClick={rowData => {
                    this.setState({
                      crop: {
                        ...this.state.crop,
                        name: rowData.name,
                        color: rowData.color,
                        art: rowData.art,
                      },
                    });
                  }}
                />
              </div>
            </div>

            <div className={'mt-5'}>
              <Form
                ref={this.cropFormRef}
                type={plant ? CropModel.model() : CropModel.planModel()}
                options={() => CropModel.options({
                  crop: this.state.crop,
                  isOwnerOfField,
                  markAreaComponent: () => {
                    if (!this.state.crop) {
                      return;
                    }

                    const markedArea = get(this.state, 'crop.markedArea', {
                      polygon: [],
                      areaSize: 0,
                      center: null,
                    });

                    return (
                      <CropArea 
                        markedArea={markedArea}
                        field={this.props.field}
                        showDialog={this.state.showMapAreaDialog}
                        onOpen={() => {
                          this.setState({...this.state, showMapAreaDialog: true });
                        }}
                        onClose={() => {
                          this.setState({...this.state, showMapAreaDialog: false });
                        }}
                        onSave={(value) => {
                          this.setState({
                            ...this.state,
                            ...{
                              ...{
                                showMapAreaDialog: false,
                              },
                              ...{
                                crop: {
                                  ...this.state.crop,
                                  markedArea: value,
                                },
                              },
                            },
                          });
                        }}
                      />
                    );
                  },
                  precropsComponent: () => {
                    const { precrops } =  this.props.field;
                    const _precrops = this.state.precropsToDelete.length
                      ? [
                        ...(precrops as any).filter(p => !this.state.precropsToDelete.includes(p)),
                      ]
                      : precrops;

                    const _precropsFromApi = precropsFromApi || []; 

                    return (
                      <PrecropsFromField
                        precrops={_precrops}
                        precropsFromApi={_precropsFromApi}
                        onRemoveUserCrop={(cropName) => {
                          this.setState({...this.state, precropsToDelete: [...this.state.precropsToDelete, cropName]});
                        }}/>
                    );
                  },
                })
                }
                value={this.state.crop}
                onChange={this.onChange.bind(this)}
              />
            </div>
          </div>
          <AlertDialog
            show={!!this.state.alertMessage}
            onClose={() =>
              this.setState({ alertMessage: null, alertTitle: null })
            }
            title={this.state.alertTitle}
          >
            {this.state.alertMessage}
          </AlertDialog>
        </div>
      </Dialog>
    );
  }
}

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompany = selectors.getOpenCompanyId(state);
  const openField = selectors.getOpenFieldId(state);
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, openField);
  const favorites = getFavorites(state, openCompany, 'crops');
  const browsingGroup = getBrowsingGroupKey(state, openCompany);

  const isOwnerOfField = field
    ? isFieldOwner(field.company_id, openCompany)
    : true;

  return {
    openCompany,
    field,
    plant: ownProps.plant,
    favorites: favorites,
    auth: state.firebase.auth,
    browsingGroup,
    isOwnerOfField,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...cropActions,
          copyField,
          openField,
        },
      ),
      dispatch,
    ),
  };
};

const HOC = (props) => {
  const {field, openCompany} = props;
  const { key: fieldId } = field || {};
  const preCrops = usePrecrops(openCompany, fieldId);
  return <PlanCrop {...props} precropsFromApi={preCrops} />;
};

export default compose(
  connect(
    selector,
    mapDispatchToProps,
  ),
  firestoreConnect(props => {
    const paths = [
      getFavoritesQuery(props.openCompany, 'crops', props.browsingGroup),
    ];

    return paths;
  }),
  withRouter,
)(HOC);
