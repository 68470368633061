import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getOpenCompanyId, getEditEmployee } from 'farmerjoe-common/lib/selectors/selectors';
import { getCompanyGroupProfileForLoggedInUser, getGroupUsers} from 'farmerjoe-common/lib/selectors/user';

import NoResults from '../Common/NoResults';
import FlatList from '../Common/FlatList';
import Avatar from '../../containers/Avatar';

import Icon from './Icon';
import Dialog from '../Dialog/Dialog';
import SearchInput from './SearchInput';

import I18n from '../../language/i18n';

import * as constants from '../../styles/style';
import './style.css';

type TUsersSelection = Record<string, boolean>;

type Props = {
  openCompany: string;
  selected: TUsersSelection;
  onSave: (users: TUsersSelection) => {};
  onClose: () => {};
  show?: any;
  companyUsers: any;
};

type State = {
  selected: TUsersSelection;
};

/**
 * A component that displays a screen on which the user is able to
 * select different fields
 */
const UsersSelection = (props: Props) => {
  const { show } = props;
  const [selected, setSelected] = useState(props.selected);
  const [search, setSearch] = useState('');

  const close = () => {
    const { onClose } = props;
    onClose && onClose();
  };

  const onSave = () => {
    props.onSave(selected);
    close();
  };

  const searchInput = () => {
    return (
      <SearchInput
        key={'searchField'}
        onChange={(value) => setSearch(value)}
        search={search}
      />
    );
  };

  const _companyUsers = props.companyUsers.filter((user) => {
    return user.name.toLowerCase().includes(search.toLowerCase());
  });

  const renderEmployee = ({ item }) => {
    const user = item;
    return (
      <div key={'user' + user.key}>
        <div
          onClick={() => {
            setSelected({
              ...selected,
              [user.key]: !selected[user.key],
            });
          }}
          className={
            'd-flex justify-content-between cursor-pointer list-item'
          }>
          <div className={'d-flex'} style={{ width: '100%' }}>
            <div style={{ flex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div
                  style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <Avatar
                      uid={user.key}
                      size={'small'}
                      style={{ borderRadius: 30, backgroundColor: '#EEE' }}
                    />
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <span style={{ fontWeight: 'bold' }}>{user.name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ width: 40 }}
            className={'d-flex justify-content-center'}>
            {selected[user.key]
              ? (
                <Icon
                  name={'ios-checkmark-circle'}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )
              : (
                <Icon
                  name={'ios-radio-button-off'}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      show={show}
      title={I18n.t('acl.users.selectUsers')}
      onClose={close}
      dialogClassName={'employee-select-fields'}
      footer={
        <div className="buttons">
          <button
            className="ml-auto btn btn-secondary"
            onClick={close}>
            {I18n.t('cancel')}
          </button>{' '}
          <button
            className="btn btn-primary"
            onClick={onSave}>
            {I18n.t('done')}
          </button>
        </div>
      }>
      <div className={'d-flex flex-column'}>
        <div style={{ height: 50 }}>{searchInput()}</div>
        <FlatList
          className="employees-list"
          tabLabel={I18n.t('employees.plural')}
          ListEmptyComponent={
            <NoResults text={I18n.t('noSearchResults')} />
          }
          data={_companyUsers}
          renderItem={renderEmployee}
        />
      </div>
    </Dialog>
  );
};

const selector = (state, ownProps) => {
  const openCompany = getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const openEmployee = getEditEmployee(state);
  const companyUsers = getGroupUsers(state, openCompany, 'main').filter(user => user.key !== openEmployee);

  return {
    openCompany,
    selected: ownProps.selected || {},
    browsingGroup,
    myCompanyProfile,
    companyUsers,
  };
};

export default connect(
  selector,
)(UsersSelection);

