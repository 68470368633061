import React from 'react';
import { Avatar } from '../Common/Avatar';
import Icon from '../Common/Icon';
import I18n from '../../language/i18n';

const FieldCollaboratorsList = (props) => {
  const { collaborators, onClick, openCompany } = props;
  if (!collaborators || !collaborators.length) {
    return null;
  }

  return (
    <div>
      <p>
        {I18n.t('fieldSharing.fieldOptions.fieldCollaboratorsText')}
      </p>
      <div>
        {collaborators.map((c, key: string) => {
          const logoURL = c.logo ? c.logo.original.downloadURL : null;
          return (
            <div
              key={key}
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '5px 0'}}
            >
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Avatar
                  downloadUrl={logoURL}
                  style={{ backgroundColor: '#EEE', marginRight: '5px' }}
                  isLoaded={true}
                  size="small"
                />
                <span>
                  {c.name}
                </span>
              </div>
              {c.key !== openCompany ? (
                <div onClick={() => { onClick(c.key); }}>
                  <Icon
                    style={{
                      marginRight: 10,
                      fontSize: 14,
                      cursor: 'pointer',
                    }}
                    name="times"
                    iconType={'fa'}
                  />
                </div>
              ) : null }
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FieldCollaboratorsList;
