import React from 'react';
import './style.css';
import I18n from '../../language/i18n';

const CalendarWeekDifference = (props: {
  cropActiveSince: number;
  cropYieldedAt: number;
}) => {
  const { cropActiveSince, cropYieldedAt } = props;
  const diff = cropYieldedAt - cropActiveSince;
  return (
    <div>
      {diff > 0
        ? (
          <span
            style={{
              fontSize: '14px',
            }}>
            {`${I18n.t('crop.age')} (${I18n.t('crop.calendarWeeks')}): ${diff}`}
          </span>
        )
        : null}
    </div>
  );
};

export default CalendarWeekDifference;
