import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { filters } from 'farmerjoe-common/lib/actions/actions';
import * as fieldActions from 'farmerjoe-common/lib/actions/field';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import SearchInput from '../Common/SearchInput';
import './style.css';
import { isEqual } from 'lodash-es';

// TODO: improve typings
type Props = any;
type State = any;

class MapSearch extends PureComponent<Props, State> {
  static propTypes = {
    search: PropTypes.string,
    displayFilter: PropTypes.bool,
  };

  static defaultProps = {
    displayFilter: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      openMarker: 0,
      filter: props.filter.search,
      onFocus: false,
    };
  }

  componentWillUpdate(nextProps) {
    if (!isEqual(this.props.filter, nextProps.filter)) {
      this.setState({
        filter: nextProps.filter.search,
      });
    }
  }

  render() {
    const { displayFilter } = this.props;
    return displayFilter
      ? (
        <SearchInput
          onChange={text => {
            this.setState({ filter: text, openMarker: 0 });
            this.props.actions.filters(this.props.openCompany, {
              search: text,
            });
          }}
          search={this.state.filter}
          autoCorrect="off"
        />
      )
      : null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
        },
      ),
      dispatch,
    ),
  };
}

const defaultFilter = { search: '', mapType: 'satellite' };
const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);

  return {
    openCompany: openCompany,
    filter: state.filtersByCompany[openCompany]
      ? state.filtersByCompany[openCompany]
      : defaultFilter,
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(MapSearch);
