import React, { useRef, useEffect, forwardRef, useCallback } from 'react';
import { classes } from '../../utils/dom';
import type { DataCellProps } from './flow';

const getTextContent = (el: HTMLDivElement) => {
  if (el) {
    return el.textContent;
  }
  return null;
};

const DataCell = forwardRef<HTMLDivElement, DataCellProps>(function DataCell(props: DataCellProps, ref: any) {
  const {
    column,
    columnClassName,
    columnIndex,
    additionalProps,
    onClick,
    style,
    selected,
    row,
  } = props;

  const internalRef = useRef(null);
  const div = ref || internalRef;

  const value = (column.accessor as any)(row);

  const updateTitleAttribute = useCallback(() => {
    const el = div.current;
    if (el) {
      const text = getTextContent(el);
      if (text) {
        (el as any).setAttribute('title', text);
      }
    }
  }, [div]);

  useEffect(() => {
    updateTitleAttribute();
  }, [row, updateTitleAttribute]);

  return (
    <div
      className={classes(
        'cell',
        columnClassName,
        columnIndex === 0 && 'cell-column-0',
        column.fixed && 'fixed',
        selected && 'selected',
      )}
      style={style as any}
      onClick={() => {
        onClick && onClick(row);
      }}
      ref={div}>
      {column.Cell
        ? (
          column.Cell(value, row, additionalProps)
        )
        : (
          <>{value}</>
        )}
    </div>
  );
});

export default DataCell;
