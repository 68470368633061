import React from 'react';
import 'ionicons/dist/css/ionicons.css';
import { classes } from '../../utils/dom';

const Ionicon = ({ name, style, className }) => (
  <i className={classes(`ion-${name}`, className)} style={style} />
);
const FaIcon = ({ name, style, className }) => (
  <i className={classes(`fa fa-${name}`, className)} style={style} />
);
const FalIcon = ({ name, style, className }) => (
  <i className={classes(`fal fa-${name}`, className)} style={style} />
);
const FjIcon = ({ name, style, className }) => (
  <i className={classes(`fj fj-${name}`, className)} style={style} />
);
const FarIcon = ({ name, style, className }) => (
  <i className={classes(`far fa-${name}`, className)} style={style} />
);

export type FjIconType = 'ion' | 'fa' | 'fal' | 'fj' | 'far'; ;

type Props = {
  name: string;
  iconType?: FjIconType;
  style?: React.CSSProperties;
  iconStyle?: any;
  className?: string;
  light?: any;
  color?: any;
  size?: number;
};

const Icon  = (props: Props) => {
  const { name, iconType = 'ion', className, style = {} } = props;
  let renderedIcon: any = null;

  if (iconType === 'ion') {
    renderedIcon = <Ionicon style={style} name={name} className={className} />;
  }
  if (iconType === 'fa') {
    renderedIcon = <FaIcon style={style} name={name} className={className} />;
  }

  if (iconType === 'fal') {
    renderedIcon = <FalIcon style={style} name={name} className={className} />;
  }

  if (iconType === 'fj') {
    renderedIcon = <FjIcon style={style} name={name} className={className} />;
  }

  if (iconType === 'far') {
    renderedIcon = <FarIcon style={style} name={name} className={className} />;
  }

  return renderedIcon;
};

export default Icon;
