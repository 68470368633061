import React from 'react';
import I18n from '../../../language/i18n';
import Text from '../../Common/Text';
import * as constants from '../../../styles/style';

type CropAgeProps = {
  value?: number;
};

const CropAge = ({ value }: CropAgeProps) => {
  return (value !== undefined || value !== null)
    ? <Text
      style={{
        float: 'right',
        ...constants.styles.small,
      }}>{I18n.t('x_days', { days: value })}</Text>
    : null;
};
export default React.memo(CropAge);
