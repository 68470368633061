import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  getOpenGroupId,
} from 'farmerjoe-common/lib/selectors/groups';

import MarkerWithLabel from './MarkerWithLabel';
import Icon from '../Common/Icon';
import {
  appPosToLatLng,
} from '../../utils/Map';
import type { MarkerWithClick } from '../../flowTypes';

type TProps = {
  openGroupId: string;
  mapRef: any;
  marker: MarkerWithClick;
};

const MarkerProducer = (props: TProps) => {
  const { marker, mapRef, openGroupId } = props;
  const [showBalloon, setShowBalloon] = useState(false);
  const isOpen = openGroupId === marker.key;

  const onMouseEnter = () => {
    setShowBalloon(true);
  };
  const onMouseLeave = () => {
    setShowBalloon(false);
  };

  return (
    <MarkerWithLabel
      key={marker.key}
      position={appPosToLatLng(marker.position) as any}
      width={17}
      height={16}
      map={mapRef}
      onClick={marker.onClick}
    >
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div
          style={{
            width: 25,
            height: 25,
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
          }}

          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Icon
            iconType={'fa'}
            name={'tractor'}
            style={{ fontSize: 18 }}
          />{' '}
        </div>
        {showBalloon || isOpen ? (
          <div style={{
            background: 'white',
            width: 'max-content',
            padding: '0.2em 1em',
            borderRadius: '10px',
            marginTop: '0.2em',
            zIndex: 9999,
          }}>
            {marker.title}
          </div>
        ) : null}
      </div>
    </MarkerWithLabel>
  );
};

const selector = (state) => {
  const openGroupId = getOpenGroupId(state);

  return {
    openGroupId,
  };
};

export default connect(selector)(MarkerProducer);
