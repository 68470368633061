import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { isLoaded, firestoreConnect } from 'react-redux-firebase';
import { get, map, throttle, uniq } from 'lodash-es';

import * as companyActionCreators from 'farmerjoe-common/lib/actions/company';
import * as employeeActionCreators from 'farmerjoe-common/lib/actions/employee';
import * as fieldActionCreators from 'farmerjoe-common/lib/actions/field';
import * as analysisActionCreators from 'farmerjoe-common/lib/actions/analysis';
import * as formActionCreators from 'farmerjoe-common/lib/actions/form';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import * as companySelector from 'farmerjoe-common/lib/selectors/companies';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getOpenFormId } from 'farmerjoe-common/lib/selectors/forms';
import { toggleCompanies } from 'farmerjoe-common/lib/actions/actions';
import { getUserPermissionObject } from 'farmerjoe-common/lib/selectors/user';

import './style.css';

import Icon from '../Common/Icon';
import { Avatar as Logo } from '../Common/Avatar';
import SearchInput from '../Common/SearchInput';
import { Loading } from '../Loading/Loading';
import withRouter from '../Router/withRouter';
import FormCompany from '../Company/FormCompany';
import UnreadCompanyNotificationsBadge from '../Notifications/UnreadCompanyNotificationsBadge';

import * as mapActionCreators from '../../actions/map';
import type { Company } from '../../flowTypes';
import { classes } from '../../utils/dom';
import fuseSearch from '../../utils/search';
import { getFieldPath, getPathname } from '../../utils/page';
import I18n from '../../language/i18n';
/* eslint-disable no-script-url */

type Props = {
  companies?: Array<Record<string, any>>;
  firebase?: Record<string, any>;
  auth?: Record<string, any>;
  goBack?: Boolean;
  actions?: {
    openCompany: (...args: Array<any>) => any;
    openEmployee: (...args: Array<any>) => any;
    openAnalysis: (...args: Array<any>) => any;
    openField: (...args: Array<any>) => any;
    toggleCompanies: (...args: Array<any>) => any;
    storeLastCompanyState: (...args: Array<any>) => any;
    setMapView: (...args: Array<any>) => any;
    openForm: (...args: Array<any>) => any;
    setLatestActivityCountForCompany: (...args: Array<any>) => any;
  };
  loading?: boolean;
  company?: Company;
  history?: Record<string, any>;
  open?: boolean;
  location?: Record<string, any>;
  lastCompanyState?: Record<string, any>;
  fieldId?: string | null;
  employeeId?: string | null;
  analysisId?: string | null;
  formId?: string | null;
  map?: Record<string, any> | null;
  key?: number;
};

type State = {
  showCreateForm: boolean;
  showToggle: boolean;
  search: string;
};

class Companies extends React.Component<Props, State> {
  state = {
    showCreateForm: false,
    showToggle: false,
    search: '',
  };

  constructor(props: Props, context?: any) {
    super(props, context);
    this.handleWindowResize = throttle(this.handleWindowResize.bind(this), 1000);
  }

  render() {
    const { companies, open } = this.props;

    let filteredCompanies = [];
    if (this.state.search && companies) {
      filteredCompanies = fuseSearch(companies, this.state.search, { keys: [ 'name' ] }) as any;
    }

    const companiesToShow = this.state.search ? filteredCompanies : companies;

    const maxEntries = this.calculateMaxEntries();
    const content = [
      <div className="sidebar-nav" key={1}>
        <ul className="company-list" id="sidebarnav">
          <li>
            <a
              onClick={() => this.setState({ showCreateForm: true })}>
              <Icon
                className="add-new-company-icon"
                iconType={'fj'}
                name="plus"
                style={{
                  lineHeight: '24px',
                  width: 32,
                  display: 'inline-block',
                }}
              />{' '}
              <span className="hide-menu">{I18n.t('add_company')}</span>
            </a>
          </li>
          <li>
            <div>
              <SearchInput
                search={''}
                onChange={text => {
                  this.setState({...this.state, search: text});
                  !open && this.props.actions?.toggleCompanies();
                }}
              />
            </div>
          </li>
          {companiesToShow ? companiesToShow.slice(0, maxEntries).map(item => this._renderRow(item)) : null}
          {!open && (companies?.length as number) > maxEntries
            ? (
              <li>
                <a
                  className="waves-effect waves-dark nav-link"
                  onClick={() => this.props.actions?.toggleCompanies()}>
                  <Icon iconType="ion" name="ios-more" />
                </a>
              </li>
            )
            : null}
        </ul>
      </div>,
      <div key={2}>
        <div className="toggle-companies-container">
          <a
            className="toggle-companies"
            onClick={() => this.props.actions?.toggleCompanies()}>
            <Icon
              iconType="fj"
              name={!open ? 'arrow_right' : 'arrow_left'}
              style={{
                lineHeight: '24px',
                width: 32,
                display: 'inline-block',
                fontSize: 19,
                textAlign: 'center',
              }}
            />{' '}
          </a>
        </div>
      </div>,
    ];

    return (
      <div className={classes('companies', open && 'open')}>
        {this.props.loading ? <Loading /> : content}

        {this.state.showCreateForm
          ? (
            <FormCompany
              show={this.state.showCreateForm}
              onClose={() => this.setState({ showCreateForm: false })}
            />
          )
          : null}
      </div>
    );
  }

  _openCompany(company) {
    this.props.open && this.props.actions?.toggleCompanies();
    const lastState = get(this.props.lastCompanyState, company.key);
    const isCurrentCompany =
      this.props.company && company.key === this.props.company.key;

    if (this.props.company && !isCurrentCompany) {
      this.props.actions?.storeLastCompanyState(this.props.company.key, {
        path: getPathname(), // this.props.location.pathname seems to be not updating properly
        fieldId: this.props.fieldId,
        employeeId: this.props.employeeId,
        analysisId: this.props.analysisId,
        formId: this.props.formId,
        map: this.props.map,
      });
    }

    if (lastState) {
      const {
        path,
        analysisId,
        fieldId,
        employeeId,
        formId,
        map: { center, zoom },
      } = lastState;
      this.props.history?.push(path);
      if (!isCurrentCompany) {
        this.props.actions?.openField(fieldId);
        this.props.actions?.openEmployee(employeeId);
        this.props.actions?.openAnalysis(analysisId);
        this.props.actions?.openForm(formId || null);
        this.props.actions?.openCompany(company.key);
        this.props.actions?.setMapView(center, zoom);
      }
    } else {
      if (isCurrentCompany && this.props.fieldId) {
        this.props.history?.push(getFieldPath(company.key, this.props.fieldId));
      } else {
        this.props.history?.push(getFieldPath(company.key));
      }
      if (!isCurrentCompany) {
        this.props.actions?.openField(null);
        this.props.actions?.openEmployee(null);
        this.props.actions?.openAnalysis(null);
        this.props.actions?.openForm(null);
        this.props.actions?.setMapView(null, null);
        this.props.actions?.openCompany(company.key);
      }
    }
  }

  _renderRow(data) {
    const { company } = this.props;

    return (
      <li
        key={data.key}
        className={classes(
          'company',
          company && data.key === company.key && 'active',
          'waves-effect waves-dark',
        )}
        onClick={() => {
          this._openCompany(data);
        }}>
        <a>
          <Logo
            downloadUrl={get(data, 'logo.original.downloadURL', null)}
            icon={
              <Icon
                name={'barn'}
                iconType={'fj'}
                style={{
                  fontSize: 25,
                  textAlign: 'center',
                  color: '#FFF',
                }}
              />
            }
            isLoaded={true}
            size={'small'}
          />

          <div className="name hide-menu" title={data.name}>
            {data.name}
          </div>
        </a>
      </li>
    );
  }

  calculateMaxEntries() {
    const itemHeight = 62;
    const headerHeight = 70;
    const padding = 5 * 2;
    const toggleCompaniesContainer = 56;
    return this.props.open
      ? Infinity
      : Math.floor(
        (window.innerHeight -
            headerHeight -
            padding -
            toggleCompaniesContainer) /
            itemHeight,
      ) - 2;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    this.forceUpdate();
  }
}

export default compose<typeof Companies>(
  connect(
    (state: any) => {
      const profile = state.firebase.profile;
      const userPermissions = getUserPermissionObject(state);
      const activeInCompanies = companySelector.activeInCompanies(state);
      const activeInTempCompanies = companySelector.activeInTempCompanies(state);
      const companies = companySelector.getAllActiveCompanies(state);

      const openCompany = selectors.getOpenCompanyId(state);
      const company = getCompany(state.firestore.data, openCompany);

      let dataForCompaniesHasLoaded = true;

      if (typeof profile !== 'undefined') {
        dataForCompaniesHasLoaded = hasLoaded(
          map(activeInCompanies, v => `companies/${v}`),
          state,
        );
      }

      return {
        profile: profile,
        companies: companies,
        loading: !isLoaded(
          state.firebase,
          dataForCompaniesHasLoaded ? true : undefined,
        ),
        auth: profile,
        company: company,
        open: state.openCompanies,
        lastCompanyState: state.lastCompanyState,

        fieldId: selectors.getOpenFieldId(state),
        employeeId: selectors.getOpenEmployeeId(state),
        analysisId: selectors.getOpenAnalysisId(state),
        formId: getOpenFormId(state) || null,
        map: state.map,
        activeInTempCompanies,
        userPermissions,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          ...companyActionCreators,
          toggleCompanies,
          ...analysisActionCreators,
          ...fieldActionCreators,
          ...employeeActionCreators,
          ...mapActionCreators,
          ...formActionCreators,
        },
        dispatch,
      ),
    }),
  ),

  firestoreConnect(props => {
    const companies: any[] = [];
    const paths: any[] = [];
    const activeInCompanies = uniq([
      ...get(props, ['userPermissions', 'isActiveInCompanies'], []),
      ...props.activeInTempCompanies,
    ]);

    if (props.profile.uid) {
      paths.push({
        collection: 'usersPermissions',
        doc: props.profile.uid,
        storeAs: 'userPermissions',
      });
    }

    if (activeInCompanies.length) {
      activeInCompanies.forEach(company => {
        companies.push({
          collection: 'companies',
          doc: company,
        });
      });
    }

    if (companies.length) {
      companies.forEach(value => {
        paths.push(value);
      });
    }

    return paths;
  }),
  withRouter,
)(Companies);
