import React, { Component } from 'react';

import { firestoreConnect } from 'react-redux-firebase';
import { bindActionCreators, compose } from 'redux';
import { displaySettings } from 'farmerjoe-common/lib/actions/actions';
import { connect } from 'react-redux';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import * as constants from '../../styles/style';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import { getActiveFields } from 'farmerjoe-common/lib/selectors/fields';
import Open from './Open';
import Results from './Results';
import Cancelled from './Cancelled';
import I18n from '../../language/i18n';
import { Loading } from '../Loading/Loading';
import TabBarSearchSort from '../Common/TabBarSearchSort';
import SortFilter from '../Common/SortFilter';
import ScrollableTabView from '../Common/ScrollableTabView';
import withRouter from '../Router/withRouter';
import './style.css';
import NewAnalysisDialog from './NewAnalysisDialog';
import { openAnalysis } from 'farmerjoe-common/lib/actions/analysis';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getFieldsQueries } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import UnreadCompanyNotificationsBadge from '../Notifications/UnreadCompanyNotificationsBadge';
import {
  ANALYSIS_ORDER,
  ANALYSIS_RESULT,
} from 'farmerjoe-common/lib/constants/notifications';
import { CreateNewButton } from '../Common/NewButton';
import DateRangeFilter from './DateRangeFilter';
import { get } from 'lodash-es';
import { isAdmin } from 'farmerjoe-common';
import { NotActivatedWarning } from '../Activation/NotActivatedWarning';
import { FieldState } from 'farmerjoe-common/lib/flow/types';

const tabIndexToAnalysisState = {
  0: 'open',
  1: 'results',
  2: 'cancelled',
};

// TODO: improve typings
type Props = any;
type State = any;

class Analyses extends Component<Props, State> {
  state = {
    showSelectLabForm: false,
    currentTabIndex: 0,
  };

  render() {
    const {
      company,
      loading,
      selectedAnalysis,
      sortOrders,
      sortResults,
      myCompanyProfile,
      sortCancelled,
      auth,
    } = this.props;

    const sort =
      this.state.currentTabIndex === 0
        ? sortOrders
        : this.state.currentTabIndex === 1
          ? sortResults
          : sortCancelled;

    if (loading) {
      return <Loading />;
    }

    const state = tabIndexToAnalysisState[this.state.currentTabIndex];
    return (
      <div className="analyses">
        <div style={constants.styles.containerColumn}>
          {isAdmin(myCompanyProfile) && !auth.emailVerified && (
            <NotActivatedWarning type={'analysis'} />
          )}
          <ScrollableTabView
            renderTabBar={() => (
              <TabBarSearchSort
                sortComponent={
                  <SortFilter
                    selected={sort}
                    showSelected={true}
                    onClick={selected =>
                      this.props.actions.displaySettings(
                        company.key,
                        `analysis_${state}`,
                        {
                          sort: selected,
                        },
                        true,
                      )
                    }
                    data={[
                      {
                        label: I18n.t('analysis.sortByCrop'),
                        value: 'crop',
                        testID: 'analysisSortCrop',
                      },
                      {
                        label: I18n.t('analysis.sortByLatest'),
                        value: 'latest',
                        testID: 'analysisSortLatest',
                      },
                    ]}
                  />
                }
                afterTabBarComponent={
                  isAdmin(myCompanyProfile) &&
                  auth.emailVerified && (
                    <CreateNewButton
                      onClick={() => this.setState({ showSelectLabForm: true })}
                    />
                  )
                }
                searchComponent={<DateRangeFilter status={state} />}
              />
            )}
            onChangeTab={({ i }) => {
              this.setState({ currentTabIndex: i });
            }}>
            <Open
              tabKey={'pending'}
              tabLabel={{
                text: I18n.t('analysis.pending'),
                render: () => (
                  <span>
                    {I18n.t('analysis.pending')}{' '}
                    <UnreadCompanyNotificationsBadge types={ANALYSIS_ORDER} />
                  </span>
                ),
              }}
              selectedAnalysis={selectedAnalysis}
            />
            <Results
              tabKey={'results'}
              tabLabel={{
                text: I18n.t('analysis.results'),
                render: () => (
                  <span>
                    {I18n.t('analysis.results')}{' '}
                    <UnreadCompanyNotificationsBadge types={ANALYSIS_RESULT} />
                  </span>
                ),
              }}
              selectedAnalysis={selectedAnalysis}
            />
            <Cancelled
              tabKey={'cancelled'}
              tabLabel={{
                text: I18n.t('analysis.cancelled'),
              }}
              selectedAnalysis={selectedAnalysis}
            />
          </ScrollableTabView>
        </div>
        <NewAnalysisDialog
          show={this.state.showSelectLabForm}
          onCancel={() => {
            this.setState({ showSelectLabForm: false });
          }}
          onCloseAfterCreatingAnalysis={analysis => {
            this.props.actions.openAnalysis(analysis.key);
            this.props.history.push(
              `/company/${this.props.company.key}/analysis/${analysis.key}`,
            );
            this.setState({ showSelectLabForm: false });
          }}
        />
      </div>
    );
  }
}

const selector = state => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const sortOrders = get(
    state,
    [
      'filtersByCompany',
      openCompany,
      'displaySettings',
      'analysis_open',
      'sort',
    ],
    'crop',
  );
  const sortResults = get(
    state,
    [
      'filtersByCompany',
      openCompany,
      'displaySettings',
      'analysis_results',
      'sort',
    ],
    'latest',
  );
  const sortCancelled = get(
    state,
    [
      'filtersByCompany',
      openCompany,
      'displaySettings',
      'analysis_cancelled',
      'sort',
    ],
    'crop',
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  let fields = getActiveFields(state, openCompany, state.firebase.auth.uid);

  const hasLoadedEverything = hasLoaded(
    [
      ...getFieldsQueries(openCompany, myCompanyProfile, browsingGroup, FieldState.Active),
    ],
    state,
  );

  const dateRange = get(
    state,
    [
      'filtersByCompany',
      openCompany,
      'displaySettings',
      'analysis_results',
      'date',
    ],
    'last:7',
  );

  fields = hasLoadedEverything && fields ? fields : [];
  return {
    loading: !hasLoadedEverything,
    company: company,
    fields: fields,
    openCompany: openCompany,
    browsingGroup,
    myCompanyProfile,
    sortOrders,
    sortResults,
    sortCancelled,
    auth: state.firebase.auth,
    date: dateRange,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          displaySettings,
          openAnalysis,
        },
      ),
      dispatch,
    ),
  };
}

export default compose<typeof Analyses>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  firestoreConnect(props => {
    const { company, browsingGroup, myCompanyProfile } = props;

    if (company) {
      const companyId = company.key;

      return [
        ...getFieldsQueries(companyId, myCompanyProfile, browsingGroup, FieldState.Active),
      ];
    }
    return [];
  }),
  withRouter,
)(Analyses);
