import React from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { getSharedFieldsQueries } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';
import { getActiveWaitTimesQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Waittimes';
import hoistStatics from 'hoist-non-react-statics';

const getQueriesForSharedFields = (openCompany: string, companyCollaborators) => {
  if (!companyCollaborators || !openCompany) {
    return [];
  }

  let paths: any = [];

  const sharedFields = Object
    .keys(companyCollaborators)
    .filter((collaborator) => companyCollaborators[collaborator].shared === true)
    .map((collaborator) => {
      return {
        fieldId: companyCollaborators[collaborator].key,
        companyId: companyCollaborators[collaborator].company_id,
      };
    });

  if (sharedFields.length) {
    const fieldIds = sharedFields.map(field => field.fieldId);
    paths = [...getSharedFieldsQueries(fieldIds, openCompany)];
    const sharedFieldCompanies = [...new Set(sharedFields.map(field => field.companyId))];
    sharedFieldCompanies.forEach(sharedFieldCompanyId => {
      paths.push(getActiveWaitTimesQuery(sharedFieldCompanyId, 'main')); // TODO: use exported const from fj-common
    });
  }

  return paths;
};

/**
 * HOC that loads shared fields with the company
 *
 *
 * @param WrappedComponent
 */
const WithSharedFields = (WrappedComponent) => {
  const SharedFieldsLoader = (props) => {
    return <WrappedComponent {...props} />;
  };

  const connectedToFirestore = firestoreConnect((props) => {
    const { openCompany, companyCollaborators } = props;

    const paths: any = [];

    if (openCompany && companyCollaborators) {
      paths.push(...getQueriesForSharedFields(openCompany, companyCollaborators));
    }

    return paths;
  })(SharedFieldsLoader);

  return hoistStatics(connectedToFirestore, WrappedComponent);
};

export default WithSharedFields;
