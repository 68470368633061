import React from 'react';
import t from 'tcomb-form';

import FormInput from './FormInput';
import ButtonWithDialog from '../../components/Common/ButtonWithDialog';
import I18n from '../../language/i18n';

const Component = t.form.Component;

export default class tSprayer extends Component {
  getTemplate() {
    const ref = React.createRef();
    return locals => {
      return (
        <div className={'d-flex'} style={{alignItems: 'baseline'}}>
          <FormInput
            {...this.props}
            options={{
              ...this.props.options,
              label: locals.label,
              hasError: locals.hasError,
            }}
            ref={ref as any}
            value={locals.value}
          />
          <ButtonWithDialog
            buttonLabel={I18n.t('more')}
            onChange={locals.onChange}
            options={locals.config.options}
            modalHeader={I18n.t('waittime.selectSprayer')}
            modalNoResultsText={I18n.t('waittime.noSprayers')}
          />
        </div>
      );
    };
  }
};
