import React, { useState } from 'react';
import { capitalize } from 'lodash-es';

import I18n from '../../language/i18n';
import UsersSelection from '../../components/Common/UsersSelection';
import GroupUsersSelection from '../../components/Common/GroupUsersSelection';
import './style.css';

type usersObject = Record<string, boolean>;

type Props = {
  users: usersObject;
  onChange: (users: usersObject) => any;
  error: boolean;
  groupType: 'employee' | 'producer' | 'trader';
};

const UsersSelectionForm = (props: Props) => {
  const { users: initialUsers = {}, onChange, error, groupType } = props;
  const [users, setUsers] = useState<usersObject>(initialUsers);
  const [showForm, setShowForm] = useState(false);

  const updateSelectedUsers = (newUsers: usersObject) => {
    setUsers(newUsers);
    onChange(newUsers);
  };

  const getSelectedUsersCount = () => {
    return Object.keys(users)
      .filter(key => users[key]).length;
  };

  const SelectionForm = groupType === 'employee' ? UsersSelection : GroupUsersSelection;

  // XXX: for translation purposes
  const _groupType = groupType === 'employee' ? 'user' : groupType;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div>
          {I18n.t(`acl.${_groupType}s.x_selected${capitalize(_groupType)}s`, {
            count: getSelectedUsersCount(),
          })}
        </div>
        <button
          type="button"
          className={`btn btn-secondary btn-users-selection ${
            error ? 'outline-danger' : 'outline-primary'
          }`}
          onClick={() => setShowForm(!showForm)}>
          {I18n.t(`acl.${_groupType}s.select${capitalize(_groupType)}s`)}
        </button>
      </div>

      {showForm && (
        <SelectionForm
          selected={users}
          show={showForm}
          onSave={updateSelectedUsers}
          onClose={() => setShowForm(false)}
          groupType={groupType}
        />
      )}
    </div>
  );
};

export default UsersSelectionForm;

