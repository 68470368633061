import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { map, reverse, sortBy, get } from 'lodash-es';
import { firestoreRef } from 'redux-firestore/lib/utils/query';

import { toDate } from 'farmerjoe-common';
import { canView, isAdmin } from 'farmerjoe-common/lib/utils/User';
import { getCommentsForFieldAndCropQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Comments';


import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import {  openField } from 'farmerjoe-common/lib/actions/field';
import * as actionCreators from 'farmerjoe-common/lib/actions/actions';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';

import Comment from './Comment';
import CommentsHeader from './CommentsHeader';
import CommentsHeaderComponent from './CommentsHeaderComponent';
import SectionList from '../Common/SectionList';
import FlatList from '../Common/FlatList';
import '../Field/style.css';
import { Loading } from '../Loading/Loading';

import fetch from '../../utils/fetch';
import { BASE_API_URL } from '../../constants';
import { getUidToken } from '../../utils/auth';
import I18n, { browserLanguage } from '../../language/i18n';
import type {
  Comment as CommentType,
  Crop,
  Employee,
  Field,
} from '../../flowTypes';
import firebase from '../../data/firebase';

import * as constants from '../../styles/style';

const SPECIAL_SECTION_KEY = 'sectionHack';
// XXX: This const is being used in order to reduce the amount of comments
// that we load when we open a field.

type Props = {
  myCompanyProfile: Employee;
  allActiveCrops: Crop[];
  field: Field;
  onScroll: (...args: Array<any>) => any;
  loading: boolean;
  actions?: any;
  openCompanyId?: any;
  cropId: string;
  fieldId: string;
  browsingGroup: string;
  currentFieldsTab: string;
};

const renderSectionListHeader = (field, myCompanyProfile) => {
  return (
    <CommentsHeaderComponent
      field={field}
      myCompanyProfile={myCompanyProfile as any}
    />
  );
};

const _renderRow = (data, allActiveCrops) => {
  const comment = data.item;
  return (
    <div
      key={'comment' + comment.key}
      style={{ marginLeft: 5, marginRight: 5 }}>
      <Comment comment={comment} activeCrops={allActiveCrops} />
    </div>
  );
};

const groupComments = (comments, activeCrops, field) => {
  // ohne kultur - not_a_crop 1, state 1, type 1 - we sort by created
  const noCrop = field.activeCrop.not_a_crop === 1 && field.activeCrop.state === 1 && parseInt(field.activeCrop.type) === 1;
  const permanentCrop = field.activeCrop.not_a_crop === 0 && field.activeCrop.type === '2';
  const annualCrop = field.activeCrop.not_a_crop === 0 && parseInt(field.activeCrop.type) === 1; 

  const cropGrouping =
    {
      'noCrop': [
        (o) => {
          return toDate(get(o, 'created', null));
        },
        'key',
      ],
      'permanentCrop': [
        (o) => {
          return toDate(get(o, 'created', null));
        },
        (o) => {
          return toDate(get(o, 'sown_on', null));
        },
        (o) => {
          return toDate(get(o, 'harvested_on', null));
        },
        'key',
      ],
      'annualCrop': [
        (o) => {
          return toDate(get(o, 'created', null));
        },
        (o) => {
          return toDate(get(o, 'sown_on', null));
        },
        'key',
      ],
      'unknown': [
        (o) => {
          return toDate(get(o, 'created', null));
        },
        (o) => {
          return toDate(get(o, 'sown_on', null));
        },
        'key',
      ],
    }[noCrop ? 'noCrop' : permanentCrop ? 'permanentCrop' : annualCrop ? 'annualCrop' : 'unknown'];

  const activeCropUid = reverse(
    map(
      sortBy(activeCrops, cropGrouping),
      (crop, key) => {
        return crop.key;
      },
    ),
  );

  const grouped = activeCropUid.map((id) => {
    const filteredComments = 
      sortBy(
        comments.filter((comment) => {
          if (comment && comment.active_crop_uid === id) {
            return comment;
          }
          return null;
        }).filter(f => f),
        [
          (o) => {
            return toDate(get(o, 'created', null));
          },
          (o) => {
            return toDate(get(o, 'cropMeta.sown_on', null));
          },
          (o) => {
            return toDate(get(o, 'cropMeta.harvested_on', null));
          },
          'key',
        ],
      );

    return {
      title: id,
      data: reverse(filteredComments),
    };
  });

  return grouped;
};

const generateCropPdf = (crop) => {
  return getUidToken()
    .then((uidToken) => {
      return fetch(`${BASE_API_URL}/pdf/${crop.key}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${uidToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({language: browserLanguage()}),
      })
        .then((res) => res.blob())
        .then((blob) => {
          const objectUrl = window.URL.createObjectURL(blob);
          const pdfWindow = window.open('about:blank');
          (pdfWindow as any).location.href = objectUrl;
          (pdfWindow as any).focus();
        });
    })
    .catch((e) => {
      console.error('Unable to print the crop data: ', e);
    });
};

const getOpenCrops = (cropId, field) => {
  if (cropId) {
    return {[cropId]: true};
  }
  if (field.activeCrop) {
    return {[field.activeCrop.key]: true};
  }
  return {};
};

const Comments = (props: Props) => {
  const { myCompanyProfile, loading, field, onScroll, allActiveCrops } = props;

  const initialCropId = props.cropId || get(field, 'activeCrop.key');
  const openCrops = getOpenCrops(props.cropId, field);

  const [open, setOpen] = useState(openCrops);
  const [sectionToPrint, setSectionToPrint] = useState(null);
  const [listeners, setListeners] = useState<any>({});
  const [loadedAll, setLoadedAll] = useState({[initialCropId]: false});
  const [limitSize, setLimitSize] = useState({[initialCropId]: 5});

  const [commentsPerCrop, setCommentsPerCrop] = useState<any>({[initialCropId]: []});
  const [filterCommentsBy, setFilterCommentsBy] = useState([]);
  const loadedAllRef = useRef(loadedAll);
  const commentsPerCropRef = useRef(commentsPerCrop);
  const listenersRef = useRef(listeners);
  const limitSizeRef = useRef(limitSize);
  const viewRef = useRef(null);
  const firstRun = useRef(true);
  const filterCommentsByRef = useRef(filterCommentsBy);

  useEffect(() => {
    loadedAllRef.current = loadedAll;
    listenersRef.current = listeners;
    limitSizeRef.current = limitSize;
    commentsPerCropRef.current = commentsPerCrop;
    filterCommentsByRef.current = filterCommentsBy;
  }, [loadedAll, listeners, limitSize, commentsPerCrop, filterCommentsBy]);

  const loadCommentsForSection = useCallback((cropId, limit, commentFilters = []) => {
    if (listenersRef.current[cropId]) {
      listenersRef.current[cropId]();
    }

    const callback = (querySnapshot) => {
      const docs = querySnapshot.docs.map(doc => doc.data());
      setCommentsPerCrop(prev => ({...prev, [cropId]: docs}));
      setLoadedAll(prev => ({...prev, [cropId]: docs.length + 5 < limitSizeRef.current[cropId]}));
    };

    const onError = (error) => {
      console.log(error);
    };

    const query = getCommentsForFieldAndCropQuery(
      props.fieldId,
      cropId,
      props.field.company_id,
      props.browsingGroup,
      myCompanyProfile,
      limit,
      commentFilters,
    );

    const firestoreQuery = firestoreRef(firebase, query);
    const listener = firestoreQuery.onSnapshot(callback, onError);
    setListeners(prev => ({...prev, [cropId]: listener}));
    setLimitSize(prev => ({...prev, [cropId]: limit + 5}));
  }, [myCompanyProfile, props.browsingGroup, props.fieldId, props.field]);

  useEffect(() => {
    if (firstRun.current) {
      loadCommentsForSection(initialCropId, 5, filterCommentsBy);
      firstRun.current = false;
    }

  }, [initialCropId, loadCommentsForSection, filterCommentsBy]);

  useEffect(() => {
    setTimeout(() => {
      if (viewRef.current) {
        (viewRef.current as any).scrollTop = 2**30;
      }
    }, 800);
  }, []);

  // Cleanups
  useEffect(() => {
    return () => {
      if (listenersRef.current) {
        Object.keys(listenersRef.current).forEach((key) => {
          listenersRef.current[key] && listenersRef.current[key]();
        });
      }
    };
  }, []);

  const _renderSection = (section) => {
    const title = section.title;
    const crop = allActiveCrops[title];

    const expanded = !!open[title];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '5px 0',
        }}
        onClick={() => {
          setOpen({...open, [title]: !open[title]});
        }}
      >
        <CommentsHeader
          crop={crop}
          open={expanded}
          disablePrint={!open[title]}
          printInProgress={sectionToPrint === title}
          onPrint={e => {
            e.stopPropagation();
            setSectionToPrint(title);
            return generateCropPdf(crop).finally(() => {
              setSectionToPrint(null);
            });
          }}
          onFilterChanged={value => {
            setFilterCommentsBy(value);
            loadCommentsForSection(crop.key, 5, value);
          }}
        />
        {expanded && !loadedAll[crop.key] ? (
          <div style={{textAlign: 'center', margin: '1em 0'}}>
            <span style={{
              background: constants.FJWHITE,
              padding: '5px 30px',
              color: constants.FJMUTED,
              borderRadius: '10px',
              borderColor: constants.FJMUTED,
              borderWidth: '5x',
              borderStyle: 'solid',
              cursor: 'pointer',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              loadCommentsForSection(crop.key, limitSizeRef.current[crop.key] || 5, filterCommentsBy);
            }}>
              {I18n.t('loadMore')}
            </span>
          </div>
        ) : null}
      </div>
    );
  };

  const _renderRowBySection = (data) => {
    const comment = data.item;
    const { title } = data.section;
    // Just shoot me...
    if (comment.key === SPECIAL_SECTION_KEY) {
      return (
        <div key={SPECIAL_SECTION_KEY} style={{ marginLeft: 5, marginRight: 5 }}>
          {renderSectionListHeader(field, myCompanyProfile)}
        </div>
      );
    }

    const expanded = open[comment.active_crop_uid];

    if (!expanded) {
      return null;
    }

    return (
      <div
        className={title === sectionToPrint ? 'show-print' : 'hide-print'}
        key={'comment' + comment.key}
        style={{ marginLeft: 5, marginRight: 5 }}>
        <Comment comment={comment} activeCrops={allActiveCrops} />
      </div>
    );
  };

  const comments = useMemo(() => Object
    .keys(commentsPerCrop)
    .map(cropId => commentsPerCrop[cropId])
    .reduce((acc, val) => acc.concat(val), []), [commentsPerCrop]);


  if (loading) {
    return <Loading />;
  }


  const data = groupComments(comments, allActiveCrops, field);

  const offset = isAdmin(myCompanyProfile) && comments.length > 3 ? 140 : 0;
  // The biggest fuckign hack in the history...
  // For some unknown reason setting a ListHeaderComponent on the list
  // makes the whole scene unresponsive...
  // So add the ListHeader as first element in the array here....
  if (data.length) {
    const firstEls = [{ key: SPECIAL_SECTION_KEY }, ...data[0].data];
    data[0].data = firstEls;
  }
  // The user is restricted in a way that he can see his own comments only
  // The ACL looks `views` are :
  // comments: false,
  // crops: false,
  // fields: false,
  // users: false
  if (!canView('crops', myCompanyProfile) && !canView('comments', myCompanyProfile)) {
    const listView = (
      <FlatList
        data={comments}
        renderItem={item => _renderRow(item, allActiveCrops)}
      />
    );

    return (
      <div ref={viewRef} className="comments">
        <div style={{
          padding: '0 15px',
          marginTop: '10px',
        }}>
          <CommentsHeader
            crop={field.activeCrop}
            open={true}
          />
          {listView}
        </div>
      </div>
    );
  }

  const listView = (
    <SectionList
      className={'comments-section-list'}
      renderItem={item => _renderRowBySection(item)}
      renderSectionFooter={({ section }) => _renderSection(section)}
      onScroll={onScroll}
      keyboardShouldPersistTaps={'handled'}
      contentOffset={{ y: offset }}
      inverted={true}
      sections={data}
    />
  );

  return (
    <div ref={viewRef} className="comments">
      {listView}
    </div>
  );
};


const selector = (state, ownProps) => {
  const openFieldId = selectors.getOpenFieldId(state);
  const openCropId = selectors.getOpenCropId(state) || get(ownProps, 'field.activeCrop.key');
  const openCompanyId = selectors.getOpenCompanyId(state);

  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompanyId,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompanyId);
  const currentFieldsTab = get(state, `currentFieldsTab.${openCompanyId}`, null);

  return {
    openCompanyId,
    myCompanyProfile,
    cropId: openCropId,
    fieldId: openFieldId,
    browsingGroup,
    currentFieldsTab,
    loading: false,
  };
};

export default compose<any>(
  connect(
    selector,
    dispatch => ({
      actions: bindActionCreators(
        {
          ...actionCreators,
          openField,
        },
        dispatch,
      ),
    }),
  ),
)(Comments);

