import  React, { useState } from 'react';
import Icon from '../Common/Icon';
import CreateField from '../Field/CreateField';

import I18n from '../../language/i18n';
import * as constants from '../../styles/style';

import imgFjAvatar from '../../public/images/FJ_logo_inverted.png';

type Props = {
  showCreate: boolean;
};

const NoFields = (props: Props) => {
  const { showCreate = true } = props;
  const [showCreateForm, setShowCreateForm] = useState(false);

  return (
    <div style={{ flex: 1, marginTop: 50 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: 10,
        }}>
        <img
          src={imgFjAvatar}
          style={{ width: 60, height: 60, marginRight: 10 }}
          alt=""
        />
        <span
          style={{
            ...constants.styles.big,
            ...constants.styles.muted,
            ...constants.styles.alignCenter,
            ...{
              fontSize: 25,
            },
          }}>
          {I18n.t('field.noFieldsCreated')}
        </span>
      </div>

      {showCreate ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            onClick={() => setShowCreateForm(true)}
            style={{
              minWidth: 290,
              borderRadius: 25,
              // fontWeight:   'bold'
            }}
            className={'btn btn-primary'}>
            <Icon iconType={'fj'} name={'plus'} /> {I18n.t('field.createNow')}
          </button>
        </div>
      ) : null}

      {showCreateForm
        ? (
          <CreateField
            show={showCreateForm}
            onClose={() => setShowCreateForm(false)}
          />
        )
        : null}
    </div>
  );
};

export default NoFields;
