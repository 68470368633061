import React from 'react';
import { classes } from '../../utils/dom';
import { resolve } from '../../utils/utils';
import Draggable from 'react-draggable';
import type { HeaderCellProps } from './flow';

export default class HeaderCell extends React.PureComponent<HeaderCellProps> {
  static positionZero = {
    x: 0,
    y: 0,
  };

  div: any = React.createRef();

  componentDidMount() {
    this.updateTitleAttribute();
  }

  componentDidUpdate() {
    this.updateTitleAttribute();
  }

  updateTitleAttribute() {
    const el = this.div.current;
    if (el) {
      const text = this.getTextContent();
      if (text != null) {
        el.setAttribute('title', text);
      }
    }
  }

  render() {
    const {
      column,
      columnClassName,
      columnIndex,
      style,
      onClick,
      onStartDrag,
      onDrag,
      onStopDrag,
      sortingIndexed,
    } = this.props;

    return (
      <div
        className={classes(
          'header-cell',
          columnClassName,
          columnIndex === 0 && 'cell-column-0',
          column.fixed && 'fixed',
          (column as any).sort === false && 'no-sort',
        )}
        style={style}
        onClick={onClick && onClick.bind(null, column, columnIndex)}
        ref={this.div}>
        <span className="header-title">{resolve(column.Header)}</span>
        <span
          className={classes(
            'sorting',
            sortingIndexed[column.id] && (column as any).sort !== false
              ? sortingIndexed[column.id].desc
                ? 'sorting-desc'
                : 'sorting-asc'
              : 'sorting-none',
          )}
        />

        <Draggable
          axis="x"
          onStart={onStartDrag && (() => onStartDrag(column, columnIndex))}
          onDrag={
            onDrag && ((event, data) => onDrag(data.x, column, columnIndex))
          }
          onStop={
            onStopDrag &&
            ((event, data) => onStopDrag(data.x, column, columnIndex))
          }
          position={HeaderCell.positionZero}
          // zIndex={999}
        >
          <div className="drag-handle" />
        </Draggable>
      </div>
    );
  }

  getText() {
    const { column } = this.props;
    let text: any = null;

    if ((column as any).getHeaderText) {
      text = (column as any).getHeaderText();
    } else {
      text = this.getTextContent();
    }
    return text;
  }

  getTextContent() {
    const el = this.div.current;

    if (el) {
      return el.textContent;
    }
    return null;
  }
}
