import loadImage from 'blueimp-load-image';
import 'blueimp-canvas-to-blob';

export type ImageType = Blob | HTMLCanvasElement | HTMLImageElement;

export function loadImageFile(
  file: File | Blob | string,
  type: 'image' | 'blob' | 'canvas' = 'image',
  options: Record<string, any> | void | null,
): Promise<ImageType> {
  return new Promise((resolve, reject) => {
    loadImage(
      file,
      img => {
        if (img.type === 'error') {
          reject(img);
        } else {
          resolve(img);
        }
      },
      {
        orientation: true,
        canvas: type === 'blob' || type === 'canvas',
        ...options,
      },
    );
  }).then((img: any) => {
    if (type === 'blob') {
      return new Promise((resolve, reject) => {
        img.toBlob(resolve);
      });
    } else {
      return (img as ImageType);
    }
  });
}

export type ProcessImageOptions = {
  pixelCrop?: Record<string, any> | null;
  imageQuality?: number;
  maxWidth: number;
  maxHeight: number;
};

export type ImageFile = {
  name: string;
  image: File | Blob;
  dimensions: {
    width: number;
    height: number;
  };
};

export function processImage(
  file: File,
  { pixelCrop, imageQuality = 1, maxWidth, maxHeight }: ProcessImageOptions,
): Promise<ImageFile> {
  return loadImageFile(file, 'canvas', {
    maxWidth,
    maxHeight,

    ...(pixelCrop
      ? {
        left: pixelCrop.x,
        top: pixelCrop.y,
        sourceWidth: pixelCrop.width,
        sourceHeight: pixelCrop.height,
      }
      : null),
  }).then((canvas: any) => {
    return new Promise(resolve => {
      // $FlowFixMe
      canvas.toBlob(
        blob => {
          resolve({
            name: file.name,
            image: blob,
            dimensions: {
              // $FlowFixMe
              width: canvas.width,
              // $FlowFixMe
              height: canvas.height,
            },
          });
        },
        'image/jpeg',
        imageQuality,
      );
    });
  });
}
