import * as React from 'react';
import { useState } from 'react';
import { FJAPPLERED, FJWHITE } from '../../styles/style';
import Icon from '../../components/Common/Icon';
import I18n from '../../language/i18n';
import ProfileForm from '../../components/Profile/ProfileForm';
import './style.css';

const ProfileCompletedCheckLine = (props) => {
  const [showProfileForm, setShowProfileForm] = useState(false);
  return <>
    <a
      key={'activation-line'}
      style={{
        backgroundColor: FJAPPLERED,
      }}
      onClick={e => {
        e.preventDefault();
        setShowProfileForm(true);
      }}
      className={
        'd-flex flex-row justify-content-center align-items-center fj-pointer profile-banner'
      }>
      <div
        className={'d-flex flex-row justify-content-center align-items-center'}>
        <Icon
          name={'exclamation-triangle'}
          iconType={'fal'}
          style={{ color: FJWHITE, marginRight: 10, fontSize: 20 }}
        />
        <div>
          <div style={{ color: FJWHITE }}>
            {I18n.t('profile.profileNotComplete')} {I18n.t('profile.profileAskToCLickToComplete')}
          </div>
        </div>
      </div>
    </a>
    <ProfileForm show={showProfileForm} onClose={() => setShowProfileForm(false)} />
  </>;
};

export default ProfileCompletedCheckLine;
