import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import t from 'tcomb-form';

import { getNoCrop } from 'farmerjoe-common/lib/actions/actionConstants';
import { dummy, yieldComment } from 'farmerjoe-common/lib/utils/Comment';
import { updateField, openField } from 'farmerjoe-common/lib/actions/field';
import { stopFieldSharing } from 'farmerjoe-common/lib/actions/fieldSharing';
import { addComment } from 'farmerjoe-common/lib/actions/comment';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getCropByFieldId, getCrop } from 'farmerjoe-common/lib/selectors/crops';
import { toDate, UserObj, yieldPerHa, isAdmin } from 'farmerjoe-common';
import { CropType, CropState, FieldState } from 'farmerjoe-common/lib/flow/types';
import { searchForFieldAmongTheUniverse } from 'farmerjoe-common/lib/selectors/fields';
import {
  isFieldOwner,
  hasCollaborators,
} from 'farmerjoe-common/lib/utils/Field';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { setFieldsTab } from 'farmerjoe-common/lib/actions/ui';

import Dialog, { AlertDialog } from '../Dialog/Dialog';
import withRouter from '../Router/withRouter';
import HarvestModel from '../../tcomb/models/harvest';
import HarvestFieldYield from '../../tcomb/models/harvestFieldYield';
import HarvestFieldForCollaboratorsModel from '../../tcomb/models/harvestFieldForCollaborators';
import HarvestModelSharedField from '../../tcomb/models/harvestSharedField';
import I18n from '../../language/i18n';
import * as constants from '../../styles/style';
import firebase from '../../data/firebase'; 
import fbase from 'firebase/app';
import { getFieldPath } from '../../utils/page';
import { FIELDS_TAB_HARVESTED } from '../Fields/constants';

import type { Field, Crop, User } from '../../flowTypes';

const Form = t.form.Form;

type Props = {
  field: Field & { baseFieldId?: string };
  activeCrop: Crop;
  onClose: any;
  show: boolean;
  userOwnsField?: boolean;
  actions?: any;
  isCompanyAdmin?: boolean;
  auth?: User;
  openCompany?: string;
  history?: any;
};

type State = {
  harvest: {
    archive_field: boolean;
    stop_field_sharing?: boolean;
  };
  alertMessage: any;
  alertTitle: any;
};

class HarvestForm extends Component<Props, State> {
  ref: React.RefObject<unknown>;
  constructor(props) {
    super(props);

    this.state = {
      harvest: {
        archive_field: false,
        stop_field_sharing: false,
      },
      alertMessage: null,
      alertTitle: null,
    };

    this.ref = React.createRef();
  }

  onSave() {
    const { userOwnsField } = this.props;
    const value = (this.ref.current as any).getValue();

    // If value is null, then the form has errors
    if (value === null) {
      this.setState({
        alertMessage: I18n.t('please_correct_your_entry'),
        alertTitle: I18n.t('error'),
      });
      return;
    }

    if (!userOwnsField) {
      this.harvestFieldAsCollaborator(value);
      return;
    }
    // current implementation
    this.harvestFieldAsOwner(value);
  }

  harvestFieldAsOwner(formData) {
    const { field, activeCrop, actions } = this.props;
    // structs come out as Struct custom objects which firestore doesn't like so turn them into plain objects
    const value = JSON.parse(JSON.stringify(formData));

    const { archive_field } = this.state.harvest;
    const collaborators = hasCollaborators(field);
    const harvested = moment(toDate(value.harvested_on));
    const oldUuid = field.activeCrop.key;
    const totalYield = value.yield;
    const perHaYield = yieldPerHa(value.yield, field.size);

    const oldActiveCrop = {
      ...activeCrop,
      field_id: field.key,
      harvested_on: harvested.toDate(),
      state: CropState.Harvested,
      yield: {
        total: totalYield,
        perHa: perHaYield,
      },
    };

    const db = firebase.firestore();
    const batch = db.batch();

    if (totalYield !== null) {
      this.createCommentForYield(oldActiveCrop, batch);
    }

    const oldCropRef = db.collection('crops').doc(oldUuid);

    batch.update(oldCropRef, oldActiveCrop);

    this.harvestedComment(oldUuid, value.comment, harvested, batch);
    
    // XXX: for partially planted crops on field
    // This is the sub field, copied from the base field
    // with id baseFieldId
    // In this case, we don't handle permanentCrop or normalCrop
    // We just need to harvest the field and free up the area form the base field
    
    const isSubField = !!field.baseFieldId;

    if (!isSubField) {
      if (parseInt(oldActiveCrop.type, 10) === parseInt(CropType.Permanent, 10)) {
        this.handlePermanentCrop(oldActiveCrop, batch);
      } else {
        this.handleAnnualCrop(field, harvested, batch);
      }
    } else {
      const fieldRef = db.collection('fields').doc(field.key);
      const baseFieldRef = db.collection('fields').doc(field.baseFieldId);
      batch.set(baseFieldRef, {
        usedCropArea: {
          [activeCrop.key]: fbase.firestore.FieldValue.delete(),
        },
      }, { merge: true });
      batch.set(fieldRef, {
        state: FieldState.Abandoned,
      }, { merge: true });
    }


    // we need to make a abandoned field copy for each collaborator
    if (field.collaborators) {
      field.collaborators.forEach((collaborator) => {
        const abandonedFieldRef = db.collection('fields').doc();
        const abandonedFieldRefId = abandonedFieldRef.id;
        const abandonedCropRef = db.collection('crops').doc();
        const abandonedCropRefId = abandonedCropRef.id;

        const force_create = true;
        const abandonedField = this.getAbandonedField(
          field.key,
          abandonedFieldRefId,
          abandonedCropRefId,
          collaborator,
          force_create,
        );
        const abandonedCrop = this.getAbandonedCrop(
          abandonedField,
          oldActiveCrop,
          abandonedFieldRefId,
          abandonedCropRefId,
          collaborator,
          force_create,
        );
        batch.set(abandonedCropRef, abandonedCrop);

        const {
          field: { f },
          ...abandonedActiveCrop
        } = abandonedCrop;
        batch.set(abandonedFieldRef, {
          ...abandonedField,
          activeCrop: abandonedActiveCrop,
        });
      });
    }

    batch
      .commit()
      .then(() => {
        if (value.stop_field_sharing || (collaborators && archive_field) || isSubField) {
          actions.stopFieldSharing(field.key, field.collaborators);
        }
      })
      .catch((error) => console.error(error));

    this.props.actions.openField(field.key);
    this.props.onClose();
  }

  harvestFieldAsCollaborator(formData) {
    const { field, activeCrop, actions, openCompany } = this.props;

    // structs come out as Struct custom objects which firestore doesn't like so turn them into plain objects
    const value = JSON.parse(JSON.stringify(formData));

    const harvested = moment(toDate(value.harvested_on));
    const totalYield = value.yield;
    const perHaYield = yieldPerHa(value.yield, field.size);

    const oldActiveCrop = {
      ...activeCrop,
      field_id: field.key,
      harvested_on: harvested.toDate(),
      state: CropState.Harvested,
      yield: {
        total: totalYield,
        perHa: perHaYield,
      },
    };

    const db = firebase.firestore();
    const batch = db.batch();

    if (totalYield !== null) {
      this.createCommentForYield(oldActiveCrop, batch);
    }

    const oldUuid = field.activeCrop.key;
    const oldCropRef = db.collection('crops').doc(oldUuid);

    batch.update(oldCropRef, oldActiveCrop);

    this.harvestedComment(oldUuid, value.comment, harvested, batch);

    // XXX: for partially planted crops on field
    // This is the sub field, copied from the base field
    // with id baseFieldId
    // In this case, we don't handle permanentCrop or normalCrop
    // We just need to harvest the field and free up the area form the base field
    
    const isSubField = !!field.baseFieldId;

    if (!isSubField) {
      if (parseInt(oldActiveCrop.type, 10) === parseInt(CropType.Permanent, 10)) {
        this.handlePermanentCrop(oldActiveCrop, batch);
      } else {
        this.handleAnnualCrop(field, harvested, batch);
      }
    } else {
      const fieldRef = db.collection('fields').doc(field.key);
      const baseFieldRef = db.collection('fields').doc(field.baseFieldId);
      batch.set(baseFieldRef, {
        usedCropArea: {
          [activeCrop.key]: fbase.firestore.FieldValue.delete(),
        },
      }, { merge: true });
      batch.set(fieldRef, {
        state: FieldState.Abandoned,
      }, { merge: true });
    }

    const force_create = false;
    const abandonedFieldRef = db.collection('fields').doc();
    const abandonedFieldRefId = abandonedFieldRef.id;
    const abandonedCropRef = db.collection('crops').doc();
    const abandonedCropRefId = abandonedCropRef.id;

    const abandonedField = this.getAbandonedField(
      field.key,
      abandonedFieldRefId,
      abandonedCropRefId,
      openCompany,
      force_create,
    );
    const abandonedCrop = this.getAbandonedCrop(
      abandonedField,
      oldActiveCrop,
      abandonedFieldRefId,
      abandonedCropRefId,
      openCompany,
      force_create,
    );
    batch.set(abandonedCropRef, abandonedCrop);

    const {
      field: { f },
      ...abandonedActiveCrop
    } = abandonedCrop;
    batch.set(abandonedFieldRef, {
      ...abandonedField,
      activeCrop: abandonedActiveCrop,
    });

    batch.commit().then(() => {
      if (value.stop_field_sharing || isSubField) {
        actions.stopFieldSharing(field.key, [openCompany]);
      }
    });

    this.props.actions.setFieldsTab(openCompany as string, FIELDS_TAB_HARVESTED);
    this.props.actions.openField(abandonedFieldRef.id, abandonedCropRef.id);
    const path = getFieldPath(openCompany as string, abandonedFieldRef.id, abandonedCropRef.id);
    this.props.history?.push(path);
    this.props.onClose();
  }

  getAbandonedField(
    realFieldId,
    fieldRefId,
    cropRefId,
    companyId,
    force_create,
  ) {
    const { field } = this.props;
    const { activeCrop, activeCrops, collaborators, ...fieldRest } = field;

    const abandonedField = {
      ...fieldRest,
      company_id: companyId,
      state: FieldState.Abandoned,
      realFieldId: realFieldId,
      abandonedCropId: cropRefId,
      key: fieldRefId,
      force_create,
    };

    return abandonedField;
  }

  getAbandonedCrop(
    abandonedField,
    oldActiveCrop,
    fieldRefId,
    cropRefId,
    companyId,
    force_create,
  ) {
    const { activeCrop, abandonedCropId, ...abandonedFieldRest } =
      abandonedField;

    const abandonedCrop = {
      ...oldActiveCrop,
      field_id: fieldRefId,
      field: {
        ...abandonedFieldRest,
      },
      key: cropRefId,
      company_id: companyId,
      state: CropState.Abandoned,
      realCropId: oldActiveCrop.key,
      force_create,
    };

    return abandonedCrop;
  }

  createCommentForYield(crop, batch) {
    const { field, auth } = this.props;
    const commentRef = firebase
      .firestore()
      .collection('comments')
      .doc();

    const comment = yieldComment(commentRef.id, field, crop, auth);
    batch.set(commentRef, comment);
  }

  handleAnnualCrop(field, harvested, batch) {
    const { auth } = this.props;
    const { archive_field } = this.state.harvest;

    const db = firebase.firestore();
    const fieldRef = db.collection('fields').doc(field.key);
    const commentRef = db.collection('comments').doc();

    const cropRef = db.collection('crops').doc();

    const newActiveCrop = {
      ...getNoCrop(cropRef.id, auth),
      field_id: field.key,
      company_id: field.company_id,
    };

    // Add comment
    const comment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        newActiveCrop,
        auth as any,
        'system.crop.withoutSince',
      ),
      text: {
        languageConstant: 'crop.field_without_crop_since',
        date: harvested.toDate(),
      },
    };

    batch.set(cropRef, newActiveCrop);
    batch.set(commentRef, comment);
    batch.update(fieldRef, {
      activeCrop: newActiveCrop,
    });

    if (archive_field) {
      this.handleArchiveField(field, newActiveCrop, batch);
    }
  }

  harvestedComment(oldActiveCropUuid, note, harvested, batch) {
    const { field, auth } = this.props;
    const commentRef = firebase
      .firestore()
      .collection('comments')
      .doc();
    const oldCropRef = firebase
      .firestore()
      .collection('crops')
      .doc(oldActiveCropUuid);

    const text = {
      languageConstant: 'field.harvested_on',
      date: harvested.toDate(),
    };

    // Add comment
    const comment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        field.activeCrop,
        auth as any,
        'system.crop.harvested',
      ),
      text: text,
    };

    batch.set(oldCropRef, { lastComment: comment }, { merge: true });
    batch.set(commentRef, comment);
  }

  handlePermanentCrop(oldCrop, batch) {
    const db = firebase.firestore();
    const { auth, field } = this.props;
    const cropRef = db.collection('crops').doc();
    const commentRef = db.collection('comments').doc();
    const fieldRef = db.collection('fields').doc(field.key);
    const { archive_field } = this.state.harvest;

    const newActiveCrop = {
      ...oldCrop,
      field_id: field.key,
      company_id: field.company_id,
      sown_on: oldCrop.sown_on,
      last_harvested_on: oldCrop.harvested_on,
      harvested_on: null,
      key: cropRef.id,
      state: CropState.Planted,
      // reset properties
      analysis: null,
      waittimes: null,
      lastComment: null,
    };

    // Add comment
    const comment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        newActiveCrop,
        auth as any,
        'system.crop.sown',
      ),
      text: { languageConstant: 'crop.isActiveNow' },
    };

    batch.set(cropRef, newActiveCrop);
    batch.set(commentRef, comment);
    batch.update(fieldRef, {
      activeCrop: newActiveCrop,
    });

    if (archive_field) {
      this.handleArchiveField(field, newActiveCrop, batch);
    }
  }

  handleArchiveField(field, crop, batch) {
    const db = firebase.firestore();
    const { auth } = this.props;
    const commentRef = db.collection('comments').doc();
    const fieldRef = db.collection('fields').doc(field.key);

    // Add comment
    const comment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        crop,
        auth as any,
        'system.archive',
      ),
      text: { languageConstant: 'field.movedToArchive' },
    };

    batch.set(commentRef, comment);
    batch.update(fieldRef, {
      modified: moment().toDate(),
      modified_by: UserObj(this.props.auth as any),
      state: FieldState.Archived,
    });
  }

  onChange(value) {
    this.setState({
      harvest: {
        ...value,
      },
    });
  }

  render() {
    const { show, onClose, field, userOwnsField, isCompanyAdmin } = this.props;
    if (!show) {
      return null;
    }
    return (
      <Dialog
        show={show}
        onClose={onClose}
        title={I18n.t('field.harvested')}
        footer={
          <div className="d-flex flex-grow-1">
            <button className="ml-auto btn btn-secondary" onClick={onClose}>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}>
              {I18n.t('save')}
            </button>
          </div>
        }>
        <div style={{ flex: 1 }}>
          <div>
            <span style={{ ...constants.styles.boxHeader }}></span>
            <div
              style={{
                ...constants.styles.box,
                ...{ paddingLeft: 0, paddingRight: 0 },
              }}>
              {getFormComponent(
                this.ref,
                this.state.harvest,
                field,
                userOwnsField,
                isCompanyAdmin,
                this.onChange.bind(this),
              )}
            </div>
          </div>

          <AlertDialog
            show={!!this.state.alertMessage}
            onClose={() =>
              this.setState({ alertMessage: null, alertTitle: null })
            }
            title={this.state.alertTitle}
          >
            {this.state.alertMessage}
          </AlertDialog>
        </div>
      </Dialog>
    );
  }
}

const getFormComponent = (
  componentRef,
  harvestState,
  field,
  userOwnsField,
  isCompanyAdmin,
  onChange,
) => {
  const fieldHasCollaborators = hasCollaborators(field);

  const isSubField = !!field.baseFieldId;

  if (!isCompanyAdmin) {
    // * user is owner, not admin, has collaborators
    // * user is owner, not admin
    // * user is collaborator, not admin
    return (
      <Form
        ref={componentRef}
        type={HarvestFieldYield.model}
        options={() =>
          HarvestFieldYield.options(
            harvestState,
            field.size,
            toDate(field.activeCrop.sown_on),
          )
        }
        value={harvestState}
        onChange={onChange}
      />
    );
  }

  if (!userOwnsField && isCompanyAdmin) {
    // * user is collaborator, is admin
    return (
      <Form
        ref={componentRef}
        type={isSubField ? HarvestFieldForCollaboratorsModel.modelPartialCrop : HarvestFieldForCollaboratorsModel.model}
        options={() =>
          HarvestFieldForCollaboratorsModel.options(
            harvestState,
            field.size,
            toDate(field.activeCrop.sown_on),
            isSubField,
          )
        }
        value={harvestState}
        onChange={onChange}
      />
    );
  }

  if (userOwnsField && isCompanyAdmin && fieldHasCollaborators) {
    // * user is owner, is admin, has collaborators
    return (
      <Form
        ref={componentRef}
        type={isSubField ? HarvestModelSharedField.modelPartialCrop : HarvestModelSharedField.model}
        options={() =>
          HarvestModelSharedField.options(
            harvestState,
            field.size,
            toDate(field.activeCrop.sown_on),
            isSubField,
          )
        }
        value={harvestState}
        onChange={onChange}
      />
    );
  }

  // * user is owner, is admin: add yield, archive
  return (
    <Form
      ref={componentRef}
      type={isSubField ? HarvestModel.modelPartialCrop : HarvestModel.model}
      options={() =>
        HarvestModel.options(
          harvestState,
          field.size,
          toDate(field.activeCrop.sown_on),
          isSubField,
        )
      }
      value={harvestState}
      onChange={onChange}
    />
  );
};

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompany = selectors.getOpenCompanyId(state);
  const openFieldId = selectors.getOpenFieldId(state);
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, openFieldId);
  const userOwnsField = field && isFieldOwner(field.company_id, openCompany);

  let activeCrop = field && getCropByFieldId(
    state.firestore.data,
    field.company_id,
    field.key,
    field.activeCrop.key,
  );

  if (!activeCrop) {
    activeCrop = field && getCrop(
      state.firestore.data,
      field.company_id,
      field.key,
      field.activeCrop.key,
    );
  }

  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const isCompanyAdmin = isAdmin(myCompanyProfile);

  return {
    auth: state.firebase.profile,
    openCompany,
    field,
    activeCrop,
    userOwnsField,
    isCompanyAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          updateField,
          openField,
          addComment,
          stopFieldSharing,
          setFieldsTab,
        },
      ),
      dispatch,
    ),
  };
};

export default compose<typeof HarvestForm>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(HarvestForm);
