import moment from 'moment';

import t from 'tcomb-form';
import { toDate } from 'farmerjoe-common';

export const dateTransformer = {
  format: value => moment(toDate(value)).toDate(),
  parse: value => value,
};

const Nil = t.Nil;

function toNull(value) {
  return (t.String.is(value) && value.trim() === '') || Nil.is(value)
    ? null
    : value;
}

export const numberTransformer = {
  /**
   * from value to string
   *
   * @param value
   * @returns {*}
   */
  format: value => {
    if (Nil.is(value)) {
      return null;
    }

    return String(transformToCorrectNumber(value));
  },
  /**
   * from string to value
   *
   * @param value
   * @returns {number}
   */
  parse: value => {
    if (value) {
      value = value.replace(/,/g, '.');
    }

    value = transformToCorrectNumber(value);

    const n = parseFloat(value);
    const isNumeric = value - n + 1 >= 0;
    return isNumeric ? n : toNull(value);
  },
};

/**
 * 01 => 1
 * 09 => 9
 *
 * 0.9 => 0.9
 *
 * .1 => 0.1
 * @param value
 * @returns {*}
 */
function transformToCorrectNumber(value) {
  if (value && value.length > 1) {
    if (value[0] === '.' || value[0] === ',') {
      return '0' + value;
    }

    if (Number(value[0]) === 0 && (value[1] !== '.' && value[1] !== ',')) {
      return value
        .split('')
        .slice(1)
        .join('');
    }
  }

  return value;
}

export const MultiSelectTransformer = {
  format: value => {
    return Array.isArray(value) ? value : [];
  },
  parse: value => {
    // Just trick it here by returning the first value. Otherwise validation won't work
    // TODO: find better way to do this: https://github.com/gcanti/tcomb-form-native/issues/382
    return value ? value[0] : [];
  },
};

/**
 * transform null LatLon
 **/
export const latLonTransformer = {
  format: value => {
    if (!value) {
      return '';
    }

    return String(transformToCorrectNumber(value));
  },
  parse: value => {
    if (!value) {
      return 0;
    }
    return parseFloat(value);
  },
};

export const whiteSpaceTrimmerTransformer = {
  format: value => {
    return value;
  },
  parse: value => {
    return typeof value === 'string' ? value.trim() : value;
  },
};
