import React from 'react';
import { cloneDeep } from 'lodash-es';
import t from 'tcomb-form';

import ColorPicker from '../../../../../tcomb/templates/ColorPicker';
import stylesheet from '../../../../../tcomb/stylesheets/style';

// Our color model
const model = () => {
  return t.struct({
    color: t.String,
  });
};

const options = () => {
  const styleColor = cloneDeep(stylesheet);
  return {
    stylesheet: stylesheet,
    auto: 'none',
    fields: {
      color: {
        factory: ColorPicker,
        stylesheet: styleColor,
      },
    },
  };
};

export default { model, options };
