import React, { useState } from 'react';
import { getFieldMarkers } from 'farmerjoe-common/lib/selectors/markers';

import MarkMapArea from '../Common/MarkMapArea';
import LineWithArrow from '../Common/LineWithArrow';
import SummaryLine from '../Common/SummaryLine';
import Dialog from '../Dialog/Dialog';
import I18n from '../../language/i18n';

type TPolygon = {
  polygon: any[];
  center: {
    latitude: number;
    longitude: number;
  } | null;
  areaSize?: number;
};

type TMarkAreaProps = {
  field: any;
  showDialog: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSave: (value: TPolygon) => void;
  markedArea: TPolygon;
  disabled: boolean;
};

const FertilizerMarkArea = ({ field, showDialog, onOpen, onClose, onSave, markedArea, disabled }: TMarkAreaProps) => {
  const [change, setChange] = useState(markedArea);
  const markers = getFieldMarkers([field]);
  return (
    <div>
      <LineWithArrow
        onClick={() => {
          if (disabled) {
            return;
          }
          onOpen();
        }}
        text={I18n.t('markArea')}
        disabled={disabled}
      />
      {markedArea.polygon && markedArea.polygon.length ? (
        <SummaryLine onClear={() => onSave({polygon: [], center: null})} text={I18n.t('fertilizer.applyAcrossSelectedAreaSummary', { areaSize: markedArea.areaSize })} />
      ) : null}
      <Dialog
        show={showDialog}
        title={
          <div className="header">
            <span>{I18n.t('fertilizer.applyAcrossSelectedArea')}</span>
          </div>
        }
        onClose={onClose}
        className="modal-xl"
        footer={
          <div className="d-flex flex-grow-1">
            <button
              className="ml-auto btn btn-secondary"
              onClick={onClose}
            >
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              onClick={() => onSave(change)}
              disabled={false}>
              {I18n.t('done')}
            </button>
          </div>
        }>
        <div style={{ backgroundColor: '#000' }}>
          <MarkMapArea
            position={field.region}
            markers={markers}
            pinPosition={change.center}
            onChange={(value) => setChange(value)}
            polygonDrawing={true}
            polygon={field.polygon}
            currentMarkedArea={markedArea}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default FertilizerMarkArea;
