import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import moment from 'moment/moment';
import { find, includes, isString, merge } from 'lodash-es';
import * as Yup from 'yup';

import * as companySelectors from 'farmerjoe-common/lib/selectors/companies';
import {
  getCompanyGroupProfileForLoggedInUser,
  getCompanyUser,
  getGroupUsers,
} from 'farmerjoe-common/lib/selectors/user';
import * as employeeCreators from 'farmerjoe-common/lib/actions/employee';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { generateCompanyUser, UserObj } from 'farmerjoe-common/lib/utils/User';
import {
  COMPANY_MAIN_GROUP_KEY,
  getBrowsingGroupKey,
  getGroup,
  getOpenGroupId,
} from 'farmerjoe-common/lib/selectors/groups';
import { getGroupQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Groups';
import { isNotMainGroup } from 'farmerjoe-common/lib/utils/firestoreRedux/Utils';
import {
  getUserQuery,
  getUsersQuery,
} from 'farmerjoe-common/lib/utils/firestoreRedux/Users';
import { GroupType, UserRole, UserState, NOTIFICATION_REASON_FIELD_ACTIVITY, NOTIFICATION_TYPE } from 'farmerjoe-common/lib/flow/types';
import { DefaultEmployeeAcl } from 'farmerjoe-common/lib/constants/employees';
import { getUsage } from 'farmerjoe-common/lib/requests/billing';
import { SUBSCRIPTION_PLAN_QUOTA_REACHED } from 'farmerjoe-common/lib/constants/billing';
import { toggleNotifications } from 'farmerjoe-common/lib/actions/notifications';

import './style.css';

import Dialog, {
  AlertConfirmDialog,
  AlertDialog,
} from '../../components/Dialog/Dialog';
import DeleteButton from '../../components/Common/DeleteButton';
import withRouter from '../../components/Router/withRouter';
import EmployeeForm from '../../components/Employees/Form';
import { Loading } from '../../components/Loading/Loading';
import { getEmployeePath } from '../../utils/page';
import I18n from '../../language/i18n';
import type {
  Company,
  Employee,
  ModelListDataObject,
  User,
} from '../../flowTypes';
import { getUidToken } from '../../utils/auth';
import { DataContext } from '../../contexts/CompanyEmployeesProvider';

type Props = {
  firebase?: (...args: Array<any>) => any;
  auth?: User;
  companyId?: string;
  company?: Company;
  editEmployee?: Employee;
  editMode?: boolean;
  fieldId?: string;
  users?: [Employee];
  actions?: {
    addEmployee: typeof employeeCreators.addEmployee;
    updateEmployee: typeof employeeCreators.updateEmployee;
    editEmployee: typeof employeeCreators.editEmployee;
    deleteEmployee: (...args: Array<any>) => any;
    toggleNotifications: typeof toggleNotifications;
  };
  onClose: (...args: Array<any>) => any;
  show: boolean;
  history?: Record<string, any>;
  group?: any;
  groupId?: any;
  role?: any;
  groupType?: GroupType;
  myCompanyProfile?: any;
  refreshData?: () => void;
};

type State = {
  employee: Employee;
  /**
   * Controls the Modal with the emails selection
   */
  openSelectEmail: boolean;
  data: [ModelListDataObject];
  alertTitle: string | null;
  alertMessage: string | null | React.ReactNode;
  showActivateDialog: boolean;
  loading: boolean;
};

const ref = React.createRef();



const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string()
    .email()
    .required('Required'),
  views: Yup.object().shape({
    fields: Yup.boolean(),
    crops: Yup.boolean(),
    comments: Yup.boolean(),
    users: Yup.boolean(),
  }),
});

class CreateEmployee extends React.Component<Props, State> {
  form: any | null | undefined;

  constructor(props, context) {
    super(props, context);

    const key = props.firebase
      .firestore()
      .collection('users')
      .doc().id;

    let state: State = {
      employee: {
        key: key,
        name: '',
        email: '',
        department: '',
        role: props.role ? props.role : UserRole.Standard,
        fields: {},

        active: false,
        invitation: true,
        invited_on: moment().toDate(),
        invited_by: UserObj(props.auth),

        views: {
          crops: false,
          fields: true,
          comments: false,
          users: false,
        },
        acl: DefaultEmployeeAcl,
        modified: new Date(),
        modified_by: null as any,
        state: 0,
        group_id: '',
        group_name: '',
      },

      openSelectEmail: false,
      data: [{ label: 'email', value: 'email' }],
      alertTitle: null,
      alertMessage: null,
      showActivateDialog: false,
      loading: false,
    };

    state.employee = merge(state.employee, props.editEmployee);

    if (!props.editMode && props.fieldId) {
      state = {
        ...state,
        employee: {
          ...state.employee,
          fields: { [props.fieldId]: true },
          views: {
            ...state.employee.views,
            fields: false,
          } as any,
        },
      };
    }

    this.state = state;
  }

  componentWillUnmount() {
    this.props.actions?.editEmployee(null, null);
  }

  componentWillReceiveProps(next) {
    const { employee } = this.state;
    if (
      employee !== next.editEmployee &&
      (next.editEmployee && Object.keys(next.editEmployee).length)
    ) {
      this.setState({
        employee: merge(employee, next.editEmployee),
      });
    }
  }

  onSave = (values, { setSubmitting }) => {
    let { notifications, ...employee } = values;
    let { company, editMode, actions, users, group, groupType = 'employee' } = this.props;

    // If value is null, then the form has errors
    if (employee === null) {
      this.setState({
        alertMessage: I18n.t('please_correct_your_entry'),
        alertTitle: I18n.t('error'),
      });
      return;
    }

    // structs come out as Struct custom objects which firestore doesn"t like so turn them into plain objects
    employee = JSON.parse(JSON.stringify(employee));

    if (!group) {
      group = {
        key: COMPANY_MAIN_GROUP_KEY,
        name: COMPANY_MAIN_GROUP_KEY,
      };
    }

    const existingUser = find(users, ['email', employee.email]);
    employee = generateCompanyUser(employee, existingUser, group, company?.key as any);

    // Make some checks when creating new user
    if (!editMode && existingUser) {
      this.setState({
        alertMessage: I18n.t('acl.user.alertFound.message'),
        alertTitle: I18n.t('acl.user.alertFound.title'),
      });
      return;
    }

    if (
      employee.role !== UserRole.Admin &&
      (!employee.views.fields &&
        (!employee.fields || !includes(Object.values(employee.fields), true)))
    ) {
      this.setState({
        alertMessage: I18n.t('acl.user.alertNoFields.message'),
        alertTitle: I18n.t('acl.user.alertNoFields.title'),
      });
      return;
    }

    if (editMode) {
      // If we have an invitation and the email address has been changed - delete the employee and add a new one
      // with the correct email
      if (
        employee.invitation === true &&
        employee.email !== this.props.editEmployee?.email
      ) {
        // delete employee.key
        actions?.deleteEmployee(company?.key, this.props.editEmployee)
          .catch(message => {
            if (isString(message)) {
              this.setState({
                alertMessage: message,
                alertTitle: I18n.t('error'),
              });
            }
          });
        actions?.addEmployee(company as any, employee, groupType);
      } else {
        actions?.updateEmployee(company as any, employee);
      }
      this.props.refreshData && this.props.refreshData();
    } else {
      actions?.addEmployee(company as any, employee, groupType);
    }

    this.toggleNotifications(values, employee);

    this.close();
  };

  onChange(value) {
    this.setState({
      employee: {
        ...this.state.employee,
        ...value,
      },
    });
  }

  close() {
    const { actions, onClose } = this.props;
    actions?.editEmployee(null, null);
    onClose && onClose();
  }

  submitMyForm = null;

  handleSubmitMyForm = e => {
    (ref.current as any).submitForm();
  };

  bindSubmitForm = submitForm => {
    this.submitMyForm = submitForm;
  };

  render() {
    const { editMode, show, groupType = 'employee' } = this.props;
    const { employee } = this.state;

    const navTitle = editMode ? I18n.t('edit') : I18n.t('add');
    const active =
      !this.state.employee.state || this.state.employee.state !== UserState.Deactivated;

    return (
      <Dialog
        show={show}
        onClose={this.close.bind(this)}
        dialogClassName={'employee-form-dialog'}
        title={navTitle}
        footer={
          <>
            {this.state.loading ? null : (
              <div className="buttons">
                <button
                  className="ml-auto btn btn-secondary"
                  onClick={this.close.bind(this)}>
                  {I18n.t('cancel')}
                </button>{' '}
                <button
                  className="btn btn-primary"
                  onClick={this.handleSubmitMyForm}>
                  {I18n.t('save')}
                </button>
              </div>
            )}
          </>
        }>
        <div style={{ flex: 1 }}>
          {this.state.loading ? <Loading /> : (
            <div style={{ flex: 1 }}>
              <EmployeeForm
                ref={ref}
                initialValues={employee}
                validate={values =>
                  new Promise((resolve, reject) => {
                    return schema
                      .validate(values, { abortEarly: false })
                      .then(valid => {
                        resolve(valid);
                      })
                      .catch(err => {
                        const errors = {};

                        err.inner.forEach(innerError => {
                          errors[innerError.path] = innerError.errors.join(' ');
                        });
                        this.setState({
                          alertMessage: I18n.t('please_correct_your_entry'),
                          alertTitle: I18n.t('error'),
                        });
                        reject(errors);
                      });
                  })
                }
                handleSubmit={this.onSave}
                groupType={groupType}
                myCompanyProfile={this.props.myCompanyProfile}
              />

              {editMode
                ? (
                  <div
                    className={'d-flex flex-row justify-content-center mt-5 mb-5'}>
                    {active
                      ? this.renderDeactivateButton()
                      : [
                        <div className={'mr-3'}>
                          {this.renderActivateButton()}
                        </div>,
                        this.renderDeleteButton(),
                      ]}
                  </div>
                )
                : null}
            </div>
          )} 
        </div>
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
        >
          {this.state.alertMessage}
        </AlertDialog>
      </Dialog>
    );
  }

  async getUsageFromApi() {
    const { company } = this.props;
    if (!company) {
      console.log('company is undefined');
      return Promise.reject(new Error('Unexpected error ocured!'));
    }

    const uidToken = await getUidToken();

    return getUsage(uidToken, company.key)
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error('Unexpected error occured! Try again!');
        }
        const content = await response.json();
        if (content.totalUsers >= content.maxAllowedUsers) {
          throw new Error(SUBSCRIPTION_PLAN_QUOTA_REACHED);
        }
        return Promise.resolve(true);
      });
  }

  renderActivateButton() {
    const { editEmployee } = this.props;

    const activateTitle = I18n.t(
      `${
        editEmployee?.role === UserRole.Advisor
          ? 'employees.activateAdvisor'
          : 'employees.activate'
      }`,
    );
    const activateDesc = I18n.t(
      `${
        editEmployee?.role === UserRole.Advisor
          ? 'employees.activateDoYouWantAdvisor'
          : 'employees.activateDoYouWant'
      }`,
    );

    return [
      <AlertConfirmDialog
        key="activate"
        title={activateTitle}
        onClose={result => {
          if (result === 'yes') {
            this.setState({ showActivateDialog: false, loading: true });
            return this
              .getUsageFromApi()
              .then(() => {
                const { actions, company, editEmployee, groupType = 'employee' } = this.props;
                actions?.addEmployee(company as any, {
                  ...editEmployee,
                  active: true,
                  invitation: false,
                  state: 1,
                } as any,
                groupType);
              })
              .catch(error => {
                this.handleCheckUsageLimitsError(error);
              }).finally(() => this.setState({ loading: false }));
          }
          return false;
        }}
        show={this.state.showActivateDialog}>
        {activateDesc}
      </AlertConfirmDialog>,
      <button
        key={1}
        className={'btn btn-primary'}
        onClick={() => this.setState({ showActivateDialog: true })}>
        {activateTitle}
      </button>,
    ];
  }

  renderDeleteButton() {
    const { employee } = this.state;
    const title =
      employee.role === UserRole.Advisor
        ? I18n.t('acl.user.deleteAdvisor')
        : I18n.t('acl.user.delete');
    const desc =
      employee.role === UserRole.Advisor
        ? I18n.t('acl.user.doYouWantToDeleteAdvisor')
        : I18n.t('acl.user.doYouWantToDeleteUser');

    return (
      <DeleteButton
        key="delete"
        onDelete={() => {
          const { actions, company } = this.props;

          actions?.deleteEmployee(company?.key, this.state.employee)
            .then(() => {
              this.props.history?.push(getEmployeePath(company?.key as any));
              this.props.refreshData && this.props.refreshData();
              this.close();
            })
            .catch(message => {
              if (isString(message)) {
                this.setState({
                  alertMessage: message,
                  alertTitle: I18n.t('error'),
                });
              }
            });
        }}
        buttonText={title}
        alertTitle={title}
        alertMessage={desc}
      />
    );
  }

  renderDeactivateButton() {
    const { editEmployee } = this.props;
    const title =
      editEmployee?.role === UserRole.Advisor
        ? I18n.t('acl.user.deactivateAdvisor')
        : I18n.t('acl.user.deactivate');
    const desc =
      editEmployee?.role === UserRole.Advisor
        ? I18n.t('acl.user.doYouWantToDeactivateAdvisor')
        : I18n.t('acl.user.doYouWantToDeactivateUser');

    return (
      <DeleteButton
        key="deactivate"
        onDelete={() => {
          const { actions, company } = this.props;
          return actions?.updateEmployee(company as any, {
            ...editEmployee,
            active: false,
            state: -1,
          } as any);
        }}
        buttonText={title}
        alertTitle={title}
        alertMessage={desc}
      />
    );
  }

  private handleCheckUsageLimitsError(error: Error) {
    if (error.message === SUBSCRIPTION_PLAN_QUOTA_REACHED) {
      const message = <div>
        <div> {I18n.t('billing.addMoreSeats')} </div>
        <Link
          key={'billing'}
          to={`/company/${this.props.companyId}/billing`}
          onClick={() => {
            this.closeAlert();
            this.close();
            this.props.history?.replace(`/company/${this.props.companyId}/billing`);
          }}
        >
          {I18n.t('billing.editSeats')} &#8594;
        </Link>
      </div>;
      this.showAlert(I18n.t('billing.addSeats'), message);
      return;
    }

    this.showAlert(I18n.t('error'), error.message);
  }

  private showAlert(title: string, message: string | React.ReactNode) {
    this.setState({
      alertMessage: message,
      alertTitle: title,
    });
  }

  private closeAlert() {
    this.setState({
      alertMessage: null,
    });
  }

  toggleNotifications = (formValues, employee) => {
    const { notifications } = formValues;
    if (!notifications) {
      return;
    }

    const promises: any[] = [];
    const hasNotifications = !!this.props.myCompanyProfile.notifications;

    Object.keys(notifications).forEach((notificationType) => {
      const enableNotification = notifications[notificationType];

      if (!enableNotification) {
        if (!hasNotifications) {
          return;
        }
        const filteredUserNotifications =
          this.props.myCompanyProfile.notifications.filter(
            (n) => n.from !== employee.key,
          );
        const withoutThisNotification =
          this.props.myCompanyProfile.notifications.filter(
            (n) => n.from === employee.key && n.type !== notificationType,
          );

        const disableNotificationPromise =
          this.props.actions?.toggleNotifications((this.props.companyId as string), [
            ...filteredUserNotifications,
            ...withoutThisNotification,
          ]);
        promises.push(disableNotificationPromise);
        return;
      }

      if (!hasNotifications) {
        const _enableNotificationPromise =
          this.props.actions?.toggleNotifications((this.props.companyId as string), [
            {
              from: employee.key,
              reason: NOTIFICATION_REASON_FIELD_ACTIVITY,
              type: notificationType as NOTIFICATION_TYPE,
            },
          ]);
        promises.push(_enableNotificationPromise);
        return;
      }
      const enableNotificationPromise =
        this.props.actions?.toggleNotifications((this.props.companyId as string), [
          ...this.props.myCompanyProfile.notifications,
          {
            from: employee.key,
            reason: NOTIFICATION_REASON_FIELD_ACTIVITY,
            type: notificationType,
          },
        ]);
      return promises.push(enableNotificationPromise);
    });

    Promise.resolve(promises).catch((e) => console.error(e));
  };
}

const selector = (
  state,
  ownProps: {
    fieldId?: string;
    groupId?: string;
    role?: string;
  },
) => {
  const companyId = selectors.getOpenCompanyId(state);
  const company = companySelectors.getCompany(state.firestore.data, companyId);
  const editEmployeeId = selectors.getEditEmployee(state);
  const editEmployee = editEmployeeId
    ? getCompanyUser(state, editEmployeeId, companyId)
    : {};
  const fieldId = ownProps.fieldId ? ownProps.fieldId : null;
  let openGroupId = ownProps.groupId ? ownProps.groupId : getOpenGroupId(state);

  const browsingGroup = getBrowsingGroupKey(state, companyId);
  if (browsingGroup !== COMPANY_MAIN_GROUP_KEY) {
    openGroupId = browsingGroup;
  }
  const users = getGroupUsers(state, companyId, openGroupId);
  const group = getGroup(state, companyId, openGroupId);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    companyId,
  );
  const role = ownProps.role ? ownProps.role : '';
  const editMode = editEmployee ? !!Object.keys(editEmployee).length : false;

  return {
    companyId,
    myCompanyProfile,
    editEmployeeId,
    company,
    openGroupId,
    group,
    browsingGroup,
    fieldId,
    role,
    auth: state.firebase.profile,
    editMode,
    editEmployee,
    users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...employeeCreators,
          toggleNotifications,
        },
      ),
      dispatch,
    ),
  };
};

const HOC = (props) => {
  const { refreshData } = useContext(DataContext);
  return <CreateEmployee {...props} refreshData={refreshData} />;
};

export default compose<typeof CreateEmployee>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  firestoreConnect(props => {
    const { companyId, openGroupId, editEmployeeId } = props;

    const user = getUserQuery(companyId, editEmployeeId, openGroupId);

    let collection = {
      collection: 'companies',
      doc: companyId,
    };

    if (isNotMainGroup(props.openGroupId)) {
      collection = {
        collection: 'groups',
        doc: openGroupId,
      };
    }

    const users = getUsersQuery(companyId, openGroupId);

    const paths = [
      user,
      users,
      collection,
      { collection: 'companies', doc: companyId },
    ];

    if (isNotMainGroup(openGroupId)) {
      paths.push(getGroupQuery(openGroupId));
    }

    return paths;
  }),
  withRouter,
)(HOC);
