import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import shortid from 'shortid32';
import t from 'tcomb-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, includes, isEqual, pick } from 'lodash-es';
import { isLoaded, firestoreConnect } from 'react-redux-firebase';

import * as actionAnalysis from 'farmerjoe-common/lib/actions/analysis';
import * as actionCreators from 'farmerjoe-common/lib/actions/company';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { AnalysisState, NotACropState } from 'farmerjoe-common/lib/flow/types';
import { UserObj } from 'farmerjoe-common/lib/utils/User';
import { age } from 'farmerjoe-common/lib/utils/Crop';
import { basicCompanyData } from 'farmerjoe-common/lib/utils/Company';
import { getBrowsingGroupKey, getGroup } from 'farmerjoe-common/lib/selectors/groups';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import { getFavorites } from 'farmerjoe-common/lib/selectors/favorites';
import { getFavoritesQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Favorites';
import { getGroupQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Groups';
import { noLabelingReminder } from 'farmerjoe-common/lib/utils/Analyses';
import { outputDate } from 'farmerjoe-common';
import { searchForFieldAmongTheUniverse } from 'farmerjoe-common/lib/selectors/fields';

import BoxProbeNumber from './BoxProbeNumber';
import Cost from './Cost';
import Lab from './Lab';

import AccountEdit from '../Settings/AccountEdit';
import Box from '../Common/Box/Box';
import Icon from '../Common/Icon';
import FormCompany from '../Company/FormCompany';
import { AlertConfirmDialog, AlertDialog } from '../Dialog/Dialog';
import { Loading } from '../Loading/Loading';

import * as constants from '../../styles/style';
import AnalysisModel from '../../tcomb/models/analysis/analysis';
import I18n from '../../language/i18n';
import TextInput from '../../tcomb/templates/TextInput';
import { addKeyToObj } from '../../data/util';
import { calculatePrice } from '../../utils/analysis/Analysis';
import { captureException } from '../../utils/sentry';
import { dummy } from '../../utils/Comment';
import { getCountryName } from '../../utils/Countries';

const Form = t.form.Form;

// prevent the clicks from bubbling outside of the Dialog, this causes problems
const stopPropagation = e => e.stopPropagation();

const DIALOG_CONFIRM_VALUE = 'ok';
const DIALOG_CANCEL_VALUE = 'cancel';

/**
 * EN-958: Temporary fixed values for "Krauter Herrmann" company.
 * TODO: Remove in scope of EN-1017.
 */
const HERRMANN_COMPANY_ID = 'ECJN9QMJhSM2iTtf7gQD';
const HERRMANN_EMAILS = [
  'hw@herrmann-kraeuter.de',
  'qm@herrmann-kraeuter.de',
  'info@herrmann-kraeuter.de',
  'gh@herrmann-kraeuter.de',
  '',
];

type Props = {
  company?: any;
  favorite?: any;
  user?: any;
  field?: any;
  selectedLab?: any;
  selectedOffer?: any;
  saveState: (state: State) => any;
  firebase?: any;
  browsingGroup?: any;
  onCreatedAnalysis?: (analysis: any) => any;
  onCancel?: () => any;
  openView?: (arg0: string, arg1: { address: any, labSelected?: any }) => any;
  loaded?: any;
  goBack?: any;
  actions?: any;
  producer?: any;
};

type State = {
  analysis?: any;
  user?: any;
  alertMessage?: any;
  alertTitle?: any;
  confirm?: any;
  showAccountEdit?: any;
  showFormCompany?: any;
  config?: any;
  editCompany?: boolean;
  shareResultsWithEmails: string[];
};

class FormAnalyse extends Component<Props, State> {
  static propTypes = {
    company: PropTypes.object,
    openView: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  };

  alertShown: boolean;
  displayAddressAlert: boolean;
  profileAlertShown: boolean;
  displayProfileAlert: boolean;

  constructor(props) {
    super(props);

    if (props.state) {
      this.state = props.state;
    } else {
      let address = this.props.company;

      if (this.props.favorite) {
        address = this.props.favorite;
      }

      const { user } = this.props;

      const { activeCrop } = this.props.field;

      let cropAge: any = '';

      if (activeCrop.sown_on) {
        cropAge = age(activeCrop.sown_on, activeCrop.harvested_on);
      }

      this.state = {
        analysis: {
          labSelected: this.props.selectedLab,
          labOffer: this.props.selectedOffer,
          form: {
            crop: {
              name: activeCrop.name,
              age: String(cropAge),
              sown_on: activeCrop.sown_on ? outputDate(activeCrop.sown_on) : '',
            },
          },
          company_address: {
            name: get(address, 'name'),
            street: get(address, 'street', null),
            zip: get(address, 'zip', null),
            city: get(address, 'city', null),
            country: get(address, 'country', null),
            email: get(address, 'email', null),
            tel: get(address, 'tel', null),
          },
          company_id: address.key,
          invoice_address: {
            name: get(address, 'name'),
            street: get(address, 'street', null),
            zip: get(address, 'zip', null),
            city: get(address, 'city', null),
            country: get(address, 'country', null),
            email: get(address, 'email', null),
            tel: get(address, 'tel', null),
          },
          analyse_number: shortid.generate(),
        },
        user: {
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          phoneNumber: user.phoneNumber,
        },
        alertMessage: null,
        alertTitle: null,
        confirm: null,
        showAccountEdit: false,
        showFormCompany: false,
        config: this.getOfferConfig(),
        shareResultsWithEmails: this.props.company?.key === HERRMANN_COMPANY_ID ? HERRMANN_EMAILS : ['', '', '', '', ''],
      };
    }

    this.alertShown = false;
    this.displayAddressAlert = false;
    if (!this.hasAddress(this.state.analysis.company_address)) {
      this.displayAddressAlert = true;
    }

    this.profileAlertShown = false;
    this.displayProfileAlert = false;
    if (!this.isProfileComplete(this.state.user)) {
      this.displayProfileAlert = true;
    }
  }

  getOfferConfig() {
    const { selectedLab, selectedOffer, field } = this.props;
    let formFor = 'field';

    if (field.stock) {
      formFor = field.stockType;
    }

    const config = get(selectedLab, `analysisTypes.${selectedOffer}.${formFor}`);

    return config;
  }

  componentWillUnmount() {
    this.props.saveState(this.state);
  }

  /**
   * From now on use the refresh action to modify the NavBar props
   */
  componentDidMount() {
    this.setState({ editCompany: false });
  }

  componentWillUpdate(nextProps) {
    let newInvoiceAddress = this.state.analysis.invoice_address;
    if (this.props.favorite === null && nextProps.favorite !== null) {
      newInvoiceAddress = nextProps.favorite;
      this.setState({
        analysis: {
          ...this.state.analysis,
          invoice_address: newInvoiceAddress,
        },
      });
    }

    const originalCompanyAddress = this.state.analysis.company_address;
    const company = nextProps.company;
    const newCompanyAddress = {
      name: get(company, 'name'),
      street: get(company, 'street', null),
      zip: get(company, 'zip', null),
      city: get(company, 'city', null),
      country: get(company, 'country', null),
      email: get(company, 'email', null),
      tel: get(company, 'tel', null),
    };
    // If we just edited the company, change the address
    if (this.props.company !== nextProps.company) {
      this.setState({
        analysis: {
          ...this.state.analysis,
          company_address: newCompanyAddress,
        },
      });
    }

    if (this.props.user !== nextProps.user) {
      const user = nextProps.user;
      this.setState({
        user: {
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          phoneNumber: get(user, 'phoneNumber', null),
        },
      });
    }

    // If we have new invoice_address set it
    if (
      nextProps.invoice_address &&
      !isEqual(this.state.analysis.invoice_address, nextProps.invoice_address)
    ) {
      this.setState({
        analysis: {
          ...this.state.analysis,
          invoice_address: nextProps.invoice_address,
        },
      });
    }
    // If we don't have invoice_address, but have a company_address, set this company address as the invoice address
    // Also update the invoice address if the company address is used and has been edited
    else if (
      this.hasAddress(newCompanyAddress) &&
      (!this.hasAddress(newInvoiceAddress) ||
        (isEqual(newInvoiceAddress, originalCompanyAddress) &&
          this.props.company !== nextProps.company))
    ) {
      this.setState({
        analysis: {
          ...this.state.analysis,
          invoice_address: newCompanyAddress,
        },
      });
    }
  }

  /**
   * Handle saving when the user clicks the "save" button
   */
  onSave() {
    const value = (this.refs.form as any).getValue();

    // Check the address
    if (!this.hasAddress(this.state.analysis.company_address)) {
      this.noCompanyAddress();
      return;
    }

    // Check the address
    if (!this.isProfileComplete(this.state.user)) {
      this.profileNotComplete();
      return;
    }

    // //If value is null, then the form has errors
    if (value === null) {
      this.setState({
        alertMessage: I18n.t('please_correct_your_entry'),
        alertTitle: I18n.t('error'),
      });
      return;
    }

    // Check the address
    if (!this.hasAddress(this.state.analysis.invoice_address)) {
      this.setState({
        alertMessage: I18n.t('invoice.youDontHaveAddress'),
        alertTitle: I18n.t('error'),
      });
      return;
    }

    this.setState({
      confirm: {
        title: I18n.t('analysis.commissionWithCharge'),
        children: I18n.t('analysis.orderAnalysisWithCommission'),
        buttons: [
          { label: I18n.t('cancel'), value: DIALOG_CANCEL_VALUE },
          {
            label: I18n.t('analysis.orderSent'),
            value: DIALOG_CONFIRM_VALUE,
            className: 'btn-primary',
          },
        ],
        onClose: result => {
          if (result === DIALOG_CONFIRM_VALUE) {
            this.send();
          }
          this.setState({ confirm: null });
        },
      },
    });
  }

  private async send() {
    const {
      user,
      field,
      company,
      firebase,
      selectedLab,
      browsingGroup,
      producer,
      onCreatedAnalysis,
    } = this.props;
    const db = firebase.firestore();
    const batch = db.batch();
    const formConfig = this.state.config;

    const anaRef = db.collection('analyses').doc();
    const commentRef = db.collection('comments').doc();

    const analysis = {
      key: anaRef.id,
      ...this.state.analysis,
      created_by: UserObj(user),
      group_id: browsingGroup,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      company: {
        ...basicCompanyData(company),
        ggn_number: company.ggn_number,
        qs_number: company.qs_number,
      },
      field: pick(field, [
        'name',
        'key',
        'position',
        'size',
        'activeCrop.name',
        'activeCrop.key',
        'activeCrop.color',
        'activeCrop.sown_on',
        'activeCrop.art',
      ]),
      company_id: company.key,
      field_id: field.key,
      // state 0 - lab has not accepted the analysis yet
      state: AnalysisState.Inbox,
      // lab needs to accept/reject the analysis
      lab_accepted: null,
      labSelected: pick(selectedLab, [
        'key',
        'address',
        'name',
        'logo',
        'tel',
        'email',
      ]),
      formConfig: formConfig,
    };

    if (producer) {
      analysis.producer = producer;
    }

    analysis.form.offers.custom_notification_recipients =
      this.getAdditionalEmails();

    if (formConfig.prices) {
      analysis.analysisCost = calculatePrice(
        this.state.analysis.form,
        formConfig.prices,
      );
    }

    const comment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        field.activeCrop,
        user,
        'system.analysis',
        anaRef.id,
      ),
      text: analysis,
    };

    batch.set(anaRef, analysis);

    if (!field.stock) {
      batch.set(commentRef, comment);
    }

    analysis.sent = true;
    this.setState({ analysis });

    try {
      await batch.commit();
      if (onCreatedAnalysis) onCreatedAnalysis(analysis);
    } catch (error) {
      captureException(error, { analysis });
      console.error('Failed to register the analysis order.');
    }
  }

  onChange(value, path) {
    // Todo: move logic to this function
    if (AnalysisModel.onChange) {
      value = AnalysisModel.onChange(value, this.state.config, path);

      this.setState({
        analysis: {
          ...this.state.analysis,
          form: {
            ...this.state.analysis.form,
            ...value,
          },
        },
      });
    }
  }

  render() {
    const { loaded, goBack } = this.props;

    if (!loaded) {
      return [
        this.modalHeader(),
        <Modal.Body onClick={e => e.stopPropagation()} key="body">
          <Loading />
        </Modal.Body>,
      ];
    }

    if (this.displayAddressAlert && !this.alertShown) {
      this.noCompanyAddress();
    }

    // If the address alert has been taken care of and we nee dto display a profile error, do it now
    if (
      !this.displayAddressAlert &&
      this.displayProfileAlert &&
      !this.profileAlertShown
    ) {
      this.profileNotComplete();
    }

    return [
      this.modalHeader(),
      <Modal.Body onClick={e => e.stopPropagation()} key="body">
        {this._renderForm()}
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
        >
          {this.state.alertMessage}
        </AlertDialog>
        <AlertConfirmDialog
          {...this.state.confirm}
          show={!!this.state.confirm}
        />
        {this.state.showAccountEdit
          ? (
            <AccountEdit
              show={this.state.showAccountEdit}
              onClose={() => this.setState({ showAccountEdit: false })}
            />
          )
          : null}
        {this.state.showFormCompany
          ? (
            <FormCompany
              show={this.state.showFormCompany}
              onClose={() => {
                this.props.actions.editCompany(null);
                this.setState({ showFormCompany: false });
              }}
              required={true}
              goToInfo={false}
            />
          )
          : null}
      </Modal.Body>,
      <Modal.Footer onClick={e => e.stopPropagation()} key="footer">
        <div className="d-flex flex-grow-1">
          <button className="btn btn-secondary" onClick={goBack}>
            {I18n.t('back')}
          </button>{' '}
          <button
            className="ml-auto btn btn-primary"
            onClick={this.onSave.bind(this)}>
            {I18n.t('analysis.commission')}
          </button>
        </div>
      </Modal.Footer>,
    ];
  }

  modalHeader() {
    const title = this.getAnalysisTitle(this.state.config);
    return (
      <Modal.Header closeButton={false} key="header">
        <Modal.Title onClick={stopPropagation}>{title}</Modal.Title>
        <button className="close" onClick={this.props.onCancel}>
          <i className="fa fa-times" />
        </button>
      </Modal.Header>
    );
  }

  profileNotComplete() {
    this.profileAlertShown = true;
    this.setState({
      confirm: {
        title: I18n.t('analysis.profileNotComplete'),
        children: I18n.t('analysis.toUserAnalysisEnterProfileData'),
        buttons: [
          { label: I18n.t('cancel'), value: DIALOG_CANCEL_VALUE },
          { label: I18n.t('ok'), value: DIALOG_CONFIRM_VALUE },
        ],
        onClose: result => {
          if (result === DIALOG_CONFIRM_VALUE) {
            this.setState({ showAccountEdit: true });
          } else if (result === DIALOG_CANCEL_VALUE) {
            if (this.props.onCancel) this.props.onCancel();
          }
          this.setState({ confirm: null });
        },
      },
    });
  }

  noCompanyAddress() {
    this.alertShown = true;
    this.setState({
      confirm: {
        title: I18n.t('analysis.noCompanyAddress'),
        children: I18n.t('analysis.toUseAnalysisEnterCompanyData'),
        buttons: [
          { label: I18n.t('cancel'), value: DIALOG_CANCEL_VALUE },
          { label: I18n.t('ok'), value: DIALOG_CONFIRM_VALUE },
        ],
        onClose: result => {
          if (result === DIALOG_CONFIRM_VALUE) {
            const { company } = this.props;
            this.props.actions.editCompany(company.key);
            this.setState({ editCompany: true, showFormCompany: true });
          } else if (result === DIALOG_CANCEL_VALUE) {
            if (this.props.onCancel) this.props.onCancel();
          }
          this.setState({ confirm: null });
        },
      },
    });
  }

  getAnalysisTitle(config) {
    const locale = I18n.locale;

    return get(
      config,
      `translations.${locale}.title`,
      get(config, `translations.${config.defaultLanguage}.title`, null),
    );
  }

  _renderForm() {
    const { field, selectedLab, producer } = this.props;
    const config = this.state.config;

    const prices = config.prices;

    const renderProducer = producer ? (
      <div style={{marginTop: '1em'}}>
        <div style={{ background: constants.FJBACKGROUND }}>
          <span>
            {I18n.t('producers.single')}
          </span>
        </div>
        <div>
          <div>
            <Icon
              name={'tractor'}
              iconType={'fal'}
              style={{ fontSize: 12, marginRight: 5 }}
            />
            <b>{producer.name}</b>
          </div>
          <div style={{marginTop: '1em' }}>
            {[producer.street, producer.zip, getCountryName(producer.country)].filter(el => el).join(', ')}
          </div>
          <div style={{marginTop: '1em' }}>
            <Icon
              name={'ios-mail-outline'}
              style={{
                fontSize: 18,
                color: '#7EB549',
                marginTop: 3,
              }}
            />
            <span style={{marginLeft: '5px'}}>{producer.email}</span>
          </div>
          <div>
            <Icon
              name={'ios-call-outline'}
              style={{
                fontSize: 18,
                color: '#7EB549',
                marginTop: 3,
              }}
            />
            <span style={{marginLeft: '5px'}}>{producer.tel}</span>
          </div>
        </div>
        <div style={{marginTop: '1em'}}>
          <span>
            {I18n.t('ggn_gln_number')}{':'} {producer.ggn_number}
          </span>
          <br />
          <span>
            {I18n.t('company.qs_number')}{':'} {producer.qs_number}
          </span>
        </div>
      </div>
    ) : null;

    const renderedField = field.stock
      ? (
        <div>
          <span>{field.name}</span>
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              display: 'flex',
            }}>
            <span>GPS</span>
            <div>
              <span>
                {field.position.latitude.toFixed(6)},{' '}
                {field.position.longitude.toFixed(6)}
              </span>
            </div>
          </div>
        </div>
      )
      : (
        <div>
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
              display: 'flex',
            }}>
            <span>{field.name}</span>
            <span>{field.size ? field.size : 0} ha</span>
          </div>
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              display: 'flex',
            }}>
            <span>GPS</span>
            <div>
              <span>
                {field.position.latitude.toFixed(6)},{' '}
                {field.position.longitude.toFixed(6)}
              </span>
            </div>
          </div>
          {renderProducer}
        </div>
      );

    return (
      <div style={{ flex: 1 }}>
        <div>
          <div style={{ paddingBottom: 50 }}>
            <Box
              header={I18n.t('laboratory')}
              content={
                <div>
                  <Lab lab={selectedLab} links={true} />
                </div>
              }
            />

            <Box
              header={
                field.stock
                  ? I18n.t('warehouse.warehouse')
                  : I18n.t('field.field')
              }
              content={renderedField}
            />

            <Form
              ref="form"
              type={AnalysisModel.model({
                config,
                state: this.state.analysis.form,
                companyId: this.state.analysis.company_id,
              })}
              options={() =>
                AnalysisModel.options({
                  config,
                  state: this.state.analysis.form,
                  companyId: this.state.analysis.company_id,
                })
              }
              value={this.state.analysis.form}
              onChange={this.onChange.bind(this)}
            />

            {this._renderAddress()}

            {this.renderCustomEmails()}

            {prices
              ? (
                <Cost {...calculatePrice(this.state.analysis.form, prices)} />
              )
              : null}

            <BoxProbeNumber
              probeNumber={this.state.analysis.analyse_number}
              note={this.isLabelingReminderNeeded() ? I18n.t('analysis.pleaseLabelProbe') : null}
            />
          </div>
        </div>
      </div>
    );
  }

  hasAddress(address) {
    const a = {
      ...address,
    };

    // It's not important if we don't have a tel or email, but everything else and we don't have a valid address
    delete a.tel;
    delete a.email;

    return !includes(a, undefined) && !includes(a, null);
  }

  isProfileComplete(user) {
    const u = {
      ...user,
    };

    return !includes(u, undefined) && !includes(u, null);
  }

  _renderAddress() {
    const s = this.state.analysis.invoice_address;
    const hasAddress = this.hasAddress(s);


    return (
      <Box
        header={I18n.t('invoice_address')}
        content={
          <div>
            {hasAddress
              ? (
                <div>
                  <span style={constants.styles.strong}>{s.name}</span>
                  <div
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}>
                    <div style={{ flex: 1 }}>
                      <span>{s.street}</span>
                    </div>
                  </div>
                  <div
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}>
                    <div style={{ flex: 1 }}>
                      <div>
                        <span>{s.zip} </span> <span>{s.city}</span>
                      </div>
                      <div>
                        <span>{getCountryName(s.country)}</span>

                        {s.email ? (
                          <>
                            <br />
                            <span>{s.email}</span>
                          </> ) : null
                        }
                        {s.tel ? (
                          <>
                            <br />
                            <span>{s.tel}</span>
                          </>
                        ) : null
                        }
                        {this.props.selectedLab && this.props.selectedLab.id === 'lab1' && s.bolapClientId
                          ? (<>
                            <br />
                            <span>{'BOLAP Kundennummer: '}{s.bolapClientId}</span>
                          </>) : null
                        }
                      </div>
                    </div>
                  </div>
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <button
                      className={'btn btn-secondary'}
                      onClick={() => {
                        if (this.props.openView) {
                          this.props.openView('SelectInvoiceAddress', {
                            address: this.state.analysis.invoice_address,
                            labSelected: this.props.selectedLab,
                          });
                        }
                      }}>
                      {I18n.t('change')}
                    </button>
                  </div>
                </div>
              )
              : (
                <button
                  className={'btn btn-secondary'}
                  onClick={() => {
                    if (this.props.openView) {
                      this.props.openView('SelectInvoiceAddress', {
                        address: this.state.analysis.invoice_address,
                        labSelected: this.props.selectedLab,
                      });
                    }
                  }}>
                  {I18n.t('enter_address')}
                </button>
              )}
          </div>
        }
      />
    );
  }

  private renderCustomEmails() {
    const emails = this.state.shareResultsWithEmails.map((email, index) => (
      <TextInput
        key={`customEmail${index}`}
        label={I18n.t('analysis.shareResultWith.emailLabel', {
          index: index + 1,
        })}
        value={email}
        onChange={(value) => {
          const emails = [...this.state.shareResultsWithEmails];
          emails[index] = value;
          this.setState({ shareResultsWithEmails: emails });
        }}
        type={'email' as React.InputHTMLAttributes<string>}
      />
    ));
    return (
      <Box
        header={I18n.t('analysis.shareResultWith.title')}
        content={<>
          <p>{I18n.t('analysis.shareResultWith.description')}</p>
          {emails}
        </>}
      />
    );
  }

  private isLabelingReminderNeeded() {
    const service = get(this.state, 'form.offers.service');
    return service !== 'b' && !noLabelingReminder(this.state.config);
  }

  private getAdditionalEmails() {
    return this.state.shareResultsWithEmails
      .filter(email => !!email)
      .map(email => ({ email }));
  }
}

const selector = (state, ownProps) => {
  const selectedLab = state.selectedLab;
  const selectedOffer = state.selectedOffer;
  let field: any;
  const openCompany = selectors.getOpenCompanyId(state);
  const user = state.firebase.profile;

  if (ownProps.stock) {
    field = {
      ...state.analysisWarehouse,
      stock: true,
      // Add the stock type to the object - lab needs it
      stockType: ownProps.stock,
      activeCrop: {
        name: '',
        color: '#DDD',
        not_a_crop: NotACropState.NotACrop,
      },
    };
  } else {
    // If we are coming directly from the analysis view, then this should be populated
    let fieldId = state.analysisField;

    if (!fieldId) {
      fieldId = selectors.getOpenFieldId(state);
    }
    field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, fieldId);
    field = addKeyToObj(fieldId, field);
  }
  const company = getCompany(state.firestore.data, openCompany);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);

  const favoriteCollection = getFavorites(state, openCompany, 'invoiceAddress');

  let favorite: any = null;
  const lastAddress = get(
    state,
    `firestore.data.companies.${openCompany}.preferences.lastUsedInvoiceAddress`,
  );
  if (lastAddress) {
    if (
      favoriteCollection &&
      typeof favoriteCollection[Object.keys(lastAddress)[0]] !== 'undefined'
    ) {
      favorite = favoriteCollection[Object.keys(lastAddress)[0]];
    }
  }


  const queries = [
    getFavoritesQuery(openCompany, 'invoiceAddress', browsingGroup),
  ];

  const producerKey = get(field, 'groupMeta.key', '');
  if (producerKey) {
    queries.push(
      getGroupQuery(producerKey),
    );
  }

  const producer = getGroup(state, openCompany, producerKey);

  return {
    browsingGroup,
    company,
    favorite,
    field,
    fromField: !!state.analysisFromField,
    loaded: isLoaded(queries),
    openCompany,
    selectedLab,
    selectedOffer,
    user: state.firebase.profile,
    producer,
  };
};

const wrappedAnalysis = firestoreConnect(props => {
  const queries = [
    getFavoritesQuery(props.openCompany, 'invoiceAddress', props.browsingGroup),
  ];

  const producerKey = get(props, 'field.groupMeta.key', '');
  if (producerKey) {
    queries.push(
      getGroupQuery(producerKey),
    );
  }

  return queries;
})(FormAnalyse);

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...actionAnalysis,
          ...actionCreators,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedAnalysis);
