const t = require('tcomb-form');

/**
 * Valid latitude
 */
export const latitude = t.refinement(t.Number, function(s) {
  return s >= -180 && s <= 180;
});

/**
 * Valid longitude
 */
export const longitude = t.refinement(t.Number, function(s) {
  return s >= -180 && s <= 180;
});
