import React, { useState } from 'react';
import { get } from 'lodash-es';

import { getFieldMarkers } from 'farmerjoe-common/lib/selectors/markers';
import { getColor } from 'farmerjoe-common/lib/utils/Colors';

import MarkMapArea from '../Common/MarkMapArea';
import LineWithArrow from '../Common/LineWithArrow';
import SummaryLine from '../Common/SummaryLine';
import Dialog from '../Dialog/Dialog';
import I18n from '../../language/i18n';
import Polygon from '../Map/Polygon';

import {
  getPolygonOptions,
  preparePolygonPath,
} from '../../utils/Map';

type TPolygon = {
  polygon: any[];
  center: {
    latitude: number;
    longitude: number;
  } | null;
  areaSize?: number;
};

type TMarkAreaProps = {
  field: any;
  showDialog: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSave: (value: TPolygon) => void;
  markedArea: TPolygon;
};

// XXX: Check PlantProtectionMarkArea, it's similar.
// We need to refactor and DRY

const CropArea = ({ field, showDialog, onOpen, onClose, onSave, markedArea }: TMarkAreaProps) => {
  const [change, setChange] = useState(markedArea);
  const markers = getFieldMarkers([field]);
  
  const hasMarkedArea = get(markedArea, 'polygon', false);

  return (
    <div>
      <LineWithArrow
        onClick={() => {
          onOpen();
        }}
        text={I18n.t('markArea')}
        style={{
          fontWeight: 'bolder',
        }}
      />
      {hasMarkedArea && markedArea.polygon.length ? (
        <SummaryLine
          onClear={() => {
            setChange({polygon: [], center: null, areaSize: 0});
            onSave({polygon: [], center: null, areaSize: 0});
          }}
          text={
            I18n.t('crop.plantOnSelectedAreaSummary', {
              areaSize: markedArea.areaSize,
            })
          }
        />
      ) : null}
      <Dialog
        show={showDialog}
        title={
          <div className="header">
            <span>{I18n.t('markArea')}</span>
          </div>
        }
        onClose={onClose}
        className="modal-xl"
        footer={
          <div className="d-flex flex-grow-1">
            <button
              className="ml-auto btn btn-secondary"
              onClick={onClose}
            >
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              onClick={() => onSave(change)}
              disabled={false}>
              {I18n.t('done')}
            </button>
          </div>
        }>
        <div style={{ backgroundColor: '#000' }}>
          <MarkMapArea
            position={field.region}
            markers={markers}
            pinPosition={change.center}
            onChange={(value) => setChange(value)}
            polygonDrawing={true}
            polygon={field.polygon}
            currentMarkedArea={markedArea}
            markedAreaPolygons={(mapRef) => {
              return renderMarkedAreaPolygons(field, mapRef);
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};

const renderMarkedAreaPolygons = (field, mapRef) => {
  if (field.baseField) {
    // render all polygons;
    const usedCropArea = get(field, 'usedCropArea', null);
    if (!usedCropArea) {
      return null;
    }

    return Object.keys(usedCropArea).map((key, idx) => {
      const { markedArea, color = 'noCrop' } = usedCropArea[key];
      const polygonPath = preparePolygonPath(markedArea.polygon);
      const polygonOptions = getPolygonOptions({
        color: getColor(color),
      });

      return (
        <Polygon
          key={`${key}-${idx}`}
          path={polygonPath}
          options={polygonOptions}
          map={mapRef}
          onClick={() => {}}
        />
      );
    });
  }

  // field has crop that is not occuppying the whole area
  const shouldRender = get(field, 'activeCrop.markedArea', false);
  if (!shouldRender) {
    return null;
  }

  const markedArea = get(field, 'activeCrop.markedArea');
  const polygonPath = preparePolygonPath(markedArea.polygon);
  const polygonOptions = getPolygonOptions({
    color: getColor(get(field, 'activeCrop.color', 'noCrop')),
  });

  return (
    <Polygon
      path={polygonPath}
      options={polygonOptions}
      map={mapRef}
      onClick={() => {}}
    />
  );
};

export default CropArea;
