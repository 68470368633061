import React, { PureComponent } from 'react';
import Image from '../Comments/CommentTypes/Image';
import MarkerWithLabel from './MarkerWithLabel';
import { appPosToLatLng } from '../../utils/Map';
import { get } from 'lodash-es';
import './style.css';

export const IMAGE_MAX_WIDTH = 90;
export const IMAGE_MAX_HEIGHT = 90;
export const PADDING = 5;

// TODO: improve typings
type Props = {
  map: google.maps.Map;
  marker: any;
  containerStyle?: React.CSSProperties;
  onClick: (...args: Array<any>) => any;
  zIndex?: number;
  children?: React.ReactNode;
};
type State = any;

export default class MarkerImageComment extends PureComponent<Props, State> {
  render() {
    const { marker, children, containerStyle, onClick, zIndex } = this.props;
    const comment = marker.comment;
    const width = get(comment, 'image.dimensions.width', null);
    const height = get(comment, 'image.dimensions.height', null);
    const ratio = width / height;
    let scaledWidth = IMAGE_MAX_WIDTH;
    let scaledHeight = IMAGE_MAX_HEIGHT;
    if (ratio > IMAGE_MAX_WIDTH / IMAGE_MAX_HEIGHT) {
      scaledHeight = Math.floor(IMAGE_MAX_WIDTH / ratio);
    } else {
      scaledHeight = Math.min(height, scaledHeight);
      scaledWidth = ratio * scaledHeight;
    }
    return (
      <MarkerWithLabel
        key={marker.key}
        zIndex={ zIndex}
        position={appPosToLatLng(marker.position) as any}
        noTriangle={true}
        onClick={onClick}
        width={scaledWidth + 2 * PADDING}
        height={scaledHeight + 2 * PADDING}
        labelClass="marker-label marker-image-comment"
        map={this.props.map}
        reorderEvents={false}>
        <MarkerImageCommentLabel
          comment={comment}
          containerStyle={containerStyle}>
          {children}
        </MarkerImageCommentLabel>
      </MarkerWithLabel>
    );
  }
}

export const MarkerImageCommentLabel = ({
  comment,
  containerStyle,
  children,
}) => {
  return (
    <div style={containerStyle}>
      <div className="marker-bubble">
        <Image
          comment={comment}
          size={{ width: IMAGE_MAX_WIDTH, height: IMAGE_MAX_HEIGHT }}
          hideText={true}
          hideRotateButton = {true}
        />
        {children}
      </div>
    </div>
  );
};
