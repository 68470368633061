import * as React from 'react';
import { get } from 'lodash-es';
import './style.css';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { displaySettings } from 'farmerjoe-common/lib/actions/actions';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import DateRangeFilterBase from '../DateRangeFilter/DateRangeFilter';

type Props = {
  openCompany: string;
  actions: Record<string, any>;
  date: string;
  status: string;
};

class DateRangeFilter extends React.Component<Props> {
  render() {
    const { date } = this.props;

    return <DateRangeFilterBase onSave={this.save} date={date} />;
  }

  save = (value: string) => {
    const { openCompany, status } = this.props;
    this.props.actions.displaySettings(
      openCompany,
      `analysis_${status}`,
      {
        date: value,
      },
      true,
    );
  };
}

export default compose(
  connect(
    (state: any, ownProps: any) => {
      const openCompany = getOpenCompanyId(state);
      return {
        openCompany: openCompany,
        date: get(
          state,
          [
            'filtersByCompany',
            openCompany,
            'displaySettings',
            `analysis_${ownProps.status}`,
            'date',
          ],
          'last:30',
        ),
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          displaySettings,
        },
        dispatch,
      ),
    }),
  ),
)(DateRangeFilter);
