import React, { Component } from 'react';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';

import FieldMarkModel from '../../tcomb/models/fieldMark';

import DeleteButton from '../Common/DeleteButton';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';

import { searchForFieldAmongTheUniverse } from 'farmerjoe-common/lib/selectors/fields';
import I18n from '../../language/i18n';

import { isLoaded } from 'react-redux-firebase';
import firebase from '../../data/firebase';
import { dummy } from '../../utils/Comment';
import { get } from 'lodash-es';

import t from 'tcomb-form';
import Dialog, { AlertDialog } from '../Dialog/Dialog';

const Form = t.form.Form;

// TODO: improve typings
type Props = any;
type State = any;

class FieldMark extends Component<Props, State> {
  constructor(props) {
    super(props);

    const state = {
      alertMessage: null,
      alertTitle: null,
      mark: {},
      modified: false,
    };
    if (this.props.editMode) {
      state.mark = get(this.props.field, 'activeCrop.mark') || {};
    }

    this.state = state;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editMode) {
      this.setState({ ...get(nextProps.field, 'activeCrop.mark') });
    }
  }

  onSave = () => {
    const { field, auth } = this.props;

    let value = (this.refs.form as any).getValue();

    // If value is null, then the form has errors
    if (value === null || (!value.color && !value.emoji)) {
      // hack to set the error state on color and emoji components
      value = (this.refs.form as any).validate().value;
      if (!value || (!value.color && !value.emoji)) {
        (this.refs.form as any).getComponent('color').setState({ hasError: true });
        (this.refs.form as any).getComponent('emoji').setState({ hasError: true });
      }

      this.setState({
        alertMessage: I18n.t('please_correct_your_entry'),
        alertTitle: I18n.t('error'),
      });
      return;
    }

    // structs come out as Struct custom objects which firestore doesn't like so turn them into plain objects
    value = JSON.parse(JSON.stringify(value));

    const db = firebase.firestore();
    const batch = db.batch();

    value.reason = String(value.reason || '').trim();
    const commentRef = db.collection('comments').doc();

    const comment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        field.activeCrop,
        auth,
        'system.field_mark_add',
        null,
      ),
      text: value,
    };

    batch.set(commentRef, comment);

    batch.commit();
    this.props.onClose();
  };

  onChange(value) {
    // when the user is switching between color and emoji reset the other value
    if (!this.state.mark.color && value.color) {
      value.emoji = null;
    } else if (!this.state.mark.emoji && value.emoji) {
      value.color = null;
    }

    this.setState({
      mark: {
        ...this.state.mark,
        ...value,
      },
      modified: true,
    });
  }

  render() {
    const { loaded, editMode, show, onClose } = this.props;
    if (!loaded) {
      return null;
    }

    return (
      <Dialog
        show={show}
        onClose={onClose}
        title={I18n.t('fieldMark.marks')}
        footer={
          <div className="d-flex flex-grow-1">
            <button className="ml-auto btn btn-secondary" onClick={onClose}>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}
              disabled={editMode && !this.state.modified}>
              {I18n.t('save')}
            </button>
          </div>
        }
        className="form">
        <Form
          ref="form"
          type={FieldMarkModel.model}
          options={() => FieldMarkModel.options()}
          value={this.state.mark}
          onChange={this.onChange.bind(this)}
        />
        {editMode
          ? (
            <div className="text-center">
              <DeleteButton
                buttonText={I18n.t('fieldMark.deleteMark')}
                alertTitle={I18n.t('fieldMark.deleteMark')}
                alertMessage={I18n.t('fieldMark.doYouReallyWantToDelete')}
                onDelete={() => {
                  const { auth, field } = this.props;
                  const db = firebase.firestore();
                  const batch = db.batch();
                  const commentRef = db.collection('comments').doc();

                  const comment = {
                    ...dummy(
                      commentRef.id,
                      field.company_id,
                      field,
                      field.activeCrop,
                      auth,
                      'system.field_mark_remove',
                      null,
                    ),
                    text: {},
                  };

                  batch.set(commentRef, comment);
                  batch.commit();

                  this.props.onClose();
                }}
              />
            </div>
          )
          : null}
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
          children={this.state.alertMessage}
        />
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, {}), dispatch),
  };
}

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompany = selectors.getOpenCompanyId(state);
  const openField = selectors.getOpenFieldId(state);
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, openField);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  return {
    editMode: field && get(field, 'activeCrop.mark'),
    commentId: get(field, 'activeCrop.comment_id'),
    auth: state.firebase.profile,
    myCompanyProfile: myCompanyProfile,
    loaded: isLoaded(field),
    field,
  };
};

export default compose(
  connect(
    selector,
    mapDispatchToProps,
  ),
)(FieldMark);
