import * as fbUtil from '../data/util';
import { dummy } from '../utils/Comment';
import { get } from 'lodash-es';
import type { ImageFile } from '../utils/image';
import type { Field, Company } from '../flowTypes';

export const uploadImages = (images: Array<ImageFile>, field: Field) => {
  return (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
    getFirebase: (...args: Array<any>) => any,
  ) => {
    const state = getState();
    const firebase = getFirebase();
    const profile = get(state, 'firebase.profile');

    const promises = images.map(({ name, image, dimensions }: ImageFile) => {
      const db = firebase.firestore();
      const cRef = db.collection('comments').doc();

      const comment = {
        ...dummy(
          cRef.id,
          field.company_id,
          field,
          field.activeCrop,
          profile,
          'user.image',
        ),
      };

      const storageRef = firebase.storage().ref();

      const fileRef = storageRef.child(
        `images/${field.company_id}/${field.key}/${getUUIDFileName(name)}`,
      );

      return fileRef
        .put(image)
        .then(() => fileRef.getDownloadURL())
        .then(downloadURL => {
          return new Promise((resolve, reject) => {
            // It's unlikely that we run into this since we are uploading a big file..., but just in case wait 1s before updating the comment
            // firestore has a write limit of 1 per second...
            setTimeout(() => {
              const data = {
                ...comment,
                image: {
                  dimensions,
                  uri: downloadURL,
                },
                extraData: {
                  image: {
                    dimensions,
                    ref: {
                      bucket: fileRef.bucket,
                      path: fileRef.fullPath,
                    },
                  },
                },
              };
              cRef.set(data).then(resolve, reject);
            }, 200);
          });
        })
        .catch(err => console.error('error uploading file', err));
    });

    return Promise.all(promises);
  };
};

const ID_LENGTH = 20;

function autoId() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  for (let i = 0; i < ID_LENGTH; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
}

function getUUIDFileName(name) {
  const extRegex = /\.(\w{1,4})$/;
  const match = extRegex.exec(name);
  if (match) {
    return autoId() + '.' + match[1];
  } else {
    return autoId();
  }
}

export const uploadAvatar = ({ name, image }: ImageFile) => {
  return (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
    getFirebase: (...args: Array<any>) => any,
  ) => {
    const state = getState();
    const firebase = getFirebase();
    const profile = get(state, 'firebase.profile');
    const db = firebase.firestore();

    const userDoc = db.collection('users').doc(profile.uid);

    userDoc.update({ 'avatar.placeholder': true }).then(() => {
      const storageRef = firebase.storage().ref();

      const fileRef = storageRef.child(
        fbUtil.getUserPath(profile.uid) + `/avatar/${name}`,
      );

      fileRef.put(image).then(snap => {
        const { metadata } = snap;

        fileRef.getDownloadURL().then(downloadURL => {
          setTimeout(() => {
            userDoc.update({
              avatar: {
                original: { downloadURL: downloadURL, name: metadata.name },
              },
            });
          }, 200);
        });
      });
    });
  };
};

export const uploadProfileImage = ({ name, image }: ImageFile) => {
  return (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
    getFirebase: (...args: Array<any>) => any,
  ) => {
    const state = getState();
    const firebase = getFirebase();
    const profile = get(state, 'firebase.profile');
    const db = firebase.firestore();
    const userDoc = db.collection('users').doc(profile.uid);

    userDoc.update({ 'coverImage.placeholder': true }).then(() => {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`users/${profile.uid}/profile/${name}`);

      fileRef.put(image).then(snap => {
        const { metadata } = snap;

        fileRef.getDownloadURL().then(downloadURL => {
          setTimeout(() => {
            userDoc.update({
              coverImage: {
                original: { downloadURL: downloadURL, name: metadata.name },
              },
            });
          }, 200);
        });
      });
    });
  };
};

export const uploadLogo = ({ name, image }: ImageFile, company: Company) => {
  return (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
    getFirebase: (...args: Array<any>) => any,
  ) => {
    const firebase = getFirebase();
    const db = firebase.firestore();

    const companyDoc = db.collection('companies').doc(company.key);

    companyDoc.update({ 'logo.placeholder': true }).then(() => {
      const storageRef = firebase.storage().ref();

      const fileRef = storageRef.child(`images/${company.key}/logo/${name}`);

      fileRef.put(image).then(snap => {
        const { metadata } = snap;

        fileRef.getDownloadURL().then(downloadURL => {
          setTimeout(() => {
            companyDoc.update({
              logo: {
                original: { downloadURL: downloadURL, name: metadata.name },
              },
            });
          }, 200);
        });
      });
    });
  };
};
