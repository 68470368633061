import React, { useState } from 'react';
import { isAbandonedField } from 'farmerjoe-common/lib/utils/Field';
import CommentForm from '../Comments/CommentForm';

import CameraImage from './CameraImage';
import SubMenu from './Menus/Plus';

type Props = {
  field: any;
  activeCrops: any;
  onInteraction?: (...args: Array<any>) => any;
  myCompanyProfile: any;
};

type State = {
  waitTime: boolean;
  currentComment: string;
};

const styles = {
  footer: {
    padding: 5,
    paddingTop: 7,
    paddingBottom: 7,
    borderTopColor: '#CCC',
    borderTopWidth: 1,
    zIndex: 1,
    backgroundColor: '#fbfbfb',
  },
};

const Footer = (props: Props, state: State) => {
  const { field, activeCrops, myCompanyProfile } = props;

  const [waitTime, setWaitTime] = useState(true);
  const [currentComment, setCurrentComment] = useState('');

  return (
    <div
      className={'comment-footer d-flex flex-row justify-content-center align-items-center'}
      style={styles.footer}>
      {isAbandonedField(field.state)
        ? null
        : (
          <SubMenu
            field={field}
            activeCrops={activeCrops}
            myCompanyProfile={myCompanyProfile}
          />
        )
      }
      <CommentForm
        field={field}
        onChange={comment => {
          if (comment.length) {
            setWaitTime(false);
          } else {
            setWaitTime(true);
          }
          setCurrentComment(comment);
        }}
        onFocus={() => {
          if (currentComment.length) {
            setWaitTime(false);
            return;
          }
          setWaitTime(true);
        }}
        onBlur={() => {
          if (currentComment.length) {
            setWaitTime(false);
            return;
          }
          setWaitTime(true);
        }}
      />

      {waitTime ? <CameraImage field={field} /> : null}
    </div>
  );
};

export default Footer;
