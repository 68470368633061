import React, { CSSProperties, PureComponent } from 'react';

import * as constants from '../../styles/style';

type Props = {
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
};

export default class FJText extends PureComponent<Props> {
  render() {
    return (
      // Set our stdSize for the text component
      <span
        className={this.props.className}
        style={{ ...constants.styles.stdSize, ...this.props.style }}>
        {this.props.children}
      </span>
    );
  }
}
