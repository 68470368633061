import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash-es';

import { outputDate } from 'farmerjoe-common';
import { searchForFieldAmongTheUniverse } from 'farmerjoe-common/lib/selectors/fields';
import { getFieldMarkers } from 'farmerjoe-common/lib/selectors/markers';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';

import IconHeader from '../../Common/IconHeader';
import Fertilizing from '../../Common/Fertilizing/Fertilizing';
import FormTextRow from '../../Common/FormTextRow';
import ViewOnlyMarkMapArea from '../../Common/ViewOnlyMarkMapArea';
import Dialog from '../../Dialog/Dialog';

import './style.css';

import type { Comment } from '../../../flowTypes';
import I18n from '../../../language/i18n';

type Props = {
  comment: Comment;
  field?: any;
};
class FertilizingComment extends React.PureComponent<Props> {
  render() {
    const { comment, field } = this.props;
    const sum = get(comment, 'extraData.total', {});

    const fertilizing = comment.text;

    return (
      <div className="fertilizing-comment">
        <IconHeader
          icon="fertilization"
          text={I18n.t('fertilizer.fertilization')}
          iconType="fj"
          showIcon={true}
        />
        <div className="title">
          <span className="amount">{fertilizing.kg} kg/ha</span>
          <span className="name">{fertilizing.name}</span>
        </div>

        <Fertilizing fertilizing={fertilizing} sum={sum} />

        {comment.text.applied_on ? (
          <FormTextRow
            label={I18n.t('waittime.application_day')}
            value={outputDate(comment.text.applied_on)}
          />
        )
          : null}
        {comment.text.markedArea ? (
          <>
            <FormTextRow
              label={I18n.t('markedArea')}
              value={
                <CommentMarkedArea field={field} comment={comment} />
              }/>
            <FormTextRow
              label={I18n.t('field.area')}
              value={comment.text.markedArea.areaSize + ' ha'}
            />
          </>
        ) : null}
      </div>
    );
  }
}

type TPoint = {
  latitude: number;
  longitude: number;
};

type TCommentMarkedArea = {
  field: {
    polygon: TPoint[];
  };
  comment: {
    text: {
      markedArea: {
        center: TPoint;
        areaSize: number;
        polygon: TPoint[];
      };
    };
  };
};

const CommentMarkedArea = ({ field, comment }: TCommentMarkedArea) => {
  const [ enlargeMap, setEnlargeMap ] = useState(false);
  if (!field) {
    return null;
  }

  const { text: { markedArea }  } = comment;

  if (!markedArea) {
    return null;
  }

  const markers = getFieldMarkers([field]);

  return (
    <div>
      <div style={{height: '300px'}}>
        <ViewOnlyMarkMapArea
          position={markedArea.center}
          markers={markers}
          pinPosition={markedArea.center}
          polygon={field.polygon}
          currentMarkedArea={markedArea}
          mapContainerStyle={{ height: '300px' }}
          googleMapsOptions={{
            disableDefaultUI: false,
          }}
          enlargeMap={true}
          onEnlargeMap={() => setEnlargeMap(true)}
        />
      </div>
      <div>
        {enlargeMap ? (
          <Dialog
            show={enlargeMap}
            title={
              <div className="header">
                <span>{I18n.t('markedArea')}</span>
              </div>
            }
            onClose={() => setEnlargeMap(false)}
            className="modal-xl"
            footer={
              <div className="d-flex flex-grow-2">
                <button
                  className="btn btn-primary"
                  onClick={() => setEnlargeMap(false)}
                  disabled={false}>
                  {I18n.t('done')}
                </button>
              </div>
            }>
            <div style={{ backgroundColor: '#000' }}>
              <ViewOnlyMarkMapArea
                position={markedArea.center}
                markers={markers}
                pinPosition={markedArea.center}
                polygon={field.polygon}
                currentMarkedArea={markedArea}
                mapContainerStyle={{ height: '70vh' }}
                googleMapsOptions={{
                  disableDefaultUI: false,
                }}
              />
            </div>
          </Dialog>
        ) : null}
      </div>
    </div>
  );
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const user = state.firebase.profile;
  const comment = ownProps.comment;
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, comment.field_id);
  return {
    comment,
    field,
  };
};

export default connect(
  selector,
)(FertilizingComment);
