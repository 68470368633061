import React from 'react';
import Text from './Text';
import './style.css';

type Props = {
  tag: string;
  color: string;
  containerStyle?: {};
  textStyle?: {};
};
export const Tag = ({ tag, color, containerStyle, textStyle }: Props) => {
  return (
    <div className="tag">
      <div
        style={{
          backgroundColor: color,
          ...containerStyle,
        }}>
        <Text style={textStyle}>{tag}</Text>
      </div>
    </div>
  );
};
