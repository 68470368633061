import React from 'react';

const NonSupported = () => {
  return (
    <label className={'text-danger'}>
      Please make sure to use the latest farmerJoe version. If this message
      persist, please contact support.
    </label>
  );
};

export default React.memo(NonSupported);
