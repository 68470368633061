import React from 'react';
import PlanUpgradeModal from './PlanUpgradeModal';
import SubscriptionPlan from './SubscriptionPlan';
import { calcPrice } from './prices';
import { TCurrentUsage, TSubscriptionPlan } from './types';
import I18n from '../../language/i18n';

type Props = {
    show: boolean;
    onClose: (arg0: any) => any;
    subscriptionPlan: TSubscriptionPlan | null;
    currentUsage: TCurrentUsage;
    children: any;
};

const PaidPlanDialog = (props: Props) => {
  const { show, onClose, subscriptionPlan, currentUsage, children } = props;
  const quotaReached = currentUsage.totalUsers > currentUsage.maxAllowedUsers;
  const newSeats = quotaReached ? currentUsage.totalUsers : (subscriptionPlan as any).users;

  return (
    <PlanUpgradeModal
      show={show}
      onClose={onClose}
      title={I18n.t('billing.upgradeToTeam')}
    >
      <div style={{
        maxWidth: '300px',
        margin: '0 auto',
      }}>
        <SubscriptionPlan subscriptionPlan={subscriptionPlan} isCurrent={false} showLabel={false} />
      </div>
      <>
        <div style={{padding: '20px'}}>
          <table style={{width: '100%'}}>
            <tbody>
              <tr>
                <td>
                  {I18n.t('billing.currentSeats')}:
                </td>
                <td style={{textAlign: 'right'}}>
                  {currentUsage.totalUsers}
                </td>
              </tr>
              <tr>
                <td>
                  {I18n.t('billing.newSeats')}:
                </td>
                <td style={{textAlign: 'right'}}>
                  {newSeats}
                </td>
              </tr>
              <tr style={{borderBottom: '1px solid'}}>
                <td> {I18n.t('billing.totalPrice')}:</td>
                <td style={{textAlign: 'right'}}> { calcPrice((subscriptionPlan as any).price, newSeats) }&#8364; </td>
              </tr>
            </tbody>
          </table>
        </div>
        {children}
      </>
    </PlanUpgradeModal>
  );
};

export default PaidPlanDialog;
