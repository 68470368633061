const t = require('tcomb-form');

/* eslint-disable no-useless-escape */

const Email = t.refinement(t.String, function(s) {
  const pattern = /^[a-z0-9\u007F-\uffff!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9\u007F-\uffff!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}$/i;
  return pattern.test(s);
});

export default Email;
