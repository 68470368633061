import React, { createContext, useState, useContext, useEffect } from 'react';
import firebase from '../data/firebase';

type LatestActivitiesCountContextType = {
  newLatestActivitiesCount: number;
  setNewLatestActivitiesCount: (value: number) => void;
};

const LatestActivitiesCountContext = createContext<LatestActivitiesCountContextType>({
  newLatestActivitiesCount: 0,
  setNewLatestActivitiesCount: () => {},
});

export const useLatestActivitiesCount = () =>
  useContext(LatestActivitiesCountContext);

export const LatestActivitiesCountProvider = ({
  children,
  openCompany,
  activitiesSeenAt,
}) => {
  const [newLatestActivitiesCount, setNewLatestActivitiesCount] = useState(0);
  const loggedUser = firebase.auth().currentUser;

  useEffect(() => {
    if (!openCompany) {
      return;
    }

    const unsubscribe = firebase.firestore()
      .collection('comments')
      .where('company_id', '==', openCompany)
      .where('created', '>', activitiesSeenAt)
      .orderBy('created', 'desc')
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        const unreadMessages = data.filter(d => d.created_by.uid !== loggedUser?.uid);
        setNewLatestActivitiesCount(unreadMessages.length); // Count of unread messages
      });

    return () => unsubscribe();
  }, [openCompany, activitiesSeenAt, loggedUser]);

  return (
    <LatestActivitiesCountContext.Provider
      value={{ newLatestActivitiesCount, setNewLatestActivitiesCount }}
    >
      {children}
    </LatestActivitiesCountContext.Provider>
  );
};
