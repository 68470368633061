import React from 'react';
import geokdbush from 'geokdbush';
import { get } from 'lodash-es';

import { getCropAge } from 'farmerjoe-common/lib/utils/Crop';
import { getColor } from 'farmerjoe-common/lib/utils/Colors';
import { formatCommentDate, outputDate, toDate } from 'farmerjoe-common';
import { formatSum } from 'farmerjoe-common/lib/utils/Fertilizing';
import { CropType, NotACropState } from 'farmerjoe-common/lib/flow/types';
import { NO_CROP_NAME } from 'farmerjoe-common/lib/constants/crops';

import type { AdditionalProps, ColumnTypes, GroupTypes } from './flow';
import CropIcon from '../../Common/CropIcon';
import CropAge from '../../Common/CropAge';
import Icon from '../../Common/Icon';
import Fertilizing from '../../Common/Fertilizing/Fertilizing';
import Waittime from '../../WaitTime/Waittime';
import FieldSizeDifference from '../../Field/FieldSizeDifference';
import Mark from '../../FieldMark/Mark';
import * as constants from '../../../styles/style';
import I18n from '../../../language/i18n';
import * as commentUtils from '../../../utils/Comment';

import './style.css';

const styles = constants.styles;

function PositionCell(coordinates) {
  return (
    <a
      style={{
        ...constants.styles.stdSize,
        ...{ color: constants.FJNEWGREEN },
      }}
      href={`http://maps.google.com/maps?q=${coordinates.latitude.toFixed(
        4,
      )},${coordinates.longitude.toFixed(4)}`}
      target="_blank"
      onClick={e => e.stopPropagation()}
      rel="noreferrer"
    >
      {coordinates.latitude.toFixed(4)}, {coordinates.longitude.toFixed(4)}
    </a>
  );
}

function DateTimeCell(value) {
  if (!value) {
    return null;
  }

  return (
    <div className="table-date-cell">
      <span className={'table-date-cell-date'}>{outputDate(value)}</span>
      <span className={'table-date-cell-time'}>
        {' '}
        {outputDate(value, 'HH:mm')}
      </span>
    </div>
  );
}

function CropTypeCell(value) {
  const numValue = parseInt(value, 10);
  return numValue === parseInt(CropType.Annual, 10)
    ? I18n.t('crop.temporary')
    : numValue === parseInt(CropType.Permanent, 10)
      ? I18n.t('crop.permanent')
      : null;
}

function FieldSizeCell(value, field) {
  return !field.stock ? `${(value || 0).toFixed(1)} ha` : '';
}

function FieldAreaSizeCell(areaSize, { size }) {
  return (
    <FieldSizeDifference size={size} areaSize={areaSize}>
      {`${(areaSize || 0).toFixed(2)} ha`}
      {'\t'}
    </FieldSizeDifference>
  );
}

function BooleanCell(value) {
  return value ? I18n.t('yes') : I18n.t('no');
}

function CommentCell(lastComment) {
  return lastComment
    ? (
      <>
        <span className={'table-comment-date'}>
          {outputDate(lastComment.created, 'DD.MM.YYYY hh:mm')}
        </span>{' '}
        <span>{commentUtils.getCommentText(lastComment, 40)}</span>
      </>
    )
    : null;
}

function CropAgeCell(activeCrop) {
  return (
    <CropAge
      style={{
        ...styles.small,
        ...styles.alignRight,
        ...{
          display: 'inline-block',
          fontWeight: 'bold',
          color: constants.FJMUTEDDARK,
        },
      }}
      fullText={true}
      crop={activeCrop}
    />
  );
}

function WaitTimeCell(activeCrop, field, { waitTimes }) {
  waitTimes = waitTimes && waitTimes[field.key] ? waitTimes[field.key] : [];
  return waitTimes
    ? (
      <Waittime
        iconStyle={{ fontSize: 12 }}
        style={styles.small}
        dateStyle={styles.small}
        waitTimes={waitTimes}
        showDates={true}
      />
    )
    : null;
}

function FertilizingCell(activeCrop) {
  const total = get(activeCrop, 'fertilizingTotal');

  if (total) {
    return <Fertilizing sum={total} onlyTextRowSum={true} />;
  }

  return null;
}

function YieldCell(cropYield) {
  const total = get(cropYield, 'total', '');
  const perHa = get(cropYield, 'perHa', '');

  return (
    <>
      {total && <span>{total} t</span>}
      {'\t'}
      {perHa && <span className="yield-perha">{perHa} t/ha</span>}
    </>
  );
}

function DistanceCell(field, field2, { locationPermission, userPosition }) {
  if (
    locationPermission === null ||
    locationPermission === false ||
    userPosition === null
  ) {
    return null;
  }
  const distance = geokdbush.distance(
    userPosition.longitude,
    userPosition.latitude,
    field.position.longitude,
    field.position.latitude,
  );
  const tooFarAway = distance > 999;

  return (
    <>
      {tooFarAway
        ? (
          <span>999+ km</span>
        )
        : (
          <span>{distance.toFixed(distance < 10 ? 1 : 0)} km</span>
        )}
    </>
  );
}

function AnalysesCell(analysesCount) {
  if (!analysesCount) {
    return null;
  }

  return (
    <>
      <span
        style={{
          ...constants.styles.small,
          ...{ paddingTop: 0, color: constants.FJMUTEDDARK },
        }}>
        {analysesCount}
      </span>
      <Icon
        name="analysis"
        iconType={'fj'}
        style={{
          fontSize: 12,
          marginLeft: 5,
          color: constants.FJMUTEDDARK,
        }}
      />
    </>
  );
}

function CropNameCell(crop) {
  let cropName = get(crop, 'name', '');
  if (cropName === NO_CROP_NAME) {
    cropName = I18n.t('crop.without');
  }
  return (
    <>
      <div
        style={{ backgroundColor: getColor(crop.color) }}
        className={'cropTag'}>
        <CropIcon crop={crop} />
        {cropName}
      </div>
    </>
  );
}

const CropArtCell = (crop) => {
  const cropArt = get(crop, 'art', '');
  return (
    <>
      <div
        style={{ backgroundColor: getColor(crop.color) }}
        className={'cropTag'}>
        <CropIcon crop={crop} />
        {cropArt}
      </div>
    </>
  );
};

function MarkCell(mark) {
  return <Mark mark={mark} />;
}

function getMarkDataText(mark) {
  return (mark && mark.reason) || '';
}

function CropNameGroupCell({ cropName }) {
  return (
    <div className="crop-group">
      <span className="crop-name">{cropName}</span>
    </div>
  );
}

function FieldSizeGroupCell({ size }) {
  return (
    <div className="crop-group">
      <span className="crop-size">{`${(size || 0).toFixed(1)} ha`}</span>
    </div>
  );
}

function FieldNameGroupCell({ count }) {
  return (
    <div className="crop-group">
      <span className="field-count">{I18n.t('x_fields', { count })}</span>
    </div>
  );
}

export function sortByProps(...props: Array<any>) {
  return function(a: any, b: any, desc: boolean) {
    return props.reduce((sum, prop, i) => {
      return (
        sum +
        defaultSortMethod(get(a, prop), get(b, prop), desc) *
          2 ** (props.length - i - 1)
      );
    }, 0);
  };
}

export function customSortByProp(items: Array<string>, prop) {
  const ordering = {};
  for (let i = 0; i < items.length; i++) {
    ordering[items[i]] = i;
  }

  return function(a: any, b: any, desc: boolean) {
    return defaultSortMethod(ordering[get(a, prop)], ordering[get(b, prop)], desc);
  };
}

export function sortByCropAge(a: any, b: any, desc: boolean) {
  return defaultSortMethod(
    getCropAge(a),
    getCropAge(b),
    desc,
  );
}

export function sortByWaitTime({ waitTimes }: AdditionalProps) {
  function getWaitTimeDays(crop) {
    const waitTime = Waittime.getMaxWaitTime(
      waitTimes && waitTimes[crop.field_id] ? waitTimes[crop.field_id] : [],
    );

    if (!waitTime) {
      return null;
    }

    return Waittime.calculateRestTime(waitTime);
  }

  return function(a: any, b: any, desc: boolean) {
    return defaultSortMethod(getWaitTimeDays(a), getWaitTimeDays(b), desc);
  };
}

export function sortByDistance({
  locationPermission,
  userPosition,
}: AdditionalProps) {
  function getDistance(field) {
    if (
      locationPermission === null ||
      locationPermission === false ||
      userPosition === null
    ) {
      return null;
    }
    return geokdbush.distance(
      userPosition.longitude,
      userPosition.latitude,
      field.position.longitude,
      field.position.latitude,
    );
  }

  return function(a: any, b: any, desc: boolean) {
    return defaultSortMethod(getDistance(a), getDistance(b), desc);
  };
}

export const columnTypes: ColumnTypes = {
  producerName: {
    accessor: field => get(field, 'groupMeta.name'),
    id: 'producer-name',
    Header: () => I18n.t('producers.name'),
    sortMethod: sortByProps('groupMeta.name'),
  },
  producerEmail: {
    accessor: field => get(field, 'groupMeta.email'),
    id: 'producer-email',
    Header: () => I18n.t('producers.email'),
    sortMethod: sortByProps('groupMeta.email'),
  },
  producerPhone: {
    accessor: field => get(field, 'groupMeta.phone'),
    id: 'producer-phone',
    Header: () => I18n.t('producers.phone'),
    sortMethod: sortByProps('groupMeta.phone'),
  },
  trader: {
    accessor: field => get(field, 'traderMeta.name'),
    id: 'trader',
    Header: () => I18n.t('traders.single'),
    sortMethod: sortByProps('traderMeta.name'),
  },
  cropArt: {
    accessor: field => get(field, 'activeCrop'),
    id: 'cropArt',
    Header: () => I18n.t('crop.variety'),
    Cell: CropArtCell,
  },
  cropPieces: {
    accessor: field => get(field, 'activeCrop.pieces'),
    id: 'cropPieces',
    Header: () => I18n.t('crop.pieces.long'),
    sortMethod: sortByProps('activeCrop.pieces'),
  },
  cropHarvested_on: {
    accessor: field => get(field, 'activeCrop.harvested_on'),
    id: 'cropHarvested_on',
    Header: () => I18n.t('crop.harvested_on'),
    Cell: DateTimeCell,
  },
  cropLastComment: {
    accessor: field => get(field, 'activeCrop.lastComment'),
    id: 'cropLastComment',
    Header: () => I18n.t('crop.lastComment'),
    Cell: CommentCell,
    sortMethod: sortByProps('created'),
  },
  cropName: {
    accessor: field => get(field, 'activeCrop'),
    id: 'cropName',
    Header: () => I18n.t('crop.name'),
    Cell: CropNameCell,
    GroupCell: CropNameGroupCell,
    textColumns: () => [
      {
        id: 'cropName-name',
        getDataText: crop => {
          let cropName = get(crop, 'name', '');
          if (cropName === NO_CROP_NAME) {
            cropName = I18n.t('crop.without');
          }
          return cropName;
        },
        getHeaderText: () => I18n.t('crop.name'),
      },
      {
        id: 'cropName-color',
        getDataText: crop => (crop.color === 'noCrop' ? '' : crop.color),
        getHeaderText: () => I18n.t('crop.color'),
      },
      {
        id: 'cropName-notACrop',
        getDataText: crop => {
          if (
            crop.not_a_crop === NotACropState.HarvestedCrop &&
            (typeof crop.harvested_on === 'undefined' ||
              crop.harvested_on === null)
          ) {
            return 'planted';
          }
          if (crop.not_a_crop === NotACropState.HarvestedCrop && crop.harvested_on) {
            return 'harvested';
          }
          if (crop.not_a_crop === NotACropState.NotACrop) {
            return 'no crop';
          }
          if (crop.not_a_crop === NotACropState.PlannedCrop) {
            return 'planned';
          }
          throw new Error('Unexpected crop:' + crop);
        },
        getHeaderText: () => I18n.t('crop.status'),
      },
    ],
  },
  cropSown_on: {
    accessor: field => get(field, 'activeCrop.sown_on'),
    id: 'cropSown_on',
    Header: () => I18n.t('crop.sown_on'),
    Cell: DateTimeCell,
  },
  cropType: {
    accessor: field => get(field, 'activeCrop.type'),
    id: 'cropType',
    Header: () => I18n.t('crop.type'),
    Cell: CropTypeCell,
  },
  cropAge: {
    accessor: field => get(field, 'activeCrop'),
    id: 'cropAge',
    Header: () => I18n.t('crop.age'),
    Cell: CropAgeCell,
    sortMethod: sortByCropAge,
    getDataText: crop => String(getCropAge(crop)),
  },
  cropAnalyses: {
    accessor: field => get(field, 'activeCrop.analysesCount'),
    id: 'analyses',
    Header: () => I18n.t('field.analyses'),
    Cell: AnalysesCell,
  },
  fieldLand_id: {
    accessor: field => get(field, 'land_id'),
    id: 'fieldLand_id',
    Header: () => I18n.t('field.number'),
  },
  fieldName: {
    accessor: field => get(field, 'name'),
    id: 'fieldName',
    Header: () => I18n.t('field.name'),
    GroupCell: FieldNameGroupCell,
  },
  fieldNotes: {
    accessor: field => get(field, 'notes'),
    id: 'fieldNotes',
    Header: () => I18n.t('note'),
  },
  fieldPosition: {
    accessor: field => get(field, 'position'),
    id: 'fieldPosition',
    Header: () => I18n.t('position'),
    Cell: PositionCell,
    sortMethod: sortByProps('latitude', 'longitude'),
  },
  fieldSize: {
    accessor: field => get(field, 'size'),
    id: 'fieldSize',
    Header: () => I18n.t('field.area'),
    Cell: FieldSizeCell,
    GroupCell: FieldSizeGroupCell,
    getDataText: (value, field) =>
      !field.stock ? (value || 0).toFixed(2) : '',
    getGroupDataText: ({ size }) => (size || 0).toFixed(2),
  },
  fieldAreaSize: {
    accessor: field => get(field, 'areaSize'),
    id: 'fieldAreaSize',
    Header: () => I18n.t('polygon.calculatedSizeInHa'),
    Cell: FieldAreaSizeCell as any,
    getDataText: value => (value || 0).toFixed(2),
  },

  // sort methods for columns below are added in the render method
  waitTime: {
    accessor: field => get(field, 'activeCrop'),
    id: 'waitTime',
    Header: () => I18n.t('waittime.waitTime'),
    Cell: WaitTimeCell as any,
    textColumns: () => {
      return [
        {
          id: 'waitTime-days',
          getDataText: (activeCrop, field, { waitTimes }) => {
            waitTimes =
              waitTimes && waitTimes[field.key] ? waitTimes[field.key] : [];
            return String(
              Waittime.calculateRestTime(Waittime.getMaxWaitTime(waitTimes)),
            );
          },
          getHeaderText: () =>
            I18n.t('waittime.waitTime') +
            ' [' +
            I18n.t('x_days', { days: 0 })
              .replace(/0/, '')
              .trim() +
            ']',
        },
        {
          id: 'waitTime-dates',
          getDataText: (activeCrop, field, { waitTimes }) => {
            waitTimes =
              waitTimes && waitTimes[field.key] ? waitTimes[field.key] : [];
            const waitTime = Waittime.getMaxWaitTime(waitTimes);
            return waitTime
              ? String(formatCommentDate(toDate(waitTime.applied_on)))
              : '';
          },
          getHeaderText: () =>
            I18n.t('waittime.waitTime') +
            ' [' +
            I18n.t('waittime.application_day') +
            ']',
        },
      ];
    },
  },
  fertilizing: {
    accessor: field => get(field, 'activeCrop'),
    id: 'fertilizing',
    HeaderTableConfig: () => {
      return I18n.t('fertilizer.fertilization');
    },
    Header: () => {
      return (
        <div className={'fertilizing-header'}>
          <div>
            <span>&nbsp;</span>
            {'\t'}
            <span>N</span>
            {'\t'}
            <span>P₂O₅</span>
            {'\t'}
            <span>K₂O</span>
            {'\t'}
            <span>S</span>
            {'\t'}
            <span>Mg</span>
            <span>&nbsp;</span>
          </div>
        </div>
      );
    },
    Cell: FertilizingCell,
    sort: false,
    textColumns: () => {
      const makeColumn = (path, header) => ({
        id: 'fertilizing.' + path,
        accessor: field => {
          const total = get(field, 'activeCrop.fertilizingTotal');
          if (!total) {
            return '';
          }
          return formatSum(get(total, path, 0));
        },
        getDataText: sum => sum,
        getHeaderText: () => header + ' [kg/ha]',
      });

      return [
        makeColumn('n.kg', 'N'),
        makeColumn('p2o5.kg', 'P₂O₅'),
        makeColumn('k2o.kg', 'K₂O'),
        makeColumn('s.kg', 'S'),
        makeColumn('mg.kg', 'Mg'),
      ];
    },
  },
  distance: {
    accessor: field => field,
    id: 'distance',
    Header: () => I18n.t('field.distance'),
    Cell: DistanceCell as any,
    getDataText: (field, field2, { locationPermission, userPosition }) => {
      if (
        locationPermission === null ||
        locationPermission === false ||
        userPosition === null
      ) {
        return '';
      }
      const distance = geokdbush.distance(
        userPosition.longitude,
        userPosition.latitude,
        field.position.longitude,
        field.position.latitude,
      );
      return distance.toFixed(distance < 10 ? 1 : 0);
    },
  },
  irrigation: {
    accessor: field => {
      const type = get(field, 'irrigation', null);

      if (type) {
        return I18n.t(`irrigation.types.${type}`);
      }

      return null;
    },
    id: 'irrigation',
    Header: () => I18n.t('irrigation.irrigationType'),
  },
  waterOrigin: {
    accessor: field => {
      const origin = get(field, 'waterOrigin', null);

      if (origin) {
        return I18n.t(`irrigation.waterOriginTypes.${origin}`);
      }

      return null;
    },
    id: 'irrigation',
    Header: () => I18n.t('irrigation.waterOrigin'),
  },
  mark: {
    accessor: field => {
      return get(field, 'activeCrop.mark');
    },
    Cell: MarkCell,
    id: 'mark',
    Header: () => I18n.t('fieldMark.marks'),
    getDataText: getMarkDataText,
  },
  yield: {
    accessor: field => {
      return get(field, 'activeCrop.yield');
    },
    id: 'yield',
    Header: () => I18n.t('crop.yield'),
    sortMethod: sortByProps('perHa'),
    Cell: YieldCell,
    textColumns: () => {
      return [
        {
          id: 'yield-total',
          getDataText: cropYield => String(get(cropYield, 'total', '')),
          getHeaderText: () => I18n.t('crop.yield') + ' [t]',
        },
        {
          id: 'yield-perha',
          getDataText: cropYield => String(get(cropYield, 'perHa', '')),
          getHeaderText: () => I18n.t('crop.yield') + ' [t/ha]',
        },
      ];
    },
  },
  sharedBy: {
    accessor: field => {
      return get(field, 'shared_by.company.name');
    },
    id: 'sharedBy',
    Header: () => I18n.t('fieldFiltersModal.showSharedByCompany'),
  },
  sharedWith: {
    id: 'sharedBy',
    Header: () => I18n.t('fieldFiltersModal.showSharedWithCompanies'),
  },
  estimatedYield: {
    accessor: field => {
      const estimatedAmount = get(field, 'activeCrop.advancedSettings.yieldPrediction.estimatedAmount');
      const calendarWeek = get(field, 'activeCrop.advancedSettings.yieldPrediction.calendarWeek');
      if (estimatedAmount && calendarWeek) {
        return `${estimatedAmount}t ${calendarWeek}`;
      }
      return '';
    },
    id: 'estimatedYieldAmount',
    Header: () => `${I18n.t('crop.yieldPrediction')} / ${I18n.t('crop.calendarWeek')}`,
  },
  activeSinceCalendarWeek: {
    accessor: field => {
      return get(field, 'activeCrop.activeSinceCalendarWeek');
    },
    id: 'activeSinceCalendarWeek',
    Header: () => `${I18n.t('crop.cropActiveSince')} ${I18n.t('crop.calendarWeek')}`,
  },
  cropAgeCalnedarWeek: {
    accessor: field => {
      const calendarWeek = get(field, 'activeCrop.advancedSettings.yieldPrediction.calendarWeek');
      const activeSince = get(field, 'activeCrop.activeSinceCalendarWeek');
      if (calendarWeek && activeSince) {
        return `${calendarWeek-activeSince}`;
      }
      return '';
    },
    id: 'activeSinceCalendarWeek',
    Header: () => `${I18n.t('crop.age')} (${I18n.t('crop.calendarWeeks')})`,
  },
  precrops: {
    accessor: field => {
      const precrops = get(field, 'precrops', []);
      return precrops.join(', ');
    },
    id: 'precrops',
    Header: () => I18n.t('precrops.precrops'),
    Cell: (value) => <div style={{textAlign: 'right'}}>{value}</div>,
  },
  externalCropId: {
    accessor: field => {
      const cropId = get(field, 'activeCrop.externalCropId', null);
      return cropId ? [cropId.slice(0, 3), cropId.slice(3, 6), cropId.slice(6)].join(' ') : ''; 
    },
    id: 'externalCropId',
    Header: () => I18n.t('crop.externalCropId'),
  },
};

export const groupTypes: GroupTypes = {
  crop: {
    keyAccessor: field => {
      const notACrop = get(field, 'activeCrop.not_a_crop');
      const isUncultivated = notACrop === NotACropState.NotACrop;
      return (
        get(field, 'activeCrop.name') +
        '--' +
        get(field, 'activeCrop.color') +
        '--' +
        String(isUncultivated)
      );
    },
    sortMethod: sortByProps('isUncultivated', 'cropName'),
    props: (field, group) => {
      const notACrop = get(field, 'activeCrop.not_a_crop');
      return {
        size: get(group, 'size', 0) + (+field.size || 0),
        cropName:
          notACrop === NotACropState.NotACrop
            ? I18n.t('crop.without')
            : get(field, 'activeCrop.name'),
        count: get(group, 'count', 0) + 1,
        isUncultivated: notACrop === NotACropState.NotACrop,
      };
    },
  },
};

export function defaultSortMethod(a: any, b: any, desc: boolean) {
  // force null and undefined to the bottom
  a = a === null || a === undefined ? '' : a;
  b = b === null || b === undefined ? '' : b;
  // force any string values to lowercase
  a = typeof a === 'string' ? a.toLowerCase() : a;
  b = typeof b === 'string' ? b.toLowerCase() : b;
  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return desc ? -1 : 1;
  }
  if (a < b) {
    return desc ? 1 : -1;
  }
  // returning 0, undefined or any falsey value will use subsequent sorts or
  // the index as a tiebreaker
  return 0;
}
