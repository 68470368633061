import t from 'tcomb-form';
import { map, get } from 'lodash-es';
import I18n from '../../../language/i18n';

const tcombTypes = {
  string: t.String,
  boolean: t.Boolean,
  enum: t.enums,
  date: t.Date,
  number: t.Number,
  image: t.Object,
};

const generateTCombField = (field, key, state) => {
  const type = tcombTypes[field.type];
  const dependsOn = field.dependsOn;
  const required = get(field, 'required', true);

  if (dependsOn) {
    const dependency = get(state, dependsOn, null);
    const dependsOnValue = get(field, 'dependsOnValue');

    // We are depending on a particular value
    if (dependsOnValue) {
      if (dependsOnValue !== dependency) {
        return {};
      }
    }
    // we are depending on the whole field having a value, so if we don't we don't show this
    else if (dependency === null) {
      return {};
    }
  }

  if (field.type === 'enum') {
    return { [key]: required ? type(field.enums) : t.maybe(type(field.enums)) };
  }

  return { [key]: required ? type : t.maybe(type) };
};

export const generateTCombModel = ({ config, state, ref, companyId }) => {
  // Get the current's user language and check whether the form has a translation in that language
  // if not, use the default language for the form
  const currentLanguage = I18n.locale;
  const form =
    config.translations[currentLanguage] ||
    config.translations[config.defaultLanguage];

  const dynModel = t.struct(
    // Go over the blocks in the defined order
    map(form.order, blockName => {
      const block = get(form, `blocks.${blockName}`);

      // If we have an order key, but the block doesn't exist for some reason return here
      if (!block) {
        return {};
      }

      // If the block doesn't have fields, then we are dealing with a field block
      if (!block.fields) {
        return generateTCombField(block, blockName, state);
      }

      return {
        [blockName]: t.struct(
          // Go over the fields in a block
          map(block.order, fieldName => {
            const field = get(block, `fields.${fieldName}`);

            // if we have a field, then generate the tcomb code
            if (field) {
              return generateTCombField(field, fieldName, state);
            }

            return {};
          }).reduce((prev, curr) => {
            return {
              ...prev,
              ...curr,
            };
          }, {}),
        ),
      };
    }).reduce((prev, curr) => {
      return {
        ...prev,
        ...curr,
      };
    }, {}),
  );

  return dynModel;
};
