import React, { useCallback } from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import moment from 'moment';
import sanitizeFilename from 'sanitize-filename';

import * as fieldActions from 'farmerjoe-common/lib/actions/field';
import { filters } from 'farmerjoe-common/lib/actions/actions';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import { getFilteredHarvestedFields, sharedFieldsSelector } from 'farmerjoe-common/lib/selectors/fields';
import { isAdmin } from 'farmerjoe-common/lib/utils/User';
import { orderWaitTimesByFieldId } from 'farmerjoe-common/lib/selectors/waittimes';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { updatePosition } from 'farmerjoe-common/lib/actions/position';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getFormSchemasQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Forms';
import { getFeature } from 'farmerjoe-common/lib/selectors/features';
import { getFormSchemas } from 'farmerjoe-common/lib/selectors/forms';
import { convertStringToRange } from 'farmerjoe-common';
import { getGroupsQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Groups';

import Table from './Table';
import { getQueries } from '../utils';
import '../style.css';
import NoResults from '../../Common/NoResults';
import withRouter from '../../Router/withRouter';
import I18n from '../../../language/i18n';
import { saveFieldTableState } from '../../../actions/fieldTable';
import { getFieldPath } from '../../../utils/page';
import WithFieldCollaborators from '../../../containers/HOC/WithFieldCollaborators';

// TODO: improve typings
type Props = any;
type State = any;

const HarvestedTable = (props) => {
  const {
    company,
    fields,
    loading,
    waitTimes,
    myCompanyProfile,
    openFieldId,
    locationPermission,
    userPosition,
    fieldTableState,
    onTableStateChange,
    search,
    formSchemas,
    forwardedRef,
  } = props;

  const emptyView = useCallback((company, search) => {
    return search
      ? (
        <div style={{ flex: 1 }}>
          <NoResults text={I18n.t('noSearchResults')} />
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() =>
                props.actions.filters(company.key, {
                  search: '',
                })
              }
              style={{ minWidth: 250, borderRadius: 25, marginTop: 10 }}>
              {I18n.t('resetFilter')}
            </button>
          </div>
        </div>
      )
      : (
        <NoResults text={I18n.t('crop.nothingHarvested')} />
      );
  }, [props.actions]);

  return (
    <Table
      ref={forwardedRef}
      fields={loading ? null : fields}
      waitTimes={waitTimes}
      loading={loading}
      onClick={(key, cropKey) => {
        props.actions.openField(key, cropKey);
        props.history.push(getFieldPath(company.key, key, cropKey));
      }}
      onTableStateChange={onTableStateChange}
      openFieldId={openFieldId}
      locationPermission={locationPermission}
      userPosition={userPosition}
      isAdmin={isAdmin(myCompanyProfile)}
      emptyView={emptyView(company, search)}
      fieldTableState={fieldTableState}
      tableKey="harvested"
      formSchemas={formSchemas}
      exportFileName={`farmerjoe-${sanitizeFilename(
        I18n.t('harvested'),
      )}-${moment().format('DD.MM.YYYY')}`}
      fieldsCollaborators={[]}
    />
  );
};


const selector = state => {
  const openCompany = selectors.getOpenCompanyId(state);
  const withProducers = getFeature(state, openCompany, 'producers') === true;
  const fields = getFilteredHarvestedFields(state, openCompany, {}, withProducers);
  const company = getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const date = get(
    state,
    ['filtersByCompany', openCompany, 'harvestedDate'],
    'last:730',
  );

  const boniturOn = getFeature(state, openCompany, 'bonitur') === true;
  const dateRange = convertStringToRange(date);
  const sharedFields = sharedFieldsSelector(state, openCompany);

  const queries = getQueries(openCompany, browsingGroup, myCompanyProfile, dateRange, sharedFields);
  if (boniturOn) {
    queries.push(getFormSchemasQuery(openCompany));
  }
  if (withProducers) {
    queries.push(getGroupsQuery(openCompany, myCompanyProfile));
  }
  const formSchemas = getFormSchemas(state);

  return {
    openCompany,
    company,
    loading: !hasLoaded(queries,state),
    search: state.filtersByCompany[openCompany]
      ? state.filtersByCompany[openCompany].search
      : '',
    openFieldId: state.openFieldId,
    myCompanyProfile,
    // @ts-ignore
    waitTimes: orderWaitTimesByFieldId(state, openCompany),
    fields: fields || [],
    userPosition: state.userPosition,
    locationPermission: state.locationPermission,
    browsingGroup,
    boniturOn,
    formSchemas,
    dateRange,
    sharedFields,
    withProducers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
          saveFieldTableState,
          updatePosition,
        },
      ),
      dispatch,
    ),
  };
};

const wrappedHarvestedTable = firestoreConnect(
  ({ openCompany, browsingGroup, myCompanyProfile, dateRange, sharedFields, boniturOn, withProducers }) => {
    const queries = getQueries(openCompany, browsingGroup, myCompanyProfile, dateRange, sharedFields);

    if (boniturOn) {
      queries.push(getFormSchemasQuery(openCompany));
    }
    if (withProducers) {
      queries.push(getGroupsQuery(openCompany, myCompanyProfile));
    }
    return queries;
  },
)(HarvestedTable);

const HarvestedTableHOC = compose<typeof HarvestedTable>(
  WithFieldCollaborators,
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(wrappedHarvestedTable);

export default React.forwardRef(function(props: any, ref) {
  return <HarvestedTableHOC {...props} forwardedRef={ref} />;
});
