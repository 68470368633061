import React, { Component } from 'react';
import moment from 'moment';
import { clone } from 'lodash-es';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import t from 'tcomb-form';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import shortid from 'shortid32';

import * as cropActions from 'farmerjoe-common/lib/actions/crop';
import * as commentActions from 'farmerjoe-common/lib/actions/comment';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { searchForFieldAmongTheUniverse } from 'farmerjoe-common/lib/selectors/fields';
import { getColor } from 'farmerjoe-common/lib/utils/Colors';
import { getCropByFieldId } from 'farmerjoe-common/lib/selectors/crops';
import { toDate } from 'farmerjoe-common';
import { NotACropState } from 'farmerjoe-common/lib/flow/types';
import { isFieldOwner } from 'farmerjoe-common/lib/utils/Field';

import Icon from '../Common/Icon';
import withRouter from '../Router/withRouter';
import Dialog, { AlertDialog } from '../Dialog/Dialog';
import CropModel from '../../tcomb/models/crop';
import I18n from '../../language/i18n';
import { dummy } from '../../utils/Comment';
import * as constants from '../../styles/style';


const Form = t.form.Form;

// TODO: improve typings
type Props = any;
type State = any;

class FormCrop extends Component<Props, State> {
  static propTypes = {
    crop: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    // const ds = new ListView.DataSource({rowHasChanged: (r1, r2) => r1 !== r2})
    this.state = {
      crop: clone(this.props.field.activeCrop),
      field: this.props.field,
      select: false,
      renderForm: false,
      // uniqCrops: ds.cloneWithRows(this.props.uniqCrops),
      alertMessage: null,
      alertTitle: null,
    };

    if (this.state.crop.sown_on) {
      this.state.crop.sown_on = new Date();
    }
  }

  onSave() {
    const value = (this.refs.form as any).getValue();

    // If value is null, then the form has errors
    if (value === null) {
      this.setState({
        alertMessage: I18n.t('please_correct_your_entry'),
        alertTitle: I18n.t('error'),
      });
      return;
    }

    const { field, auth, crop } = this.props;
    const currentCrop = {
      ...clone(field.activeCrop),
      ...crop,
      art: value.art,
      not_a_crop: NotACropState.HarvestedCrop,
      key: this.props.field.activeCrop.key,
      sown_on: moment(toDate(value.sown_on)).toDate(),
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      externalCropId: shortid.generate(),
    };

    const db = firebase.firestore();
    const batch = db.batch();

    const commentRef = db.collection('comments').doc();

    // Add comment
    const comment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        currentCrop,
        auth,
        'system.crop.sown',
      ),
      text: { languageConstant: 'crop.planned_crop_is_active' },
    };

    const cropRef = db.collection('crops').doc(field.activeCrop.key);
    const fieldRef = db.collection('fields').doc(field.key);
    const favoriteRef = db.collection('favorites').doc();

    batch.update(cropRef, currentCrop);

    batch.set(commentRef, comment);
    batch.update(fieldRef, {
      activeCrop: currentCrop,
    });

    const favorite = {
      name: currentCrop.name,
      replace: { name: currentCrop.name, color: currentCrop.color },
      company_id: field.company_id,
      type: 'crops',
      key: favoriteRef.id,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (value.favorite) {
      batch.set(favoriteRef, favorite);
    }

    batch.commit();

    this.props.onClose();
  }

  onChange(value) {
    this.setState({
      crop: {
        ...value,
      },
    });
  }

  render() {
    const { show, onClose } = this.props;
    if (!show) {
      return null;
    }

    return (
      <Dialog
        show={show}
        onClose={onClose}
        title={I18n.t('crop.plant')}
        footer={
          <div className="d-flex flex-grow-1">
            <button className="ml-auto btn btn-secondary" onClick={onClose}>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}>
              {I18n.t('save')}
            </button>
          </div>
        }
        className={'plant-crop-dialog'}>
        <div style={{ flex: 1 }}>
          <div
            style={{
              ...constants.styles.box,
              ...{ paddingLeft: 0, paddingRight: 0 },
            }}>
            {this.renderForm()}
          </div>
          <AlertDialog
            show={!!this.state.alertMessage}
            onClose={() =>
              this.setState({ alertMessage: null, alertTitle: null })
            }
            title={this.state.alertTitle}
            children={this.state.alertMessage}
          />
        </div>
      </Dialog>
    );
  }

  renderForm() {
    const { isOwnerOfField } = this.props;

    return (
      <div>
        <Form
          ref="form"
          type={CropModel.shortModel()}
          options={() =>
            CropModel.options(
              { crop: this.state.crop, isOwnerOfField },
              'short',
              <div
                className={
                  'd-flex flex-row align-items-center mb-4 flex-grow-1'
                }
                style={{
                  backgroundColor: getColor(this.state.crop.color),
                  padding: 5,
                  borderRadius: 5,
                }}>
                <Icon
                  iconType="fj"
                  style={{ marginLeft: 0, marginRight: 5 }}
                  name="time"
                  size={17}
                  color={constants.FJBLACK}
                />

                <span style={{ ...constants.styles.stdSize }}>
                  {this.state.crop.name}
                </span>
              </div>,
            )
          }
          value={this.state.crop}
          onChange={this.onChange.bind(this)}
        />
      </div>
    );
  }
}

const selector = state => {
  const user = state.firebase.profile;
  const openCompany = selectors.getOpenCompanyId(state);
  const openField = selectors.getOpenFieldId(state);
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, openField);
  const crop = getCropByFieldId(
    state.firestore.data,
    openCompany,
    openField,
    field.activeCrop.key,
  );

  const isOwnerOfField = field
    ? isFieldOwner(field.company_id, openCompany)
    : true;

  return {
    openCompany,
    crop,
    field,
    uniqCrops: [],
    auth: state.firebase.profile,
    isOwnerOfField,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...cropActions,
          ...commentActions,
        },
      ),
      dispatch,
    ),
  };
};

export default compose<typeof FormCrop>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(FormCrop);
