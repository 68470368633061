import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actionCreators from 'farmerjoe-common/lib/actions/actions';
import * as constants from '../../styles/style';

import firebase from '../../data/firebase';

import { translateError } from 'farmerjoe-common/lib/actions/errorTranslations';
import I18n, { browserLanguage, tWithComponent } from '../../language/i18n';
import { LoadingIcon } from '../Loading/Loading';
import Logo from './Logo';
import Icon from '../Common/Icon';
import TextInput from '../Common/TextInput';
import Page from '../Page/Page';
import { AlertDialog } from '../Dialog/Dialog';
import { get } from 'lodash-es';
import { compose } from 'redux';
import withRouter from '../Router/withRouter';
import { requestNewPassword } from 'farmerjoe-common/lib/utils/Profile';
import { KeyCodes } from '../../flowTypes';

// TODO: improve typings
type Props = any;
type State = any;

class RequestPassword extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      success: false,
      alertMessage: null,
      alertTitle: null,
    };
  }

  requestPassword() {
    if (this.validate()) {
      this.setState({ isFetching: true });

      requestNewPassword({
        language: browserLanguage(),
        email: this.state.email,
      }).then(
        response => {
          this.setState({
            isFetching: false,
            success: response.success,
          });

          if (response.success === false) {
            this.setState({
              alertMessage: I18n.t('crypticErrorMessage'),
              alertTitle: I18n.t('error'),
            });
          }
        },
        error => {
          this.setState({
            isFetching: false,
            alertMessage: translateError(error),
            alertTitle: I18n.t('error'),
          });
        },
      );
    }
  }

  validate() {
    if (!this.state.email) {
      this.setState({
        alertMessage: I18n.t('no_email_entered'),
        alertTitle: I18n.t('error'),
      });
      return false;
    }

    return true;
  }

  render() {
    const container = (
      <div style={{ ...styles.container }}>
        <Logo />

        <h1 className="text-center mb-4">{I18n.t('password.forgotten')}</h1>

        {this.state.success ? (
          // We need the key for android. Otherwise the app crashes on RN 0.36
          <div key="wtf">
            <div
              style={{
                ...constants.styles.box,
                ...{
                  borderRadius: 5,
                  borderColor: '#CCC',
                  borderWidth: 1,
                },
              }}>
              <span style={{ marginBottom: 10, fontSize: 16 }}>
                <span style={{ color: '#707274' }}>
                  {tWithComponent('forgotten_password_mail_sent_to', [
                    <span key="email" style={{ fontWeight: 'bold' }}>
                      {this.state.email}
                    </span>,
                  ])}
                </span>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.props.history.push('/login');
                }}
                disabled={!!this.state.isFetching}>
                {I18n.t('back')}
              </button>
            </div>
          </div>
        ) : null}

        {!this.state.success
          ? (
            <div>
              <div style={{ position: 'relative' }}>
                <TextInput
                  placeholder={I18n.t('email')}
                  style={{
                    paddingLeft: 45,
                  }}
                  autoFocus={true}
                  id="email"
                  name="email"
                  autoCorrect="false"
                  autoCapitalize="none"
                  onChange={e => this.setState({ email: e.target.value })}
                  onKeyUp={e => {
                    if (e.keyCode === KeyCodes.Enter) {
                      this.requestPassword();
                    }
                  }}
                  label={
                    <Icon
                      name="ios-mail-outline"
                      color="#CCCCCC"
                      style={{
                        position: 'absolute',
                        zIndex: 9999,
                        left: 13,
                        top: -1,
                        fontSize: 28,
                      }}
                    />
                  }
                  disabled={!!this.state.isFetching}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                className="mt-5">
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    this.props.history.push('/login');
                  }}
                  disabled={!!this.state.isFetching}>
                  {I18n.t('back')}
                </button>

                <button
                  className="btn btn-primary ml-auto"
                  onClick={() => this.requestPassword()}
                  disabled={!!this.state.isFetching}>
                  {I18n.t('request_new_password')}
                </button>
              </div>
            </div>
          )
          : null}
      </div>
    );

    return (
      <Page
        wrapperClassName="d-flex align-content-center justify-content-center flex-column login request-password"
        header={null}>
        <div className="login-register login-sidebar">
          <div className="login-box card">
            <div className="card-body" style={{ overflowX: 'hidden' }}>
              {container}
            </div>

            {this.state.isFetching
              ? (
                <div
                  style={{
                    display: 'flex',
                    backgroundColor: 'rgba(255,255,255,0.6)',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    zIndex: 9999,
                  }}>
                  <LoadingIcon style={{ transform: [{ scale: 1.5 }] }} />
                </div>
              )
              : null}
          </div>
        </div>
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
          children={this.state.alertMessage}
        />
      </Page>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    justifyContent: 'flex-end',
  },
};

const selector = state => {
  return {
    profile: get(state, 'firebase.profile'),
  };
};

export default compose(
  connect(
    selector,
    actionCreators,
  ),
  withRouter,
)(RequestPassword);
