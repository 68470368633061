import React from 'react';
import { formatNumber, NumberFormat, ParsedNumber } from 'libphonenumber-js';

import CountryTelList from './CountryTelList';
import './style.css';
import TextInput from '../../../tcomb/templates/TextInput';

import { getCallCodeAndPhone } from '../../../utils/phone';
import { SelectedCountry } from '../../../types/phone';

type Props = {
  value: null | string;
  onChange: (...args: Array<any>) => any;
  editable: boolean;
  placeholder: string;
  hasError: boolean;
  format: NumberFormat;
  floatingLabels?: boolean;
  label?: string | React.ReactNode | null;
  style?: Record<string, any>;
  countryListStyle?: Record<string, any>;
  error?: any;
};

type State = {
  selected: SelectedCountry | null;
  value: null | string;
  filter: any;
};

export default class CountryTelInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    format: 'INTERNATIONAL',
    floatingLabels: true,
  };

  constructor(props: Props) {
    super(props);
    const parsedPhone = getCallCodeAndPhone(this.props.value);

    this.state = {
      filter: null,
      selected: parsedPhone ? parsedPhone.country as SelectedCountry : null,
      value: parsedPhone ? parsedPhone.phone : '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      const parsedPhone = getCallCodeAndPhone(this.props.value);
      if (parsedPhone) {
        this.setState({ selected: parsedPhone.country });
      }
    }
  }

  render() {
    const {
      placeholder,
      editable,
      hasError,
      floatingLabels,
      label,
      style,
      countryListStyle,
    } = this.props;
    const { selected } = this.state;
    const editableValue = typeof editable !== 'undefined' ? editable : true;

    const { value } = this.state;

    const cca2 = selected !== null ? selected.cca2 : null;
    const callCode = selected !== null ? selected.callCode : null;

    return (
      <div style={{ flex: 1, ...style }} className="country-input">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <CountryTelList
            editable={editableValue}
            hasError={hasError}
            cca2={cca2 as any}
            callCode={callCode as any}
            onChange={item => {
              this.setState({ selected: item });
              const res = {
                value: formatNumber(
                  { country: item.cca2, phone: value || '' },
                  this.props.format,
                ),
              };
              this.props.onChange(res);
            }}
            style={{ marginRight: 5, ...countryListStyle }}
          />

          <TextInput
            ref="input"
            style={{ flex: 1, ...(editableValue ? null : { color: 'grey' }) }}
            value={value}
            onChange={text => {
              if (text) {
                this.setState({ value: text });
                this.props.onChange({
                  value: formatNumber(
                    { country: cca2, phone: text } as ParsedNumber,
                    this.props.format,
                  ),
                });
              } else {
                this.setState({ value: text });
                this.props.onChange({ value: null });
              }
            }}
            placeholder={placeholder}
            label={label}
            hasError={hasError}
            disabled={!editableValue}
            floatingLabels={floatingLabels}
          />
        </div>
      </div>
    );
  }
}
