import React from 'react';
import I18n from '../../../../language/i18n';
import SegmentedControlComponent from '../../../../tcomb/templates/SegmentedControl';
import { SegmentedControlOptionsProps, SegmentedControlProps } from './SegmentedControlTypes';
import styles from './SegmentedControl.module.css';
import { FJERROR } from '../../../../styles/style';

const translateOptions = (optionKeys: SegmentedControlOptionsProps[]) => {
  return optionKeys.map(option => ({ text: I18n.t(option.text), value: option.value, color: option.color }));
};

const SegmentedControl = ({ config, formik, value, error }: SegmentedControlProps) => {
  const {
    name,
    options,
    isDisabled,
    segmentedControlOptions,
  } = config;

  const controlOptions = options?.hasSegmentedControlTranslationKey === true ? translateOptions(segmentedControlOptions) : segmentedControlOptions;
  const { setFieldValue } = formik;

  return (
    <SegmentedControlComponent
      options={controlOptions}
      config={{ order: false }}
      onChange={(option: SegmentedControlOptionsProps['value']) => {
        setFieldValue(name, option);
      }}
      value={value}
      className={styles.container}
      containerStyle={{ display: 'inherit' }}
      optionContainerStyle={{
        flexGrow: 1,
        borderColor: error ? FJERROR : '#ABA7A4',
        borderTopWidth: 1,
        marginTop: 0,
        textAlign: 'center',
      }}
      hasError={error}
      isDisabled={isDisabled}
    />
  );
};

export default React.memo(SegmentedControl);
