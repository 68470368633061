import React from 'react';
import { get } from 'lodash-es';
import t from 'tcomb-form';

import Producer from './Producer';
import Trader from './Trader';
import { addressTransformerFn } from '../utils';

import type { tCombLocals } from '../../../flowTypes';
import { styles } from '../../../styles/style';
const Component = t.form.Component;

class GroupFactory extends Component {
  getTemplate() {
    return (locals: tCombLocals) => {
      let errorStyle = {};

      if (locals.hasError) {
        errorStyle = styles.danger;
      }

      if (locals.config.hidden) {
        return null;
      }

      const type = get(locals, 'config.type', 'producer');
      const readOnly = get(locals, 'config.readOnly', 'false');

      let label = <span>{' '}</span>;
      if (locals.label) {
        label = <span style={errorStyle}>{locals.label}</span>;
      }

      return (
        <div style={{ marginLeft: 20, marginRight: 20, marginTop: 5 }}>
          {label}
          {this.renderInnerComponent(locals, type, readOnly)}
        </div>
      );
    };
  }

  private renderInnerComponent(locals: tCombLocals, type: string, readOnly: boolean) {
    if (type === 'producer') {
      return (
        <Producer
          value={locals.value}
          onChange={locals.onChange}
          addressTransformer={addressTransformerFn}
          readOnly={readOnly}
        />
      );
    } else if (type === 'trader') {
      return (
        <Trader
          value={locals.value}
          onChange={locals.onChange}
          addressTransformer={addressTransformerFn}
          readOnly={readOnly}
        />
      );
    } else {
      throw new Error('Unsupported type: ' + type);
    }
  }
}

export default GroupFactory;
