import * as React from 'react';
import { connect } from 'react-redux';

import * as constants from '../../styles/style';

import I18n from '../../language/i18n';
import md5 from 'md5';
import Icon from '../Common/Icon';
import { LoadingIcon } from '../Loading/Loading';
import FlatList from '../Common/FlatList';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import './style.css';
import { getCountryName } from '../../utils/Countries';
import { NotActivatedWarning } from '../Activation/NotActivatedWarning';
import { InviteStatus } from 'farmerjoe-common/lib/flow/types';

const { map, get } = require('lodash-es');

type Props = {
  invites?: any[];
  auth?: {
    email: string;
    emailVerified?: boolean;
  };
  firebase?: {
    firestore: (...args: Array<any>) => any;
  };
  allInvites?: {};
};

class Invites extends React.PureComponent<Props> {
  render() {
    let { invites, auth } = this.props;

    invites = map(invites, (value, key) => {
      return {
        key,
        ...value,
      };
    });

    if (!auth?.emailVerified) {
      return <NotActivatedWarning type={'invite'} />;
    }

    if (!invites?.length) {
      return <div className="invites">{I18n.t('invites.noInvites')}</div>;
    }

    return (
      <FlatList
        data={invites}
        renderItem={({ item }) => this.renderInvite(item)}
        className="invites"
      />
    );
  }

  /**
   * Prior to 2.1 the invite was located in the md5 hash of the email.
   * That's why we try to read this part of the state and if we have an invite there
   * consider it the email key. If we don't find anything there, then the email is the key
   *
   * TODO: remove this in version 3
   * @param companyKey
   * @returns {*}
   */
  findEmailKey(companyKey) {
    const { allInvites, auth } = this.props;
    const md5Invite = get(allInvites, [
      md5(auth?.email),
      'companies',
      companyKey,
    ]);

    // check if the searched key is in the md5
    if (md5Invite) {
      return md5(auth?.email);
    }

    // if it is not in the md5invite, then we are dealing with the new invites
    return auth?.email;
  }

  renderInvite(invite) {
    const  { company } = invite;
    if (!company) {
      // @ts-ignore
      return;
    }

    const styles = constants.styles;
    let street: any = null;
    let zipCity: any = null;
    let country: any = null;

    const key = company.group_id
      ? `${invite.key}:=:${company.group_id}`
      : invite.key;

    if (company.street) {
      street = <span style={{ ...styles.stdSize }}>{company.street}</span>;
    }
    if (company.zip || company.city) {
      zipCity = (
        <span style={{ ...styles.stdSize }}>
          {company.zip} {company.city}
        </span>
      );
    }
    if (company.country) {
      country = (
        <span style={{ ...styles.stdSize }}>
          {getCountryName(company.country)}
        </span>
      );
    }
    return (
      <div key={invite.key} className="card">
        <div className="card-body">
          <h4 className="card-title">
            {I18n.t('invites.from', {
              name: invite.invited_by.name
                ? invite.invited_by.name
                : invite.invited_by.email,
            })}
          </h4>

          <div style={{ ...styles.strong, ...styles.stdSize }}>
            {company.name}
          </div>
          <p>
            {street}
            <br />
            {zipCity}
            <br />
            {country}
          </p>

          <div
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'center',
            }}>
            <button
              onClick={() => {
                const { firebase } = this.props;
                const db = firebase?.firestore();

                db.collection('invites')
                  .doc(this.findEmailKey(invite.key))
                  .collection('companies')
                  .doc(key)
                  .update({ accept: InviteStatus.Rejected });
              }}
              className={'btn btn-danger'}>
              {invite.accept === InviteStatus.Rejected
                ? (
                  <LoadingIcon
                    style={{ marginRight: 5, width: 22, height: 22 }}
                  />
                )
                : (
                  <Icon name="md-close" />
                )}
              {' ' + I18n.t('invites.reject')}
            </button>

            <button
              className={'btn btn-success'}
              onClick={() => {
                const { firebase } = this.props;

                const db = firebase?.firestore();

                db.collection('invites')
                  .doc(this.findEmailKey(invite.key))
                  .collection('companies')
                  .doc(key)
                  .update({ accept: InviteStatus.Accepted });
              }}
              style={{ marginLeft: 15 }}>
              {invite.accept === InviteStatus.Accepted
                ? (
                  <LoadingIcon
                    style={{ marginRight: 5, width: 22, height: 22 }}
                  />
                )
                : (
                  <Icon name="md-checkmark" />
                )}
              {' ' + I18n.t('invites.accept')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const selector = (state, ownProps) => {
  const allInvites = get(state, 'firestore.data.invites');
  const profile = state.firebase.profile;

  let invites;

  if (typeof profile.email !== 'undefined') {
    invites = get(state, [
      'firestore',
      'data',
      'invites',
      profile.email,
      'companies',
    ]);
  }

  return {
    allInvites: allInvites,
    invites: invites,
    auth: state.firebase.auth,
  };
};

export default compose<typeof Invites>(
  connect(
    selector,
    () => ({}),
  ),
  firestoreConnect(),
)(Invites);
