import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';

import Logo from './Logo';
import { LOGIN_SCREEN_CREDENTIALS_PROVIDER } from './constants';
import TextInput from '../Common/TextInput';

import * as profileActions from '../../actions/profile';
import I18n from '../../language/i18n';
import { captureException } from '../../utils/sentry';
import * as styles from '../../styles/style';

const LoginWithEmail = (props) => {
  const { email, actions, history, location, onError, setScreen } = props;
  const [loginData, setLoginData] = useState({
    password: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  const login = () => {
    actions
      .login(email, loginData.password)
      .then(() => {
        const pathname = get(location, 'state.from.pathname') || '/';
        history.push(pathname);
      })
      .catch(error => {
        captureException(error);
        console.error(error);
        onError({ alertMessage: error.message,
          alertTitle: I18n.t('error'),
        });
      });
  };

  return (
    <div
      style={{
        ...{ flex: 1 },
      }}>
      <div style={{ position: 'relative' }}>
        <Logo />
        <h1 className="text-center mb-4">{I18n.t('login')}</h1>
        <form
          onSubmit={handleSubmit}
          id={'login'}
          className="form-horizontal">
          <p style={{textAlign: 'center'}}>
            {email}
          </p>
          <TextInput
            placeholder={I18n.t('password.password')}
            style={{ paddingLeft: 20 }}
            containerClassName={'mt-4 mb-4'}
            autoFocus={true}
            id="password"
            name="password"
            type="password"
            autoCorrect="false"
            autoCapitalize="none"
            onChange={e => setLoginData({...loginData, password: e.target.value })}
          />

          <div
            style={{
              display: 'flex',
            }}>
            <button
              className="btn btn-primary btn-block">
              {I18n.t('login')}
            </button>
          </div>
        </form>

        <div
          style={{
            display: 'flex',
            margin: '1em 0',
          }}>
          <button
            className="btn btn-default btn-block"
            onClick={() => setScreen(LOGIN_SCREEN_CREDENTIALS_PROVIDER)}
          >
            {I18n.t('back')}
          </button>
        </div>


        <div className="text-center" style={{
          margin: '1em 0',
        }}>
          <Link
            style={{
              color: styles.FJGREY,
              fontSize: 14,
              fontWeight: 400,
              cursor: 'pointer',


            }}
            to="/forgotpassword">
            {I18n.t('password.forgotten')}?
          </Link>
        </div>

        <div
          style={{
            margin: '1rem 0',
            display: 'flex',
          }}>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...profileActions,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state) => {
  const { credentialsProvider: { meta: { email }}} = state;
  return {
    email,
  };
};

export default compose(
  connect(
    selector,
    mapDispatchToProps,
  ),
)(LoginWithEmail);
