import React from 'react';
import { whatCropButtonToRender } from 'farmerjoe-common';

import Harvest from '../Field/Harvest';
import NewCrop from '../Field/NewCrop';
import Unarchive from '../Field/Unarchive';
import YieldButton from '../Field/YieldButton';
import type { Field, Profile } from '../../flowTypes';

type Props = {
  field: Field;
  myCompanyProfile: Profile;
};

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 140,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
  },
  rowContainerWithMargin: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
};

/**
 * This component renders the necessary crop/field buttons in the Field view
 * bellow the comments (plan, plant, harvest, unarchive)
 */
export default class CommentsHeaderComponent extends React.PureComponent<Props> {
  /**
   * Renders the 2 buttons plan & plant crop
   * @returns {*}
   */
  private renderNotACropButtons() {
    const { activeCrop } = this.props.field;
    return (
      <div>
        <div style={{ marginTop: 0 }}>
          <NewCrop crop={activeCrop} plant={false} />
        </div>
        <div style={{ marginTop: 10 }}>
          <NewCrop crop={activeCrop} plant={true} />
        </div>
      </div>
    );
  }

  /**
   * renders just a plant crop button
   *
   * @returns {*}
   */
  private renderPlannedCropButton() {
    const { activeCrop } = this.props.field;
    return (
      <div style={styles.rowContainer}>
        <NewCrop crop={activeCrop} />
      </div>
    );
  }

  /**
   * Render a harvest button
   *
   * @returns {*}
   */
  private renderHarvestButton() {
    const { field } = this.props;
    const { activeCrop } = field;

    return <Harvest field={field} activeCrop={activeCrop} />;
  }

  /**
   * Renders unarhive button
   *
   * @returns {*}
   */
  private renderUnarchive() {
    const { field } = this.props;
    return (
      <div style={styles.rowContainer}>
        <Unarchive field={field} />
      </div>
    );
  }

  private renderYield() {
    const { field } = this.props;
    const { activeCrop } = field;

    return (
      <div className={'d-flex flex-row justify-content-center'}>
        <YieldButton crop={activeCrop} />
      </div>
    );
  }

  private renderYieldAndArchive() {
    const { field } = this.props;
    const { activeCrop } = field;

    return (
      <div>
        <div className={'d-flex flex-row justify-content-center'}>
          <YieldButton crop={activeCrop} />
        </div>
        <div style={styles.rowContainerWithMargin}>
          <Unarchive field={field} />
        </div>
      </div>
    );
  }

  render() {
    const { field, myCompanyProfile } = this.props;
    const { activeCrop } = field;
    let content: any = whatCropButtonToRender(myCompanyProfile as any, activeCrop, field);

    switch (content) {
    case 'notACrop':
      content = this.renderNotACropButtons();
      break;
    case 'plannedCrop':
      content = this.renderPlannedCropButton();
      break;
    case 'harvest':
      content = this.renderHarvestButton();
      break;
    case 'unarchive':
      content = this.renderUnarchive();
      break;
    case 'yield':
      content = this.renderYield();
      break;
    case 'yield & archive':
      content = this.renderYieldAndArchive();
      break;
    case 'none':
    default:
      content = null;
      break;
    }

    return (
      <div style={styles.mainContainer} className="comments-header-component">
        {content}
      </div>
    );
  }
}
