import { get } from 'lodash-es';
import React, { Component } from 'react';

import { firebaseConnect } from 'react-redux-firebase';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { uploadProfileImage } from '../../actions/image';

import CoverImage from '../Common/CoverImage';

// TODO: improve typings
type Props = any;
type State = any;

class Cover extends Component<Props, State> {
  render() {
    const cover = get(this.props.owner, 'coverImage.original.downloadURL', null);
    const placeholder = get(this.props.owner, 'coverImage.placeholder', false);

    if (placeholder) {
      return cover
        ? (
          <div
            style={{
              background: `url(${cover
                .replace(/\(/g, '%28')
                .replace(/\)/g, '%29')}) no-repeat center center`,
              backgroundSize: 'cover',
              height: 150,
            }}
          />
        )
        : null;
    }

    return (
      <CoverImage
        cover={cover}
        onPickImage={this.handleProfileImageUpload.bind(this)}
        pickerSettings={{
          width: 820,
          height: 462,
          cropping: true,
          imageQuality: 0.5,
        }}
      />
    );
  }

  handleProfileImageUpload(image) {
    this.props.actions.uploadProfileImage(image);
  }
}

const wrappedCover = firebaseConnect(props => {
  return [];
})(Cover);

const selector = state => {
  return {
    owner: state.firebase.profile,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          uploadProfileImage,
        },
      ),
      dispatch,
    ),
  };
}

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedCover);
