import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';

import Logo from './Logo';

import { AlertDialog } from '../Dialog/Dialog';
import { LoadingIcon } from '../Loading/Loading';
import Page from '../Page/Page';
import withRouter from '../Router/withRouter';

import { captureException } from '../../utils/sentry';
import firebase from '../../data/firebase';
import I18n from '../../language/i18n';
import * as constants from '../../styles/style';

import { translateError } from 'farmerjoe-common/lib/actions/errorTranslations';
import * as actionCreators from 'farmerjoe-common/lib/actions/actions';

// TODO: improve typings
type Props = any;
type State = any;

class RequestPassword extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      alertMessage: null,
      alertTitle: null,
      error: null,
    };
  }

  componentDidMount() {
    this.recoverEmail();
  }

  recoverEmail() {
    this.setState({ isFetching: true });

    const auth = firebase.auth();

    auth.applyActionCode(this.props.actionCode).then(
      () => {
        this.setState({
          isFetching: false,
          success: true,
        });
      },
      error => {
        this.setState({
          isFetching: false,
          error,
        });
        console.warn(error);
        captureException(error, void 0);
      },
    );
  }

  render() {
    let errorMessage: any = null;
    if (this.state.error) {
      errorMessage = translateError(this.state.error);
    }

    const container = (
      <div style={{ ...styles.container }}>
        <Logo />

        {this.state.success || this.state.error
          ? (
            <div>
              <div
                style={{
                  ...constants.styles.box,
                  ...{
                    borderRadius: 5,
                    borderColor: '#CCC',
                    borderWidth: 1,
                  },
                }}>
                <span style={{ marginBottom: 10, fontSize: 16 }}>
                  <span style={{ color: '#707274' }}>
                    {this.state.error
                      ? errorMessage
                      : I18n.t('emailChangeReverted')}
                  </span>
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    this.props.history.push('/login');
                  }}
                  disabled={!!this.state.isFetching}>
                  {I18n.t('back')}
                </button>
              </div>
            </div>
          )
          : null}
      </div>
    );

    return (
      <Page
        wrapperClassName="d-flex align-content-center justify-content-center flex-column login request-password"
        header={null}>
        <div className="login-register login-sidebar">
          <div className="login-box card">
            <div className="card-body" style={{ overflowX: 'hidden' }}>
              {container}
            </div>

            {this.state.isFetching
              ? (
                <div
                  style={{
                    display: 'flex',
                    backgroundColor: 'rgba(255,255,255,0.6)',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    zIndex: 9999,
                  }}>
                  <LoadingIcon style={{ transform: [{ scale: 1.5 }] }} />
                </div>
              )
              : null}
          </div>
        </div>
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
          children={this.state.alertMessage}
        />
      </Page>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    justifyContent: 'flex-end',
  },
};

const selector = state => {
  return {
    profile: get(state, 'firebase.profile'),
  };
};

export default compose<typeof RequestPassword>(
  connect(
    selector,
    actionCreators,
  ),
  withRouter,
)(RequestPassword);
