import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { filters } from 'farmerjoe-common/lib/actions/actions';
import * as fieldActions from 'farmerjoe-common/lib/actions/field';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import './style.css';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Icon from '../Common/Icon';
import * as constants from '../../styles/style';
import TextInput from '../Common/TextInput';
import I18n from '../../language/i18n';
import { LoadingIcon } from '../Loading/Loading';
import { classes } from '../../utils/dom';

// TODO: improve typings
type Props = any;
type State = any;

class MapGeoSearch extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.map.setCenter(latLng);
        this.props.map.setZoom(20);
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{ bounds: this.props.map.getBounds() }}
        highlightFirstSuggestion={true}
        shouldFetchSuggestions={this.state.address.length > 2}
        clearItemsOnError={true}
        debounce={500}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="search-input map-geo-search">
            <Icon
              name="search"
              iconType="fj"
              style={{
                color: constants.FJMUTEDDARK,
              }}
            />
            <TextInput
              type="text"
              placeholder={I18n.t('search')}
              {...getInputProps()}
            />
            {loading
              ? (
                <span className="loading">
                  <LoadingIcon style={{ height: 35 }} />
                </span>
              )
              : (
                <span
                  className="clear"
                  onClick={() => this.setState({ address: '' })}>
                  <Icon
                    name="md-close"
                    style={{
                      color: constants.FJBORDERCOLOR,
                      fontSize: '1.4em',
                    }}
                  />
                </span>
              )}
            <div className="autocomplete-dropdown-container">
              {suggestions.map(suggestion => {
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: classes(
                        'suggestion-item',
                        suggestion.active && 'active',
                      ),
                    })}>
                    <span>
                      <Icon iconType="ion" name="md-pin" />{' '}
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
        },
      ),
      dispatch,
    ),
  };
}

const defaultFilter = { search: '', mapType: 'satellite' };
const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);

  return {
    openCompany: openCompany,
    filter: state.filtersByCompany[openCompany]
      ? state.filtersByCompany[openCompany]
      : defaultFilter,
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(MapGeoSearch);
