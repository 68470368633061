import React from 'react';
import stylesheet from '../stylesheets/style';

import I18n from '../../language/i18n';
import t from 'tcomb-form';
import FormInput from '../templates/FormInput';
import Country from '../templates/Country/Country';
import CountryTel from '../templates/Country/CountryTel';
import Boolean from '../templates/Boolean';
import Email from '../validators/email';
import Tel from '../validators/tel';

// Our account model
const model = () => {
  const address = t.struct({
    country: t.String,
    region: t.maybe(t.String),
    city: t.String,
    postalCode: t.String,
    address_line_1: t.String,
    address_line_2: t.maybe(t.String),
  });

  const contact = t.struct({
    firstName: t.String,
    lastName: t.String,
    address,
    email: Email,
    phoneNumber: Tel,
  });

  const company = t.struct({
    name: t.maybe(t.String),
    taxId: t.maybe(t.String),
    address: t.maybe(address),
    addressSameAsContactAddress: t.Boolean,
  });

  const structure = {
    contact,
    company,
  };

  return t.struct(structure);
};

const options = function(component) {
  return {
    stylesheet: stylesheet,
    fields: {
      contact: {
        label: I18n.t('billing.billingContact'),
        template: locals => templateContact(locals),
        fields: {
          firstName: {
            label: I18n.t('first_name') + ' *',
            blurOnSubmit: false,
            factory: FormInput,
            autoCorrect: false,
            onSubmitEditing: () =>
              component.refs.form.getComponent('lastName').refs.input.focus(),
          },
          lastName: {
            label: I18n.t('last_name') + ' *',
            blurOnSubmit: false,
            factory: FormInput,
            autoCorrect: false,
          },
          email: {
            label: I18n.t('email') + ' *',
            returnKeyType: 'next',
            blurOnSubmit: false,
            keyboardType: 'email-address',
            factory: FormInput,
            autoCorrect: false,
          },
          phoneNumber: {
            label: I18n.t('phone') + ' *',
            placeholder: `${I18n.t('phones.enterNumber')} *`,
            factory: CountryTel,
          },
          address: {
            label: I18n.t('billing.billingAddress'),
            template: locals => templateAddress(locals),
            fields: {
              country: {
                label: I18n.t('country') + ' *',
                factory: Country,
              },
              region: {
                label: I18n.t('billing.stateRegion'),
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
              city: {
                label: I18n.t('city') + ' *',
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
              postalCode: {
                label: I18n.t('billing.postalCode') + ' *',
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
              address_line_1: {
                label: I18n.t('billing.addressLine') + ' 1 *',
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
              address_line_2: {
                label: I18n.t('billing.addressLine') + ' 2',
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
            },
          },
        },
      },
      company: {
        label: I18n.t('billing.companyInfo'),
        template: locals => templateCompany(locals),
        fields: {
          name: {
            label: I18n.t('billing.companyName'),
            blurOnSubmit: false,
            factory: FormInput,
            autoCorrect: false,
          },
          taxId: {
            label: 'VAT/Tax ID',
            blurOnSubmit: false,
            factory: FormInput,
            autoCorrect: false,
          },
          address: {
            label: I18n.t('billing.companyAddress'),
            template: locals => templateAddress(locals),
            fields: {
              country: {
                label: I18n.t('country') + ' *',
                factory: Country,
              },
              region: {
                label: I18n.t('billing.stateRegion'),
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
              city: {
                label: I18n.t('city') + ' *',
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
              postalCode: {
                label: I18n.t('billing.postalCode') + ' *',
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
              address_line_1: {
                label: I18n.t('billing.addressLine') + ' 1 *',
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
              address_line_2: {
                label: I18n.t('billing.addressLine') + ' 2',
                blurOnSubmit: false,
                factory: FormInput,
                autoCorrect: false,
              },
            },
          },
          addressSameAsContactAddress: {
            label: I18n.t('billing.companyAddressSameAsContactAddress'),
            factory: Boolean,
            autoCorrect: false,
          },
        },
      },
    },
  };
};

const templateContact = (locals) => {
  return (
    <div>
      <div
        style={{
          padding: '0.5em 0',
          fontWeight: 'bold',
        }}>
        {locals.label}
      </div>
      {locals.inputs.firstName}
      {locals.inputs.lastName}
      {locals.inputs.email}
      {locals.inputs.phoneNumber}
      {locals.inputs.address}
    </div>
  );
};
const templateCompany = (locals) => {
  const showAddress = !locals.value.addressSameAsContactAddress;

  return (
    <div>
      <div
        style={{
          padding: '0.5em 0',
          fontWeight: 'bold',
        }}>
        {locals.label}
      </div>
      <div>
        {locals.inputs.name}
        {locals.inputs.taxId}
        {showAddress ? locals.inputs.address : null}
        {locals.inputs.addressSameAsContactAddress}
      </div>
    </div>
  );
};

const templateAddress = (locals) => {
  return (
    <div style={{margin: '1em 0'}}>
      <div
        style={{
          padding: '0.5em 0',
          fontWeight: 'bold',
        }}>
        {locals.label}
      </div>
      <div>
        {locals.inputs.country}
        {locals.inputs.region}
        {locals.inputs.city}
        {locals.inputs.postalCode}
        {locals.inputs.address_line_1}
        {locals.inputs.address_line_2}
      </div>
    </div>
  );
};

export default { model, options };
