import { useState, useEffect } from 'react';
import { v1_GetPaymentMethods } from 'farmerjoe-common/lib/requests/billing';

import { getUidToken } from '../../utils/auth';

type Response = string[];
const usePaymentMethods = (companyId: string) : [Response, boolean] => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [ apiLoading, setApiLoading ] = useState(false);

  useEffect(() => {
    const getData = () => {
      setApiLoading(true);
      return getUidToken()
        .then((uidToken) => {
          return v1_GetPaymentMethods(companyId, uidToken)
            .then((response) => {
              return response.json().then((result) => {
                if (response.status === 200) {
                  setPaymentMethods(result);
                }
              });
            });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setApiLoading(false);
        });
    };
    if (companyId) {
      getData();
    }
  }, [companyId]);

  return [paymentMethods, apiLoading];
};

export default usePaymentMethods;
