import React from 'react';
import './style.css';
import { classes } from '../../utils/dom';
import { CropGroup, Section } from 'farmerjoe-common/lib/utils/FieldsGrouper';

type Props = {
  className?: string | Array<string> | null | void | boolean;
  initialNumToRender?: any;
  ListHeaderComponent?: any;
  renderItem: (arg0: Record<string, any>) => React.ReactNode;
  renderSectionHeader?: any;
  renderSectionFooter?: any;
  sections: any;
  ListEmptyComponent?: any;
  SectionSeparatorComponent?: any;
  stickySectionHeadersEnabled?: any;
  keyExtractor?: any;
  onScroll?: any;
  onContentSizeChange?: any;
  keyboardShouldPersistTaps?: any;
  contentOffset?: any;
  inverted?: any;
  centerContent?: any;
  getItemLayout?: any;
};

export interface FieldListItemType {
  item: CropGroup[];
  section: Section;
}

export default function SectionList({
  className,
  ListHeaderComponent,
  renderItem,
  renderSectionHeader,
  renderSectionFooter,
  sections,
  ListEmptyComponent,
  inverted,
}: Props) {
  const _sections = !inverted
    ? sections
    : sections.map(section => ({ ...section, data: section.data.reverse() }));

  return (
    <div className={classes('section-list', className)}>
      {ListHeaderComponent ? <div>{ListHeaderComponent}</div> : null}
      {!_sections.length && ListEmptyComponent ? ListEmptyComponent : null}
      {_sections.length
        ? (
          <ul>
            {_sections.map((section, sectionIndex) => {
              const items = section.data.map((data, itemIndex) => {
                const fieldListItem: FieldListItemType = {
                  item: data,
                  section,
                };
                const el = renderItem(fieldListItem);
                return <li key={`${sectionIndex}-${itemIndex}`}>{el}</li>;
              });

              return (
                <li key={`${sectionIndex}`} className="section">
                  {renderSectionHeader ? renderSectionHeader({ section }) : null}
                  <ul>{items}</ul>
                  {renderSectionFooter ? renderSectionFooter({ section }) : null}
                </li>
              );
            })}
          </ul>
        )
        : null}
    </div>
  );
};
