import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateUi } from 'farmerjoe-common/lib/actions/ui';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { getFieldSharingRequests } from 'farmerjoe-common/lib/selectors/fields';

import FieldSharingRequestListItem from './FieldSharingRequestListItem';
import './styles.css';

import NoResults from '../Common/NoResults';
import FlatList from '../Common/FlatList';
import I18n from '../../language/i18n';


const FieldSharingRequests = (props) => {
  const { fieldSharingRequests, openCompany, actions } = props;

  return (
    <div className="field-sharing-requests">
      <FlatList
        className="field-sharing-requests-list"
        ListEmptyComponent={
          <NoResults text={I18n.t('fieldSharing.emptyRequestsListText')} />
        }
        data={fieldSharingRequests}
        renderItem={({ item }) => {
          return (
            <FieldSharingRequestListItem
              key={item.key}
              fieldSharingRequest={item}
              openCompany={openCompany}
            />
          );
        }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          updateUi,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state) => {
  const openCompany = getOpenCompanyId(state);
  const requests = getFieldSharingRequests(state, openCompany);
  return {
    fieldSharingRequests: requests,
    openCompany,
  };
};

export default connect(selector, mapDispatchToProps)(FieldSharingRequests);
