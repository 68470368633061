import React, { useRef, useState, useEffect } from 'react';
import { calcPrice } from './prices';
import I18n from '../../language/i18n';
import { Loading } from '../Loading/Loading';

const RemoveSeatsInput = (props) => {
  const { value, onChange, price, currentQuantity, minUsage, onConfirm } = props;
  const total = Number.isInteger(value) ? value : 0;
  const newTotalQty = total === 0 ? currentQuantity - 1 : currentQuantity - total;
  const exceeds = newTotalQty < minUsage;
  const totalQty = exceeds ? minUsage : newTotalQty;

  const [ waiting, setWaiting ] = useState(false);

  useEffect(() => {
    return () => {
      setWaiting(false);
    };
  }, []);

  const btnRef = useRef(null);

  const onClick = () => {
    if (btnRef) {
      (btnRef as any).current.disabled = true;
    }
    setWaiting(true);
    onConfirm(totalQty);
  };

  return (
    <div>
      <form>
        <div>
          <input
            style={{
              maxWidth: '60px',
              textAlign: 'right',
            }}
            value={value}
            onChange={e => {
              if (e.target.value === '') {
                onChange('');
                return;
              }

              const numb = parseInt(e.target.value);
              onChange(numb);
            }}
          />
          <span> {I18n.t('billing.seats')}, -{price}&#8364; {I18n.t('billing.perSeatPerMonth')} </span>
        </div>
      </form>
      <div
        style={{
          borderTop: '1px solid grey',
          marginTop: '15px',
        }}
      >
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <td>
                {I18n.t('billing.currentSeats')}:
              </td>
              <td style={{textAlign: 'right'}}>
                {currentQuantity}
              </td>
            </tr>
            <tr>
              <td>
                {I18n.t('billing.newSeats')}:
              </td>
              <td style={{textAlign: 'right'}}>
                {totalQty}
              </td>
            </tr>
            <tr>
              <td> {`${I18n.t('billing.totalPrice')}*`} </td>
              <td style={{textAlign: 'right'}}>{calcPrice(price, totalQty)}&#8364;</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{fontWeight: 'bold', fontSize: '14px'}}>
        {`*${I18n.t('billing.netPrice')}`}
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        {waiting ? (
          <Loading />
        ) : (
          <button
            ref={btnRef}
            className={'btn btn-outline-secondary'}
            onClick={onClick}
            style={{
              textTransform: 'uppercase',
            }}
          >
            {I18n.t('billing.reduceSeats')}
          </button>
        )}
      </div>
    </div>
  );
};

export default RemoveSeatsInput;
