import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actionCreators from '../../actions/profile';
import * as constants from '../../styles/style';

import PasswordModel from '../../tcomb/models/password';
import I18n from '../../language/i18n';
import t from 'tcomb-form';
import Dialog, { AlertDialog } from '../Dialog/Dialog';
import { Loading } from '../Loading/Loading';
import { MIN_PASSWORD_LENGTH } from 'farmerjoe-common/lib/constants/registration';

const Form = t.form.Form;

// TODO: improve typings
type Props = any;
type State = any;

class ChangePassword extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: null,
      alertTitle: null,
    };
  }

  onChange(value) {
    this.setState({
      ...value,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.owner.isFetching && !nextProps.owner.isFetching) {
      if (!nextProps.owner.error) {
        // Reset the state
        this.setState({
          currentPassword: '',
          password1: '',
          password2: '',
          alertMessage: null,
          alertTitle: null,
        });
        this.setState({
          alertMessage: I18n.t('password.yourPasswordWasUpdated'),
          alertTitle: I18n.t('password.updated'),
          closeModalOnCloseOfAlert: true,
        });
      }

      if (nextProps.owner.error) {
        this.setState({
          alertMessage: I18n.t('password.notUpdated'),
          alertTitle: nextProps.owner.errorMessage,
        });
      }
    }
  }

  render() {
    const { owner, show, onClose } = this.props;

    return (
      <Dialog
        show={show}
        onClose={onClose}
        title={I18n.t('password.change')}
        footer={
          <div className="d-flex flex-grow-1">
            <button
              className="ml-auto btn btn-secondary"
              disabled={owner.isFetching}
              onClick={onClose}>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              disabled={owner.isFetching}
              onClick={this.onSave.bind(this)}>
              {I18n.t('save')}
            </button>
          </div>
        }>
        <div style={{ flex: 1 }}>
          {owner.isFetching
            ? (
              <div
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  position: 'absolute',
                  justifyContent: 'center',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  zIndex: 9999,
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Loading />
              </div>
            )
            : null}
          <div style={{ flex: 1 }}>
            <div style={{ marginTop: 30 }}>
              <div style={{ ...styles.box, ...{ padding: 0 } }}>
                <Form
                  ref="form"
                  type={PasswordModel.model}
                  options={() => PasswordModel.options(this)}
                  value={this.state}
                  onChange={this.onChange.bind(this)}
                />
              </div>
            </div>
          </div>

          <AlertDialog
            show={!!this.state.alertMessage}
            onClose={() => {
              this.setState({ alertMessage: null, alertTitle: null });
              if (this.state.closeModalOnCloseOfAlert) {
                this.props.onClose();
              }
            }}
            title={this.state.alertTitle}
            children={this.state.alertMessage}
          />
        </div>
      </Dialog>
    );
  }

  onSave() {
    const value = (this.refs.form as any).getValue();

    // If value is null, then the form has errors
    if (value === null) {
      this.setState({
        alertMessage: I18n.t('please_correct_your_entry'),
        alertTitle: I18n.t('error'),
      });
      return false;
    }

    if (value.password1.length < MIN_PASSWORD_LENGTH) {
      this.setState({
        alertMessage: I18n.t('password.minXchars', { length: MIN_PASSWORD_LENGTH }),
        alertTitle: I18n.t('error'),
      });
      return false;
    }

    if (value.password1 !== value.password2) {
      this.setState({
        alertMessage: I18n.t('password.dontMatch'),
        alertTitle: I18n.t('error'),
      });
      return false;
    }

    const data = {
      user_id: this.props.owner.user.user_id,
      password: value.currentPassword,
      newPassword: value.password1,
    };

    this.props.changePassword(data, this.props.owner.user.token).catch(e => {
      console.error(e);
    });
    return true;
  }
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
  },
  box: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: constants.FJBORDERCOLOR,
    paddingTop: 10,
    paddingBottom: 10,
    padding: 20,
    backgroundColor: '#FFFFFF',
  },
};

const selector = state => {
  return {
    owner: state.owner,
  };
};

export default connect(
  selector,
  actionCreators,
)(ChangePassword);
