import RangeCheck from 'range_check';
import { every } from 'lodash-es';

const t = require('tcomb-form');

const IpList = t.refinement(t.maybe(t.String), function(s) {
  if (!s) {
    return true;
  }

  const ranges = s.split(/\s*,\s*/g);
  return (
    !ranges.length ||
    every(ranges, range => RangeCheck.isRange(range) || RangeCheck.isIP(range))
  );
});

export default IpList;
