import React, { useState } from 'react';
import { debounce, get } from 'lodash-es';

import { getAlgoliaInstance } from '../../../services/algolia';
import { ALGOLIA_INDEX_COMPANIES } from '../../../constants';

import Icon from '../../Common/Icon';
import FlatList from '../../Common/FlatList';
import SearchInput from '../../Common/SearchInput';
import { Avatar } from '../../Common/Avatar';
import * as constants from '../../../styles/style';
import firebase from '../../../data/firebase';

import styles from './ShareWithRenderer.module.css';

const algoliaInstance = getAlgoliaInstance(ALGOLIA_INDEX_COMPANIES);

type TShareWith = {
  [companyId: string]: {
    formSubmissionId: string;
    name: string;
    uid: string;
  };
};

type TShareWithRendererProps = {
  config: any;
  formik: any;
  value: TShareWith;
  error: any;
};

const ShareWithRenderer = ({ config, formik, value, error }: TShareWithRendererProps) => {
  const { setFieldValue } = formik;
  const { name: fieldName } = config;

  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState('');
  const companies = value || {};

  const searchForCompanies = debounce((value) => {
    if (!value) {
      setSearchResults([]);
      return;
    }

    return algoliaInstance.searchIndex(value).then((result) => {
      setSearchResults(result.hits);
    });
  }, 1000);

  return (
    <div>
      {Object.keys(companies).length ? (
        <div className={styles['bubble-row']}>
          {Object.keys(companies).map((key) => (
            <ItemBubble
              key={key}
              companyId={key}
              companies={companies}
              fieldName={fieldName}
              setFieldValue={setFieldValue}
            />
          ))}
        </div>
      ) : null}
      <SearchInput
        placeholder="Betriebsname eingeben"
        search={search}
        onChange={(text) => {
          setSearch(text);
          searchForCompanies(text);
        }}
      />

      <FlatList
        className="crop-rating-share-with-list"
        data={searchResults}
        renderItem={({ item }) => (
          <SearchResultItem
            name={item.name}
            logo={item.logo}
            selected={!!companies[item.uid]}
            onSelect={() => {
              if (companies && companies[item.uid]) {
                const { [item.uid]: removed, ...rest } = companies;
                setFieldValue(fieldName, rest);
                return;
              }

              const selection = {
                ...companies,
                [item.uid]: {
                  name: item.name,
                  uid: item.uid,
                  logo: item.logo || '',
                  formSubmissionId: firebase
                    .firestore()
                    .collection('formSubmissions')
                    .doc().id,
                },
              };
              setFieldValue(fieldName, selection);
            }}
          />
        )}
      />
    </div>
  );
};

export default ShareWithRenderer;

type SearchResultItemProps = {
  name: string;
  logo: {
    original: {
      downloadURL: string;
    };
  };
  selected: boolean;
  onSelect: () => void;
};

const SearchResultItem = ({ name, logo, selected, onSelect }: SearchResultItemProps) => {
  const companyImageUri = get(logo, 'original.downloadURL', null);
  return (
    <div onClick={onSelect} className={styles['search-result-row']}>
      <div className={styles['flex-row-center']}>
        {companyImageUri ? (
          <Avatar
            downloadUrl={companyImageUri}
            style={{ backgroundColor: '#EEE' }}
            isLoaded={false}
            size="small"
          />
        ) : (
          <Icon
            name="barn"
            iconType={'fj'}
            style={{
              fontSize: 30,
            }}
          />
        )}
        <span style={{ marginLeft: 5 }}>{name}</span>
      </div>
      <div>
        <Icon
          name={selected ? 'ios-checkmark-circle' : 'ios-radio-button-off'}
          style={styleIcon}
        />
      </div>
    </div>
  );
};

type ItemBubbleProps = {
  companyId: string;
  companies: TShareWith;
  fieldName: string;
  setFieldValue: (fieldName: string, value: any) => void;
};

const ItemBubble = ({ companyId, companies, fieldName, setFieldValue }: ItemBubbleProps) => {
  return (
    <div className={styles.bubble}>
      <div className={styles['flex-row-center']}>
        <div>{companies[companyId].name}</div>
        <div
          className={styles['bubble-close']}
          onClick={() => {
            if (companies && companies[companyId]) {
              const { [companyId]: removed, ...rest } = companies;
              setFieldValue(fieldName, rest);
            
            }
          }}
        >
          <i className="fa fa-times" />
        </div>
      </div>
    </div>
  );
};

const styleIcon = { fontSize: 25, color: constants.FJAPPLEBLUE };
