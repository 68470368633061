import React from 'react';

import * as constants from '../../styles/style';

import { FJNEWGREEN } from '../../styles/style';

import { get, without, orderBy, includes } from 'lodash-es';
import Icon from '../../components/Common/Icon';
import './style.css';

function isObject(obj) {
  return obj instanceof Object;
}

const styles = Object.freeze({
  row: {
    ...constants.styles.containerRow,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const priceRow = (item, price, help) => {
  return (
    <div style={styles.row}>
      <div>
        <span>{item}</span>
        {help ? <span style={constants.styles.extraSmall}>{help}</span> : null}
      </div>
      <span style={{ marginRight: 10, whiteSpace: 'nowrap' }}>{price}</span>
    </div>
  );
};

export default function MultiSelect(locals) {
  const options = orderBy(locals.options, 'value');

  let label: any = null;

  const multiple = get(locals, 'config.multiple', true);
  let iconColor = FJNEWGREEN;

  if (locals.hasError) {
    iconColor = 'red';
  }

  if (locals.label) {
    label = <span style={{ marginBottom: 5, fontWeight: 'bolder' }}>{locals.label}</span>;
  }

  return (
    <div
      className="multi-select"
      style={{ ...{ borderBottomWidth: 0, marginTop: 10 } }}>
      {label}
      <ul className="list-style-none">
        {options.map((item, i) => {
          let text = item.text;

          if (isObject(text)) {
            text = priceRow(text.title, text.price, text.subtitle);
          }

          const selected = includes(locals.value, item.value);

          return (
            <li
              className="item"
              style={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
                alignItems: 'center',
              }}
              onClick={() => {
                locals.onChange(
                  selected
                    ? without(locals.value, item.value)
                    : [...(multiple ? locals.value : []), item.value],
                );
              }}
              key={i}>
              <div style={{ flex: 1 }}>{text}</div>
              <div
                style={{
                  display: 'flex',
                  margin: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                {selected
                  ? (
                    <Icon
                      name={'ios-checkmark-circle'}
                      style={{ fontSize: 25, color: iconColor }}
                    />
                  )
                  : (
                    <Icon
                      name={'ios-radio-button-off'}
                      style={{ fontSize: 25, color: iconColor }}
                    />
                  )}
              </div>
            </li>
          );
        })}
      </ul>
      {locals.config.help}
    </div>
  );
}
