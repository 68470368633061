import React from 'react';
import { get } from 'lodash-es';
import SegmentedControl from './SegmentedControl';
import I18n from '../../../language/i18n';

// TODO: improve typings
type Props = any;
type State = any;

export default class BooleanSegmentedControls extends React.PureComponent<Props, State> {
  render() {
    const { optionLabels } = this.props;
    const labelFalsy = get(optionLabels, 'falsy', I18n.t('no'));
    const labelTruthy = get(optionLabels, 'truthy', I18n.t('yes'));
    return (
      <SegmentedControl
        {...this.props}
        options={[
          { text: labelFalsy, value: false },
          { text: labelTruthy, value: true },
        ]}
      />
    );
  }
}
