import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash-es';

import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { getActiveFields } from 'farmerjoe-common/lib/selectors/fields';
import {
  DISPLAY_SETTINGS_SHOW_CROPS_WITH,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH_PIECES,
} from 'farmerjoe-common/lib/constants/filters';
import { NotACropState } from 'farmerjoe-common/lib/flow/types';

import IconHeader from '../Common/IconHeader';
import { FjIconType } from '../Common/Icon';
import I18n from '../../language/i18n';

import useNumberFormatter from '../../hooks/useNumberFormatter';

import * as constants from '../../styles/style';
import './style.css';

interface Props {
  sectionId?: any;
  fields?: any;
  expanded?: any;
  onClick?: (sectionId: any) => any;
  showCropPieces?: boolean;
}

interface IconStyle {
  color: string;
  marginRight: number;
  fontSize: number;
}

interface SectionHeader {
  iconName: string;
  iconType: FjIconType;
  iconStyle: IconStyle;
  text: string;
  className?: string;
}

const getDefaultIconStyle = (): IconStyle => {
  const iconStyle: IconStyle = {
    color: constants.FJMUTEDLIGHTER,
    marginRight: 10,
    fontSize: 12,
  };
  return iconStyle;
};

const getSectionHeaderForType = (sectionId: string, sectionExpanded: boolean): SectionHeader => {
  const iconStyle = getDefaultIconStyle();
  const defaultSectionHeader: SectionHeader = {
    iconName: sectionExpanded ? 'arrow_down' : 'arrow_up',
    iconType: 'fj',
    iconStyle,
    text: I18n.t('crop.active'),
  };

  switch (sectionId) {
  case 'crop':
    return defaultSectionHeader;
  case 'planned':
    return { ...defaultSectionHeader, text: I18n.t('crop.planned'), className: 'justify-content-start' };
  case 'noCrop':
    return { ...defaultSectionHeader, text: I18n.t('crop.without') };
  default:
    throw new Error(`Unable to get header for header type: ${sectionId}`);
  }
};

const getSectionHeader = (sectionId: string, sectionExpanded: boolean) => {
  const headerForType: SectionHeader = getSectionHeaderForType(sectionId, sectionExpanded);
  return <IconHeader {...headerForType} />;
};

const getHektarForSection = (sectionId: string, fields: Array<any>) => {
  return getFilteredFields(sectionId, fields)
    .reduce((acc, val) => {
      return acc + (val.size || 0);
    }, 0)
    .toFixed(2);
};

const getFilteredFields = (sectionId: string, fields: Array<any>) => {
  switch (sectionId) {
  case 'noCrop':
    return fields
      .filter(field => field.activeCrop.not_a_crop === NotACropState.NotACrop);
  case 'crop':
    return fields
      .filter(field => field.activeCrop.not_a_crop === NotACropState.HarvestedCrop);
  case 'planned':
    return fields
      .filter(field => field.activeCrop.not_a_crop === NotACropState.PlannedCrop);
  default:
    return [];
  }
};

const FieldsListSectionHeader = (props: Props) => {
  const { sectionId, fields, expanded: sectionExpanded, showCropPieces } = props;
  const numberFormatter = useNumberFormatter(I18n.locale, 0);
  return (
    <div
      className="fields-list-section-header"
      onClick={() => {
        if (props.onClick) props.onClick(sectionId);
      }}>
      {showCropPieces ? renderCropPieces(numberFormatter)(fields, sectionId, sectionExpanded) : renderArea(fields, sectionId, sectionExpanded)}
    </div>
  );
};

const renderArea = (fields: Array<any>, sectionId, expanded) => {
  const ha = getHektarForSection(sectionId, fields);
  const sectionHeader = getSectionHeader(sectionId, expanded);

  return (
    <div className="uncultivated-section-header">
      <div>{sectionHeader}</div>
      <div className="area">
        <span>{ha} ha</span>
      </div>
    </div>
  );
};

const renderCropPieces = (numberFormatter) => (fields: Array<any>, sectionId, expanded) => {
  const quantity = getFilteredFields(sectionId, fields)
    .reduce((acc, val) => {
      const pieces = Number.parseInt(val.activeCrop.pieces);
      const piecesNumber = Number.isNaN(pieces) ? 0 : pieces;
      return acc + (piecesNumber);
    }, 0);

  const sectionHeader = getSectionHeader(sectionId, expanded);

  return (
    <div className="uncultivated-section-header">
      <div>{sectionHeader}</div>
      <div className="area">
        <span>{numberFormatter(quantity)} {I18n.t('crop.pieces.short')}</span>
      </div>
    </div>
  );
};

export default compose<typeof FieldsListSectionHeader>(
  connect((state: any) => {
    const openCompany = getOpenCompanyId(state);
    const fields = getActiveFields(state, openCompany, state.firebase.auth.uid);

    const displaySettings = get(
      state,
      ['filtersByCompany', openCompany, 'displaySettings'],
      {},
    );

    const currentFieldsTab = get(state, `currentFieldsTab[${openCompany}]`, null);

    const showCropPieces = get(
      displaySettings,
      [
        currentFieldsTab,
        DISPLAY_SETTINGS_SHOW_CROPS_WITH,
      ],
      DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
    ) === DISPLAY_SETTINGS_SHOW_CROPS_WITH_PIECES;

    return {
      fields: fields || [],
      showCropPieces,
    };
  }),
)(FieldsListSectionHeader);
