import React, { useState} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';

import HarvestForm from './HarvestForm';
import I18n from '../../language/i18n';
import Icon from '../Common/Icon';

type Props = {
  field: any;
  activeCrop?: any;
};

const Harvest  = ({ field, activeCrop }: Props) => {
  const [showHarvestForm, setShowHarvestForm] = useState(false);
  const style = {
    minWidth: 290,
    borderRadius: 25,
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <button
        onClick={() => setShowHarvestForm(true)}
        className={'btn btn-primary'}
        style={style}>
        <Icon iconType="ion" name={'md-checkmark-circle-outline'} />{' '}
        {I18n.t('field.harvested')}
      </button>
      <HarvestForm
        field={field}
        activeCrop={activeCrop}
        show={showHarvestForm}
        onClose={() => setShowHarvestForm(false)}
      />
    </div>
  );
};


export default compose(
  connect((state: any) => ({
    openCompanyId: selectors.getOpenCompanyId(state),
    openFieldId: selectors.getOpenFieldId(state),
  })),
)(Harvest);
