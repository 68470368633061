import React, { Component, MouseEventHandler } from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { get, reduce } from 'lodash-es';

import * as fieldActions from 'farmerjoe-common/lib/actions/field';
import { filters } from 'farmerjoe-common/lib/actions/actions';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import {
  getHarvestedCrops,
  sortByYear,
} from 'farmerjoe-common/lib/selectors/crops';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { convertStringToRange } from 'farmerjoe-common';
import { sharedFieldsSelector } from 'farmerjoe-common/lib/selectors/fields';

import CropFieldsListItem from './CropFieldsListItem';
import { getFieldsGroup, getFieldsSorting, calculateFieldsArea, getQueries } from './utils';
import './style.css';

import NoResults from '../Common/NoResults';
import IconHeader from '../Common/IconHeader';
import SectionList from '../Common/SectionList';
import { Loading } from '../Loading/Loading';
import withRouter, { FjRouteChildrenProps } from '../Router/withRouter';

import I18n from '../../language/i18n';
import * as constants from '../../styles/style';
import { getFieldPath } from '../../utils/page';
import { Employee } from '../../flowTypes';
import WithFieldCollaborators from '../../containers/HOC/WithFieldCollaborators';

type SectionHeaderProps = {
  onClick?: MouseEventHandler;
  section: any;
  expanded: boolean;
};

class HarvestedSectionHeader extends Component<SectionHeaderProps> {
  render() {
    const { section, onClick, expanded } = this.props;
    const fieldsArea = calculateFieldsArea(section.data[0]);

    return (
      <div className={'fields-list-section-header'}>
        <div
          className={'harvested-section-header'}
          onClick={onClick}>
          <div>
            <IconHeader
              showIcon={true}
              text={section.title}
              iconStyle={{
                color: constants.FJMUTEDLIGHTER,
                marginRight: 10,
                fontSize: 12,
              }}
              iconType={'fj'}
              icon={expanded ? 'arrow_down' : 'arrow_up'}
            />
          </div>
          <div className="area">
            <span>{fieldsArea} ha</span>
          </div>
        </div>
      </div>
    );
  }
}

type OwnProps = {
  tabLabel: string;
  isActiveTab: boolean;
  harvestedIsShown: boolean;
};

/**
 * Typings for Props parsed from redux store
 */
type StateProps = {
  openCompany: string;
  loading: boolean;
  harvested: any[];
  search: string;
  openFieldPath?: any;
  browsingGroup: string;
  myCompanyProfile: Employee;
  dateRange: Date[];
  sharedFields: any[];
};

/**
 * Typings for reducers from the redux store
 */
type DispatchProps = {
  actions: typeof fieldActions & {
    filters: typeof filters;
  };
};

type Props = StateProps & DispatchProps & OwnProps & FjRouteChildrenProps;
type State = {
  open: any;
  firstLoad: boolean;
};

class Harvested extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      open: {},
      firstLoad: true,
    };
  }

  componentWillUpdate(newProps: Props) {
    this.update(newProps);
  }

  update(props: Props = this.props) {
    if (props.harvested && props.harvested.length && this.state.firstLoad) {
      this.setState({
        open: reduce(
          props.harvested,
          (result, value) => {
            result[value.title] = true;
            return result;
          },
          {},
        ),
        firstLoad: false,
      });
    }
  }

  render() {
    const { harvested, loading } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <SectionList
        className="harvested-list"
        renderItem={this.renderItem}
        renderSectionHeader={this.renderSectionHeader}
        centerContent={!harvested.length}
        sections={harvested}
        ListEmptyComponent={this.emptyView()}
        stickySectionHeadersEnabled={true}
        keyExtractor={this.keyExtractor}
      />
    );
  }

  renderItem = item => {
    const { openFieldPath } = this.props;
    return (
      <CropFieldsListItem
        expanded={!!this.state.open[item.section.title]}
        rowData={item}
        onClick={this.onItemClick}
        openFieldPath={openFieldPath}
      />
    );
  };

  renderSectionHeader = ({ section }) => {
    const expanded = this.state.open[section.title];

    const onClick = () => {
      this.setState({
        open: {
          ...this.state.open,
          [section.title]: !this.state.open[section.title],
        },
      });
    };

    return (
      <HarvestedSectionHeader
        onClick={onClick}
        section={section}
        expanded={expanded}
      />
    );
  };

  onItemClick = (key, cropKey) => {
    const { openCompany } = this.props;
    this.props.actions.openField(key, cropKey);
    this.props.history.push(getFieldPath(openCompany, key, cropKey));
  };

  keyExtractor = (item, index) => {
    return index;
  };

  private emptyView() {
    let noResultsText = I18n.t('crop.nothingHarvested');
    if (this.props.search) {
      noResultsText = I18n.t('noSearchResults');
    }
    return <NoResults text={noResultsText} />;
  }
}

const selector = (state): StateProps => {
  const openCompany = selectors.getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const date = get(
    state,
    ['filtersByCompany', openCompany, 'harvestedDate'],
    'last:730',
  );
  const dateRange = convertStringToRange(date);
  const sharedFields = sharedFieldsSelector(state, openCompany);
  const queries = getQueries(openCompany, browsingGroup, myCompanyProfile, dateRange, sharedFields);
  const loading = !hasLoaded(queries, state);

  let harvested = [];
  const sort = getFieldsSorting(state, openCompany, 'harvested', 'fieldName');
  const group = getFieldsGroup(state, openCompany, 'harvested', 'cropName');
  const searchWord = get(
    state,
    ['filtersByCompany', openCompany, 'search'],
    '',
  );
  // performance optimization - wait until everything is loaded before getting harvested fields
  if (!loading) {
    harvested = sortByYear(state, getHarvestedCrops(state, openCompany), sort, group);
  }

  let openFieldPath: any = null;
  if (state.openFieldId) {
    openFieldPath = (harvested as any).openFieldPath;
  }


  return {
    openCompany,
    loading,
    harvested,
    search: searchWord,
    openFieldPath,
    browsingGroup,
    myCompanyProfile,
    dateRange,
    sharedFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
        },
      ),
      dispatch,
    ),
  };
};

const wrappedHarvested = firestoreConnect(
  ({ openCompany, browsingGroup, myCompanyProfile, dateRange, sharedFields }) => {
    return getQueries(openCompany, browsingGroup, myCompanyProfile, dateRange, sharedFields);
  },
)(Harvested);

export default compose<React.ComponentClass<OwnProps>>(
  WithFieldCollaborators,
  connect<StateProps, DispatchProps, OwnProps>(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(wrappedHarvested);
