import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { get } from 'lodash-es';

import { generateAndSaveToken } from 'farmerjoe-common/lib/actions/fcmtoken';
import firebase from 'firebase/app';
import 'firebase/firestore';

class ActivityTracker extends React.Component<{
  currentUser: {
    uid: string;
  };
  actions: {
    generateAndSaveToken: (...args: Array<any>) => any;
  };
  fcmToken: string;
}> {
  render() {
    return null;
  }

  componentDidMount() {
    const { currentUser, actions, fcmToken } = this.props;

    // We don't have an uid, when the user is not logged in. So listen for state changes here
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        const uid = firebase.auth().currentUser?.uid;

        // Create a reference to this user's specific status node.
        // This is where we will store data about being online/offline.
        const userStatusDatabaseRef = firebase
          .database()
          .ref(`/presence/${uid}`);

        // We'll create two constants which we will write to
        // the Realtime database when this device is offline
        // or online.
        const isOfflineForDatabase = {
          state: 'offline',
          last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        const isOnlineForDatabase = {
          state: 'online',
          last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        // Create a reference to the special '.info/connected' path in
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.
        firebase
          .database()
          .ref('.info/connected')
          .on('value', function(snapshot) {
            // If we're not currently connected, don't do anything.
            if (
              snapshot.val() === null ||
              snapshot.val() === void 0 ||
              snapshot.val() === false
            ) {
              return;
            }

            // If we are currently connected, then use the 'onDisconnect()'
            // method to add a set which will only trigger once this
            // client has disconnected by closing the app,
            // losing internet, or any other means.
            userStatusDatabaseRef
              .onDisconnect()
              .set(isOfflineForDatabase)
              .then(function() {
                // The promise returned from .onDisconnect().set() will
                // resolve as soon as the server acknowledges the onDisconnect()
                // request, NOT once we've actually disconnected:
                // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                // We can now safely set ourselves as 'online' knowing that the
                // server will mark us as offline once we lose connection.
                userStatusDatabaseRef.set(isOnlineForDatabase);
              });
          });
      }
    });

    if (currentUser) {
      /**
       * Check if the user has a fcm token and if he doesn't try to generate one
       */
      if (!fcmToken) {
        actions.generateAndSaveToken(currentUser.uid);
      }
    }
  }
}

export default compose(
  connect(
    (state, ownProps: any) => {
      const fcmToken = get(state, 'fcmToken');

      return {
        currentUser: ownProps.currentUser,
        fcmToken: fcmToken,
      };
    },
    function mapDispatchToProps(dispatch) {
      return {
        actions: bindActionCreators(
          Object.assign(
            {},
            {
              generateAndSaveToken,
            },
          ),
          dispatch,
        ),
      };
    },
  ),
)(ActivityTracker);
