import * as React from 'react';
import { Loading } from '../../Loading/Loading';
import { AutoSizer, MultiGrid } from 'react-virtualized/dist/commonjs/index';
import { CellRenderer } from '../../Table/flow';

import '../../Table/style.css';
import './style.css';
import TableExport from '../../Table/TableExport';
import { classes } from '../../../utils/dom';
import I18n from '../../../language/i18n';
import moment from 'moment';
import sanitizeFilename from 'sanitize-filename';
import { getRoleTranslation } from '../utils';

const COLUMNS_COUNT = 4;

type TableProps = {
  users: Array<Record<string, any>> | null;
  tabLabel: string;
  loading: boolean;
  auth?: any;
  emptyView: JSX.Element;
  selectedEmployee?: string;
  onEmployeeClick: (employee: any) => void;
};

type TableState = {
  showColumnConfigurator: boolean;
};

type TableRowData = {
  key: string; // user Id
  email: string;
  phoneNumber: string;
  name: string;
  role: string;
  indexAtOrigin: number;
};

const COLUMN_MAPPERS: ((row: TableRowData) => any)[] = [
  row => row.name,
  row => row.phoneNumber,
  row => row.email,
  row => row.role,
];

export default class Table extends React.PureComponent<TableProps, TableState> {
  private tableData: TableRowData[] = [];
  private tableExport = React.createRef<TableExport>();
  private lastGridDimensions: any;
  private gridRef = React.createRef<MultiGrid>();

  // this has to be a member variable because I18n module takes time to initialize
  private HEADER_COLUMNS = [
    I18n.t('name'),
    I18n.t('phone'),
    I18n.t('email'),
    I18n.t('acl.role.type'),
  ];

  render() {
    const { users, loading, emptyView } = this.props;
    if (loading) {
      return <Loading />;
    }
    if (users === null) {
      return null;
    }

    this.tableData = mapUsersToTableRows(users);
    if (this.tableData.length === 0) {
      return emptyView;
    }
    const propsForUpdating = {
      selectedEmployee: this.props.selectedEmployee,
    };

    const rowCount = this.tableData.length + 1; // rows + header

    return (
      <div className="table-container">
        <AutoSizer
          key="autosizer"
          onResize={this.recomputeGridSizeIfDimensionsAreUpdated.bind(this)}
        >
          {({ width, height }) => (
            <MultiGrid
              ref={this.gridRef}
              cellRenderer={this.renderCell.bind(this)}
              columnCount={COLUMNS_COUNT}
              columnWidth={() => Math.max(150, width / COLUMNS_COUNT)}
              rowCount={rowCount}
              rowHeight={38}
              height={height}
              width={width}
              fixedRowCount={1}
              // for updating the table when some prop changes
              {...propsForUpdating}
            />
          )}
        </AutoSizer>
        <TableExport
          ref={this.tableExport}
          cellRenderer={this.renderCell.bind(this)}
          fileName={`farmerjoe-${sanitizeFilename(
            I18n.t('employees.plural'),
          )}-${moment().format('DD.MM.YYYY')}`}
        />
      </div>
    );
  }

  exportData() {
    if (this.tableExport.current && this.tableData) {
      const rowCount = this.tableData.length + 1;
      this.tableExport.current.exportData(
        COLUMNS_COUNT,
        rowCount,
      );
    }
  }

  private renderCell(options: CellRenderer) {
    if (options.rowIndex === 0) {
      return this.renderHeaderCell(options);
    } else {
      return this.renderColumnCell(options);
    }
  }

  private renderHeaderCell(options: CellRenderer) {
    const { key, columnIndex, style } = options;

    const cellClasses = ['header-cell'];
    if (columnIndex === 0) {
      cellClasses.push('cell-column-0');
    }

    return (
      <div
        className={classes(...cellClasses)}
        style={style}
        key={key}
      >
        {this.getHeaderText(options.columnIndex)}
      </div>
    );
  }

  private renderColumnCell(options: CellRenderer) {
    const { selectedEmployee } = this.props;
    const { key, columnIndex, style } = options;

    // adding one as the very first row is column headers
    const rowIndex = options.rowIndex - 1;

    const isRowSelected = this.tableData[rowIndex].key === selectedEmployee;

    const cellContent = this.getCellContent(this.tableData[rowIndex], columnIndex);
    const cellClasses = ['cell'];
    if (columnIndex === 0) {
      cellClasses.push('cell-column-0');
    }
    if (isRowSelected) cellClasses.push('selected');

    return (
      <div
        className={classes(...cellClasses)}
        style={style}
        key={key}
        onClick={() => this.onCellClick(options.rowIndex)}
      >
        {cellContent}
      </div>
    );
  }

  private onCellClick(rowIndex: number) {
    const { onEmployeeClick, users } = this.props;
    if (!onEmployeeClick || !users) {
      return;
    }
    if (rowIndex === 0) {
      // header row is clicked
      return;
    }

    const employeeIdx = rowIndex - 1;
    const userPropIndex = this.tableData[employeeIdx].indexAtOrigin;
    onEmployeeClick(users[userPropIndex]);
  }

  private getHeaderText(columnIndex: number) {
    if (columnIndex >= 0 && columnIndex < this.HEADER_COLUMNS.length) {
      return this.HEADER_COLUMNS[columnIndex];
    }
    throw new Error('Unexpected column index: ' + columnIndex);
  }

  private getCellContent(row: TableRowData, columnIndex: number) {
    if (columnIndex >= 0 && columnIndex < COLUMN_MAPPERS.length) {
      return COLUMN_MAPPERS[columnIndex](row);
    }
    throw new Error('Unexpected column index: ' + columnIndex);
  }

  private recomputeGridSizeIfDimensionsAreUpdated(dimensions) {
    if (this.lastGridDimensions !== dimensions) {
      this.lastGridDimensions = dimensions;
      this.gridRef.current?.recomputeGridSize();
    }
  }
}

function mapUsersToTableRows(users: Array<any>): TableRowData[] {
  if (!users || users.length === 0) {
    return [];
  }

  const results: TableRowData[] = [];
  for (let idx = 0; idx < users.length; idx++) {
    const user = users[idx];
    results.push({
      indexAtOrigin: idx,
      key: user.key,
      name: user.name,
      phoneNumber: user.phoneNumber,
      email: user.email,
      role: getRoleTranslation(user.role),
    });
  }
  return results;
}
