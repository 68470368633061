import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionCreators from 'farmerjoe-common/lib/actions/actions';

import * as constants from '../../../styles/style';
import { FJAPPLERED } from '../../../styles/style';

import IconHeader from '../../Common/IconHeader';
import I18n from '../../../language/i18n';
import { get } from 'lodash-es';
import Icon from '../../Common/Icon';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getAnalysisType } from 'farmerjoe-common/lib/utils/Analyses';
import withRouter from '../../Router/withRouter';
import { compose } from 'redux';
import { outputDate } from 'farmerjoe-common';
import { AnalysisState, AnalysisType } from 'farmerjoe-common/lib/flow/types';
import { getOrderAnalysisTranslation } from '../../../utils/analysis/Analysis';

// TODO: improve typings
type Props = any;
type State = any;

class Analysis extends Component<Props, State> {
  static propTypes = {
    comment: PropTypes.object.isRequired,
  };

  render() {
    const { comment } = this.props;
    const styles = constants.styles;
    const a = comment.text;

    const iconLabel = this.getIconLabel(a);

    let resultOn = get(a, 'form.offers.result_on');

    if (resultOn) {
      resultOn = outputDate(resultOn);
    }

    let text = I18n.t('analysis.result_expected_on', { date: resultOn });

    if (a.state && a.result) {
      text = '';
    }

    return (
      <div
        onClick={() =>
          this.props.history.push(
            `/company/${this.props.openCompany}/analysis/${a.key}`,
          )
        }
        style={{ ...styles.containerRow, cursor: 'pointer' }}>
        <div style={{ flex: 1 }}>
          <IconHeader
            icon="analysis"
            iconType={'fj'}
            text={iconLabel}
            iconStyle={{
              fontSize: 12,
              marginRight: 10,
              color: constants.FJMUTED,
            }}
            showIcon={true}
          />
          <div>
            <div
              style={
                a.state === AnalysisState.Cancelled ? { textDecorationLine: 'line-through' } : {}
              }>
              {text}
            </div>
            {a.state === AnalysisState.Cancelled
              ? (
                <div style={{ color: FJAPPLERED }}>
                  {I18n.t('analysis.cancelledOn', {
                    date: outputDate(a.cancelled_on),
                  })}
                </div>
              )
              : null}
          </div>
        </div>
        <div
          className="no-print"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Icon
            name={'ios-arrow-forward'}
            style={{ fontSize: 30, color: constants.FJMUTEDLIGHTER }}
          />
        </div>
      </div>
    );
  }

  private getIconLabel(analysis) {
    const analysisType = getAnalysisType(analysis);
    const analysisState = analysis.state;

    if ([AnalysisState.Cancelled, AnalysisState.Inbox].includes(analysisState)) {
      return getOrderAnalysisTranslation(analysisType);
    } else if (analysisState === AnalysisState.Completed) {
      const translations = {
        [AnalysisType.Soil]: I18n.t('analysis.resultSoil'),
        [AnalysisType.Residue]: I18n.t('analysis.resultResidue'),
        [AnalysisType.Drone]: I18n.t('analysis.resultDrone'),
      };
      return get(translations, analysisType, 'Unknown');
    } else {
      return 'Unknown';
    }
  }
}

const selector = (state, ownProps) => {
  const comment = ownProps.comment;
  return {
    comment: comment,
    openCompany: selectors.getOpenCompanyId(state),
  };
};

export default compose<typeof Analysis>(
  connect(
    selector,
    actionCreators,
  ),
  withRouter,
)(Analysis);
