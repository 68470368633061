import React, { useState, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';

import {
  LOGIN_SCREEN_EMAIL_AUTH,
  LOGIN_SCREEN_PHONE_AUTH,
  LOGIN_SCREEN_CREDENTIALS_PROVIDER,
} from './constants';
import LoginCredentialsProvider from './LoginCredentialsProvider';
import LoginWithEmail from './LoginWithEmail';
import LoginWithPhone from './LoginWithPhone';

import Page from '../Page/Page';
import withRouter from '../Router/withRouter';
import { AlertDialog } from '../Dialog/Dialog';

import * as profileActions from '../../actions/profile';
import firebase from '../../data/firebase';

import './style.css';

const Login = (props) => {
  const { location, actions, history, owner } = props;

  const [loginError, setLoginError] = useState({
    alertMessage: '',
    alertTitle: '',
  });

  const [credentialsScreen, setCredentialScreen] = useState(LOGIN_SCREEN_CREDENTIALS_PROVIDER);

  useEffect(() => {
    if (location.pathname === '/logout') {
      actions
        .logout()
        .then(() => {
          history.replace('/login');
        });
    }
  }, [location, actions, history]);

  const currentUser = firebase.auth().currentUser;
  useEffect(() => {
    if (currentUser) {
      history.push('/');
    }
  }, [currentUser, history]);

  const currentScreen = {
    [LOGIN_SCREEN_CREDENTIALS_PROVIDER]: <LoginCredentialsProvider onError={setLoginError} setScreen={setCredentialScreen} />,
    [LOGIN_SCREEN_EMAIL_AUTH]: <LoginWithEmail history={history} location={location} onError={setLoginError} setScreen={setCredentialScreen} />,
    [LOGIN_SCREEN_PHONE_AUTH]: <LoginWithPhone history={history} location={location} onError={setLoginError} setScreen={setCredentialScreen}/>,
  }[credentialsScreen];

  return (
    <Page
      wrapperClassName="d-flex align-content-center justify-content-center flex-column login"
      footer={true}
      header={null}>
      <div className="login-register">
        <div className="login-box card">
          <div className="card-body" style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}>
              {currentScreen}
              <p className={'mt-5 mb-0 small'} style={{textAlign: 'center'}}>
                ©2015–{new Date().getFullYear()} All Rights Reserved.
                farmerJoe® is a registered trademark of farmsupport GmbH.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlertDialog
        show={!!loginError.alertMessage}
        onClose={() =>
          setLoginError({ alertMessage: '', alertTitle: '' })
        }
        title={loginError.alertTitle}
      >
        {loginError.alertMessage}
      </AlertDialog>
    </Page>
  );
};

const selector = state => {
  return {
    owner: state.owner,
    profile: get(state, 'firebase.profile'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...profileActions,
        },
      ),
      dispatch,
    ),
  };
};

export default compose(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(Login);
