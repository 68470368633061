import React from 'react';
import t from 'tcomb-form';
import { get } from 'lodash-es';

import CountryTelInput from '../../../components/Common/Country/CountryTelInput';
import { styles } from '../../../styles/style';

const Component = t.form.Textbox;

export default class CountryTel extends Component {
  private groupFormRef = React.createRef();

  getTemplate() {
    return locals => {
      const value = locals.value;
      let errorStyle = {};
      if (locals.hasError) {
        errorStyle = styles.danger;
      }

      const label = <span style={errorStyle}>{locals.label}</span>;

      return (
        <div>
          {label}
          <CountryTelInput
            ref={this.groupFormRef as any}
            editable={locals.editable}
            hasError={locals.hasError}
            placeholder={get(locals, 'config.placeholder')}
            value={locals.value}
            // Set the phone number
            onChange={({ value }) => {
              if (this.props.onChange) this.props.onChange(value);
            }}
          />
        </div>
      );
    };
  }
}
