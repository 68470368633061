import React from 'react';
import { get } from 'lodash-es';
import './style.css';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { filters } from 'farmerjoe-common/lib/actions/actions';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import DateRangeFilterBase from '../DateRangeFilter/DateRangeFilter';

type Props = {
  openCompany: string;
  actions: Record<string, any>;
  date: string;
};

class DateRangeFilter extends React.Component<Props> {
  ranges = [
    { value: 'custom', label: 'Benutzerdefiniert' },
    { value: 'last:7', label: 'Letzte 7 Tage' },
    { value: 'last:30', label: 'Letzte 30 Tage' },
    { value: 'last:90', label: 'Letzte 90 Tage' },
    { value: 'last:365', label: 'Letzte 365 Tage' },
    { value: 'last:730', label: 'Letzte 730 Tage' },
  ];

  render() {
    const { date } = this.props;

    return (
      <DateRangeFilterBase
        onSave={this.save}
        date={date}
        ranges={this.ranges}
      />
    );
  }

  save = (value: string) => {
    const { openCompany } = this.props;
    this.props.actions.filters(openCompany, {
      harvestedDate: value,
    });
  };
}

export default compose(
  connect(
    (state: any, ownProps) => {
      const openCompany = getOpenCompanyId(state);
      return {
        openCompany: openCompany,
        date: get(
          state,
          ['filtersByCompany', openCompany, 'harvestedDate'],
          'last:730',
        ),
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          filters,
        },
        dispatch,
      ),
    }),
  ),
)(DateRangeFilter);
