import React from 'react';
import ImageCrop from 'react-image-crop';

import { AlertConfirmDialog } from '../Dialog/Dialog';
import type { ImageFile } from '../../utils/image';
import { loadImageFile, processImage } from '../../utils/image';

import './style.css';
import 'react-image-crop/dist/ReactCrop.css';
import { classes } from '../../utils/dom';

type Props = {
  children?: React.ReactNode;
  className?: string;
  width: number;
  height: number;
  imageQuality?: number;
  cropping?: boolean;
  onPick: (...args: Array<any>) => any;
};

export default class ImagePicker extends React.Component<
  Props,
  {
    file: File | null;
    imageUrl: string | null;
    crop: Record<string, any> | null;
    pixelCrop: Record<string, any> | null;
  }
> {
  state = {
    file: null,
    imageUrl: null,
    crop: {},
    pixelCrop: null,
  };

  fileInput: any = React.createRef();

  componentWillMount() {
    this.update();
  }

  componentWillUpdate(props: Props) {
    if (
      this.props.width !== props.width ||
      this.props.height !== props.height
    ) {
      this.update(props);
    }
  }

  update(props: Props = this.props) {
    this.setState({
      crop: {
        aspect: props.width / props.height,
      },
    });
  }

  onChange(e: React.SyntheticEvent<HTMLInputElement>) {
    e.currentTarget as HTMLInputElement;
    const files = e.currentTarget.files;
    if (files && files.length) {
      const file = files[0];
      loadImageFile(file, 'blob')
        .then(img => {
          this.setState(
            {
              file,
              imageUrl: URL.createObjectURL(img),
            },
            () => {
              if (!this.props.cropping) {
                this.accept();
              }
            },
          );
        })
        .catch((err) => {
          console.log('Error occured while getting img url: ', err);
        });
    }
  }

  accept() {
    const file = this.state.file;
    if (file != null) {
      const pixelCrop = this.state.pixelCrop;
      const imageQuality =
        this.props.imageQuality != null ? this.props.imageQuality : 1;

      processImage(file, {
        pixelCrop,
        imageQuality,
        maxWidth: this.props.width,
        maxHeight: this.props.height,
      }).then((image: ImageFile) => {
        this.props.onPick(image);
        this.reset();
      });
    }
  }

  reset() {
    if (this.state.imageUrl) {
      URL.revokeObjectURL(this.state.imageUrl);
    }
    this.setState({ file: null, imageUrl: null, crop: {}, pixelCrop: null });
    this.fileInput.current.value = '';
    this.update();
  }

  componentWillUnmount() {
    this.reset();
  }

  render() {
    const { children, className } = this.props;
    return (
      <div
        className={classes('image-picker', className)}
        onClick={e => {
          e.stopPropagation();
          if (this.state.file == null) {
            this.fileInput.current.click();
          }
        }}>
        <input
          className={classes('file-input')}
          ref={this.fileInput}
          type="file"
          accept="image/*"
          onChange={this.onChange.bind(this)}
        />
        {children}
        <AlertConfirmDialog
          show={this.state.imageUrl != null && !!this.props.cropping}
          onClose={result => {
            if (result === 'yes') {
              this.accept();
            } else {
              this.reset();
            }
          }}>
          {this.state.imageUrl != null
            ? (
              <ImageCrop
                src={this.state.imageUrl}
                onChange={(crop, pixelCrop) => {
                  this.setState({ crop, pixelCrop });
                }}
                crop={this.state.crop}
              />
            )
            : null}
        </AlertConfirmDialog>
      </div>
    );
  }
}
