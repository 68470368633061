import React from 'react';
import { ContentRendererProps } from './BoniturContentRenderersType';
import DataRow from './Helpers/DataRow';
import { getColorForValue, parseNumber, isText } from '../utils';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import I18n from '../../../language/i18n';

interface TextRendererProps {
  element: ContentRendererProps['element'] & {
    // NOTE: for backward compatibiltity
    inputUnit?: string;
  };
  value: ContentRendererProps['value'];
  valueOnly: ContentRendererProps['valueOnly'];
};

const TextRenderer = ({ element: { label, name, options, inputUnit }, value, valueOnly }: TextRendererProps) => {
  const changedColor = getColorForValue(options?.colorSchemes, { [name]: value });
  const numberFormatter = useNumberFormatter(I18n.locale, options.precision);
  const style = { fontWeight: 'normal', color: value ? changedColor : 'black' };

  if (typeof value === 'undefined' || value === null) {
    return null;
  }

  const _value = isText(value) ? value : parseNumber(value, numberFormatter);

  return <DataRow
    label={label}
    value={_value}
    valueOnly={valueOnly}
    valueLabel={options?.valueLabel || inputUnit}
    tableValueLabel={options?.tableValueLabel}
    style={style}
  />;

};

export default React.memo(TextRenderer);
