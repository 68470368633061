import React from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { getAnalysesQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Analysis';

/**
 * HOC that loads analyses
 *
 * @param WrappedComponent
 */
const WithAnalyses = (WrappedComponent) => {
  const AnalysesLoader = (props) => {
    return <WrappedComponent {...props} />;
  };

  const connectedToFirestore = firestoreConnect((props) => {
    const { company, browsingGroup, myCompanyProfile } = props;
    if (company) {
      const analyses = getAnalysesQuery(
        company.key,
        browsingGroup,
        myCompanyProfile,
      );
      return [analyses];
    }

    return [];
  })(AnalysesLoader);

  return connectedToFirestore;
};

export default WithAnalyses;
