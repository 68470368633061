import React, { useState } from 'react';
import I18n from '../../language/i18n';
import NoResults from '../Common/NoResults';

import Employee from './AddEmployeeToField';

import { Loading } from '../Loading/Loading';
import SearchInput from '../Common/SearchInput';
import Separator from '../Common/Separator';
import TabBarSearchSort from '../Common/TabBarSearchSort';
import { hasAccessToField } from 'farmerjoe-common/lib/selectors/user';
import FlatList from '../Common/FlatList';
import ScrollableTabView from '../Common/ScrollableTabView';
import Dialog from '../Dialog/Dialog';
import './style.css';
import { sortUsersAndMoveCurrentUserToTop } from 'farmerjoe-common';
import { UserRole } from 'farmerjoe-common/lib/flow/types';
import fuseSearch from '../../utils/search';

type Props = {
  users?: any[];
  field?: any;
  company?: any;
  loading?: boolean;
  actions?: any;
  search?: any;
  show?: any;
  onClose?: any;
  auth?: any;
};

const EmployeesOnField = (props: Props) => {
  const {
    users,
    field,
    company,
    loading,
    show,
    onClose,
    auth,
  } = props;

  const [search, setSearch] = useState('');

  const allUsers = sortUsersAndMoveCurrentUserToTop(users as any, auth);
  const filteredUsers = search ? fuseSearch(allUsers, search, { keys: ['name', 'email'] }) : allUsers;


  const advisors = filteredUsers.filter(user => user.role === UserRole.Advisor);
  const normal = filteredUsers.filter(
    user =>
      user.role !== UserRole.Advisor &&
                (user.active ||
                  (user.active === false && user.invitation === true)),
  );

  const content = (
    <ScrollableTabView
      renderTabBar={() => (
        <TabBarSearchSort
          searchComponent={
            <SearchInput
              onChange={text => {
                setSearch(text);
              }}
              search={search}
            />
          }
          onCloseSearch={() => {
            setSearch('');
          }}
          sortComponent={null}
        />
      )}>
      <FlatList
        tabLabel={I18n.t('employees.plural')}
        ListEmptyComponent={
          <NoResults text={I18n.t('employees.noAdvisors')} />
        }
        data={normal}
        renderItem={({ item }) => (
          <Employee
            user={item}
            hasAccess={hasAccessToField(item, field.key)}
            company={company}
            field={field}
          />
        )}
        ItemSeparatorComponent={<Separator style={{}}/>}
        className="employees-list"
      />

      <FlatList
        tabLabel={I18n.t('employees.advisors')}
        ListEmptyComponent={
          <NoResults text={I18n.t('employees.noAdvisors')} />
        }
        data={advisors}
        renderItem={({ item }) => (
          <Employee
            user={item}
            hasAccess={hasAccessToField(item, field.key)}
            company={company}
            field={field}
          />
        )}
        ItemSeparatorComponent={<Separator style={{}}/>}
        className="employees-list"
      />
    </ScrollableTabView>
  );

  return (
    <Dialog
      show={show}
      onClose={onClose}
      dialogClassName={'employees-on-field'}
      footer={
        <div className="d-flex flex-grow-1">
          <button className="ml-auto btn btn-primary" onClick={onClose}>
            {I18n.t('ok')}
          </button>
        </div>
      }>
      {loading ? <Loading /> : content}
    </Dialog>
  );
};

export default EmployeesOnField;
