import { get } from 'lodash-es';
import React, { PureComponent } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ButtonCamera from '../Common/ButtonCamera';
import Icon from '../Common/Icon';
import * as constants from '../../styles/style';

import { uploadLogo } from '../../actions/image';

import { Loading } from '../Loading/Loading';
import ImagePicker from '../ImagePicker/ImagePicker';

import imgFjAvatar from '../../public/images/FJ_logo_inverted.png';

const pickerSettings = {
  width: 512,
  height: 512,
  // includeBase64: true,
  compressImageMaxWidth: 512,
  compressImageMaxHeight: 512,
  compressImageQuality: 0.5,
  freeStyleCropEnabled: true,
  mediaType: 'photo',
  cropping: true,
};

// TODO: improve typings
type Props = any;
type State = any;

class Avatar extends PureComponent<Props, State> {
  render() {
    const { company, isAdmin } = this.props;
    const placeholder = get(company, 'logo.placeholder', false);
    const logo = get(company, 'logo.original.downloadURL', null);
    let source = imgFjAvatar;

    let content: any = null;

    if (logo) {
      source = logo;
      content = placeholder
        ? (
          <div
            style={{
              background: `url(${source
                .replace(/\(/g, '%28')
                .replace(/\)/g, '%29')}) center center / cover no-repeat`,
              width: 100,
              height: 100,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              filter: 'blur(3px)',
            }}>
            <Loading />
          </div>
        )
        : (
          <div
            style={{
              background: `url(${source
                .replace(/\(/g, '%28')
                .replace(/\)/g, '%29')}) center center / cover no-repeat`,
              width: 100,
              height: 100,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderRadius: 5,
            }}
          />
        );
    } else {
      content = placeholder
        ? (
          <div
            style={{
              display: 'flex',
              width: 100,
              height: 100,
              justifyContent: 'center',
            }}>
            <Icon
              name={'barn'}
              iconType={'fj'}
              style={{
                fontSize: 55,
                textAlign: 'center',
                color: '#7EB549',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}>
              <Loading size={'small'} scale={2.0} color={'#000'} />
            </div>
          </div>
        )
        : (
          <div
            style={{
              display: 'flex',
              width: 100,
              height: 100,
              borderRadius: 5,
              justifyContent: 'center',
            }}>
            <Icon
              name={'barn'}
              iconType={'fj'}
              style={{
                fontSize: 55,
                textAlign: 'center',
                color: '#7EB549',
              }}
            />
          </div>
        );
    }

    return (
      <div
        style={{
          padding: 2,
          backgroundColor: '#fff',
          borderRadius: 5,
          marginBottom: 5,
          cursor: 'pointer',
          position: 'relative',
        }}>
        {isAdmin
          ? (
            <ImagePicker
              {...pickerSettings}
              onPick={image => {
                this.props.actions.uploadLogo(image, this.props.company);
              }}>
              {content}
              <ButtonCamera />
            </ImagePicker>
          )
          : (
            content
          )}
      </div>
    );
  }
}

const selector = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          uploadLogo,
        },
      ),
      dispatch,
    ),
  };
}

export default connect(
  selector,
  mapDispatchToProps,
)(Avatar);
