import t from 'tcomb-form';
const Website = t.refinement(t.String, (input) => {
  if (!input) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const urlPattern =
    /^(http(s)?:\/\/)(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/\w \.-]*)*\/?$/;
  return urlPattern.test(input);
});

export default Website;
