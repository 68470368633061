import React from 'react';
import { firebase, firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Select, { components, createFilter } from 'react-select';
import { uniqBy } from 'lodash-es';

import {
  COMPANY_MAIN_GROUP_KEY,
  getBrowsingGroupKey,
  getCompanyGroups,
  getGroupIdsOfSharedFields,
} from 'farmerjoe-common/lib/selectors/groups';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { lastUsedGroup } from 'farmerjoe-common/lib/actions/group';
import { isNotMainGroup } from 'farmerjoe-common/lib/utils/firestoreRedux/Utils';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';

import { getGroupsQueries } from '../../../containers/Groups/utils';
import WithFieldCollaborators from '../../../containers/HOC/WithFieldCollaborators';
import WithSharedFields from '../../../containers/HOC/WithSharedFields';
import { getCountryName } from '../../../utils/Countries';
import type { Group, Employee } from '../../../flowTypes';
import I18n from '../../../language/i18n';

type OwnProps = {
  onChange: (value: string) => void;
  value: string | null;
  addressTransformer: (group: Group) => string;
  readOnly: boolean;
};

/**
 * Typings for Props parsed from redux store
 */
type StateProps = {
  /**
   * Are we loading producers right now
   */
  loading: boolean;

  /**
   * The currently selected producer
   */
  group: Group | null;

  /**
   * The open company
   */
  openCompany: string;

  /**
   * The id of the currently selected producer
   */
  groupId: string | null;

  /**
   * All producers in the company
   */
  producers?: Record<string, Group>;

  myCompanyProfile: Employee;

  externalGroupIds?: string[];
};

/**
 * Typings for reducers from the redux store
 */
type DispatchProps = {
  actions: {
    lastUsedGroup: typeof lastUsedGroup;
  };
};

type FirebaseWrapperProps = {
  firestore: typeof firebase;
};

type Props = StateProps & DispatchProps & FirebaseWrapperProps & OwnProps;

class Producer extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(producer) {
    if (producer) {
      this.props.onChange(producer.value);
    } else {
      this.props.onChange(COMPANY_MAIN_GROUP_KEY);
    }
  }

  render() {
    const { groupId, group, producers, loading, openCompany } = this.props;

    const options = producers ? Object.values(producers)
      .filter(g => g.company_id === openCompany)
      .map(g => {
        const country = g.country ?? '';
        return {
          label: g.name,
          value: g.key,
          address: this.props.addressTransformer(g),
          country: country,
        };
      }) : [];

    const CustomOption = props => {
      const { data } = props;
      return (
        <components.Option {...props}>
          {data.label}
          <div className="small text-muted">{data.address}</div>
          <div className="small text-muted">{getCountryName(data.country)}</div>
        </components.Option>
      );
    };

    const filterOption = createFilter({
      stringify: ({ data: { label, address, country } }) =>
        `${label} ${address} ${country}`,
    });

    let defaultValue: any = null;

    if (groupId && isNotMainGroup(groupId) && group) {
      defaultValue = {
        value: groupId,
        label: group.name,
      };
    }

    const customStyles = {
      menuList: base => ({
        ...base,
        minHeight: 250,
        maxHeight: 250,
      }),
    };

    return (
      <Select
        styles={customStyles}
        onChange={this.onChange}
        searchable={true}
        autoload={false}
        labelKey="label"
        valuekey="value"
        isClearable={true}
        placeholder={I18n.t('producers.select')}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        noOptionsMessage={() => {
          return I18n.t('producers.noProducer');
        }}
        value={defaultValue}
        filterOption={filterOption}
        isLoading={loading}
        options={options}
        menuPlacement={'auto'}
        components={{ Option: CustomOption }}
        menuPortalTarget={document.getElementById('react-select-portal')}
        isDisabled={this.props.readOnly}
      />
    );
  }
}

/**
 * XXX:
 * Since we are showing the producers and traders in the same form,
 * we are performing a query that stores the results on the same
 * redux path. In order to prevent overwrite of the data at the path,
 * we need to store it in a different way. The propducers are stored
 * under `producers` path, the traders under `traders`.
 * That's why the hackery below when getting the producers/ traders.
 */

const GROUP_TYPE_PRODUCER: string = 'producer';
const STORE_AS_PRODUCERS = 'producers';

const selector = (state, ownProps: OwnProps): StateProps => {
  const openCompany = selectors.getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const groupId = ownProps.value;
  let producers = getCompanyGroups(state, openCompany, GROUP_TYPE_PRODUCER, browsingGroup);
  const producersFromStoreAs =
    state.firestore.data[STORE_AS_PRODUCERS] as Record<string, Group> | undefined;
  if (producersFromStoreAs) {
    producers = uniqBy([...producers, ...Object.keys(producersFromStoreAs).map(k => producersFromStoreAs[k])], 'key');
  }

  const group = producers.find(p => p.key === groupId) || null;
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(state, openCompany);
  const externalGroupIds = getGroupIdsOfSharedFields(state, openCompany);

  const hasLoadedEverything = hasLoaded(
    [
      ...getGroupsQueries(
        openCompany,
        myCompanyProfile,
        GROUP_TYPE_PRODUCER,
        externalGroupIds,
        STORE_AS_PRODUCERS,
      ),
    ],
    state,
  );

  return {
    loading: !hasLoadedEverything,
    openCompany,
    group,
    groupId,
    producers,
    myCompanyProfile,
    externalGroupIds,
  };
};

const wrappedProducer = firestoreConnect(props => {
  const { openCompany, myCompanyProfile, externalGroupIds } = props;

  return getGroupsQueries(
    openCompany,
    myCompanyProfile,
    GROUP_TYPE_PRODUCER,
    externalGroupIds,
    STORE_AS_PRODUCERS,
  );
})(Producer);

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          lastUsedGroup,
        },
      ),
      dispatch,
    ),
  };
};

export default compose<React.ComponentClass<OwnProps>>(
  connect<StateProps, DispatchProps, OwnProps>(selector, mapDispatchToProps),
  WithFieldCollaborators,
  WithSharedFields,
)(wrappedProducer);
