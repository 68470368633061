import * as React from 'react';

import I18n from '../../language/i18n';
import Box from '../Common/Box/Box';
import * as constants from '../../styles/style';

type Props = {
  probeNumber: string;
  note?: string;
};
const BoxProbeNumber = (props: Props) => {
  const { probeNumber, note } = props;

  return (
    <Box
      header={I18n.t('analysis.number')}
      content={
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <span
              style={{
                ...constants.styles.strong,
                ...{
                  color: constants.FJAPPLEGREEN,
                  fontSize: 60,
                  marginStart: 10,
                },
              }}>
              {probeNumber.substring(0, 3)}
            </span>
            <span
              style={{
                ...constants.styles.strong,
                ...{
                  color: constants.FJAPPLEGREEN,
                  fontSize: 60,
                },
              }}>
              {probeNumber.substring(3, 6)}
            </span>
            <span
              style={{
                ...constants.styles.strong,
                ...{
                  color: constants.FJAPPLEGREEN,
                  fontSize: 60,
                  marginEnd: 10,
                },
              }}>
              {probeNumber.substring(6, probeNumber.length)}
            </span>
          </div>
          {note
            ? (
              <div
                style={{
                  ...constants.styles.strong,
                  ...constants.styles.big,
                  ...constants.styles.alignCenter,
                  ...{ color: constants.FJAPPLERED },
                }}>
                {note}
              </div>
            )
            : null}
        </div>
      }
    />
  );
};

export default BoxProbeNumber;
