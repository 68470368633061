import React from 'react';
import { get, cloneDeep } from 'lodash-es';
import t from 'tcomb-form';

import { calculateAreaDifference } from 'farmerjoe-common/lib/utils/Field';

import Textbox from '../templates/Textbox';
import FormInput from '../templates/FormInput';
import HiddenComponent from '../templates/HiddenComponent';
import { positionTemplate } from '../templates/Position';
import GroupFactory from '../templates/Producer/GroupFactory';
import { ReactSelect } from '../templates/ReactSelect';
import '../templates/style.css';
import { numberTransformer } from '../transformers/transformers';
import { latitude, longitude } from '../validators/coordinates';
import FieldSizeDifference from '../../components/Field/FieldSizeDifference';
import stylesheet from '../stylesheets/style';
import * as constants from '../../styles/style';
import I18n from '../../language/i18n';

const maxSize = 1000000;
const SizeType = t.refinement(t.Number, (n) => n <= maxSize);

SizeType.getValidationErrorMessage = () => {
  return I18n.t('field.hectareMustBeLessThanMaxSize', { maxSize: maxSize });
};

// Our field model
const tPosition = t.struct({
  latitude: latitude,
  longitude: longitude,
});
const model = () => {
  const model: any = {
    company_id: t.String,
    name: t.String,
    position: tPosition,
    polygon: t.maybe(t.list(tPosition)),
    areaSize: t.maybe(t.Number),
    size: SizeType,
    land_id: t.maybe(t.String),
    notes: t.maybe(t.String),
    irrigation: t.maybe(
      t.enums({
        '0none': I18n.t('irrigation.types.0none'),
        drip: I18n.t('irrigation.types.drip'),
        sprinkler: I18n.t('irrigation.types.sprinkler'),
        machine: I18n.t('irrigation.types.machine'),
        flood: I18n.t('irrigation.types.flood'),
        pivot: I18n.t('irrigation.types.pivot'),
      }),
    ),
    waterOrigin: t.maybe(
      t.enums({
        wells: I18n.t('irrigation.waterOriginTypes.wells'),
        river: I18n.t('irrigation.waterOriginTypes.river'),
        waterNetwork: I18n.t('irrigation.waterOriginTypes.waterNetwork'),
      }),
    ),
    group_id: t.String,
    trader_id: t.maybe(t.String),
    seed_id: t.maybe(t.String),
  };

  return t.struct(model);
};

const multiline = cloneDeep(stylesheet);
multiline.textbox.normal = {
  ...multiline.textbox.normal,
  height: 'auto',
};

function template(locals, defaults) {
  const {
    PolygonComponent,
    field: { areaSize, size },
  } = defaults;
  const areaDiff = calculateAreaDifference(
    areaSize,
    numberTransformer.parse(String(size)),
  );
  const sign = areaDiff > 0 ? '+' : '';

  // in locals.inputs you find all the rendered fields
  return (
    <div>
      {locals.inputs.company_id}
      {locals.inputs.polygon}
      {locals.inputs.name}
      {PolygonComponent}
      <div>{locals.inputs.position}</div>
      {locals.inputs.areaSize}

      <FieldSizeDifference size={size} areaSize={areaSize}>
        {locals.inputs.size}
      </FieldSizeDifference>
      {locals.inputs.land_id}
      {locals.inputs.notes}
      {locals.inputs.irrigation}
      {locals.inputs.waterOrigin}
      {locals.inputs.group_id}
      {locals.inputs.trader_id}
      {locals.inputs.seed_id}
    </div>
  );
}

const options = function(defaults) {
  const {
    field,
    hideGroupIdSelection,
    hideTraderIdSelection,
    isOwnerOfField,
    cropRatings,
  } = defaults;
  const areaSize = get(defaults, 'field.areaSize');

  const options = {
    template: locals => template(locals, defaults),
    stylesheet: stylesheet,
    auto: 'none',
    fields: {
      company_id: {
        type: 'hidden',
      },
      name: {
        label: `${I18n.t('field.name')} *`,
        autoCorrect: false,
        // autoFocus: true,
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
        config: {
          multiline: true,
        },
        /* numberOfLines: 2, */
      },
      areaSize: {
        label: I18n.t('polygon.calculatedSizeInHa'),
        factory: FormInput,
        config: {
          readOnly: true,
          disabled: true,
          hidden: !areaSize,
        },
      },
      size: {
        label: 'ha *',
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
        transformer: numberTransformer,
        selectTextOnFocus: defaults.field.size === 0,
      },
      land_id: {
        label: I18n.t('field.number'),
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
        keyboardType: 'default',
      },
      position: {
        label: I18n.t('polygon.fieldCenter') + ' *',
        template: positionTemplate,
        fields: {
          latitude: {
            label: I18n.t('latitude'),
            factory: FormInput,
            transformer: numberTransformer,
          },
          longitude: {
            label: I18n.t('longitude'),
            factory: FormInput,
            transformer: numberTransformer,
          },
        },
      },
      notes: {
        label: I18n.t('notes'),
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
        stylesheet: multiline,
        template: Textbox,
        autoCorrect: false,
        config: {
          multiline: true,
        },
      },
      polygon: {
        type: 'hidden',
        factory: HiddenComponent,
      },
      irrigation: {
        label: I18n.t('irrigation.irrigationType'),
        template: ReactSelect,
        // 'transformer': SingleSelectTransformer,
        // config: {
        //   multiple: false
        // },
        value: field.irrigation,
      },
      waterOrigin: {
        label: I18n.t('irrigation.waterOrigin'),
        template: ReactSelect,
        // 'transformer': SingleSelectTransformer,
        // nullOption: false,
        // config: {
        //   multiple: false,
        //
        // },
        value: field.waterOrigin,
      },
      group_id: {
        label: I18n.t('producers.single'),
        factory: GroupFactory,
        value: field.group_id,
        config: {
          type: 'producer',
          hidden: hideGroupIdSelection,
          readOnly: !isOwnerOfField,
        },
      },
      trader_id: {
        label: I18n.t('traders.single'),
        factory: GroupFactory,
        value: field.trader_id,
        config: {
          type: 'trader',
          hidden: hideTraderIdSelection,
          readOnly: !isOwnerOfField,
        },
      },
      seed_id: {
        label: I18n.t('seed'),
        value: field.seed_id,
        template: (locals) => {
          locals.options = cropRatings;
          return ReactSelect(locals);
        },
      },
    },
  };

  return options;
};

export default { model, options };
