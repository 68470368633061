import React from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { connect } from 'react-redux';
import firebase from 'firebase/app';

type Props = {
  user: firebase.User;
  loaded: boolean;
  children: React.ReactNode;
};

/**
 * Load the user permission object before doing anything else
 *
 */
class LoadUserPermissions extends React.PureComponent<Props> {
  render() {
    const { loaded, children } = this.props;

    if (!loaded) {
      return null;
    }

    return children;
  }
}

const wrappedLoadUserPermissions = firestoreConnect(props => {
  const { user } = props;
  const paths: any[] = [];
  if (user) {
    paths.push({
      collection: 'usersPermissions',
      doc: user.uid,
      storeAs: 'userPermissions',
      // byIds: `usersByCompany/${company}`
    });
  }

  return paths;
})(LoadUserPermissions);

const selector = (state, ownProps) => {
  const { user } = ownProps;
  const loaded = hasLoaded(['userPermissions'], state);

  return {
    user,
    loaded,
  };
};

export default connect(
  selector,
)(wrappedLoadUserPermissions);
