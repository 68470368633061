import React from 'react';

const UnauthorizedAccessToCompanyPage = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <p style={{
        textAlign: 'center',
        margin: '50% auto',
      }}>
        {'You don\'t have access to this company!'}
      </p>
    </div>
  );
};

export default UnauthorizedAccessToCompanyPage;
