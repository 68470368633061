import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { bindActionCreators, compose } from 'redux';
import { get, keys } from 'lodash-es';
import { Polygon } from '@react-google-maps/api';

import { editForm, openForm } from 'farmerjoe-common/lib/actions/form';
import {
  getForm,
  getFormSchema,
  getOpenFormId,
  getBoniturisImageKey,
} from 'farmerjoe-common/lib/selectors/forms';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import {
  getFormQuery,
  getFormSchemaQuery,
} from 'farmerjoe-common/lib/utils/firestoreRedux/Forms';
import { outputDate, getColor } from 'farmerjoe-common';
import { getCommentQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Comments';
import { getComment } from 'farmerjoe-common/lib/selectors/comments';
import {
  COMPANY_MAIN_GROUP_KEY,
  getBrowsingGroupKey,
} from 'farmerjoe-common/lib/selectors/groups';
import { searchForFieldAmongTheUniverse } from 'farmerjoe-common/lib/selectors/fields';
import { getFieldMarkers } from 'farmerjoe-common/lib/selectors/markers';
import { getFieldQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';

import BoniturFormContent from './BoniturFormContent';
import FormBonitur from './CreateBoniturForm';
import NoFieldBoniturForm from './NoFieldBoniturForm';
import { EditButton } from '../Common/EditButton';
import NavbarBasic from '../Common/NavbarBasic';
import { FieldName } from '../Common/FieldName';
import { Tag } from '../Common/Tag';
import Icon from '../Common/Icon';
import withRouter from '../Router/withRouter';
import Map, { fieldPolygonOptions } from '../Map/Map';
import { Loading } from '../Loading/Loading';
import AvatarName from '../Users/AvatarName';
import MapControl, {
  ControlButton,
  ControlContainer,
  RIGHT_BOTTOM,
} from '../Map/MapControl';
import MarkerImageComment from '../Map/MarkerImageComment';
import { findAncestor, classes } from '../../utils/dom';
import { appPosToLatLng } from '../../utils/Map';
import { FJMUTED, FJWHITE, FJBROWN } from '../../styles/style';
import { getCropRatingPdf } from '../../data/api/crop-ratigs';

type Props = {
  actions?: Record<string, any>;
  history?: Record<string, any>;
  formDoesntExist?: boolean;
  companyId: string;
  formId: string;
  openForm?: string | null;
  form?: any; // FormDataType | null
  markers?: any;
  field?: any;
  loading?: boolean;
  formSchema?: any; // FormSchemaType | null
  comment?: Comment | null;
  browsingGroup?: any;
  boniturImages?: any[];
};

type State = {
  showMap: boolean;
  showEditForm: boolean;
  mapRef?: google.maps.Map;
  showTooltipDropDown: boolean;
  showImages: boolean;
};

class Bonitur extends React.Component<Props, State> {
  state = {
    showMap: false,
    showEditForm: false,
    mapRef: undefined,
    showTooltipDropDown: false,
    showImages: false,
  };

  openedForm: boolean | null | undefined;

  setMapRef = (ref) => {
    this.setState({ mapRef: ref });
  };

  // componentWillUpdate(nextProps: Props) {
  //   if (nextProps.formDoesntExist) {
  //     this.props.history?.push(`/company/${nextProps.companyId}/form`);
  //   }
  //   this.openCrop(nextProps);

  //   if (
  //     this.state.showMap &&
  //     get(this.props, "form.key") !== get(nextProps, "form.key")
  //   ) {
  //     this.setState({ showMap: false });
  //   }
  // }

  componentDidMount() {
    this.openCrop();
  }

  openCrop(props = this.props) {
    if (!this.openedForm && props.formId !== props.openForm) {
      this.openedForm = true;
      props.actions?.openForm(props.formId);
    }
  }

  renderBoniturForm() {
    const { form, comment } = this.props;
    const { showEditForm } = this.state;
    const fieldRequired = form && form.form_id && form.comment_id;

    if (!this.state.showEditForm) {
      return null;
    }

    if (fieldRequired) {
      return <FormBonitur
        comment={comment}
        editedFormData={form}
        show={showEditForm}
        onClose={() => this.setState({ showEditForm: false })}
        editMode={true}
      />;
    }
    return <NoFieldBoniturForm
      editFormData={form}
      show={showEditForm}
      onClose={() => this.setState({ showEditForm: false })}
      editMode={true}
    />;
  }

  render() {
    const {
      form,
      formSchema,
      loading,
      companyId,
      boniturImages,
      markers,
    } = this.props;

    const { mapRef, showImages, showTooltipDropDown } = this.state;
    const formSchemaName = get(formSchema, 'name', null);
    const cropName = get(form, 'meta.crop_name');
    const cropSort = get(form, 'meta.crop_sort');
    const color = getColor(get(form, 'meta.crop_color'));

    let body: any = null;
    if (loading) {
      body = <Loading />;
    } else {
      body =
        this.state.showMap && form.position ? (
          <Map
            mapRef={this.setMapRef}
            filterNotACrop={false}
            markers={markers}
            position={form.position}
            zoomedIn={true}
          >
            {mapRef && (
              <MapControl
                position={RIGHT_BOTTOM}
                map={mapRef}
                mergeControls={false}
              >
                <ControlContainer className="map-toolbar-container vertical">
                  <ControlButton
                    className={classes(showImages && 'active')}
                    onClick={() => this.setState({ showImages: !showImages })}
                  >
                    <Icon name={'image'} iconType={'fal'} />
                  </ControlButton>
                </ControlContainer>
                {showImages &&
                  boniturImages?.map((marker) => {
                    return (
                      <MarkerImageComment
                        key={marker.key}
                        marker={marker}
                        onClick={() => null}
                        map={mapRef}
                        zIndex={10}
                      />
                    );
                  })}
              </MapControl>
            )}
            {this.props.field && this.props.field.polygon ? (
              <Polygon
                path={this.props.field.polygon.map(appPosToLatLng) as any}
                key={'polygon'}
                options={fieldPolygonOptions}
              />
            ) : null}
          </Map>
        ) : (
          <React.Fragment>
            {this.props.field ? (
              <div
                className={
                  'd-flex justify-content-between align-items-center flex-row pl-3 pr-3 pb-2 pt-2'
                }
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  this.props.history?.push(`/company/${companyId}/field/${form.field_id}`);
                }}
              >
                <FieldName
                  fieldName={get(form, 'meta.field_name')}
                />
                <Tag
                  tag={`${cropName}${cropSort ? ' - ' + cropSort : ''}`}
                  color={color}
                />
              </div>
            ) : null}
            <BoniturFormContent
              formSchema={formSchema}
              values={form.formValues}
              previewFor={'all'}
              renderedBy="details"
            />
            <div className="bonitur-footer">
              <AvatarName
                uid={form.created_by.uid}
                producersOn={true}
                groupId={
                  form.created_by.group
                    ? form.created_by.group
                    : COMPANY_MAIN_GROUP_KEY
                }
                name={form.created_by.name}
              />
              <span className="date-time">
                {outputDate(form.created, ' HH:mm   DD.MM.YYYY')}
              </span>
            </div>
          </React.Fragment>
        );
    }

    return (
      <div className="bonitur">
        <NavbarBasic
          title={
            <div
              onClick={() => {
                this.setState({ showTooltipDropDown: !showTooltipDropDown });
              }}
            >
              <span>{formSchemaName}</span>
              <Icon
                key="info"
                name={'ios-information-circle-outline'}
                style={{
                  fontSize: 18,
                  marginLeft: 10,
                  color: FJMUTED,
                }}
              />
            </div>
          }
          leftButton={
            <div
              className="top-bar-button gray-text"
              onClick={(e) => {
                // button is only used for closing the field
                const column = findAncestor(
                  e.target as any,
                  '.sliding-column',
                ) as any;
                if (column) {
                  const classList = column.classList;
                  classList.remove('open');
                }

                this.props.actions?.openForm(null);
                this.props.history?.push(`/company/${companyId}/form`);
              }}
            >
              <Icon iconType="fa" name="times" style={{ fontSize: 25 }} />
            </div>
          }
          rightButton={
            <div className={'grouped-buttons'}>
              <div
                className="top-bar-button gray-text"
                onClick={() => {
                  getCropRatingPdf(form.key);
                }}>
                <Icon name="print" iconType="fa" style={{
                  cursor: 'pointer',
                }}/>
              </div>
              {!loading && form.position ? (
                <div
                  className="top-bar-button gray-text"
                  onClick={() => {
                    this.setState({ showMap: !this.state.showMap });
                  }}
                >
                  <Icon
                    iconType={this.state.showMap ? 'fa' : 'fal'}
                    name="map"
                    style={{ fontSize: 25 }}
                  />
                </div>
              ) : null}
              {!loading ? (
                <EditButton
                  onClick={() => {
                    this.props.actions?.editForm(form.key);
                    this.setState({ showEditForm: true });
                  }}
                />
              ) : null}
            </div>
          }
        />
        {showTooltipDropDown && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1000,
              backgroundColor: FJWHITE,
              width: '100%',
              padding: '12px 14px 15px',
              color: FJBROWN,
              fontSize: '16px',
              lineHeight: '32px',
            }}
            onClick={() => {
              this.setState({ showTooltipDropDown: !showTooltipDropDown });
            }}
          >
            <div className={'d-flex justify-content-between'}>
              <span>Boniturname:</span>
              <span>{formSchema.name}</span>
            </div>
            <div className={'d-flex justify-content-between'}>
              <span>Bonitur-ID:</span>
              <span>{formSchema.typeId}</span>
            </div>
          </div>
        )}
        <div className="scrollable-content">{body}</div>
        {this.renderBoniturForm()}
      </div>
    );
  }
}

export default compose<typeof Bonitur>(
  withRouter,
  connect(
    (state: any, ownProps: any) => {
      const user = state.firebase.profile;
      const { formId  } = ownProps; // formSubmissionId

      const openCompany = selectors.getOpenCompanyId(state);
      const browsingGroup = getBrowsingGroupKey(state, openCompany);
      const openForm =  getOpenFormId(state);

      const form = getForm(state, formId);
      const markers: any[] = [];
      let field;

      if (form?.field_id) {
        field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, form.field_id);
        if (field) {
          const fieldMarker = getFieldMarkers([field] || []);
          markers.push(...fieldMarker);
        }
      }

      if (form?.position) {
        markers.push({
          title: form.meta ? form.meta.group_name : '',
          position: form.position,
          type: 'bonitur',
          key: form.comment_id,
        });
      }

      const formSchemaId = get(form, 'schema_id');
      const formSchema = getFormSchema(state, formSchemaId);
      const boniturImagesKeys = getBoniturisImageKey(state, { formId });
      const boniturImages: any[] = keys(form?.formValues)
        .map((key) => {
          if (boniturImagesKeys.includes(key) && form.formValues[key]) {
            const img = new Image();
            img.src = form.formValues[key].uri;
            return {
              key: form.key,
              comment: {
                key: form.comment_id,
                type: 'user.image',
                extraData: { image: form.formValues[key] },
                image: {
                  dimensions: { width: img.width, height: img.height },
                  ...form.formValues[key],
                },
              },
              position: form.position,
            };
          }
          return null;
        });

      const comment = form
        ? getComment(state.firestore.data, form.comment_id)
        : null;

      const queryPaths = [
        getFormQuery(formId),
        getFormSchemaQuery(formSchemaId),
      ];

      if (form && form.comment_id) {
        queryPaths.push(getCommentQuery(form.comment_id) as any);
      };

      if (form && form.field_id) {
        queryPaths.push(getFieldQuery(openCompany, form.field_id) as any);
      }

      return {
        companyId: openCompany,
        form,
        markers,
        field,
        openForm,
        loading: !hasLoaded(queryPaths, state),
        formDoesntExist: !form && hasLoaded([getFormQuery(formId)], state),
        formSchema,
        comment,
        browsingGroup,
        boniturImages,
      };
    },
    (dispatch) => ({
      actions: bindActionCreators(
        {
          openForm,
          editForm,
        },
        dispatch,
      ),
    }),
  ),
  firestoreConnect((props) => {
    const { companyId, form } = props;
    const paths: any = [];

    if (form) {
      const { key, schema_id, comment_id, field_id } = form;
      if (key) paths.push(getFormQuery(key) as any);
      if (schema_id) paths.push(getFormSchemaQuery(schema_id) as any);
      if (comment_id) paths.push(getCommentQuery(comment_id) as any);
      if (field_id) paths.push(getFieldQuery(companyId, field_id) as any);
    }

    return paths;
  }),
)(Bonitur);
