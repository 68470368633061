import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash-es';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import * as companySelectors from 'farmerjoe-common/lib/selectors/companies';
import { fieldsCount } from 'farmerjoe-common/lib/utils/Field';
import {
  getAllGroupsSettingsForLoggedInUser,
  getCompanyGroupProfileForLoggedInUser,
} from 'farmerjoe-common/lib/selectors/user';
import {
  COMPANY_MAIN_GROUP_KEY,
  getBrowsingGroupKey,
} from 'farmerjoe-common/lib/selectors/groups';

import Icon from '../Common/Icon';
import withRouter from '../Router/withRouter';
import * as constants from '../../styles/style';
import type { Company, Field } from '../../flowTypes';
import { truncate } from '../../utils/String';
import fieldsContainer from '../../containers/Fields';

type Props = {
  company?: Company;
  fields?: Array<Field>;
  history?: Record<string, any>;
  wholeProfile?: null | {
    group_ids: string[];
    [groupId: string]: {};
  };
  browsingAsGroup?: string;
  restrictedAccess?: boolean;
};

type SelectorProps = {
  meta?: {
    [companyId: string]: {
    stats: {
      fields: {
        ha: number;
        count: number;
      };
      users: {
        count: number;
      };
    };
  };
};
};

class CompanyTitle extends React.Component<Props & SelectorProps> {
  render() {
    const { company, meta, wholeProfile, browsingAsGroup, restrictedAccess } = this.props;
    let groupName = '';

    if (!company) {
      return null;
    }

    if (browsingAsGroup !== '' && browsingAsGroup !== COMPANY_MAIN_GROUP_KEY) {
      groupName = get(wholeProfile, [browsingAsGroup, 'group_name'], '');
    }

    const count = fieldsCount(get(meta, `${company.key}.stats.fields.count`, 0));
    const name = this.props.company?.name && truncate(this.props.company.name, 27);
    const size = get(meta, `${company.key}.stats.fields.ha`, 0).toFixed(2);

    return (
      <div
        className="title-container"
        onClick={() =>
          this.props.history?.push(`/company/${this.props.company?.key}/info`)
        }>
        <div>
          <span>
            {' '}
            {groupName ? groupName + ' | ' : ''} {name}
          </span>
        </div>
        {!restrictedAccess ? (

          <div className="info-row">
            <span
              style={{
                ...constants.styles.extraSmall,

                textAlign: 'left',
                fontSize: 14,
                color: '#3D312E',
                whiteSpace: 'nowrap',
                marginRight: 0,
              }}>
              {count}
            </span>

            <span
              style={{
                ...constants.styles.extraSmall,
                ...{
                  color: '#3D312E',
                  paddingRight: 2,
                  paddingLeft: 20,
                  whiteSpace: 'nowrap',
                  marginRight: 20,
                },
              }}>
              {size} ha
            </span>

            <Icon
              name={'ios-information-circle-outline'}
              style={{
                fontSize: 16,
                color: '#7EB549',
                verticalAlign: 'middle',
                lineHeight: 0,
                display: 'inline-block',
              }}
            />
          </div>
        ) : null
        }
      </div>
    );
  }
}

export default compose<typeof CompanyTitle>(
  connect((state: any) => {
    const openCompany = selectors.getOpenCompanyId(state);
    const company = companySelectors.getCompany(
      state.firestore.data,
      openCompany,
    );
    const browsingAsGroup = getBrowsingGroupKey(state, openCompany);
    const wholeProfile = getAllGroupsSettingsForLoggedInUser(state, openCompany);
    const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
      state,
      openCompany,
    );

    const meta = get(state, 'firestore.data.meta', {});

    return {
      company,
      browsingAsGroup,
      wholeProfile,
      myCompanyProfile,
      openCompany,
      restrictedAccess: company ? (company.restrictedAccess || false) : false,
      meta, 
    };
  }),
  fieldsContainer,
  withRouter,
)(CompanyTitle);
