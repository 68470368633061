import React, { useState, useRef } from 'react';
import t from 'tcomb-form';
import { Loading } from '../Loading/Loading';
import { getUidToken } from '../../utils/auth';
import PlanUpgradeModal from './PlanUpgradeModal';
import I18n from '../../language/i18n';
import EnterpriseForm from '../../tcomb/models/billingEnterprise';

import { requestEnterpriseSubscription } from 'farmerjoe-common/lib/requests/billing';

type Props = {
  show: boolean;
  onClose: () => any;
  companyId: string;
  billingDetails: any;
  onSuccess: () => any;
};

const EnterprisePlanDialog = (props: Props) => {
  const Form = t.form.Form;

  const { show, onClose, companyId, billingDetails, onSuccess } = props;
  const initState = () => {
    if (!billingDetails) {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
      };
    }

    return {
      firstName: billingDetails.contact.firstName,
      lastName: billingDetails.contact.lastName,
      email: billingDetails.contact.email,
      phoneNumber: billingDetails.contact.phoneNumber,
      message: '',
    };
  };
  const [formValue, setFormValue] = useState(initState());
  const [apiLoading, setApiLoading] = useState(false);

  const formRef = useRef(null);

  const onChange = (value) => {
    const _value = JSON.parse(JSON.stringify(value));
    setFormValue({
      ...formValue,
      ..._value,
    });
  };

  const onClick = () => {
    if (formRef.current) {
      const { errors, value } = (formRef as any).current.validate();

      if (value === null || errors.length) {
        return;
      }

      setApiLoading(true);
      return getUidToken()
        .then((uidToken: string) => {
          return requestEnterpriseSubscription(uidToken, {...formValue, companyId}).then(() => {
            onClose();
            onSuccess();
          });
        }).finally(() => {
          setApiLoading(false);
        });
    }
    return true;
  };

  return (
    <PlanUpgradeModal
      show={show}
      onClose={onClose}
      title={I18n.t('billing.upgradeToEnterprise')}
      footer={
        <>
          {apiLoading ? null : (
            <div className="d-flex flex-grow-1">
              <button className="btn btn-secondary ml-auto" onClick={onClose}>
                {I18n.t('cancel')}
              </button>{' '}
              <button
                style={{textTransform: 'capitalize'}}
                className="btn btn-primary"
                onClick={onClick}>
                {I18n.t('billing.send')}
              </button>
            </div>
          )}
        </>
      }
    >
      {apiLoading ? (
        <Loading />
      ): (
        <div style={{
          margin: '0 auto',
        }}>
          <Form
            ref={formRef}
            type={EnterpriseForm.model()}
            options={() => EnterpriseForm.options(this)}
            value={formValue}
            onChange={onChange}
          />
        </div>
      )}
    </PlanUpgradeModal>
  );
};

export default EnterprisePlanDialog;
