import moment from 'moment';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import { isFunction } from 'lodash-es';
import React from 'react';

import I18n from '../../language/i18n';

/**
 * rc-calendar relies on setting the locale in moment for translating months, days etc.,
 * but if no value prop is given to calendar it uses the default which is english.
 * This component fixes it by using the current locale for new moment objects.
 */

export function getTodayTime(value) {
  const today = moment();
  today.locale(I18n.locale).utcOffset(value.utcOffset());
  return today;
}

function isEmptyArray(arr) {
  return (
    Array.isArray(arr) &&
    (arr.length === 0 ||
      arr.every(function(i) {
        return !i;
      }))
  );
}

function isArraysEqual(a, b) {
  if (a === b) return true;
  if (
    a === null ||
    typeof a === 'undefined' ||
    b === null ||
    typeof b === 'undefined'
  ) {
    return false;
  }
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function getValueFromSelectedValue(selectedValue) {
  let start = selectedValue[0];
  let end = selectedValue[1];

  if (end && (start === undefined || start === null)) {
    start = end.clone().subtract(1, 'month');
  }

  if (start && (end === undefined || end === null)) {
    end = start.clone().add(1, 'month');
  }
  return [start, end];
}

function normalizeAnchor(props, init) {
  const selectedValue =
    props.selectedValue || (init && props.defaultSelectedValue);
  const value = props.value || (init && props.defaultValue);
  const normalizedValue = value
    ? getValueFromSelectedValue(value)
    : getValueFromSelectedValue(selectedValue);
  return !isEmptyArray(normalizedValue)
    ? normalizedValue
    : init && [
      moment().locale(I18n.locale),
      moment()
        .locale(I18n.locale)
        .add(1, 'months'),
    ];
}

type FixedRangeCalendarProps = {
  showToday: boolean;
  showDateInput: boolean;
  selectedValue: any;
  onSelect: (arg0: [any, any])=> any;
};
type FixedRangeCalendarType = React.ComponentClass<FixedRangeCalendarProps>;

export default (class FixedRangeCalendar extends RangeCalendar {
  constructor(props, context) {
    super(props, context);
    (this as any).state.value = normalizeAnchor(props, 1);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const newState: any = {};
    if ('value' in nextProps) {
      newState.value = normalizeAnchor(nextProps, 0);
    }
    if (
      'hoverValue' in nextProps &&
      !isArraysEqual(state.hoverValue, nextProps.hoverValue)
    ) {
      newState.hoverValue = nextProps.hoverValue;
    }
    if ('selectedValue' in nextProps) {
      let selectedValue = nextProps.selectedValue;
      if (selectedValue && isFunction(selectedValue.locale)) {
        selectedValue = selectedValue.locale(I18n.locale);
      }
      newState.selectedValue = selectedValue;
      newState.prevSelectedValue = selectedValue;
    }
    if ('mode' in nextProps && !isArraysEqual(state.mode, nextProps.mode)) {
      newState.mode = nextProps.mode;
    }
    return newState;
  }
}) as (FixedRangeCalendarType);
