import when from '@flipbyte/when-condition';
import { FJBLACK } from '../../styles/style';
import { BoniturTextColorScheme, FormikFormValueObject } from '../../flowTypes';
import { BoniturDependsOnProps } from './BoniturTypes';

export const willRenderFormElement = (dependsOn: Array<BoniturDependsOnProps> | undefined, existingFormData) => {
  if (!dependsOn) {
    return true;
  }

  if (dependsOn.length === 0) {
    return true;
  }

  let willRender = false;

  dependsOn.forEach((dependency) => {
    for (const [formElement, formElementValue] of Object.entries(
      existingFormData,
    )) {
      if (dependency.element === formElement) {
        willRender = when(dependency.condition, {
          [formElement]: formElementValue,
        });
      }
    }
  });

  return willRender;
};

export const getColorForValue = (colorSchemes: Array<BoniturTextColorScheme>, formValue: FormikFormValueObject): string => {
  if (!colorSchemes) {
    return FJBLACK;
  }

  const foundScheme = colorSchemes.find(scheme => when(scheme.condition, formValue));
  return foundScheme ? foundScheme.color : FJBLACK;
};

export const parseNumber = (value: string | number, numberFormatter: (value: number) => string) => {
  if (!isNumber(value)) {
    return value;
  }
  if (typeof value === 'string') {
    const num = parseFloat(value);
    return numberFormatter(num);
  }
  if (typeof value === 'number') {
    return numberFormatter(value);
  }
  return 0;
};


export const isNumber = (value: string | number): boolean => {
  if (typeof value === 'number') {
    return true;
  }

  if (value.match(/^-?(0|[1-9]\d*)(\.\d+)?$/)) {
    return true;
  }

  return false;
};

export const isText = (value: string | number) : boolean => {
  return !isNumber(value);
};
