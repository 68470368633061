import React from 'react';
import imgFjAvatar from '../../public/images/fj_avatar_round.png';

const sizes = {
  'x-small': { width: 16, height: 16, borderRadius: 2 },
  small: { width: 32, height: 32, borderRadius: 2 },
  medium: { width: 64, height: 64, borderRadius: 4 },
  large: { width: 128, height: 128, borderRadius: 4 },
  'x-large': { width: 256, height: 256, borderRadius: 5 },
  original: { width: 512, height: 512, borderRadius: 5 },
};

const roundedStyles = {
  'x-small': { borderRadius: 8 },
  small: { borderRadius: 16 },
  medium: { borderRadius: 32 },
  large: { borderRadius: 64 },
  'x-large': { borderRadius: 128 },
  original: { borderRadius: 256 },
};

type Props = {
  downloadUrl: string | null;
  isLoaded: boolean;
  style?: {};
  round?: boolean;
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'original';
  icon?: React.ReactNode;
};

export const Avatar = (props: Props) => {
  let { size = 'x-small', downloadUrl = null, isLoaded, style, round = false, icon } = props;

  style = { ...sizes[size], ...style };
  if (round) {
    style = {
      ...style,
      ...roundedStyles[size],
    };
  }

  if (isLoaded && !downloadUrl) {
    return (
      <div className={'avatar'} style={{ ...style }}>
        {icon || (
          <img src={imgFjAvatar} style={style} alt="" />
        )}
      </div>
    );
  }
  // If we don't have a downloadUrl, display a placeholder
  if (!downloadUrl) {
    return <div className={'avatar'} style={style}></div>;
  }

  return (
    <div className={'avatar'} style={{ ...style }}>
      <img src={downloadUrl} style={style} alt="" />
    </div>
  );
};
