import * as React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { classes } from '../../utils/dom';

type Props = {
  children?: React.ReactNode;
  position: string;
  style?: Record<string, any>;
  mergeControls?: boolean;
  mergedContainerClassName?: string;
  className?: string;
  map: google.maps.Map;
};

export default class MapControl extends React.Component<Props> {
  static propTypes = {
    position: PropTypes.string.isRequired,
    children: PropTypes.node,
    style: PropTypes.object,
  };

  static defaultProps = {
    mergeControls: true,
  };

  controlDiv?: HTMLElement;
  control?: HTMLElement;

  componentDidMount() {
    if (!this.props.map) {
      return;
    }
    this.controlDiv = document.createElement('div');
    const map = this.props.map;
    const controls =
      map?.controls[(window as any).google.maps.ControlPosition[this.props.position]];

    if (this.props.mergeControls) {
      let mergedControlContainer = findMergedControlContainer(controls);
      if (!mergedControlContainer) {
        mergedControlContainer = document.createElement('div');
        mergedControlContainer.className = 'merged-control-container';
        // $FlowFixMe
        mergedControlContainer.mergedControlContainer = true;
        controls?.push(mergedControlContainer);
      }
      mergedControlContainer.appendChild(this.controlDiv);
      if (this.props.mergedContainerClassName) {
        mergedControlContainer.className +=
          ' ' + this.props.mergedContainerClassName;
      }
      this.control = mergedControlContainer;
    } else {
      controls?.push(this.controlDiv);
      this.control = this.controlDiv;
    }

    if (this.props.style || this.props.className) {
      (window as any).google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
        if (this.props.style) {
          Object.assign(this.controlDiv?.style, this.props.style);
        }
        if (this.props.className) {
          this.controlDiv?.classList.add(
            String(this.props.className).split(/\s+/) as any,
          );
        }
      });
      if (this.props.style) {
        Object.assign(this.controlDiv.style, this.props.style);
      }
      if (this.props.className) {
        this.controlDiv.classList.add(String(this.props.className).split(/\s+/) as any);
      }
    }
    this.forceUpdate();
  }

  componentWillUnmount() {
    const map = this.props.map;
    const controls = map?.controls[
      (window as any).google.maps.ControlPosition[this.props.position]
    ] as unknown as any;
    if (this.props.mergeControls) {
      const mergedControlContainer = findMergedControlContainer(controls);
      if (mergedControlContainer) {
        mergedControlContainer.removeChild(this.controlDiv);

        if (mergedControlContainer.children.length === 0) {
          const index = controls.indexOf(mergedControlContainer);
          if (index !== -1) {
            controls.removeAt(index);
          }
        }
      }
    } else {
      if (!controls) return;
      const index = controls.indexOf(this.controlDiv);
      if (index !== -1) {
        controls.removeAt(index);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    (window as any).google.maps.event.trigger(this.control, 'resize');
  }

  render() {
    return this.controlDiv
      ? ReactDOM.createPortal(this.props.children, this.controlDiv)
      : null;
  }
}

export const BOTTOM = 'BOTTOM';
export const BOTTOM_CENTER = 'BOTTOM_CENTER';
export const BOTTOM_LEFT = 'BOTTOM_LEFT';
export const BOTTOM_RIGHT = 'BOTTOM_RIGHT';
export const CENTER = 'CENTER';
export const LEFT = 'LEFT';
export const LEFT_BOTTOM = 'LEFT_BOTTOM';
export const LEFT_CENTER = 'LEFT_CENTER';
export const LEFT_TOP = 'LEFT_TOP';
export const RIGHT = 'RIGHT';
export const RIGHT_BOTTOM = 'RIGHT_BOTTOM';
export const RIGHT_CENTER = 'RIGHT_CENTER';
export const RIGHT_TOP = 'RIGHT_TOP';
export const TOP = 'TOP';
export const TOP_CENTER = 'TOP_CENTER';
export const TOP_LEFT = 'TOP_LEFT';
export const TOP_RIGHT = 'TOP_RIGHT';

export const ControlContainer = ({ children, className }) => (
  <div className={classes('control-container gmnoprint', className)}>
    {children}
  </div>
);

export const ControlButton = ({ children, className, ...restProps }: any) => (
  <button className={classes('control-button', className)} {...restProps}>
    {children}
  </button>
);

function findMergedControlContainer(controls) {
  if (!controls) {
    return null;
  }
  for (let i = 0; i < controls.getLength(); i++) {
    if (controls.getAt(i).mergedControlContainer) {
      return controls.getAt(i);
    }
  }
  return null;
}
