import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isLoaded, firestoreConnect } from 'react-redux-firebase';
import Modal from 'react-bootstrap-modal';

import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getFormSchemas } from 'farmerjoe-common/lib/selectors/forms';
import { getFormSchemasQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Forms';
import { openFormSchema, editForm } from 'farmerjoe-common/lib/actions/form';

import { addKeyToObj } from '../../data/util';
import { Loading } from '../Loading/Loading';

// import type { Company, FormSchema } from 'farmerjoe-common'
type Props = {
  company: any; // Company
  openView: (...args: Array<any>) => any;
  goBack: (...args: Array<any>) => any;
  loaded: boolean;
  templates: any[]; // FormSchema[]
  actions: {
    editForm: typeof editForm;
    openFormSchema: typeof openFormSchema;
  };
};

const FormSelectBonitur = (props: Props) => {
  const { loaded, templates, actions, openView } = props;

  if (!loaded) {
    return <Loading />;
  }

  const content = templates.map((template) => {
    return (
      <div
        key={template.key}
        className={'card fj-pointer'}
        onClick={() => {
          actions.editForm(null);
          actions.openFormSchema(template.key);
          openView('CreateBoniturForm');
        }}
      >
        <div className="card-body">
          <h4 className={'card-title'}>{template.name}</h4>
          <div className="card-text">{template.description}</div>
        </div>
      </div>
    );
  });

  return (
    <Modal.Body onClick={(e) => e.stopPropagation()}>
      <div style={{ flex: 1, paddingTop: 10 }}>{content}</div>
    </Modal.Body>
  );
};

const selector = (state) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);

  const templates = getFormSchemas(state);
  templates.sort(templatesSortFn);

  return {
    company: addKeyToObj(openCompany, company),
    loaded: isLoaded(templates),
    openCompany,
    templates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          openFormSchema,
          editForm,
        },
      ),
      dispatch,
    ),
  };
};

const templatesSortFn = (templA, templB) => {
  return templA.typeId - templB.typeId;
};

const wrappedFormSelectLab = firestoreConnect((props) => {
  const { openCompany } = props;

  return [getFormSchemasQuery(openCompany)];
})(FormSelectBonitur);

export default connect(selector, mapDispatchToProps)(wrappedFormSelectLab);
