import React from 'react';
import { cloneDeep } from 'lodash-es';
import t from 'tcomb-form';

import Email from '../validators/email';
import Tel from '../validators/tel';
import Website from '../validators/website';
import { latitude, longitude } from '../validators/coordinates';
import FormInput from '../templates/FormInput';
import modalCountry from '../templates/Country/Country';
import modalCountryTel from '../templates/Country/CountryTel';
import { numberTransformer } from '../transformers/transformers';
import { positionTemplate } from '../templates/Position';

import stylesheet from '../stylesheets/style';
import * as constants from '../../styles/style';
import I18n from '../../language/i18n';

const model = required => {
  return t.struct({
    // company_id:     t.String,
    name: t.String,
    email: Email,
    company_number: t.maybe(t.String),
    ggn_number: t.maybe(t.String),
    qs_number: t.maybe(t.String),
    street: itemRequired(t.String, required),
    zip: itemRequired(t.String, required),
    city: itemRequired(t.String, required),
    country: itemRequired(t.String, required),
    tel: itemRequired(Tel, required),
    notes: t.maybe(t.String),
    position: t.struct({
      latitude: latitude,
      longitude: longitude,
    }),
    website: t.maybe(Website),
  });
};

const itemRequired = (type, required = false) => {
  return required ? type : t.maybe(type);
};

const multiline = cloneDeep(stylesheet);
multiline.textbox.normal = {
  ...multiline.textbox.normal,
  height: 'auto',
};

const formGroupOuter = cloneDeep(stylesheet);
formGroupOuter.formGroup.normal.borderBottomWidth = 0;

function template(locals, PolygonComponent) {
  // in locals.inputs you find all the rendered fields
  return (
    <div>
      {locals.inputs.company_id}
      {locals.inputs.name}
      {locals.inputs.email}
      {locals.inputs.street}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <div style={{ width: 150 }}>{locals.inputs.zip}</div>
        <div style={{ flex: 1 }}>{locals.inputs.city}</div>
      </div>

      {locals.inputs.country}
      {PolygonComponent}
      <div style={{}}>{locals.inputs.position}</div>
      {locals.inputs.tel}
      {locals.inputs.company_number}
      {locals.inputs.ggn_number}
      {locals.inputs.qs_number}
      {locals.inputs.notes}
      {locals.inputs.website}
    </div>
  );
}

const options = function(component, addressRequired, PolygonComponent) {
  const asterisk = addressRequired ? ' *' : '';

  return {
    template: locals => template(locals, PolygonComponent),
    stylesheet: stylesheet,
    auto: 'none',
    fields: {
      name: {
        label: `${I18n.t('company_name')} *`,
        placeholderTextColor: constants.FJLABEL,
        returnKeyType: 'next',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        /* testID: "testFieldName", */
        onSubmitEditing: () =>
          component.refs.form.getComponent('email').refs.input.focus(),
      },
      email: {
        label: `${I18n.t('email')} *`,
        placeholderTextColor: constants.FJLABEL,
        returnKeyType: 'next',
        blurOnSubmit: false,
        keyboardType: 'email-address',
        factory: FormInput,
        autoCorrect: false,
        autoCapitalize: 'none',
        onSubmitEditing: () =>
          component.refs.form.getComponent('street').refs.input.focus(),
      },
      company_number: {
        label: `${I18n.t('company_number')}`,
        placeholderTextColor: constants.FJLABEL,
        returnKeyType: 'next',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () =>
          component.refs.form.getComponent('ggn_number').refs.input.focus(),
      },
      ggn_number: {
        label: `${I18n.t('ggn_gln_number')}`,
        placeholderTextColor: constants.FJLABEL,
        returnKeyType: 'next',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () =>
          component.refs.form.getComponent('qs_number').refs.input.focus(),
      },
      qs_number: {
        label: `${I18n.t('company.qs_number')}`,
        placeholderTextColor: constants.FJLABEL,
        returnKeyType: 'next',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () =>
          component.refs.form.getComponent('street').refs.input.focus(),
      },

      street: {
        label: I18n.t('street') + asterisk,
        placeholderTextColor: constants.FJLABEL,
        returnKeyType: 'next',
        blurOnSubmit: false,
        autoCorrect: false,
        factory: FormInput,
        /* testID: "testFieldStreet", */
        onSubmitEditing: () =>
          component.refs.form.getComponent('zip').refs.input.focus(),
      },
      zip: {
        label: I18n.t('zip') + asterisk,
        placeholderTextColor: constants.FJLABEL,
        returnKeyType: 'next',
        blurOnSubmit: false,
        autoCorrect: false,
        factory: FormInput,
        /* testID: "testFieldZip", */
        onSubmitEditing: () =>
          component.refs.form.getComponent('city').refs.input.focus(),
      },
      city: {
        label: I18n.t('city') + asterisk,
        placeholderTextColor: constants.FJLABEL,
        returnKeyType: 'next',
        autoCorrect: false,
        factory: FormInput,
        /* testID: "testFieldCity", */
        onSubmitEditing: () =>
          component.refs.form
            .getComponent('country')
            .refs.input.setModalVisible(true),
      },
      country: {
        label: I18n.t('country') + asterisk,
        factory: modalCountry,
        // onSubmitEditing:      () => component.refs.form.getComponent('tel').refs.input.input.focus()
      },
      tel: {
        label: I18n.t('mobile_phone') + asterisk,
        factory: modalCountryTel,
        onSubmitEditing: () =>
          component.refs.form.getComponent('notes').refs.input.focus(),
      },

      position: {
        label: `${I18n.t('position_company')} *`,
        template: positionTemplate,
        fields: {
          latitude: {
            label: I18n.t('latitude'),
            factory: FormInput,
            transformer: numberTransformer,
          },
          longitude: {
            label: I18n.t('longitude'),
            factory: FormInput,
            transformer: numberTransformer,
          },
        },
      },
      notes: {
        label: I18n.t('notes'),
        placeholder: I18n.t('notes'),
        stylesheet: multiline,
        textAlignVertical: 'bottom',
        placeholderTextColor: constants.FJLABEL,
        autoCorrect: false,
        factory: FormInput,
        config: {
          multiline: true,
        },
      },
      website: {
        label: I18n.t('website'),
        placeholder: I18n.t('website'),
        placeholderTextColor: constants.FJLABEL,
        autoCorrect: false,
        factory: FormInput,
        transformer: {
          format: (value) => {
            return typeof value === 'string' ? value.toLowerCase() : value;
          },
          parse: (value) => {
            return typeof value === 'string' ? value.toLowerCase() : value;
          },
        },
      },
    },
  };
};

export default { model, options };
