import React from 'react';
import { get } from 'lodash-es';
import t from 'tcomb-form';
import Icon from '../../components/Common/Icon';
import './style.css';

const Component = t.form.Component;

export default class RadioGroup extends Component {
  getTemplate() {
    return (locals) => RadioGroupContainer({ locals });
  }
}

const RadioGroupContainer = (props) => {
  const { locals } = props;
  // const stylesheet = locals.stylesheet;
  const config = get(locals, 'config', null);

  return (
    <div className='radio-group-container'>
      <div style={{marginBottom: '10px'}}>
        <span className={locals.hasError ? 'radio-group-label-error' : 'radio-group-label'}>
          {locals.label}
        </span>
      </div>
      <RadioGroupComponent
        config={config}
        onChange={locals.onChange}
        value={locals.value}
      />
    </div>
  );
};

const RadioGroupComponent = (props) => {
  const { config } = props;
  const { options } = config;

  const onClickCb = (value) => {
    props.onChange(value);
  };

  return (
    <div>
      {options.map((data, key) => {
        return (
          <div
            key={key}
            className='radio-group-option'
            onClick={() => {
              onClickCb(data.value);
            }}
          >
            <span className='radio-group-text-muted'>{data.label}</span>
            {data.value === props.value ? (
              <Icon
                name={'ios-checkmark-circle'}
                className='radio-group-option-icon'
              />
            ) : (
              <Icon
                name={'ios-radio-button-off'}
                className='radio-group-option-icon'
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
