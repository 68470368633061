import React from 'react';
import PropTypes from 'prop-types';

import * as constants from '../../styles/style';
import Icon from '../Common/Icon';
// import Text from '../Common/Text'

const Lab = ({ lab, links = false, orientation = 'row' }) => {
  let center = {};

  // if (orientation === 'column') {
  center = {
    justifyContent: 'flex-start',
    alignItems: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  };
  // }
  return (
    <div>
      <div
        style={{
          flexDirection: orientation as any,
          marginLeft: 5,
          justifyContent: 'space-between',
          display: 'flex',
        }}>
        <div style={{ ...center }}>
          <span
            style={{ ...constants.styles.strong, ...constants.styles.muted }}>
            {lab.name}
          </span>
          <span style={{ ...constants.styles.muted }}>
            {lab.address.street}
          </span>
          <span style={{ ...constants.styles.muted }}>
            {lab.address.zip} {lab.address.city}
          </span>
          <span style={{ ...constants.styles.muted }}>
            {lab.address.country}
          </span>
          {links
            ? (
              <div style={center}>
                <div
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <a
                    href={`tel:${lab.tel}`}
                    style={{ color: constants.FJNEWGREEN, paddingLeft: 4 }}>
                    <Icon
                      name={'ios-call'}
                      style={{
                        color: constants.FJNEWGREEN,
                        backgroundColor: 'transparent',
                        fontSize: 17,
                        marginTop: 2,
                      }}
                    />{' '}
                    {lab.tel}
                  </a>
                </div>
                <div
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <a
                    href={`mailto:${lab.email}`}
                    style={{ color: constants.FJNEWGREEN, paddingLeft: 4 }}>
                    <Icon
                      name={'ios-mail-outline'}
                      style={{
                        color: constants.FJNEWGREEN,
                        backgroundColor: 'transparent',
                        fontSize: 17,
                        marginTop: 2,
                      }}
                    />{' '}
                    {lab.email}
                  </a>
                </div>
              </div>
            )
            : (
              <div style={center}>
                <span>{lab.tel}</span>
                <span>{lab.email}</span>
              </div>
            )}
        </div>
        <div style={{ marginRight: 5 }}>
          <img
            style={{ width: lab.logo.width, height: lab.logo.height }}
            src={lab.logo.uri}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

Lab.propTypes = {
  lab: PropTypes.object.isRequired,
  links: PropTypes.bool,
};

export default Lab;
