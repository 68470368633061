import React from 'react';
import I18n from '../../language/i18n';

import FlatList from '../Common/FlatList';
import NoResults from '../Common/NoResults';
import Profile from '../../containers/Profile/index';
import EmployeeContent from '../Employees/EmployeeContent';

type Props = {
  loading: boolean;
  company?: any;
  myCompanyProfile?: any;
  actions?: any;
  groupId?: any;
  users?: any[];
};

type State = {
  showProfile: false;
};
class GroupEmployeesComponent extends React.PureComponent<Props, State> {
  render() {
    const { company, myCompanyProfile, actions, groupId } = this.props;

    return (
      <FlatList
        className="employees-list"
        ListEmptyComponent={
          <NoResults text={I18n.t('producers.noEmployees')} />
        }
        data={this.props.users}
        renderItem={({ item }) => {
          return (
            <Employee
              item={item}
              company={company}
              actions={actions}
              myCompanyProfile={myCompanyProfile}
              groupId={groupId}
            />
          );
        }}
        // ItemSeparatorComponent={() => <View style={{marginLeft: 90}}><Separator/></View>}
      />
    );
  }
}

type EmployeeProps = {
  item?: any;
  company?: any;
  actions?: any;
  myCompanyProfile?: any;
  groupId?: any;
};

type EmployeeState = {
  showProfile: boolean;
};

class Employee extends React.PureComponent<EmployeeProps, EmployeeState> {
  state = {
    showProfile: false,
  };

  render() {
    const { item, company, actions, myCompanyProfile, groupId } = this.props;
    return (
      <div className={'list-item employee-item'}>
        <div
          onClick={() => {
            this.setState({ showProfile: true });
          }}>
          <EmployeeContent
            user={item}
            company={company}
            actions={actions}
            myCompanyProfile={myCompanyProfile}
          />
        </div>

        {this.state.showProfile
          ? (
            <Profile
              uid={item.key}
              groupId={groupId}
              showInDialog={true}
              show={this.state.showProfile}
              onClose={() => {
                this.setState({ showProfile: false });
              }}
              key={1}
            />
          )
          : null}
      </div>
    );
  }
}

export default GroupEmployeesComponent;
