import React, { useState } from 'react';

import FormCompany from '../Company/FormCompany';
import SubscriptionPlans from './SubscriptionPlans';
import I18n from '../../language/i18n';

const NoBillingDetails = (props) => {
  const { subscriptionPlans, currentSubscription, currentSubscriptionPlan } = props;
  const [showCompanyForm, setShowCompanyForm] = useState(false);

  return (
    <>
      <div
        style={{
          marginLeft: '2em',
          textAlign: 'left',
        }}
      >
        <span style={{cursor: 'pointer', fontWeight: 'bold', color: 'rgb(197, 53, 32)'}} onClick={() => {
          setShowCompanyForm(true);
          props.actions.editCompany(props.companyId);
        }}>{I18n.t('billing.addBillingDetails')}{'!'}</span>
      </div>

      <SubscriptionPlans
        subscriptionPlans={subscriptionPlans}
        currentSubscription={currentSubscription}
        currentSubscriptionPlan={currentSubscriptionPlan}
        onSelect={() => {
          setShowCompanyForm(true);
          props.actions.editCompany(props.companyId);
        }}
      />
      {showCompanyForm
        ? (
          <FormCompany
            show={showCompanyForm}
            activeTab={'COMPANY_BUSINESS_ADDRESS'}
            onClose={() => setShowCompanyForm(false)}
          />
        )
        : null}
    </>
  );
};

export default NoBillingDetails;
