import { useEffect, useState } from 'react';

import fetch from '../utils/fetch';
import { getUidToken } from '../utils/auth';
import { BASE_API_URL } from '../constants';

const API_PRECROPS = `${BASE_API_URL}/precrops`;

const usePrecrops = (companyId: string, fieldId: string) => {
  const [precrops, setPrecrops] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchPrecrops = async () => {
      try {
        const uidToken = await getUidToken();
        const response = await fetch(`${API_PRECROPS}/${companyId}/${fieldId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${uidToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const json = await response.json();
        setPrecrops(json);
      } catch (e: any) {
        console.error('Unable to fetch precrops: ', e);
      }
    };
    fetchPrecrops();
    return () => {
      controller.abort();
    };
  }, [companyId, fieldId]);

  return precrops;
};

export default usePrecrops;
