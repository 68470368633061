import { useEffect, useState } from 'react';

import fetch from '../utils/fetch';
import { getUidToken } from '../utils/auth';
import { BASE_API_URL } from '../constants';

export const useSeeds = (companyId: string) => {
  const [seeds, setSeeds] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchPrecrops = async () => {
      try {
        const uidToken = await getUidToken();
        const response = await fetch(`${BASE_API_URL}/seeds/company/${companyId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${uidToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const json = await response.json();
        setSeeds(json);
      } catch (e: any) {
        console.error('Unable to fetch seeds: ', e);
      }
    };
    fetchPrecrops();
    return () => {
      controller.abort();
    };
  }, [companyId]);

  return seeds;
};

export const useSeedForFieldAndCrop = (companyId: string, fieldId: string, cropId: string) => {
  const [seed, setSeed] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const query = async () => {
      if (!fieldId || !cropId) {
        return;
      }
      try {
        const uidToken = await getUidToken();
        const response = await fetch(`${BASE_API_URL}/seeds/company/${companyId}/fields/${fieldId}/crops/${cropId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${uidToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const json = await response.json();
        setSeed(json);
      } catch (e: any) {
        console.error('Unable to fetch seed: ', e);
      }
    };
    query();
    return () => {
      controller.abort();
    };
  }, [companyId, fieldId, cropId]);

  return seed;
};
