import React, { useState, useEffect } from 'react';
import firebase from '../../data/firebase';
import I18n from '../../language/i18n';
import { LoadingIcon } from '../Loading/Loading';
import { leaveCompany } from '../../data/queries/support';
import './style.css';


export const LeaveCompanyButton = (props) => {
  const { companyId, history } = props;
  const [apiRunning, setApiRunning] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    if (apiRunning) {
      const doRequest = async () => {
        const user = firebase.auth().currentUser;
        const token = await user?.getIdToken(true);
        return leaveCompany(token, companyId)
          .then(() => {
            setApiRunning(false);
            setApiSuccess(true);
            history.push('/');
          }).catch((e) => {
            setApiRunning(false);
            setApiSuccess(false);
            setApiError(e);
          });
      };
      doRequest().catch(console.log);
    }
  }, [apiRunning, companyId, history]);

  return (
    <div>
      <button
        className={'btn btn-primary'}
        onClick={() => setApiRunning(true)}
        style={{ marginRight: 10 }}>
        {
          apiRunning
            ? (<LoadingIcon style={{ height: 22 }} />)
            : (I18n.t('company.leaveCompany'))
        }
      </button>
      {
        !apiSuccess && !apiRunning && apiError
          ? (
            <p>Error occured: { apiError }</p>
          )
          : null
      }
    </div>
  );
};
