import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import { get, isEqual } from 'lodash-es';

import {
  setImageMetadata,
  getImageMetadata,
  getUrl,
} from 'farmerjoe-common/lib/utils/Images';

import { Loading } from '../../Loading/Loading';
import Icon from '../../Common/Icon';
import type { Comment } from '../../../flowTypes';
import iconImage from '../../../public/images/icon/image.svg';
import './style.css';

type Props = {
  comment: Comment;
  size?: {
    width: number;
    height: number;
  };
  hideText?: boolean;
  onClick?: () => void;
  firebase: Record<string, any>;
  hideRotateButton?: boolean;
};

type State = {
  isError: boolean;
  isLoading: boolean;
  url: string | null;
  imageRotation: number;
  thumbnail: any;
};

class Image extends PureComponent<Props, State> {
  static propTypes = {
    comment: PropTypes.object.isRequired,
  };

  static defaultProps = {
    size: { height: 300, width: 300 },
  };

  state = {
    isError: false,
    isLoading: true,
    url: null,
    imageRotation: 0,
    thumbnail: null,
  };

  mounted = false;
  urlRequestIndex = 0;

  constructor(props: Props, context: any) {
    super(props, context);
    this.updateUrl();
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.mounted) {
      return;
    }
    if (
      !isEqual(
        get(this.props.comment, 'extraData.thumbs'),
        get(prevProps.comment, 'extraData.thumbs'),
      ) ||
      !isEqual(
        get(this.props.comment, 'extraData.image.ref'),
        get(prevProps.comment, 'extraData.image.ref'),
      ) ||
      !isEqual(
        get(this.props.comment, 'image.uri'),
        get(prevProps.comment, 'image.uri'),
      )
    ) {
      this.updateUrl();
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { comment, size, hideText, hideRotateButton } = this.props;

    const url = this.state.url;
    const showLoading = !url && this.state.isLoading;
    const showPlaceholder = showLoading || this.state.isError || !url;
    const width = get(comment, 'image.dimensions.width', null);
    const height = get(comment, 'image.dimensions.height', null);
    const ratio = (width / height) || 1.33;
    const sizeWidth = get(size, 'width', 0);
    const sizeHeight = get(size, 'height', 0);

    return (
      <React.Fragment>
        {hideRotateButton
          ? null
          : (<button
            className={'btn'}
            onClick={() => {
              const rotation = (this.state.imageRotation + 90) % 360;
              if (this.mounted) {
                this.setState({
                  imageRotation: rotation,
                });
              }
              setImageMetadata(rotation, comment);
            }}
            style={{
              zIndex: 1000,
            }}>
            <Icon iconType="fa" name="redo" style={{ fontSize: 20 }} />
          </button>)}
        <div
          className="image-comment"
          style={{
            width: sizeWidth || null,
            maxHeight: sizeHeight || 'none',
          }}>
          <div
            key={this.state.imageRotation}
            className="image"
            onClick={this.onClick}
            style={{
              backgroundImage: `url(${
                showPlaceholder ? iconImage : url
              })`,
              transform: `rotate(${this.state.imageRotation}deg)`,
            }}
          />
          {showLoading ? <Loading isLoading={true} /> : null}
          {/* sets height on the container, keeping the aspect ratio of the image */}
          <div style={{ paddingBottom: (1 / ratio) * 100 + '%' }} />
        </div>
        {!hideText && comment.text
          ? (
            <div className={'comment-image-text'}>{String(comment.text)}</div>
          )
          : null}
      </React.Fragment>
    );
  }

  onClick = e => {
    e.stopPropagation();
    this.props.onClick && this.props.onClick();
  };

  async updateUrl() {
    const index = ++this.urlRequestIndex;

    if (this.mounted) {
      this.setState({ isLoading: true });
    }

    let url: string;

    try {
      url = await getUrl(this.props.comment, this.props.size, (thumb) => {
        if (this.mounted) {
          this.setState({ thumbnail: thumb });
        }
      });
    } catch(e) {
      console.log('Error occured: ', e);
    }

    return new Promise((resolve, reject) => {
      const image = new window.Image();
      image.onerror = image.onabort = reject;
      image.onload = () => resolve(url);
      image.src = url;
    }).then(async () => {
      if (index === this.urlRequestIndex && this.mounted) {
        const comment = this.props.comment;
        await getImageMetadata(comment, null, (imageRotation) => {
          if (this.mounted) {
            this.setState({ imageRotation: Number(imageRotation) });
          }
        });
        if (this.mounted) {
          this.setState({ url, isLoading: false, isError: false });
        }
      }
    }).catch(e => {
      console.log('Error occured: ', e);
      if (index === this.urlRequestIndex && this.mounted) {
        if (this.mounted) {
          this.setState({ url: null, isLoading: false, isError: true });
        }
      }
    });
  }
}

export default compose(firestoreConnect())(Image);
