import React from 'react';
import { get } from 'lodash-es';

import YieldComment from './CropYield';
import Icon from '../../Common/Icon';
import * as commentUtils from '../../../utils/Comment';
import * as constants from '../../../styles/style';
import I18n from '../../../language/i18n';

const STYLE = { display: 'flex', flexDirection: 'row' as 'row', flex: 1 };
const styleWithIcon = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  flex: 1,
  alignItems: 'center',
};
const TEXT_STYLE = { marginLeft: 5 };
const textStyleWithIcon = {
  color: constants.FJMUTED,
  textAlign: 'center' as 'center',
  marginBottom: 10,
};
const iconStyle = { fontSize: 80, color: constants.FJAPPLEGREEN };

const Crop = (props) => {
  const { comment } = props;
  let icon: any = null;
  const text = commentUtils.getCommentText(comment);
  let style: React.CSSProperties = STYLE;
  let textStyle: React.CSSProperties = TEXT_STYLE;


  if (commentUtils.typeIs('yield', comment.type)) {
    return <YieldComment comment={comment} />;
  }

  if (commentUtils.typeIs('withoutSince', comment.type)) {
    icon = <Icon iconType={'fj'} name={'no_crop'} style={iconStyle} />;
    style = styleWithIcon;
    textStyle = textStyleWithIcon;
  }

  if (commentUtils.typeIs('sown', comment.type)) {
    return <CropSownComment comment={comment} />;
  }

  if (commentUtils.typeIs('harvested', comment.type)) {
    icon = <Icon iconType={'fj'} name={'truck'} style={iconStyle} />;
    style = styleWithIcon;
    textStyle = textStyleWithIcon;
  }

  return (
    <div style={style}>
      {icon ? renderIcon(icon) : null}
      <div style={{ flex: 1 }}>
        <span style={textStyle}>{text}</span>
      </div>
    </div>
  );
};

const CropSownComment = (props) => {
  const { comment } = props;
  const text = commentUtils.getCommentText(comment);

  const icon = <Icon iconType={'fj'} name={'crop_sown'} style={iconStyle} />;
  const style: React.CSSProperties = styleWithIcon;
  const textStyle: React.CSSProperties = textStyleWithIcon;
  const cropId = get(comment, 'cropMeta.externalCropId', null);
  const transformedCropId = cropId ? [cropId.slice(0, 3), cropId.slice(3, 6), cropId.slice(6)].join(' ') : null;

  return (
    <div style={style}>
      {renderIcon(icon)}
      <div style={{ flex: 1 }}>
        <span style={textStyle}>{text}</span>
        {cropId
          ? <div style={textStyle}>
            {I18n.t('crop.externalCropId')}{':'} {transformedCropId}
          </div>
          : null
        }
      </div>
    </div>
  );
};

const renderIcon = (icon) => {
  return (
    <div className={'crop-container-icon'}>
      <div className="line left">
        <hr />
      </div>
      <div>{icon}</div>
      <div className="line right">
        <hr />
      </div>
    </div>
  );
};

export default Crop;
