import React from 'react';
import { SimpleStatus } from 'farmerjoe-common/lib/constants/FileUpload';
import { FJNEWGREEN, FJAPPLERED } from '../../styles/style';
import Icon from './Icon';

type Props = {
  sStatus: string;
  progress: number;
  cancelUpload?: (key: string) => any;
  retryImageUpload: (key: string) => any;
  cancelImageUpload: (key: string) => any;
  onPressDelete: (...args: Array<any>) => any;
  uploadKey: string;
};
export const ImageUploadProgress = ({
  sStatus,
  progress,
  cancelImageUpload,
  retryImageUpload,
  onPressDelete,
  uploadKey,
}: Props) => {
  return (
    <div
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>
      {sStatus === SimpleStatus.IN_PROGRESS ||
      sStatus === SimpleStatus.PENDING
        ? (
          <div
            className={
              'd-flex justify-content-center align-items-center flex-column'
            }
            style={{ height: '100%' }}>
            <div
              className={'progress-bar progress-animated bg-success'}
              style={{ width: `${progress}%`, height: 12 }}
              role={'progressbar'}>
              <span className={'sr-only'}>{progress}% complete </span>
            </div>
            <button
              type={'button'}
              className={'btn btn-small'}
              onClick={() => cancelImageUpload(uploadKey)}>
              <Icon
                name={'times'}
                iconType={'fal'}
                style={{ fontSize: 30, color: FJAPPLERED }}
              />
            </button>
          </div>
        )
        : null}
      {sStatus === SimpleStatus.FAILURE
        ? (
          <div
            className={
              'd-flex justify-content-center align-items-center flex-row'
            }
            style={{ height: '100%' }}>
            <button
              type={'button'}
              className={'btn btn-small'}
              onClick={() => retryImageUpload(uploadKey)}>
              <Icon
                name="sync"
                iconType={'fal'}
                style={{ fontSize: 30, color: FJNEWGREEN }}
              />
            </button>
            <button
              type={'button'}
              className={'btn btn-small'}
              onClick={onPressDelete}>
              <Icon
                name="trash"
                iconType={'fal'}
                style={{ fontSize: 30, color: FJAPPLERED }}
              />
            </button>
          </div>
        )
        : null}
    </div>
  );
};
