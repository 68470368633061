import React from 'react';
import {
  createPortal,
  findDOMNode,
} from 'react-dom';
import { find } from 'lodash-es';
import XLSX from 'xlsx';

import { ConfirmDialog } from '../Dialog/Dialog';
import Select from '../Common/Select';
import I18n from '../../language/i18n';
import { getTextWidth } from '../../utils/String';

import './style.css';
import type { CellRenderer } from './flow';

const DIALOG_CONFIRM_VALUE = 'yes';

type Props = {
  cellRenderer: (arg0: CellRenderer) => React.ReactElement<any> | null;
  fileName: string;
};

type State = {
  cells: (React.ReactElement<any> | null)[][];
  cellRefs: ({
    current: null | Element;
  } | null)[][];
  waitingForUpdate: boolean;
  columnCount: number;
  rowCount: number;
  format: string;
  data: string[][] | null;
  showDialog: boolean;
  waitingToDownload: boolean;
};

export default class TableExport extends React.Component<Props, State> {
  portalContainer: any = null;
  mounted = false;
  doneWaitingForUpdateTimeout?: NodeJS.Timeout;
  waitingForDialogToShowTimeout?: NodeJS.Timeout;

  state = {
    cells: [],
    cellRefs: [],
    waitingForUpdate: false,
    columnCount: 0,
    rowCount: 0,
    format: 'xlsx',
    data: null,
    showDialog: false,
    waitingToDownload: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = true;
    if (this.doneWaitingForUpdateTimeout) clearTimeout(this.doneWaitingForUpdateTimeout);
    if (this.waitingForDialogToShowTimeout) clearTimeout(this.waitingForDialogToShowTimeout);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.waitingForUpdate && !prevState.waitingForUpdate) {
      // bonitur cells don't seem to be rendered immediately
      if (this.doneWaitingForUpdateTimeout) clearTimeout(this.doneWaitingForUpdateTimeout);
      this.doneWaitingForUpdateTimeout = setTimeout(() => {
        if (this.mounted) {
          this.setState(
            {
              cells: [],
              cellRefs: [],
              waitingForUpdate: false,
              data: this.getData(),
              waitingToDownload: false,
            },
            () => {
              if (this.state.waitingToDownload) {
                this.setState({ showDialog: false });
                this.downloadFile(this.state.format);
              }
            },
          );
        }
      }, 0);
    }
  }

  render() {
    const waitingForUpdate = this.state.waitingForUpdate;

    if (!waitingForUpdate && this.portalContainer) {
      document.body.removeChild(this.portalContainer);
      this.portalContainer = null;
    }

    if (waitingForUpdate && !this.portalContainer) {
      this.portalContainer = document.createElement('div');
      this.portalContainer.className = 'table-export-container';
      document.body.appendChild(this.portalContainer);
    }

    const formatOptions = [
      { value: 'csv', label: '.csv' },
      { value: 'xlsx', label: '.xslx' },
    ];
    const selectedFormatOption = find(
      formatOptions,
      ({ value }) => value === this.state.format,
    );

    return (
      <React.Fragment>
        {waitingForUpdate
          ? createPortal(
            <React.Fragment>
              {this.state.cells.reduce((acc: any[], row: any, rowIndex) => {
                acc.splice(acc.length, 0, ...row);
                return acc;
              }, [])}
            </React.Fragment>,
            this.portalContainer,
          )
          : null}
        {this.state.showDialog
          ? (
            <ConfirmDialog
              show={true}
              onClose={value => {
                if (value === DIALOG_CONFIRM_VALUE) {
                  if (this.state.data) {
                    this.setState({ showDialog: false });
                    this.downloadFile(this.state.format);
                  } else {
                    this.setState({ waitingToDownload: true });
                  }
                } else {
                  this.setState({ showDialog: false });
                }
              }}
              buttons={[
                {
                  value: 'no',
                  label: I18n.t('cancel'),
                  className: 'btn-secondary',
                },
                {
                  value: DIALOG_CONFIRM_VALUE,
                  label: I18n.t('ok'),
                  className: 'btn-primary',
                  disabled: this.state.waitingToDownload,
                },
              ]}
              title={I18n.t('chooseExportFormat')}>
              <Select
                value={selectedFormatOption}
                options={formatOptions}
                onChange={({ value }) => this.setState({ format: value })}
              />
            </ConfirmDialog>
          )
          : null}
      </React.Fragment>
    );
  }

  exportData(columnCount: number, rowCount: number) {
    const { cellRenderer } = this.props;
    const cells: any[] = [];
    const cellRefs: any[] = [];

    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      const row: any[] = [];
      const refsRow: any[] = [];
      cells.push(row);
      cellRefs.push(refsRow);

      for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
        let cell = cellRenderer({
          columnIndex,
          rowIndex,
          key: `${rowIndex}-${columnIndex}`,
          style: {},
        });
        let ref: any = null;

        if (cell) {
          ref = React.createRef();
          cell = React.cloneElement(cell, { ref });
        }

        row.push(cell);
        refsRow.push(ref);
      }
    }

    this.setState({ showDialog: true }, () => {
      if (this.mounted) {
        if (this.waitingForDialogToShowTimeout) clearTimeout(this.waitingForDialogToShowTimeout);
        this.waitingForDialogToShowTimeout = setTimeout(() => {
          if (this.mounted) {
            this.setState({
              cells,
              cellRefs,
              waitingForUpdate: true,
              columnCount,
              rowCount,
            });
          }
        }, 310);
      }
    });
  }

  getData() {
    const { columnCount, rowCount } = this.state;

    const data: any[] = [];

    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      const row: any[] = [];
      data.push(row);

      // XXX: We start from 1 because the first column is for notifications
      for (let columnIndex = 1; columnIndex < columnCount; columnIndex++) {
        let column = '';
        const cell = this.state.cellRefs[rowIndex][columnIndex] as React.RefObject<any>;

        if (cell && cell.current) {
          if (cell.current.getText) {
            column = cell.current.getText();
          } else {
            const element = findDOMNode(cell.current);
            if (element) {
              column = element.textContent;
            }
          }
        }

        row.push(column);
      }
    }

    return data;
  }

  downloadFile(format: string) {
    const fileName = this.props.fileName;
    const data = this.state.data;

    if (!data) {
      return;
    }

    const sheet = XLSX.utils.aoa_to_sheet(data);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, I18n.t('field.fields'));

    if (format === 'xlsx') {
      const cols: any[] = [];
      const font = '10px sans-serif';
      const zeroWidth = getTextWidth('0', font);

      for (
        let columnIndex = 0;
        columnIndex < this.state.columnCount;
        columnIndex++
      ) {
        let columnSize = 0;

        for (let rowIndex = 0; rowIndex < this.state.rowCount; rowIndex++) {
          const text = data[rowIndex][columnIndex];

          // excel's cell width is measured in how many zeroes can a cell display...
          columnSize = Math.max(
            columnSize,
            getTextWidth(text, font) / zeroWidth,
          );
        }

        cols.push({ wch: Math.max(5, Math.min(40, columnSize)) });
      }

      sheet['!cols'] = cols;
    }

    XLSX.writeFile(book, `${fileName}.${format}`);
  }
}

