import { getUidToken } from '../../utils/auth';
import { BASE_API_URL } from '../../constants';

export const createSeedForField = (companyId: string, fieldId: string, formSubmissionId: string) => {
  if (!formSubmissionId) {
    console.log('No form submission id provided');
    return Promise.resolve(true);
  };

  return getUidToken()
    .then((uidToken) => {
      return fetch(`${BASE_API_URL}/seeds`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${uidToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyId,
          fieldId,
          formSubmissionId,
        }),
      });
    })
    .catch((e) => {
      console.error('Unable to create seed for crop: ', e);
    });
};

export const deleteSeedForField = (companyId: string, fieldId: string) => {
  return getUidToken()
    .then((uidToken) => {
      return fetch(`${BASE_API_URL}/seeds/company/${companyId}/fields/${fieldId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${uidToken}`,
          'Content-Type': 'application/json',
        },
      });
    })
    .catch((e) => {
      console.error('Unable to delete seed for crop: ', e);
    });
};
