import React from 'react';
import Modal from 'react-bootstrap-modal';
import I18n from '../../language/i18n';

const stopPropagation = e => e.stopPropagation();

const UpdatePaymentsMethodModal = (props) => {
  const { show, onClose, children } = props;
  return (
    <Modal
      show={show}
      dialogClassName="update-payment-modal"
      onHide={onClose}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title onClick={stopPropagation}>{I18n.t('billing.updateYourCard')}</Modal.Title>
        <button className="close" onClick={onClose}>
          <i className="fa fa-times" />
        </button>
      </Modal.Header>
      <Modal.Body onClick={(e) => e.stopPropagation()}>
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePaymentsMethodModal;
