type groupParams = {
  street?: string;
  zip?: string;
  city?: string;
};

export const addressTransformerFn = (group: groupParams): string => {
  const street = group.street ?? '';
  const zip = group.zip ?? '';
  const city = group.city ?? '';

  return `${street} ${zip} ${city}`;
};
