import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { CREDENTIALS_PROVIDER_EMAIL, CREDENTIALS_PROVIDER_PHONE } from 'farmerjoe-common/lib/constants/credentialsProviders';
import { SIMPLE_EMAIL_REGEX, INTERNATIONAL_PHONE_NUMBER_REGEX } from 'farmerjoe-common/lib/constants/validators';

import { LOGIN_SCREEN_EMAIL_AUTH, LOGIN_SCREEN_PHONE_AUTH } from './constants';
import Logo from './Logo';
import LoginWithGoogle from './LoginWithGoogle';
import TextInput from '../Common/TextInput';
import Separator from '../Common/Separator';


import * as profileActions from '../../actions/profile';
import I18n from '../../language/i18n';

const LoginCredentialsProvider = (props) => {
  const { onError, setScreen, actions } = props;
  const [loginData, setLoginData] = useState({
    emailOrPhone: '',
  });

  // TODO constants
  const handleSubmit = (e) => {
    e.preventDefault();
    const isEmail = SIMPLE_EMAIL_REGEX.test(loginData.emailOrPhone);
    if (isEmail) {
      actions.setCredentialsProvider(CREDENTIALS_PROVIDER_EMAIL, { email: loginData.emailOrPhone });
      setScreen(LOGIN_SCREEN_EMAIL_AUTH);
    }

    const isPhone = INTERNATIONAL_PHONE_NUMBER_REGEX.test(loginData.emailOrPhone);
    if (isPhone) {
      actions.setCredentialsProvider(CREDENTIALS_PROVIDER_PHONE, { phone: loginData.emailOrPhone });
      setScreen(LOGIN_SCREEN_PHONE_AUTH);
    }

    if (!isEmail && !isPhone) {
      onError({ alertMessage: I18n.t('invalidEmailOrPhone'),
        alertTitle: I18n.t('error'),
      });
    }
  };

  return (
    <div
      style={{
        ...{
          flex: 1,
          // paddingBottom: bottom,
        },
      }}>
      <div style={{ position: 'relative' }}>
        <Logo />
        <h1 className="text-center mb-4">{I18n.t('login')}</h1>
        <form
          onSubmit={handleSubmit}
          id={'login'}
          className="form-horizontal">
          <TextInput
            placeholder={I18n.t('loginEmailOrPhone')}
            style={{ paddingLeft: 20 }}
            containerClassName={'mt-4 mb-4'}
            autoFocus={true}
            id="email"
            name="email"
            autoCorrect="false"
            autoCapitalize="none"
            onChange={e => setLoginData({...loginData, emailOrPhone: e.target.value })}
          />

          <div
            style={{
              display: 'flex',
            }}>
            <button
              className="btn btn-primary btn-block">
              {I18n.t('continue')}
            </button>
          </div>
        </form>

        <div
          style={{
            margin: '1rem 0',
            display: 'flex',
          }}>
          <Link to="/register" style={{display: 'block', width: '100%'}}>
            <div className="btn btn-default btn-block">
              {I18n.t('createAccount')}
            </div>
          </Link>
        </div>
      </div>
      <Separator style={{ width: '100%', marginBottom: '1rem' }} />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <LoginWithGoogle />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...profileActions,
        },
      ),
      dispatch,
    ),
  };
};

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(LoginCredentialsProvider);
