import React from 'react';
import SectionList from '../Common/SectionList';
import CropHeaderContainer from '../Common/CropHeaderContainer';
import * as constants from '../../styles/style';
import Icon from '../Common/Icon';
import AnalysesListItemField from './AnalysesListItemField';

// TODO: improve typings
type Props = any;
type State = any;

export default class AnalysesListItem extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      open: {},
    };
  }

  render() {
    const { item } = this.props;

    return (
      <SectionList
        renderItem={item => this._renderField(item)}
        renderSectionHeader={({ section }) => this._renderCropHeader(section)}
        sections={[item]}
        stickySectionHeadersEnabled={true}
      />
    );
  }

  _renderSeparator(item) {
    const openKey = `${item.section.title}${item.section.color}`;
    const open = !!this.state.open[openKey];

    if (!open) {
      return null;
    }

    return <hr style={{ marginLeft: 20 }} />;
  }

  _renderField(item) {
    const { selectedAnalysis } = this.props;
    const ana = item.item;
    const openKey = `${item.section.title}${item.section.color}`;
    const expanded = this.state.open[openKey];

    if (!expanded) {
      return null;
    }

    return (
      <AnalysesListItemField
        ana={ana}
        history={this.props.history}
        openCompany={this.props.openCompany}
        selected={selectedAnalysis === ana.key}
      />
    );
  }

  _renderCropHeader(section) {
    const openKey = `${section.title}${section.color}`;
    const open = !!this.state.open[openKey];

    return (
      <div
        onClick={() => {
          this.setState({
            open: {
              ...this.state.open,
              [openKey]: !this.state.open[openKey],
            },
          });
        }}
        style={{ cursor: 'pointer' }}>
        <CropHeaderContainer color={section.color}>
          <span
            style={{
              ...constants.styles.stdSize,
            }}>
            {section.title}
          </span>
          <div
            style={{
              display: 'flex',
              paddingTop: 10,
              paddingBottom: 4,
              width: 50,
              alignItems: 'center',
              marginRight: 15,
              flexDirection: 'column',
              lineHeight: 1,
            }}>
            <div>
              <span style={{ ...constants.styles.small }}>
                {section.data.length}
              </span>
            </div>
            <div style={{}}>
              {open
                ? (
                  <Icon
                    iconType={'fj'}
                    style={{ fontSize: 10 }}
                    name="arrow_down"
                  />
                )
                : (
                  <Icon
                    iconType={'fj'}
                    style={{ fontSize: 10 }}
                    name="arrow_up"
                  />
                )}
            </div>
          </div>
        </CropHeaderContainer>
      </div>
    );
  }
}
