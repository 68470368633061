import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Loading } from '../Loading/Loading';
import I18n from '../../language/i18n';
import { keyBy, map, orderBy } from 'lodash-es';
import { firestoreConnect } from 'react-redux-firebase';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import * as companySelectors from 'farmerjoe-common/lib/selectors/companies';
import type { ApiLog as ApiLogType, Company } from '../../flowTypes';
import NavbarBasic from '../Common/NavbarBasic';
import './style.css';
import moment from 'moment';
import Icon from '../Common/Icon';
import { getScrollableAncestor } from '../../utils/dom';
import { findDOMNode } from 'react-dom';
import { outputDate } from 'farmerjoe-common';
import { OrderByDirection } from 'farmerjoe-common/lib/flow/types';

type Props = {
  apiLogs?: Array<Record<string, any>>;
  loading?: boolean;
  company?: Company;
  firebase?: Record<string, any>;
};

type State = {
  lastItem?: ApiLogType;
  loadingMore: boolean;
  loading: boolean;
  hasMore: boolean;
  items: Array<ApiLogType>;
  itemsCache: Record<string, ApiLogType>;
  maxDate: Date;
};

const limit = 10;

class ApiLog extends React.Component<Props, State> {
  state: State = {
    lastItem: void 0,
    loading: true,
    loadingMore: false,
    hasMore: false,
    items: [],
    itemsCache: {},
    maxDate: moment(new Date())
      .subtract(1, 'day')
      .toDate(),
  };

  listeners: Array<(...args: Array<any>) => any> = [];
  ancestor?: Element;
  el?: Element;

  constructor(props: Props) {
    super(props);

    this.onAncestorScroll = this.onAncestorScroll.bind(this);
  }

  render() {
    const { items, loading, loadingMore } = this.state;

    let content: any = null;
    if (loading) {
      content = (
        <div>
          <Loading />
        </div>
      );
    } else {
      content = [
        <table className="table table-striped" key="table">
          <thead>
            <tr>
              <th>{I18n.t('date')}</th>
              <th className="result-column">{I18n.t('apiLogs.result')}</th>
              <th>{I18n.t('apiLogs.ipAddress')}</th>
              <th>{I18n.t('apiLogs.method')}</th>
              <th className="execution-time-column">
                {I18n.t('apiLogs.execTime')}
              </th>
              <th>{I18n.t('apiLogs.userAgent')}</th>
              <th className="version-column">{I18n.t('apiLogs.version')}</th>
              <th className="response-column">{I18n.t('apiLogs.response')}</th>
            </tr>
          </thead>
          <tbody>
            {map(
              items,
              (
                {
                  date,
                  response,
                  execution_time_ns,
                  status_code,
                  ip_address,
                  url,
                  user_agent,
                  version,
                  method,
                }: ApiLogType,
                i,
              ) => {
                return (
                  <tr key={i}>
                    <td>{outputDate(date, 'DD.MM.YYYY HH:mm:ss')}</td>
                    <td className="result-column">
                      {status_code >= 200 && status_code < 400
                        ? (
                          <Icon
                            className="request-succeeded"
                            iconType="fa"
                            name="check"
                          />
                        )
                        : (
                          <Icon
                            className="request-failed"
                            iconType="fa"
                            name="times"
                          />
                        )}
                    </td>
                    <td>{ip_address.replace(/:/g, ':\u200B')}</td>
                    <td>{`${method.toUpperCase()} ${url.replace(
                      /\//g,
                      '\u200B/',
                    )}`}</td>
                    <td className="execution-time-column">
                      {(execution_time_ns / 10 ** 9).toFixed(3)} s
                    </td>
                    <td>{user_agent}</td>
                    <td className="version-column">{version}</td>
                    <td className="response-column">{response}</td>
                  </tr>
                );
              },
            )}
            {!items.length
              ? (
                <tr className="no-results">
                  <td colSpan={8}>{I18n.t('apiLogs.noResults')}</td>
                </tr>
              )
              : null}
          </tbody>
        </table>,
        loadingMore
          ? (
            <div key="loading">
              <Loading />
            </div>
          )
          : null,
      ];
    }

    return (
      <div className="api-logs" ref="apiLogs">
        <NavbarBasic title={I18n.t('apiLogs.header')} />

        <div className="wrapper">
          <p>{I18n.t('apiLogs.description')}</p>
          {content}
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { lastItem } = this.state;

    if (!lastItem) {
      this.loadMoreLogs(true);
    }

    this.initScrollWatching();
  }

  initScrollWatching() {
    const el = findDOMNode(this.refs.apiLogs);
    if (!el) {
      return;
    }
    this.el = el;

    const ancestor = getScrollableAncestor(el);
    if (ancestor) {
      ancestor.addEventListener('scroll', this.onAncestorScroll);
      this.ancestor = ancestor as any;
    }
  }

  onAncestorScroll() {
    if (
      this.ancestor &&
      this.el &&
      this.ancestor.getBoundingClientRect().bottom >=
      0.8 * this.el.getBoundingClientRect().bottom
    ) {
      if (this.state.hasMore && !this.state.loading) {
        this.setState(
          {
            loadingMore: true,
          },
          () => this.loadMoreLogs(),
        );
      }
    }
  }

  componentWillUnmount() {
    this.clearListeners();

    if (this.ancestor) {
      this.ancestor.removeEventListener('scroll', this.onAncestorScroll);
    }
  }

  clearListeners() {
    this.listeners.forEach(listener => {
      listener && listener();
    });
    this.listeners = [];
  }

  loadMoreLogs(reset = false) {
    const { firebase, company } = this.props;
    const { lastItem } = this.state;

    if (reset) {
      this.setState({ loading: true, items: [], itemsCache: {} });
      this.clearListeners();
    }

    const db = firebase?.firestore();

    let query = db.collection('apiLogs');

    query = query.where('company_id', '==', company?.key);
    query = query.where('date', '>=', this.state.maxDate);

    query = query.orderBy('date', OrderByDirection.Desc);

    if (!reset && lastItem) {
      query = query.startAfter(lastItem.date);
    }

    query = query.limit(limit);

    const callback = querySnap => {
      const docs = querySnap.docs.map(doc => ({ key: doc.id, ...doc.data() }));
      const docsObject = keyBy(docs, 'key');

      const itemsCache = { ...this.state.itemsCache, ...docsObject };

      querySnap.docChanges().forEach(function(change) {
        // Remove a deleted item from the object
        if (change.type === 'removed') {
          const removedDoc = change.doc.data();
          delete itemsCache[removedDoc.key];
        }
      });

      this.setState({
        hasMore: true,
        loading: false,
        loadingMore: false,
        itemsCache: itemsCache,
        items: orderBy(
          map(itemsCache, value => value),
          item => new Date(item.date),
          [OrderByDirection.Desc],
        ),
      });

      if (docs[docs.length - 1]) {
        this.setState({ lastItem: docs[docs.length - 1] });
      }

      if (docs.length < limit) {
        this.setState({ hasMore: false });
      }
    };

    const onError = error => {
      console.log(error);
      this.setState({ loading: false });
    };

    this.listeners.push(query.onSnapshot(callback, onError));
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = companySelectors.getCompany(state.firestore.data, openCompany);
  return {
    company: company,
  };
};

export default compose<typeof ApiLog>(
  connect(selector),
  firestoreConnect(),
)(ApiLog);
