import React from 'react';
import {
  every,
  find,
  get,
  isEqual,
  keyBy,
  sortBy,
  throttle,
  values,
} from 'lodash-es';
import moize from 'moize';
import sanitizeFilename from 'sanitize-filename';
import { AutoSizer, MultiGrid } from 'react-virtualized';

import { NO_CROP_NAME } from 'farmerjoe-common/lib/constants/crops';
import { getColor } from 'farmerjoe-common/lib/utils/Colors';
import { outputDate } from 'farmerjoe-common';

import type { CellRenderer, Column, Sorting, TableProps } from './flow';

import { Loading } from '../../Loading/Loading';
import {
  DEFAULT_COLUMN_HEIGHT,
  DEFAULT_COLUMN_WIDTH,
  HEADER_ROW_COUNT,
  MIN_COLUMN_WIDTH,
  NOTIFICATION_READ_COLUMN_WIDTH,
} from '../../Fields/Table/constants';
import {
  customSortByProp,
  defaultSortMethod,
  sortByProps,
} from '../../Fields/Table/columns';
import BoniturDataCell from '../../Table/Bonitur/DataCell';
import DataCell from '../../Table/DataCell';
import HeaderCell from '../../Table/HeaderCell';
import TableExport from '../../Table/TableExport';
import { FixedCellMeasurerCache } from '../../Table/TableCellMeasurerCache';
import I18n from '../../../language/i18n';
import '../../Table/style.css';

import BlueDot from '../../Common/BlueDot';

export default class Table extends React.PureComponent<TableProps> {
  grid: any;
  startDragColumnWidth = 0;
  isResizingColumn = false;
  tableExport = React.createRef<TableExport>();

  prepareColumns = (formSubmissions, formSchema) => {
    const columns: any[] = [];

    columns.push({
      accessor: (formSubmission) => get(formSubmission, 'read', true),
      id: 'cropRatingNotification',
      sortMethod: () => 0, // XXX: Avoid sorting
      Cell: (read) => {
        if (read) {
          return null;
        }
        return (
          <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <BlueDot />
          </div>
        );
      },

    });

    columns.push({
      accessor: (formSubmission) => get(formSubmission, 'formValues'),
      id: 'cropRatingId',
      Header: 'Crop Rating ID',
      Cell: (values) => (
        <BoniturDataCell
          schemaId={formSchema.key}
          elementId={'uniqueId'}
          values={values}
          renderedBy="bonitur"
        />
      ),
    });


    columns.push({
      accessor: (formSubmission) => { 
        return get(formSubmission, 'meta.field_shared_by.company_name');
      }, 
      id: 'sharedBy',
      Header: () => I18n.t('fieldSharing.fieldInfoModal.header'),
    });

    columns.push({
      accessor: (formSubmission) => {
        const producer = get(formSubmission, 'meta.group_name');
        return producer === 'main' ? '' : producer;
      },
      id: 'producer',
      Header: () => I18n.t('producers.single'),
    });

    columns.push({
      accessor: (formSubmission) => get(formSubmission, 'meta.field_name'),
      id: 'fieldName',
      Header: () => I18n.t('field.name'),
    });
    columns.push({
      accessor: (formSubmission) => get(formSubmission, 'meta'),
      id: 'cropName',
      Header: () => I18n.t('crop.name'),
      Cell: (meta) => {
        let cropName = get(meta, 'crop_name');
        if (cropName === NO_CROP_NAME) {
          cropName = I18n.t('crop.without');
        }
        const cropColor = get(meta, 'crop_color');
        return (
          <>
            <div
              style={{ backgroundColor: getColor(cropColor) }}
              className={'cropTag'}
            >
              {/* <CropIcon crop={crop}/> */}
              {cropName}
            </div>
          </>
        );
      },
      sortMethod: sortByProps('crop_name'),
    });
    columns.push({
      accessor: (formSubmission) => get(formSubmission, 'meta'),
      id: 'cropArt',
      Header: () => I18n.t('crop.variety'),
      Cell: (meta) => {
        const cropSort = get(meta, 'crop_sort');
        const cropColor = get(meta, 'crop_color');
        return (
          <>
            <div
              style={{ backgroundColor: getColor(cropColor) }}
              className={'cropTag'}
            >
              {cropSort}
            </div>
          </>
        );
      },
    });
    columns.push({
      accessor: (formSubmission) => get(formSubmission, 'created'),
      id: 'created_at',
      Header: () => I18n.t('created'),
      Cell: (created) => {
        return outputDate(created, 'DD.MM.YYYY HH:mm');
      },
    });
    columns.push({
      accessor: (formSubmission) => get(formSubmission, 'created_by.name'),
      id: 'created_by',
      Header: () => I18n.t('created_by'),
    });
    sortBy(
      values(get(formSchema, 'schema.elements', {})),
      ['positionInTable', 'position'],
    )
      .filter((element) => get(element, 'options.previewInTable'))
      .forEach((element) => {
        const { name, labelTranslationKey, label, options, inputUnit } =
            element;

        const id = `bonitur:${formSchema.key}:${name}`;
        const isSmileyFaceRating = name === 'smileyFaceRating';
        const isBoniturAge = name === 'boniturAge';
        const isUniqueId = name === 'uniqueId';
        const isYieldStatus = name === 'yieldStatus';
        const header = isBoniturAge
          ? I18n.t(labelTranslationKey)
          : (inputUnit
            ? `${label} (${options.valueLabel || inputUnit})`
            : label) || options.valueLabel;

        if (isUniqueId) {
          return;
        }

        if (isSmileyFaceRating) {
          const sortOrder = ['sad-cry', 'frown', 'meh', 'question', 'smile', 'laugh-beam'];
          columns.push({
            accessor: (formSubmission) => {
              return get(formSubmission, ['formValues']);
            },
            id,
            Header: label,
            Cell: (values) => (
              <BoniturDataCell
                schemaId={formSchema.key}
                elementId={name}
                values={values}
                renderedBy="bonitur"
              />
            ),
            sortMethod: customSortByProp(
              sortOrder,
              'smileyFaceRating.smileyIcon',
            ),
          });
        } else if (isBoniturAge) {
          columns.push({
            accessor: (formSubmission) => get(formSubmission, 'created'),
            id,
            Header: I18n.t(labelTranslationKey) || label,
            Cell: (created) => (
              <BoniturDataCell
                schemaId={formSchema.key}
                elementId={name}
                createdDate={created}
                renderedBy="bonitur"
              />
            ),
          });
        } else if (isYieldStatus) {
          const sortOrder = ['harvested', 'pickup', 'collected'];
          columns.push({
            accessor: (formSubmission) => {
              return get(formSubmission, ['formValues']);
            },
            id,
            Header: label,
            Cell: (values) => (
              <BoniturDataCell
                schemaId={formSchema.key}
                elementId={name}
                values={values}
                renderedBy="bonitur"
              />
            ),
            sortMethod: customSortByProp(
              sortOrder,
              'yieldStatus',
            ),
          });
        } else {
          columns.push({
            accessor: (formSubmission) => {
              return get(formSubmission, ['formValues']);
            },
            id,
            Header: header,
            Cell: (values) => (
              <BoniturDataCell
                schemaId={formSchema.key}
                elementId={name}
                values={values}
                renderedBy="bonitur"
              />
            ),
            sortMethod: sortByProps(name),
          });
        }
      });
    return columns;
  };

  getColumns() {
    const { formSubmissions, formSchema } = this.props;
    return this.prepareColumns(formSubmissions, formSchema);
  }

  sortBoniturs = moize(
    function sortFields(sorting: Sorting, columns, formSubmissions) {
      const columnsById = keyBy(columns, 'id');
      let secondLevelSort = (formSubmissions) => formSubmissions;
      if (sorting.length > 0) {
        const { id: sortingColumnId, desc: sortingColumnDesc } = sorting[0];
        const column = columnsById[sortingColumnId];
        if (column) {
          const sortMethod = column.sortMethod || defaultSortMethod;
          secondLevelSort = (formSubmissions) =>
            formSubmissions.sort((a, b) =>
              sortMethod(
                column.accessor(a),
                column.accessor(b),
                sortingColumnDesc,
              ),
            );
        }
      }
      return secondLevelSort(formSubmissions);
    },
    {
      maxSize: 1,
      matchesKey: (newArgs, oldArgs) => {
        return (
          newArgs[2] === oldArgs[2] &&
          isEqual(newArgs[0], oldArgs[0]) &&
          isEqual(newArgs[1], oldArgs[1])
        );
      },
    },
  );

  getRows() {
    const { formSubmissions } = this.props;
    const sorting = this.getSorting();
    return this.sortBoniturs(sorting, this.getColumns(), formSubmissions);
  }

  sorting = moize(
    function sorting(sortingArray, columnsArray): Sorting {
      const columns = keyBy(columnsArray, 'id');
      // remove any sorting columns that don't exist in the current columns
      if (!every(sortingArray, (s) => columns[s.id])) {
        return sortingArray.filter((s) => columns[s.id]);
      }
      return sortingArray;
    },
    {
      maxSize: 1,
      isDeepEqual: true,
    },
  );

  getSorting() {
    const { tableState } = this.props;
    return this.sorting(tableState.sorting, this.getColumns());
  }

  sortingIndexed = moize((sorting) => keyBy(sorting, 'id'), {
    maxSize: 1,
  });

  getSortingIndexed() {
    return this.sortingIndexed(this.getSorting());
  }

  sizeCache = new FixedCellMeasurerCache({
    defaultHeight: DEFAULT_COLUMN_HEIGHT,
    defaultWidth: DEFAULT_COLUMN_WIDTH,
    fixedHeight: true,
    fixedWidth: true,
    columnWidths: this.getColumnWidths(),
    keyMapper: (rowIndex, columnIndex) => {
      if (rowIndex < HEADER_ROW_COUNT) {
        return 'header-' + rowIndex + '-' + columnIndex;
      } else {
        const row = this.getRows()[rowIndex - HEADER_ROW_COUNT];
        if (row) {
          return row.key + '--' + columnIndex;
        } else {
          return rowIndex + '--' + columnIndex;
        }
      }
    },
  });

  getColumnWidths() {
    return this.getColumns().map(
      (c) => {
        if (c.id === 'cropRatingNotification') {
          return NOTIFICATION_READ_COLUMN_WIDTH;
        }
        return get(this.props.tableState, `columnWidths.${c.id}`) ||
        DEFAULT_COLUMN_WIDTH;
      });
  }

  componentDidUpdate(prevProps: TableProps) {
    if (
      !isEqual(
        get(this.props, 'tableState.columnIds'),
        get(prevProps, 'tableState.columnIds'),
      ) ||
      !isEqual(
        get(this.props, 'tableState.columnWidths'),
        get(prevProps, 'tableState.columnWidths'),
      ) ||
      (!this.props.loading && prevProps.loading)
    ) {
      this.sizeCache.setWidths(this.getColumnWidths());
    }

    if (
      this.grid &&
      (this.props.formSubmissions !== prevProps.formSubmissions ||
        this.props.formSchema !== prevProps.formSchema ||
        !isEqual(
          get(this.props, 'tableState.sorting'),
          get(prevProps, 'tableState.sorting'),
        ) ||
        !isEqual(
          get(this.props, 'tableState.columnWidths'),
          get(prevProps, 'tableState.columnWidths'),
        ) ||
        !isEqual(
          get(this.props, 'tableState.columnIds'),
          get(prevProps, 'tableState.columnIds'),
        ) ||
        (!this.props.loading && prevProps.loading))
    ) {
      this.grid.recomputeGridSize();
    }
  }

  render() {
    const { loading, emptyView, ...restProps } = this.props;

    if (loading) {
      return <Loading />;
    }

    const rows = this.getRows();

    if (!rows) {
      return null;
    }

    if (!rows.length) {
      return emptyView;
    }

    return (
      <div className="table-container">
        <AutoSizer key={0}>
          {({ width, height }) => (
            <MultiGrid
              ref={(el) => (this.grid = el)}
              cellRenderer={this.renderCell}
              columnCount={this.getColumns().length}
              rowCount={rows.length + HEADER_ROW_COUNT}
              columnWidth={this.sizeCache.columnWidth}
              rowHeight={(this.sizeCache as any).rowHeight}
              width={width}
              height={height}
              fixedRowCount={HEADER_ROW_COUNT}
              fixedColumnCount={6}
              hideTopRightGridScrollbar
              // for updating the table when some prop changes
              {...restProps}
            />
          )}
        </AutoSizer>
        <TableExport
          ref={this.tableExport}
          cellRenderer={this.renderCell.bind(this)}
          fileName={`farmerjoe-${sanitizeFilename(
            'crop-ratings',
          )}-${new Date().toISOString().split('T')[0]}`}
        />
      </div>
    );
  }

  resizeColumnStart(columnIndex: number) {
    this.startDragColumnWidth = this.sizeCache.columnWidth({
      index: columnIndex,
    });
    this.isResizingColumn = true;
  }

  resizeColumn(deltaX: number, index: number) {
    let columnWidth = this.startDragColumnWidth;
    columnWidth = Math.max(MIN_COLUMN_WIDTH, columnWidth + deltaX);

    this.sizeCache.setWidth(index, columnWidth);

    const columnWidths = {
      ...this.props.tableState.columnWidths,
      [this.getColumns()[index].id]: columnWidth,
    };
    this.sizeCache.setWidths(this.getColumns().map((c) => columnWidths[c.id]));

    this.throttledRecomputeGridSizes();
  }

  throttledRecomputeGridSizes = throttle(() => {
    this.grid.recomputeGridSize();
  }, 16);

  resizeColumnStop(deltaX: number, index: number) {
    setTimeout(() => (this.isResizingColumn = false), 0);

    let columnWidth = this.startDragColumnWidth;
    columnWidth = Math.max(MIN_COLUMN_WIDTH, columnWidth + deltaX);

    this.props.onTableStateChange({
      ...this.props.tableState,
      columnWidths: {
        ...this.props.tableState.columnWidths,
        [this.getColumns()[index].id]: columnWidth,
      },
    });

    // update row sizes
    (this.sizeCache as any).clearAll();
    this.grid.measureAllCells();
  }

  changeSorting(columnId: string, desc: boolean) {
    const sorting = [
      {
        id: columnId,
        desc: desc,
      },
    ];

    this.props.onTableStateChange({
      ...this.props.tableState,
      sorting,
    });
  }

  renderCell = ({ columnIndex, key, rowIndex, style }: CellRenderer) => {
    const { onClick, openFormId } = this.props;
    const rows = this.getRows();
    const columns = this.getColumns();
    const column = columns[columnIndex];
    const row = rows[rowIndex - HEADER_ROW_COUNT];
    const sortingIndexed = this.getSortingIndexed();
    const columnClassName = `column-${column.id}`;

    let cell;
    if (rowIndex < HEADER_ROW_COUNT) {
      cell = (
        <HeaderCell
          key={key}
          column={column}
          columnClassName={columnClassName}
          columnIndex={columnIndex}
          onClick={this.onHeaderClick}
          onStartDrag={this.headerOnStartDrag}
          onDrag={this.headerOnDrag}
          onStopDrag={this.headerOnStopDrag}
          style={style}
          sortingIndexed={sortingIndexed}
        />
      );
    } else {
      const selected = openFormId === row.key;
      cell = (
        <DataCell
          key={key}
          row={row}
          column={column}
          columnClassName={columnClassName}
          columnIndex={columnIndex}
          onClick={onClick as any}
          style={style}
          additionalProps={null as any}
          selected={selected}
        />
      );
    }

    return cell || null;
  };

  onHeaderClick = (column: Column) => {
    if (!this.isResizingColumn && (column as any).sort !== false) {
      const sorting = this.getSorting();
      const existingSorting = find(sorting, (s) => s.id === column.id);
      let desc = false;
      if (existingSorting) {
        desc = !existingSorting.desc;
      }
      this.changeSorting(column.id, desc);
    }
  };

  headerOnStartDrag = (column: Column, columnIndex: number) =>
    this.resizeColumnStart(columnIndex);

  headerOnDrag = (x: number, column: Column, columnIndex: number) =>
    this.resizeColumn(x, columnIndex);

  headerOnStopDrag = (x: number, column: Column, columnIndex: number) =>
    this.resizeColumnStop(x, columnIndex);


  exportData() {
    if (this.tableExport.current) {
      this.tableExport.current.exportData(
        this.getExportColumns().length,
        this.getExportRows().length + 1,
      );
    }
  }

  getExportColumns() {
    const columns = this.getColumns();
    return this.prepareExportColumns(columns);
  }

  prepareExportColumns = moize(
    function prepareExportColumns(columns) {
      return columns.reduce((acc, column) => {
        if (column.textColumns) {
          acc.splice(
            acc.length,
            0,
            ...column.textColumns().map((c) => ({
              Cell: () => null,
              accessor: column.accessor,
              ...c,
            })),
          );
        } else {
          acc.push(column);
        }
        return acc;
      }, []);
    },
    {
      maxSize: 1,
    },
  );

  getExportRows() {
    const rows = this.getRows();
    return this.prepareExportRows(rows);
  }


  prepareExportRows(rows) {
    return rows.filter((row) => !row._group);
  }
}
