import { get } from 'lodash-es';
import moment from 'moment';

import { generateTCombModel } from '../../../utils/analysis/tcomb/model';
import { generateOptions } from '../../../utils/analysis/tcomb/options';

const OPTION = {
  SAMPLE_DATE: 'probe_date',
  RESULT_ON: 'result_on',
};

const DEFAULT_RESULT_IN_DAYS = 3;

const model = configObj => {
  return generateTCombModel(configObj);
};

const options = function(configObj) {
  return generateOptions(configObj);
};

const onChange = (value, formConfig, path: string[] = []) => {
  const { offers } = value;
  const probeDate = get(offers, OPTION.SAMPLE_DATE);
  const resultOn = get(offers, OPTION.RESULT_ON);
  const inDays = get(formConfig, 'result_in_days', DEFAULT_RESULT_IN_DAYS);

  if (path.includes(OPTION.SAMPLE_DATE)) {
    offers.result_on = moment(probeDate)
      .add(inDays, 'days')
      .toDate();

    // Since we haven't invented time-travel, we cannot deliver the results in the past..
    if (moment(probeDate).isBefore(moment())) {
      offers.result_on = moment()
        .add(inDays, 'days')
        .toDate();
    }
  }

  if (path.includes(OPTION.RESULT_ON)) {
    offers.result_on = moment(resultOn).toDate();

    // the result cannot be delivered Before the probe is taken (probeDate).
    if (moment(resultOn).isBefore(moment(probeDate))) {
      offers.result_on = moment(probeDate)
        .add(inDays, 'days')
        .toDate();
    }
  }
  return value;
};

export default { model, options, onChange };
