import React from 'react';
import {
  SUBSCRIPTION_PLAN_2023_ENTERPRISE,
  SUBSCRIPTION_STATE_IN_REVIEW,
} from 'farmerjoe-common/lib/constants/billing';
import SubscriptionPlan from './SubscriptionPlan';
import I18n from '../../language/i18n';
import { TSubscriptionPlan, TSubscription } from './types';

type TProps = {
  currentSubscriptionPlan: string;
  currentSubscription: TSubscription | null;
  onSelect: (arg0: TSubscriptionPlan) => void;
  subscriptionPlans: {
    [key: string]: TSubscriptionPlan;
  };
};

const SubscriptionPlans = (props: TProps) => {
  const { subscriptionPlans, currentSubscriptionPlan, currentSubscription, onSelect } = props;

  const subscriptionInReview = currentSubscription
    ? currentSubscription.state === SUBSCRIPTION_STATE_IN_REVIEW
    : false;
  const currentSubscriptionEnterprise =
    currentSubscriptionPlan === SUBSCRIPTION_PLAN_2023_ENTERPRISE;

  const components = Object.keys(subscriptionPlans)
    .map((key) => subscriptionPlans[key])
    .sort((a, b) => {
      return a.priority - b.priority;
    })
    .map((subscriptionPlan) => {
      const isCurrent = subscriptionPlan.key === currentSubscriptionPlan;
      return (
        <SubscriptionPlan
          key={subscriptionPlan.key}
          subscriptionPlan={subscriptionPlan}
          isCurrent={isCurrent}
          onSelect={() => {
            onSelect(subscriptionPlan);
          }}
          showLabel={!currentSubscriptionEnterprise}
          currentSubscriptionInReview={subscriptionInReview}
          getUpgradeLabel={() => {
            switch(subscriptionPlan.type) {
            case 'free':
              return I18n.t('billing.downgrade');
            case 'paid':
              return I18n.t('billing.upgrade');
            case 'enterprise': 
              return I18n.t('billing.contactUs');
            }
          }}
          style={{
            gridTemplateRows: '2em 1em 5em 3em auto',
          }}
        />
      );
    });

  return (
    <div style={{
      display: 'flex',
      justifyContent:'space-around',
      margin: '3em 1em',
    }}>
      {components}
    </div>
  );
};

export default SubscriptionPlans;
