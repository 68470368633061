import * as React from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Icon from '../../Common/Icon';
import { FJMUTED } from '../../../styles/style';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { getFormSchema } from 'farmerjoe-common/lib/selectors/forms';
import { getFormSchemaQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Forms';
import { get } from 'lodash-es';
import './style.css';
// import type { Comment, FormSchema } from 'farmerjoe-common'
import BoniturFormContent from '../../Bonitur/BoniturFormContent';
import BoniturDialog from '../../Bonitur/BoniturDialog';

type Props = {
  comment: any; // Comment
  formSchema?: any; // FormSchema
  formSchemaId?: string;
};

type State = {
  showModal: boolean;
};

class Bonitur extends React.PureComponent<Props, State> {
  state = {
    showModal: false,
  };

  render() {
    const { comment, formSchema } = this.props;
    const extraData = comment.extraData;
    const icon = (
      <Icon
        name="analytics"
        iconType={'fal'}
        style={{ fontSize: 22, color: FJMUTED }}
      />
    );
    const name = get(extraData, 'name', '');

    return (
      <React.Fragment>
        <div className="bonitur-container">
          <div className="comment-icon-header">
            <div className="line left">
              <hr />
            </div>
            <div>{icon}</div>
            <div className="line right">
              <hr />
            </div>
          </div>
          <div className="d-flex justify-content-center flex-row">
            <h3>Bonitur {name}</h3>
          </div>
          <div
            className="d-flex flex-row justify-content-center comment-bonitur-preview"
            onClick={() => this.setState({ showModal: true })}>
            <BoniturFormContent
              formSchema={formSchema}
              values={get(comment, ['extraData', 'values'], {})}
              previewFor={'previewInComment'}
              renderedBy="comment"
            />
          </div>
        </div>

        {this.state.showModal
          ? (
            <BoniturDialog
              formId={comment.foreign_id}
              formSchemaId={formSchema.key}
              onClose={() => this.setState({ showModal: false })}
            />
          )
          : null}
      </React.Fragment>
    );
  }
}

const selector = (state, ownProps) => {
  const comment = ownProps.comment;
  const formSchemaId = get(comment, 'extraData.schema_id');
  const formSchema = getFormSchema(state, formSchemaId);

  return {
    comment: ownProps.comment,
    formSchema,
    formSchemaId,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, {}), dispatch),
  };
}

export default compose<typeof Bonitur>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  firestoreConnect(props => {
    const { formSchemaId } = props;

    if (formSchemaId) {
      return [getFormSchemaQuery(formSchemaId)];
    }

    return [];
  }),
)(Bonitur);
