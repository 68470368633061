export const FJNEWGREEN = '#7EB549';

export const FJAPPLEBLUE = '#039be5';
export const FJAPPLETEALBLUE = 'rgb(90,200,250)';
export const FJWHITE = '#FFFFFF';
export const FJNAVCOLOR = FJNEWGREEN;
export const FJNAVCOLORSECONDARY = 'rgb(0,122,255)';
export const FJAPPLEGREEN = 'rgb(97,175,60)';
export const FJAPPLERED = 'rgb(197,53,32)';
export const FJAPPLEORANGE = 'rgb(255,149,0)';
export const FJNAVBACKGROUND = '#F9F9F9';
export const FJBACKGROUNDLIGHT = '#f9f9f9';
export const FJBORDERCOLOR = '#C8C8C8';
export const FJBORDERCOLORLIGHT = '#EEEEEE';
export const FJBACKGROUND = '#E5E5E5';
export const FJNOCROP = '#EEEEEE';
export const FJMUTEDWHAT = 'rgb(41,38,39)';
export const FJGREEN = '#61AF3C';
export const FJMUTED = '#636363';
export const FJMUTEDDARK = '#4A4A4A';
export const FJMUTEDLIGHT = '#8F8E94';
export const FJMUTEDLIGHTER = '#B9BCBE';
export const FJBLACK = '#000000';
export const FJBROWN = '#6D635D';
export const FJORANGE = '#D3691E';
export const FJYELLOW = '#ECC95F';
export const FJGREY = '#707274';

export const FJLABEL = '#CCCCCC';
export const FJERROR = '#d50000';

export const styles = Object.freeze({
  stdSize: {
    fontSize: 18,
  },
  big: {
    fontSize: 20,
  },
  extraBig: {
    fontSize: 24,
  },
  extraSmall: {
    fontSize: 14,
  },
  containerColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column' as 'column',
    position: 'relative' as 'relative',
    minHeight: 1,
  },
  containerMap: {
    height: 100,
  },
  containerRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row' as 'row',
  },
  row: {
    paddingBottom: 10,
    paddingRight: 15,
  },
  lastRow: {
    borderBottomWidth: 0,
  },
  /**
   * Used in list with buttons
   */
  buttonRow: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 12,
    paddingBottom: 12,
  },
  strong: {
    fontWeight: 'bold' as 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  muted: {
    color: FJMUTEDDARK,
  },
  mutedLight: {
    color: FJMUTED,
  },
  mutedWhat: {
    color: FJMUTEDWHAT,
  },
  small: {
    fontSize: 16,
  },
  alignRight: {
    textAlign: 'right' as 'right',
  },
  alignLeft: {
    textAlign: 'left' as 'left',
  },
  alignCenter: {
    textAlign: 'center' as 'center',
  },
  btn: {
    borderColor: FJBORDERCOLOR,
    borderWidth: 1,
    padding: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
    backgroundColor: FJNAVBACKGROUND,
    color: FJNAVCOLOR,
  },
  buttonMenu: {
    display: 'flex',
    padding: 15,
    paddingLeft: 15,
    flex: 1,
    flexDirection: 'row' as 'row',
    borderBottomColor: FJBORDERCOLORLIGHT,
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonMenuWithIcon: {
    display: 'flex',
    paddingTop: 12,
    paddingBottom: 12,
    flex: 1,
    flexDirection: 'row' as 'row',
    borderBottomColor: FJBORDERCOLORLIGHT,
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  buttonMenuText: {
    fontSize: 18,
    color: FJNAVCOLOR,
  },
  buttonMenuTextWithIcon: {
    fontSize: 20,
    color: FJMUTEDWHAT,
  },
  inputField: {
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    marginBottom: 10,
    borderColor: FJBORDERCOLOR,
    borderWidth: 1,
  },
  backButtonTextStyle: {
    color: FJNAVCOLORSECONDARY,
    textAlign: 'left' as 'left',
    fontSize: 17,
  },
  titleStyle: {
    color: FJMUTEDDARK,
    fontSize: 18,
    fontWeight: 'bold' as 'bold',
  },
  box: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: FJBORDERCOLOR,
    paddingTop: 10,
    paddingBottom: 10,
    padding: 20,
    backgroundColor: '#FFFFFF',
  },
  boxHeader: {
    color: FJMUTEDDARK,
    textAlign: 'center' as 'center',
    fontWeight: 'bold' as 'bold',
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 18,
  },
  boxBtn: {
    borderColor: FJBORDERCOLOR,
    borderWidth: 1,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  boxBtnDanger: {
    backgroundColor: '#FE3824',
    borderWidth: 0,
  },
  boxBtnText: {
    color: FJNAVCOLOR,
    textAlign: 'center' as 'center',
    fontSize: 18,
  },
  boxBtnTextWhite: {
    color: '#FFF',
  },
  btnDanger: {
    color: FJAPPLERED,
  },
  btnSuccess: {
    backgroundColor: FJAPPLEGREEN,
  },
  navBar: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
  },
  // In some situations we need a margin from the top nav.
  // on ios the nav is 64px on android 54
  marginTopOs: {
    marginTop: 54,
  },
  danger: {
    color: '#fc4b6c',
  },
  headerText: {
    fontSize: 25,
  },
});
