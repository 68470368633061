import React from 'react';
import stylesheet from '../stylesheets/style';

import I18n from '../../language/i18n';
import t from 'tcomb-form';
import FormInput from '../templates/FormInput';
import CountryTel from '../templates/Country/CountryTel';
import Email from '../validators/email';
import Tel from '../validators/tel';

// Our form model
const model = () => {
  return t.struct({
    firstName: t.String,
    lastName: t.String,
    email: Email,
    phoneNumber: Tel,
    message: t.String,
  });
};

const options = function(component) {
  return {
    template: (locals) => templateForm(locals),
    stylesheet: stylesheet,
    fields: {
      firstName: {
        label: I18n.t('first_name') + ' *',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () =>
          component.refs.form.getComponent('lastName').refs.input.focus(),
      },
      lastName: {
        label: I18n.t('last_name') + ' *',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () =>
          component.refs.form.getComponent('email').refs.input.focus(),
      },
      email: {
        label: I18n.t('email') + ' *',
        returnKeyType: 'next',
        blurOnSubmit: false,
        keyboardType: 'email-address',
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () =>
          component.refs.form.getComponent('phoneNumber').refs.input.focus(),
      },
      phoneNumber: {
        label: I18n.t('phone') + '*',
        placeholder: `${I18n.t('phones.enterNumber')} *`,
        factory: CountryTel,
      },
      message: {
        label: I18n.t('billing.message') + '*',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        config: {
          numberOfLines: 3,
          multiline: true,
          placeholder: 'Tell us more...',
        },
      },
    },
  };
};

const templateForm = (locals) => {
  return (
    <div>
      <div style={{display: 'flex'}}>
        {locals.inputs.firstName}
        {locals.inputs.lastName}
      </div>
      {locals.inputs.email}
      {locals.inputs.phoneNumber}
      {locals.inputs.message}
    </div>
  );
};

export default { model, options };
