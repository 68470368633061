import React from 'react';
import './style.css';
import Icon from '../../Common/Icon';
import type { Comment } from '../../../flowTypes';
import { FJMUTED } from '../../../styles/style';

type Props = {
  comment: Comment;
};

const File = (props: Props) => {
  const { comment } = props;
  const url = comment.file.uri;

  return (
    <div>
      <Icon
        name={'file-pdf'}
        key="file-pdf"
        iconType={'fal'}
        style={{ color: FJMUTED, fontSize: 18 }}
      />
      <a href={url} referrerPolicy="no-referrer" target="_blank" rel="nofollow, noreferrer">
        <div className={'comment-file-text'}>{String(comment.text)}</div>
      </a>
    </div>
  );
};

export default File;
