import React, { Component } from 'react';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as profileActions from '../../actions/profile';

import firebase from '../../data/firebase';

import I18n, { tWithComponent } from '../../language/i18n';
import withRouter from '../Router/withRouter';
import Page from '../Page/Page';
import TextInput from '../Common/TextInput';
import CountryTelInput from '../Common/Country/CountryTelInput';
import { validate } from 'tcomb-validation';
import t from 'tcomb-form';
import './style.css';
import Email from '../../tcomb/validators/email';
import Tel from '../../tcomb/validators/tel';
import { LoadingIcon } from '../Loading/Loading';
import Icon from '../Common/Icon';
import { FJBROWN } from '../../styles/style';
import Logo from './Logo';
import ShowHidePassword from './ShowHidePassword';
import { KeyCodes } from '../../flowTypes';
import { MIN_PASSWORD_LENGTH } from 'farmerjoe-common/lib/constants/registration';

// TODO: improve typings
type Props = any;
type State = any;

class Register extends Component<Props, State> {
  state = {
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    terms: false,
    registrationError: null,
    showBottom: true,
    errors: {} as any,
    isInProgress: false,
    passwordType: true,
  };

  constructor(props: Props, context?) {
    super(props, context);
    this.Input = this.Input.bind(this);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const previousOwner = prevProps.owner;
    const currentOwner = this.props.owner;

    if (
      previousOwner.error !== currentOwner.error &&
      currentOwner.error === true
    ) {
      if (currentOwner.errorMessage) {
        this.setState({ registrationError: currentOwner.errorMessage });
      }
    }

    if (firebase.auth().currentUser) {
      this.props.history.push('/');
    }
  }

  register() {
    if (this.validate()) {
      this.setState({ isInProgress: true });
      this.validateEmail()
        .then(() => {
          return this.props.actions.register(
            this.state.email,
            this.state.password,
            {
              displayName: `${this.state.firstname} ${this.state.lastname}`,
              firstname: this.state.firstname,
              lastname: this.state.lastname,
              phoneNumber: this.state.phoneNumber,
            },
          );
        })
        .then(
          () => this.setState({ isInProgress: false }),
          () => this.setState({ isInProgress: false }),
        );
    }
  }

  validate() {
    function mySubType(type, getValidationErrorMessage, name?) {
      const Subtype = t.refinement(
        type,
        function(x) {
          return !t.String.is(getValidationErrorMessage(x));
        },
        name,
      );
      Subtype.getValidationErrorMessage = getValidationErrorMessage;
      return Subtype;
    }

    const email = mySubType(t.String, function(s) {
      if (!s) {
        return '';
      }
      if (!Email.is(s)) {
        return I18n.t('invalid_email');
      }
    });

    const nonEmptyString = mySubType(t.String, function(s) {
      if (!s) {
        return '';
      }
      // TODO: not all code paths return value, probably bug:
      // we need to return actual value instead of undefined
      return void 0;
    });

    const password = mySubType(t.String, function(s) {
      if (!s) {
        return '';
      }
      if (s.length < MIN_PASSWORD_LENGTH) {
        return I18n.t('password.minXchars', { length: MIN_PASSWORD_LENGTH });
      }
    });

    // const terms = mySubType(t.Boolean, function (s) {
    //   if (s !== true) {
    //     return I18n.t('registerScreen.youMustAgreeToTerms')
    //   }
    // })

    const phoneNumber = mySubType(t.maybe(t.String), function(s) {
      if (!s) {
        return '';
      }

      if (!Tel.is(s)) {
        return I18n.t('invalid_phone_number');
      }
    });

    const result = validate(
      this.state,
      t.struct({
        email: email,
        firstname: nonEmptyString,
        lastname: nonEmptyString,
        phoneNumber: phoneNumber,
        password: password,
        // terms:       terms
      }),
    );

    this.setState({
      errors: result.errors.reduce((acc, { message, path }) => {
        acc[path[0]] = message;
        return acc;
      }, {}),
    });

    return result.isValid();
  }

  validateEmail() {
    return firebase
      .auth()
      .fetchSignInMethodsForEmail(this.state.email)
      .then(result => {
        if (result.length) {
          this.setState({
            errors: {
              ...this.state.errors,
              email: I18n.t('registerScreen.errorEmailExist'),
            },
          });

          return false;
        }

        return true;
      })
      .catch(error => {
        this.setState({
          errors: {
            ...this.state.errors,
            email: error.message,
          },
        });

        return false;
      });
  }

  render() {
    const disabled = this.state.isInProgress;

    return (
      <Page
        wrapperClassName="d-flex align-content-center justify-content-center flex-column register"
        header={null}
        footer={null}>
        <div className="login-register login-sidebar">
          <div className="login-box card">
            <div className="card-body" style={{ overflowX: 'hidden' }}>
              <Logo />

              <h1 className="text-center mb-4">{I18n.t('register')}</h1>

              <div className="input-row mt-5">
                <this.Input
                  name="firstname"
                  placeholder={I18n.t('first_name')}
                  disabled={disabled}
                  containerClassName="firstname-container"
                />
                <this.Input
                  name="lastname"
                  placeholder={I18n.t('last_name')}
                  disabled={disabled}
                  containerClassName="lastname-container"
                />
                <Tooltip>{I18n.t('registerScreen.useRealName')}</Tooltip>
              </div>

              <div className="input-row">
                <CountryTelInput
                  editable={!disabled}
                  value={this.state.phoneNumber}
                  format={'E.164'}
                  placeholder={I18n.t('phones.enterNumber')}
                  onChange={({ value }) =>
                    this.setState({ phoneNumber: value })
                  }
                  floatingLabels={false}
                  hasError={this.state.errors.phoneNumber !== void 0}
                  error={this.state.errors.phoneNumber}
                  countryListStyle={{ flex: 1 }}
                />
                <Tooltip>{I18n.t('registerScreen.weUsePhone')}</Tooltip>
              </div>
              {this.state.errors.phoneNumber
                ? (
                  <div className="error-block small">
                    {this.state.errors.phoneNumber}
                  </div>
                )
                : null}

              <div className="input-row">
                <this.Input
                  name="email"
                  placeholder={I18n.t('registerScreen.enterEmail')}
                  disabled={disabled}
                  onChange={e =>
                    this.setState({
                      [e.target.getAttribute(
                        'name',
                      )]: e.target.value.toLowerCase(),
                    })
                  }
                />
                <Tooltip>{I18n.t('registerScreen.weUseEmail')}</Tooltip>
              </div>

              <div className="input-row">
                <div style={{ position: 'relative', flex: 1 }}>
                  <this.Input
                    name="password"
                    placeholder={I18n.t('password.password')}
                    disabled={disabled}
                    type={this.state.passwordType ? 'password' : 'text'}
                  />
                  <ShowHidePassword
                    passwordType={this.state.passwordType}
                    onPress={value => {
                      this.setState({ passwordType: value });
                    }}
                  />
                </div>
                <Tooltip>
                  {I18n.t('registerScreen.passwordHelp', { length: MIN_PASSWORD_LENGTH })}
                </Tooltip>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="terms small" style={{ color: FJBROWN }}>
                  {tWithComponent('registerScreen.confrim_agb_data_security', [
                    <a
                      key="confirm_agb_data_security_agb"
                      style={{ textDecorationLine: 'underline' }}
                      href="https://farmerjoe.com/agb.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {I18n.t('agb')}
                    </a>,
                    <a
                      key="confirm_agb_data_security_data_security"
                      style={{ textDecorationLine: 'underline' }}
                      href="https://farmerjoe.com/datenschutz.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {I18n.t('data_guidelines')}
                    </a>,
                    <a
                      key="cookie_policy"
                      style={{ textDecorationLine: 'underline' }}
                      href={'https://farmerjoe.com/cookies.html'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {I18n.t('cookie_policy')}
                    </a>,
                  ])}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 15,
                }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    this.props.history.push('/login');
                  }}
                  disabled={disabled}>
                  {I18n.t('back')}
                </button>

                <button
                  className="btn btn-primary ml-auto"
                  onClick={() => this.register()}
                  disabled={disabled}>
                  {I18n.t('register')}
                </button>
              </div>
            </div>
            {this.state.isInProgress
              ? (
                <div
                  style={{
                    display: 'flex',
                    backgroundColor: 'rgba(255,255,255,0.6)',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    zIndex: 9999,
                  }}>
                  <LoadingIcon style={{ transform: [{ scale: 1.5 }] }} />
                </div>
              )
              : null}
          </div>
        </div>
      </Page>
    );
  }

  Input({ name, onChangeCb, ...rest }: any) {
    const defaultOnChangeCb = e =>
      this.setState({ [e.target.getAttribute('name')]: e.target.value });
    const onChangeFn = onChangeCb || defaultOnChangeCb.bind(this);

    return (
      <TextInput
        id={name}
        name={name}
        autoCorrect="false"
        autoCapitalize="none"
        onChange={onChangeFn}
        onKeyUp={e => {
          if (e.keyCode === KeyCodes.Enter) {
            this.register();
          }
        }}
        value={this.state[name]}
        hasError={this.state.errors[name] !== void 0}
        error={this.state.errors[name]}
        {...rest}
      />
    );
  }
}

function Tooltip({ children }) {
  return (
    <span className="mytooltip tooltip-effect-1">
      <span className="tooltip-item">
        <Icon
          name={'ios-information-circle-outline'}
          style={{ fontSize: 20, marginTop: 3, color: '#7EB549' }}
        />
      </span>
      <span className="tooltip-content clearfix">
        <span className="tooltip-text">{children}</span>
      </span>
    </span>
  );
}

const selector = state => {
  return {
    owner: state.owner,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...profileActions,
        },
      ),
      dispatch,
    ),
  };
}

export default compose(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(Register);
