import React from 'react';
import Icon from '../Common/Icon';
import I18n from '../../language/i18n';

const RequestInvoice = (props) => {
  const { onInvoiceRequest, setLoading } = props;

  return (
    <div>
      <button
        className={'btn btn-primary'}
        style={{
          textTransform: 'uppercase',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        onClick={() => {
          setLoading(true);
          return onInvoiceRequest()
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        <Icon
          name="money-bill"
          iconType="fa"
          style={{marginRight: '3px'}}
        />
        {I18n.t('billing.creditPurchase')}
      </button>
    </div>
  );
};

export default RequestInvoice;
