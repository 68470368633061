import React, { useState } from 'react';
import Icon from '../../Common/Icon';
import { FJMUTED } from '../../../styles/style';
import ImageLightbox from 'react-image-lightbox';

const Label = (props) => {
  const { children, infoUrl, ...attributes } = props;
  const [visible, setVisible] = useState(false);

  if (!children) {
    return null;
  }

  return (
    <div
      style={{
        marginBottom: 5,
        flexDirection: 'row',
        alignItems: 'center',
      }}
      onClick={e => {
        setVisible(true);
        e.stopPropagation();
      }}>
      <label {...attributes}>{children}</label>
      <Icon
        name={'ios-information-circle-outline'}
        iconType={'ion'}
        light
        style={{ color: FJMUTED, paddingLeft: 5, fontSize: 18 }}
      />
      {visible && (
        <ImageLightbox
          mainSrc={infoUrl}
          onCloseRequest={() => setVisible(false)}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
        />
      )}
    </div>
  );
};

export default React.memo(Label);
