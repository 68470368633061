import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';

import Name from './Name';
import withRouter from '../Router/withRouter';
import { Avatar as StaticAvatar } from '../Common/Avatar';
import Avatar from '../../containers/Avatar';
import Profile from '../../containers/Profile/index';

type Props = {
  uid: string;
  name: string;
  history?: Record<string, any>;
  openCompany?: string;
  myCompanyProfile?: Record<string, any>;
  groupId: string;
  producersOn?: boolean;
  avatarUrl?: string;
};

/**
 * Display the user's avatar and name on a single line
 */
const AvatarName = (props: Props) => {
  const [showProfile, setShowProfile] = useState(false);

  const { uid, name, myCompanyProfile, groupId } = props;
  const canViewUsers =
      myCompanyProfile && get(myCompanyProfile, 'views.users', true);

  return [
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        cursor: canViewUsers ? 'pointer' : 'default',
        alignItems: 'center',
      }}
      onClick={
        canViewUsers ? () => setShowProfile(true) : () => {}
      }
      key={0}>
      <div style={{ marginRight: 5 }}>
        {props.avatarUrl ? (
          <StaticAvatar
            downloadUrl={props.avatarUrl}
            size={'x-small'}
            style={{ borderRadius: 30, backgroundColor: '#EEE', lineHeight: 0 }}
            isLoaded={true}
          />
        ) : (
          <Avatar
            uid={uid}
            round={true}
            style={{ backgroundColor: '#EEE', lineHeight: 0 }}
          />
        )}
      </div>
      <Name name={name} groupId={groupId} />
    </div>,
    showProfile
      ? (
        <Profile
          uid={uid}
          groupId={groupId}
          showInDialog={true}
          show={showProfile}
          onClose={() => setShowProfile(false)}
          key={1}
        />
      )
      : null,
  ];
};

const selector = (state) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  return {
    openCompany,
    myCompanyProfile,
  };
};


export default compose<any>(
  connect(selector),
  withRouter,
)(AvatarName);
