import React from 'react';
import styles from './DataRow.module.css';

type DataRowProps = {
  label: string;
  value: any;
  valueOnly: boolean;
  valueLabel?: string;
  tableValueLabel?: string;
  style?: object;
  className?: string;
  valueStyle?: object;
};

/*
  * This component is used to render a row in a table, as well as a row in a the details view (in a comment).
  * the valueOnly prop is used to render only the value, without the label.
  * In conjunction with the tableValueProps, we can specify to show a label for the value in the fields/ boniturs table.
  */

const DataRow = ({
  label,
  value,
  valueOnly = false,
  valueLabel,
  tableValueLabel,
  className,
  style = {},
  valueStyle={},
}: DataRowProps) => {
  if (valueOnly) {
    return (
      <span className={className ? styles[className] : ''} style={{...style, ...valueStyle}}>
        {getValueToRender(value, valueLabel, tableValueLabel)}
      </span>
    );
  }

  return (
    <tr className={valueLabel ? styles.containerSuffix : styles.container}>
      <td style={style} className={className ? styles[className] : ''}>
        {label}
      </td>
      <td style={{...style, ...valueStyle}} className={className ? styles[className] : ''}>
        {value} {valueLabel && valueLabel}
      </td>
    </tr>
  );
};

export default DataRow;

const getValueToRender = (value, valueLabel, tableValueLabel) => {
  if (valueLabel) {
    return `${value} ${valueLabel}`;
  }
  if (tableValueLabel) {
    return `${value} ${tableValueLabel}`;
  }
  return value;
};
