import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getForm, getFormSchema } from 'farmerjoe-common/lib/selectors/forms';
import { firestoreConnect } from 'react-redux-firebase';
import {
  getFormQuery,
  getFormSchemaQuery,
} from 'farmerjoe-common/lib/utils/firestoreRedux/Forms';
import Dialog from '../Dialog/Dialog';
import { get } from 'lodash-es';
import Map from '../Map/Map';
import BoniturFormContent from './BoniturFormContent';
import AvatarName from '../Users/AvatarName';
import { COMPANY_MAIN_GROUP_KEY } from 'farmerjoe-common/lib/selectors/groups';
import { outputDate } from 'farmerjoe-common';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { Loading } from '../Loading/Loading';

type Props = {
  form?: any; /* FormData */
  formSchema?: any; /* FormSchema */
  onClose: () => void;
  loading?: boolean;
  formId?: any;
  formSchemaId?: any;
};

class BoniturDialog extends React.Component<Props> {
  setMapRef=(ref) => {
    this.setState({ mapRef: ref });
  };

  render() {
    const { form, formSchema, onClose, loading } = this.props;
    return (
      <Dialog
        show={true}
        onClose={onClose}
        title={`${get(formSchema, 'name', 'Bonitur')}`}>
        {loading
          ? (
            <Loading />
          )
          : (
            <React.Fragment>
              {form.position
                ? (
                  <Map
                    mapRef={this.setMapRef} filterNotACrop={false} markers={[{ title: form.meta.group_name, position: form.position, type: 'bonitur', key: form.comment_id }]}
                    position={form.position}
                    zoomedIn={true}
                    containerStyle={{ height: '180px' }} />
                )
                : null}
              <BoniturFormContent
                formSchema={formSchema}
                values={form.formValues}
                previewFor={'all'}
                renderedBy="details"
              />
              <div className="bonitur-footer">
                <AvatarName
                  uid={form.created_by.uid}
                  producersOn={true}
                  groupId={
                    form.created_by.group
                      ? form.created_by.group
                      : COMPANY_MAIN_GROUP_KEY
                  }
                  name={form.created_by.name}
                />
                <span className="date-time">
                  {outputDate(form.created, ' HH:mm   DD.MM.YYYY')}
                </span>
              </div>
            </React.Fragment>
          )}
      </Dialog>
    );
  }
}

export default compose<typeof BoniturDialog>(
  connect((state: any, ownProps: any) => ({
    form: getForm(state, ownProps.formId),
    formSchema: getFormSchema(state, ownProps.formSchemaId),
    loading: !hasLoaded(
      [
        getFormQuery(ownProps.formId),
        getFormSchemaQuery(ownProps.formSchemaId),
      ],
      state,
    ),
  })),
  firestoreConnect(props => [
    getFormQuery(props.formId),
    getFormSchemaQuery(props.formSchemaId),
  ]),
)(BoniturDialog);
