import React, { useEffect } from 'react';
import { FJBROWN } from '../../../styles/style';
import Separator from '../../Common/Separator';
import { calculate } from 'farmerjoe-common/lib/utils/math';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { searchForFieldAmongTheUniverse } from 'farmerjoe-common/lib/selectors/fields';
import { getFieldQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';

const MathCalculationHectare = ({ config, formik, size }) => {
  const { name: fieldName, label } = config;
  const { setFieldValue } = formik;
  const {
    options: { calculation, valueLabel, withSeparator, previewInForm = true },
  } = config;
  const { values: formikValues } = formik;

  const result = calculate(calculation, { ...formikValues, size });
  const roundedResult = Math.round(result * 100) / 100;
  const localResult = !isNaN(result) ? Number(roundedResult).toLocaleString() : '-';

  useEffect(() => {
    setFieldValue(fieldName, result);
  }, [fieldName, formikValues, result, setFieldValue]);

  if(!previewInForm) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <div>
          <span style={{ color: FJBROWN }}>
            {label}
          </span>
        </div>
        <div>
          <span style={{ color: FJBROWN, fontWeight: 'bold' }}>
            {localResult}
            &nbsp;
            {valueLabel}
          </span>
        </div>
      </div>
      {withSeparator ? <Separator style={{ marginTop: 15 }} /> : null}
    </div>
  );
};

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const { config } = ownProps;
  const { companyId, fieldId } = config;

  const field = searchForFieldAmongTheUniverse(state, companyId, user.uid, fieldId);

  return {
    size: field?.size,
  };
};

export default compose<typeof MathCalculationHectare>(
  connect(
    selector,
  ),
  firestoreConnect(props => {
    const { config } = props;
    const { companyId, fieldId } = config;
    const paths: any[] = [];

    if (fieldId) {
      paths.push(getFieldQuery(companyId, fieldId));
    }

    return paths;
  }),
)(React.memo(MathCalculationHectare));
