import * as React from 'react';
import { firebaseConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'libphonenumber-js';

import CoverImage from './CoverImage';
import Avatar from './Avatar';
import SelectLanguage from './SelectLanguage';
import ChangePassword from './ChangePassword';
import AccountEdit from './AccountEdit';

import Dialog, { AlertConfirmDialog, AlertDialog } from '../Dialog/Dialog';

import * as constants from '../../styles/style';
import * as profileActions from '../../actions/profile';
import I18n from '../../language/i18n';
import { captureException } from '../../utils/sentry';
import { isEmailCredentials } from '../../utils/auth';

import './style.css';

const DIALOG_CONFIRM_VALUE = 'yes';

type Props = {
  owner?: any; // Profile
  show: boolean;
  onClose?: (value: null) => void;
  uid?: string;
  actions?: any;
  credentialsProvider?: string;
};

type State = {
  showChangePasswordForm: boolean;
  showAccountEditForm: boolean;
  showAccountRemovalDialog: boolean;
  accountRemovalInProgress: boolean;
  alertTitle?: string | null;
  alertMessage?: string | null;
  waitingToDownload?: boolean;
};

class Settings extends React.PureComponent<Props, State> {
  state: State = {
    showChangePasswordForm: false,
    showAccountEditForm: false,
    showAccountRemovalDialog: false,
    accountRemovalInProgress: false,
  };

  onRequestAccountRemovalClick = () => {
    this.setState({ showAccountRemovalDialog: true });
  };

  onConfirmAccountRemoval = () => {
    this.setState({ accountRemovalInProgress: true });

    this.props.actions
      .requestAccountRemoval('WEB')
      .then(() => {
        this.setState({
          alertTitle: I18n.t('accountRemoval.deleteAccount'),
          alertMessage: I18n.t('accountRemoval.requestSent'),
          accountRemovalInProgress: false,
        });
      })
      .catch((e) => {
        this.setState({
          alertTitle: I18n.t('error'),
          alertMessage: e.message,
          accountRemovalInProgress: false,
        });
        captureException(e);
      });
  };

  render() {
    const { owner, show, onClose, credentialsProvider } = this.props;

    return (
      <Dialog
        show={show}
        onClose={onClose}
        className={'settings-dialog'}
        title={I18n.t('account.account')}
        footer={
          <div>
            <button
              disabled={this.state.accountRemovalInProgress}
              className={'btn btn-danger'}
              onClick={this.onRequestAccountRemovalClick}>
              {I18n.t('accountRemoval.deleteAccount')}
            </button>
            {isEmailCredentials(credentialsProvider)
              ? (
                <button
                  className={'btn btn-secondary'}
                  onClick={() => this.setState({ showChangePasswordForm: true })}>
                  {I18n.t('password.change')}
                </button>
              )
              : null
            }
            <button
              className={'btn btn-secondary'}
              onClick={() => this.setState({ showAccountEditForm: true })}>
              {I18n.t('account.edit')}
            </button>{' '}
          </div>
        }>
        <div className="settings">
          <div className="cover-avatar-container">
            <CoverImage />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: -50,
                flexDirection: 'column',
              }}>
              <Avatar />
            </div>
          </div>
          <div
            className={''}
            style={{
              borderBottomWidth: 1,
              borderBottomColor: constants.FJBORDERCOLOR,
            }}>
            <div
              className={
                'd-flex flex-column align-items-center justify-content-center pb-3'
              }>
              <div style={{ fontSize: 22 }}>{owner.displayName}</div>
              <div className="text-muted">
                {owner.phoneNumber
                  ? format(owner.phoneNumber, 'INTERNATIONAL')
                  : null}
              </div>
              <div className="text-muted">
                {owner.email ? owner.email : null}
              </div>
            </div>
          </div>
          <div>
            <div className={'text-muted'}>{I18n.t('language')}</div>
            <SelectLanguage />
          </div>
          <ChangePassword
            show={this.state.showChangePasswordForm}
            onClose={() => this.setState({ showChangePasswordForm: false })}
          />
          <AccountEdit
            show={this.state.showAccountEditForm}
            onClose={() => this.setState({ showAccountEditForm: false })}
          />
        </div>

        <AlertConfirmDialog
          show={this.state.showAccountRemovalDialog}
          onClose={(value) => {
            if (value === DIALOG_CONFIRM_VALUE) {
              this.onConfirmAccountRemoval();
            }
            this.setState({ showAccountRemovalDialog: false });
          }}
          buttons={[
            {
              value: 'no',
              label: I18n.t('cancel'),
              className: 'btn-secondary',
            },
            {
              value: DIALOG_CONFIRM_VALUE,
              label: I18n.t('yes'),
              className: 'btn-primary',
              disabled: this.state.waitingToDownload,
            },
          ]}
          title={I18n.t('accountRemoval.deleteAccount')}>
          {I18n.t('accountRemoval.areYouSure')}
        </AlertConfirmDialog>

        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
          children={this.state.alertMessage}
          key="alert"
        />
      </Dialog>
    );
  }
}

const wrappedSettings = firebaseConnect((props) => {
  return [];
})(Settings);

const selector = (state) => {
  return {
    owner: state.firebase.profile,
    credentialsProvider: state.credentialsProvider.provider,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...profileActions,
        },
      ),
      dispatch,
    ),
  };
}

// $FlowFixMe
export default (connect(selector, mapDispatchToProps)(wrappedSettings) as any as (typeof Settings));
