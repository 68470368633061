import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { get } from 'lodash-es';
import { classes, findAncestor, getScrollableAncestors } from '../../utils/dom';
import './style.css';
import * as ReactDOM from 'react-dom';

type Offset = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

class _DropDownMenu extends React.Component<{
  children?: React.ReactNode;
  className?: string;
  handleClickOutside?: (...args: Array<any>) => any;
  style?: React.CSSProperties;
  menuProps?: Record<string, any> | null | undefined;
}> {
  render() {
    const { children, className, style, menuProps } = this.props;
    return (
      <div
        className={classes('dropdown-menu', className)}
        key={1}
        style={style}
        {...menuProps}>
        {children}
      </div>
    );
  }
}

const DropDownMenu = onClickOutside(_DropDownMenu);

type Props = {
  children?: React.ReactNode;
  button?: React.ReactNode;
  onHide?: (...args: Array<any>) => any;
  onShow?: (...args: Array<any>) => any;
  tagName?: string;
  className?: string;
  menuClassName?: string;
  menuPosition?: string;
  menuAnimation?: string | null;
  show?: boolean | null;
  showInPortal?: boolean;
  portalClass?: string;
  showMenuWhereClicked?: boolean;
  offsetToAncestor?: {
    left: number;
    right: number;
    bottom: number;
    top: number;
  };
  sameWidthAsAncestor?: boolean;
  menuProps?: Record<string, any> | null | undefined;
  // from onClickOutside
  outsideClickIgnoreClass?: any;
  eventTypes?: any;
  preventDefault?: any;
  stopPropagation?: any;
  disableOnClickOutside?: any;
  enableOnClickOutside?: any;
};

type State = {
  offsetToAncestor: Offset | null;
  showPortal: boolean;
  showMenu: boolean;
};

class DropDown extends React.Component<Props, State> {
  static defaultProps = {
    tagName: 'div',
    className: '',
    menuPosition: 'dropdown-menu-right',
    menuAnimation: 'scale-up',
    menuClassName: '',
  };

  portalContainer?: HTMLElement;

  animatingTill = 0;
  state = {
    offsetToAncestor: this.props.offsetToAncestor || null,
    showPortal: false,
    showMenu: false,
  };

  constructor(props) {
    super(props);
    this.updatePosition = this.updatePosition.bind(this);
  }

  componentWillUnmount() {
    if (this.props.showInPortal) {
      if (this.portalContainer && this.portalContainer.parentNode) {
        this.portalContainer.parentNode.removeChild(this.portalContainer);
      }
      getScrollableAncestors(this.refs.dropdown as any).forEach(el =>
        el.removeEventListener('scroll', this.updatePosition),
      );
    }
  }

  handleClickOutside(e: React.SyntheticEvent<any>) {
    // don't interpret clicks inside dropdown button as clicks outside
    if (
      e.target === this.refs.dropdown ||
      findAncestor((e.target as any), el => el === this.refs.dropdown)
    ) {
      return;
    }
    if (this.state.showMenu) {
      this.toggle(false);
    }
  }

  isAnimating() {
    return new Date().getTime() < this.animatingTill;
  }

  toggle(state: boolean | null | undefined) {
    const isBeingShown = state != null ? state : !this.state.showMenu;

    if (isBeingShown && this.props.showInPortal && !this.portalContainer) {
      const { portalClass } = this.props;
      // create portal container for dropdown
      const portalContainer = document.createElement('div');
      portalContainer.className = 'drop-down-portal ' + portalClass;
      document.body.appendChild(portalContainer);
      this.portalContainer = portalContainer;

      // wait for next render and then try again
      this.setState(
        {
          showPortal: true,
        },
        () => {
          setTimeout(() => {
            this.toggle(isBeingShown);
          }, 0);
        },
      );
      return;
    } else {
      this.setState({
        showMenu: isBeingShown,
      });
    }

    if (this.portalContainer) {
      this.portalContainer.classList.toggle('show', isBeingShown);
    }

    // listen to scroll events to match position of dropdown in a portal with parent's content
    if (this.props.showInPortal && isBeingShown) {
      getScrollableAncestors(this.refs.dropdown as any).forEach(el =>
        el.addEventListener('scroll', this.updatePosition),
      );
    } else if (this.props.showInPortal && !isBeingShown) {
      getScrollableAncestors(this.refs.dropdown as any).forEach(el =>
        el.removeEventListener('scroll', this.updatePosition),
      );
    }

    this.animatingTill = new Date().getTime() + 300;

    if (isBeingShown && !!this.props.onShow) {
      this.props.onShow();
    } else if (!isBeingShown && !!this.props.onHide) {
      this.props.onHide();
    }
  }

  componentDidMount() {
    if (this.props.show != null) {
      this.toggle(this.props.show);
    }
  }

  componentWillUpdate(nextProps: Props) {
    if (nextProps.show != null && this.props.show !== nextProps.show) {
      this.toggle(nextProps.show);
    }
  }

  getStyleForPosition(offsetToAncestor: Offset) {
    const { left, top, right, bottom } = offsetToAncestor;
    const style = { top: 'auto', bottom: 'auto', left: 'auto', right: 'auto' };
    const {
      menuPosition,
      showMenuWhereClicked,
      sameWidthAsAncestor,
    } = this.props;
    const ancestor = showMenuWhereClicked
      ? (this.refs.dropdown as any).parentNode
      : this.refs.dropdown;
    const {
      left: ancestorLeft,
      top: ancestorTop,
      right: ancestorRight,
      bottom: ancestorBottom,
    } = ancestor.getBoundingClientRect();

    if (
      menuPosition === 'dropdown-menu-bottom-left' ||
      menuPosition === 'dropdown-menu-bottom-right'
    ) {
      style.bottom = `${window.innerHeight - (ancestorBottom - bottom)}px`;
    } else if (
      menuPosition === 'dropdown-menu-left' ||
      menuPosition === 'dropdown-menu-right'
    ) {
      style.top = `${ancestorTop + top}px`;
    }

    if (
      menuPosition === 'dropdown-menu-right' ||
      menuPosition === 'dropdown-menu-bottom-right' ||
      sameWidthAsAncestor
    ) {
      style.right = `${window.innerWidth - (ancestorRight - right)}px`;
    }
    if (
      menuPosition === 'dropdown-menu-left' ||
      menuPosition === 'dropdown-menu-bottom-left' ||
      sameWidthAsAncestor
    ) {
      style.left = `${ancestorLeft + left}px`;
    }

    return style;
  }

  updatePosition() {
    if (this.state.offsetToAncestor && this.state.showMenu) {
      this.forceUpdate();
    }
  }

  render() {
    const {
      children,
      button,
      tagName,
      className,
      menuPosition,
      menuAnimation,
      menuClassName,
      show,
      onHide,
      onShow,
      showInPortal,
      showMenuWhereClicked,
      offsetToAncestor,
      sameWidthAsAncestor,
      menuProps,
      outsideClickIgnoreClass,
      eventTypes,
      preventDefault,
      stopPropagation,
      disableOnClickOutside,
      enableOnClickOutside, // from onClickOutside, ignore
      ...restProps
    } = this.props;

    // $FlowFixMe
    return [
      React.createElement(
        tagName as any,
        {
          ...restProps,
          ref: 'dropdown',
          className: classes(
            'dropdown',
            this.state.showMenu && 'show',
            className,
          ),
          key: 0,
        },
        [
          button ? this.renderButton() : null,
          !showInPortal ? this.renderMenu() : null,
        ],
      ),
      showInPortal && this.state.showPortal
        ? ReactDOM.createPortal(
          this.renderMenu('show-where-clicked'),
          this.portalContainer,
        )
        : null,
    ];
  }

  renderMenu(className?: string) {
    const {
      children,
      menuPosition,
      menuAnimation,
      menuClassName,
      menuProps,
    } = this.props;
    return (
      <DropDownMenu
        className={classes(
          menuPosition,
          menuAnimation,
          menuClassName,
          className,
          this.state.showMenu && 'show',
        )}
        handleClickOutside={e => this.handleClickOutside(e)}
        style={
          this.props.showInPortal && this.state.offsetToAncestor
            ? this.getStyleForPosition(this.state.offsetToAncestor)
            : null
        }
        key="dropdown-menu"
        menuProps={menuProps}>
        {children}
      </DropDownMenu>
    );
  }

  renderButton() {
    const { button, showMenuWhereClicked, showInPortal } = this.props;
    let key = 0;

    return React.Children.map(button, (button: any) =>
      React.cloneElement(button, {
        key: key++,
        onClick: e => {
          if (this.isAnimating()) {
            // do nothing
          } else if (showInPortal && !this.state.showMenu) {
            const { clientX, clientY } = e.nativeEvent;
            const ancestor = showMenuWhereClicked
              ? (this.refs.dropdown as any).parentNode
              : this.refs.dropdown;
            const {
              left: ancestorLeft,
              top: ancestorTop,
              right: ancestorRight,
              bottom: ancestorBottom,
            } = ancestor.getBoundingClientRect();
            let offsetToAncestor;

            if (showMenuWhereClicked) {
              offsetToAncestor = {
                left: clientX - ancestorLeft,
                top: clientY - ancestorTop,
                right: ancestorRight - clientX,
                bottom: ancestorBottom - clientY,
              };
            } else {
              offsetToAncestor = {
                left: 0,
                top: ancestorBottom - ancestorTop,
                right: 0,
                bottom: ancestorBottom - ancestorTop,
              };
            }

            this.toggle(null);
            this.setState({
              offsetToAncestor,
            });
          } else {
            this.toggle(null);
          }
          const onClick = get(button, 'props.onClick');
          return (onClick && onClick(e)) || null;
        },
      }),
    );
  }
}

export default DropDown;
