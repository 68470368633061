import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { isAdmin } from 'farmerjoe-common';
import * as constants from '../styles/style';

import I18n from '../language/i18n';

type TProps = {
  openCompany: string;
  company?: any;
  myCompanyProfile?: any;
};

const FailedPaymentPage = (props: TProps) => {
  const { myCompanyProfile, openCompany } = props;

  if (!isAdmin(myCompanyProfile)) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <p style={{
          textAlign: 'center',
          margin: '50% auto',
        }}>
          {I18n.t('billing.yourAdminHasToPay')}
        </p>
      </div>
    );
  }
  if (isAdmin(myCompanyProfile)) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <p style={{
          textAlign: 'center',
          margin: '50% auto',
        }}>
          {I18n.t('billing.youHaveToPayFirst')}
          <br />
          <Link
            key={'billing'}
            to={`/company/${openCompany}/billing`}
            style={{
              margin: '0 0.2em',
              fontWeight: 'bold',
              textDecoration: 'underline',
              color: constants.FJAPPLEGREEN,
            }}
          >
            {I18n.t('billing.toBilling')}
          </Link>
          <br />
          <Link
            key={'billing'}
            to={`/company/${openCompany}/employee`}
            style={{
              margin: '0 0.2em',
              fontWeight: 'bold',
              textDecoration: 'underline',
              color: constants.FJAPPLEGREEN,
            }}
          >
            {I18n.t('billing.toWorkers')}
          </Link>
        </p>
      </div>
    );
  }
  return null;

};

const selector = (state) => {
  const openCompany = getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(state, openCompany);
  return {
    openCompany,
    company,
    myCompanyProfile,
  };
};
export default connect(selector)(FailedPaymentPage);
