import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as employeeCreators from 'farmerjoe-common/lib/actions/employee';

import EmployeeContent from './EmployeeContent';
import withRouter from '../Router/withRouter';
import { classes } from '../../utils/dom';
import type { Company, Employee as CompanyUser, User } from '../../flowTypes';

type Props = {
  actions: {
    sendInvitation: typeof employeeCreators.sendInvitation;
    updateEmployee: typeof employeeCreators.updateEmployee;
    deleteEmployee: typeof employeeCreators.deleteEmployee;
    openEmployee: typeof employeeCreators.openEmployee;
  };
  user: CompanyUser & {
    profile: {
      avatar: {
        original: {
          downloadUrl: string;
        };
      };
    };
  };
  myCompanyProfile: CompanyUser;
  isOnline?: boolean;
  auth?: User;
  company: Company;
  selected: boolean;
  history?: Record<string, any>;
};

const Employee = (props: Props) => {
  const { user, company, selected } = props;
  return (
    <div
      key={user.key}
      className={classes('list-item', selected && 'selected')}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={() => {
          props.actions.openEmployee(user.key);
          props.history?.push(
            `/company/${company.key}/employee/${user.key}/${
              user.group_id ? user.group_id : 'main'
            }`,
          );
        }}>
        <EmployeeContent {...props} />
      </div>
    </div>
  );
};

export default compose<typeof Employee>(
  connect(
    (state: any, ownProps: any) => {
      return {
        user: ownProps.user,
        auth: state.firebase.auth,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          openEmployee: employeeCreators.openEmployee,
          sendInvitation: employeeCreators.sendInvitation,
          updateEmployee: employeeCreators.updateEmployee,
          deleteEmployee: employeeCreators.deleteEmployee,
        },
        dispatch,
      ),
    }),
  ),
  withRouter,
)(Employee);
