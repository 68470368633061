import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as fieldActions from 'farmerjoe-common/lib/actions/field';
import * as commentActions from 'farmerjoe-common/lib/actions/comment';
import { dummy } from '../../utils/Comment';
import I18n from '../../language/i18n';

// TODO: improve typings
type Props = any;
type State = any;

class Unarchive extends Component<Props, State> {
  static propTypes = {
    field: PropTypes.object.isRequired,
  };

  render() {
    return (
      <button
        onClick={() => this.unarchive()}
        className={'btn btn-primary'}
        style={{
          minWidth: 250,
          borderRadius: 25,
          paddingTop: 5,
          paddingBottom: 5,
          fontWeight: 'bold',
        }}>
        {I18n.t('field.cultivate')}
      </button>
    );
  }

  unarchive() {
    const field = {
      ...this.props.field,
      state: 1,
    };

    this.props.actions.updateField(field);

    // Add comment
    const comment = {
      ...dummy(
        null as any,
        field.company_id,
        field,
        field.activeCrop,
        this.props.auth,
        'system.unarchive',
      ),
      text: { languageConstant: 'field.cultivateAgain' },
    };

    this.props.actions.addComment(comment);
  }
}

const selector = state => {
  return {
    auth: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          ...commentActions,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(Unarchive);
