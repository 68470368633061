import React from 'react';
import { cloneDeep } from 'lodash-es';
import t from 'tcomb-form';

import stylesheet from '../stylesheets/style';
import ModalDate from '../templates/ModalDate';

import {
  dateTransformer,
  numberTransformer,
} from '../transformers/transformers';
import { defaultDateFormat } from '../../tcomb/utils/options';
import I18n from '../../language/i18n';
import Yield from '../templates/Yield';
import RadioGroup from '../templates/RadioGroup';

// Our crop model
const model = t.struct({
  harvested_on: t.Date,
  archive_field: t.Boolean,
  yield: t.maybe(t.Number),
});

const modelPartialCrop = t.struct({
  harvested_on: t.Date,
  yield: t.maybe(t.Number),
});

const formGroupOuter = cloneDeep(stylesheet);
formGroupOuter.formGroup.normal.borderBottomWidth = 0;

const multiline = cloneDeep(stylesheet);
multiline.textbox.normal.height = 36;
multiline.textbox.normal.marginTop = 60;
multiline.formGroup.normal.marginBottom = 50;

const template = (locals, isSubField) => {
  return (
    <div>
      {locals.inputs.harvested_on}
      {!isSubField ? (
        <>{locals.inputs.archive_field}</>
      ) : null }
      {locals.inputs.yield}
    </div>
  );
};

const options = function(state, ha, activeCropSownOnDate: Date, isSubField: boolean) {
  const opts = {
    template: locals => template(locals, isSubField),
    stylesheet: stylesheet,
    auto: 'none',
    fields: {
      harvested_on: {
        label: I18n.t('field.when_was_the_field_harvested'),
        maximumDate: new Date(),
        transformer: dateTransformer,
        config: {
          mode: 'date',
          format: date => defaultDateFormat(date),
          minimumDate: activeCropSownOnDate,
        },
        stylesheet: formGroupOuter,
        template: ModalDate,
      },
      yield: {
        label: I18n.t('crop.yieldInT'),
        factory: Yield,
        value: state.yield,
        transformer: numberTransformer,
        config: {
          ha: ha,
        },
      },
    },
  };

  // XXX: If the crop is occuppying only part of the field,
  // this is the case with the subfields. We have a base field
  // which we copy and then the copied field, or the subfield has the crop
  // we want to harvest. 
  
  // XXX: Fields that are sub fields cannot be moved into archive. They can only be
  // harvested.
  
  if (!isSubField) {
    opts.fields =  {
      ...opts.fields,
      ...{
        archive_field: {
          label: '',
          factory: RadioGroup,
          config: {
            options: [
              {
                label: I18n.t('field.dontMoveToArchive'),
                value: false,
              },
              {
                label: I18n.t('field.moveToArchive'),
                value: true,
              },
            ],
          },
        },
      },
    };
  }

  return opts;
};

// export let planModel = {planCropModel, options};
export default { model, modelPartialCrop, options };
