import React from 'react';

import Icon from '../../../Common/Icon';

const SmileyFaceRatingRenderer = (props) => {
  const { label, value } = props;
  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    }}>
      <div>{label}</div>
      <div>
        <Icon name={value.smileyIcon} iconType={'fal'} style={{ color: value.color, fontSize: 20 }} />
      </div>
    </div>
  );
};

export default SmileyFaceRatingRenderer;
