import { clone, orderBy } from 'lodash-es';
import FieldTemplate from './Fields/FieldTemplate';
import UniqueIdRenderer from './Renderers/UniqueIdRenderer';
import FieldSizeRenderer from './Renderers/FieldSizeRenderer';
import BoniturAgeRenderer from './Renderers/BoniturAge';
import TextRenderer from './Renderers/Text/Text';
import CropAgeRenderer from './Renderers/CropAge';
import NonSupportedRenderer from './Renderers/NonSupported';
import SliderRenderer from './Renderers/Slider';
import SwitchRenderer from './Renderers/Switch';
import BooleanRenderer from './Renderers/Boolean/Boolean';
import LabelRenderer from './Renderers/Label';
import ImageRenderer from './Renderers/Image';
import StaticImageRenderer from './Renderers/StaticImage';
import SmileyFaceRenderer from './Renderers/SmileyFaceRating';
import ColorPickerRenderer from './Renderers/ColorPicker';
import MathCalculationRenderer from './Renderers/MathCalculationRenderer';
import MathCalculationHectare from './Renderers/MathCalculationHectare';
import AmountRenderer from './Renderers/Amount/Amount';
import TextAreaRenderer from './Renderers/TextArea/TextArea';
import SegmentedControlRenderer from './Renderers/SegmentedControl/SegmentedControl';
import RadioRenderer from './Renderers/RadioRenderer';
import ShareWithRenderer from './Renderers/ShareWithRenderer';

const supportedRenderers = {
  'fj-slider': SliderRenderer,
  'fj-boolean': BooleanRenderer,
  'fj-switch': SwitchRenderer,
  'fj-text': TextRenderer,
  'fj-label': LabelRenderer,
  'fj-image': ImageRenderer,
  'fj-static-image': StaticImageRenderer,
  'fj-smiley-face-rating': SmileyFaceRenderer,
  'fj-color-picker': ColorPickerRenderer,
  // NOTE: not needed for new boniturs but required for backward
  // compatibility/existing companies with 'fj-non-animated-input' enabled.
  'fj-non-animated-input': TextRenderer,
  'fj-crop-age': CropAgeRenderer,
  'fj-math': MathCalculationRenderer,
  'fj-math-hectare': MathCalculationHectare,
  'fj-amount': AmountRenderer,
  'fj-textarea': TextAreaRenderer,
  'fj-segmented-control': SegmentedControlRenderer,
  'fj-radio': RadioRenderer,
  'fj-bonitur-age': BoniturAgeRenderer,
  'fj-unique-id': UniqueIdRenderer,
  'fj-field-size': FieldSizeRenderer,
  'fj-share-with': ShareWithRenderer,
};
/**
 * The schema that we store in firebase is somewhat different than
 * what formikJSON needs. For example firebase doesn't support nested arrays [['required']]
 * but we need those for the validation. So we store this valus with JSON.stringify and need to
 * transform them to valid js data.
 */

export const firebaseToFormikJSON = (firebaseSchema, metaParams) => {
  let elements = firebaseSchema.elements;
  const { companyId, fieldId } = metaParams;
  elements = orderBy(
    Object.keys(elements).map((key) => {
      const element = clone(elements[key]);
      element.companyId = companyId;
      element.fieldId = fieldId;
      // NOTE: for backward compatiblity
      const isAmountRenderer = element.options?.isSmallTextInput;
      const isTextAreaRenderer = element.options?.multiline;

      if (isAmountRenderer) {
        element.renderer = AmountRenderer;
      } else if (isTextAreaRenderer) {
        element.renderer = TextAreaRenderer;
      } else {
        // set the correct renderer
        element.renderer =
          supportedRenderers[element.renderer] || NonSupportedRenderer;
      }

      // set the correct validation
      element.validation = JSON.parse(element.validation);
      element.template = FieldTemplate;
      // element.value = element.hasOwnProperty("defaultValue")
      //   ? element.defaultValue
      //   : undefined;

      return { key, value: element, position: element.position };
    }),
    'position',
  ).reduce((prev, curr) => {
    prev[curr.key] = curr.value;
    return prev;
  }, {});

  return { ...firebaseSchema, elements };
};
