import React from 'react';
import ShareButton from './ShareButton';
import Avatar from '../../containers/Avatar';
import { UsersListItemProps } from './types';

const UsersListItem = ({ user, setSelectedUser, setCurrentDialog }: UsersListItemProps) => {
  const { firstname, lastname, uid } = user;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '1em 0 1em' }}>
      <Avatar
        uid={uid}
        round={true}
        style={{ backgroundColor: '#EEE', width: '40px', height: '40px' }}
      />
      <div style={{ marginLeft: '1em' }}>
        <div>
          <span style={{ fontWeight: 'bold' }}>{`${firstname + ' ' + lastname}`}</span>
        </div>
      </div>
      <ShareButton onClick={() => {
        setSelectedUser(user);
        setCurrentDialog('PROFILE');
      }} />
    </div>
  );
};

export default UsersListItem;
