import BoniturAgeRenderer from './BoniturAgeRenderer';
import BooleanRenderer from './Boolean';
import ColorPickerRenderer from './ColorPickerRenderer';
import CropAgeRenderer from './CropAgeRenderer';
import FieldSizeRenderer from './FieldSizeRenderer';
import ImageRenderer from './Image';
import LabelRenderer from './Label';
import MathCalculationRenderer from './MathCalculationRenderer';
import RadioRenderer from './RadioRenderer';
import SegmentedControlRenderer from './SegmentedControlRenderer';
import SliderRenderer from './Slider';
import SmileyRatingRenderer from './SmileyRatingRenderer';
import StaticImageRenderer from './StaticImageRenderer';
import TextRenderer from './TextRenderer';
import UniqueIdRenderer from './UniqueIdRenderer';
import ShareWithRenderer from './ShareWithRenderer';

export const supportedContentRenderers = {
  'fj-slider': SliderRenderer,
  'fj-boolean': BooleanRenderer,
  'fj-text': TextRenderer,
  'fj-image': ImageRenderer,
  'fj-smiley-face-rating': SmileyRatingRenderer,
  'fj-color-picker': ColorPickerRenderer,
  'fj-static-image': StaticImageRenderer,
  'fj-crop-age': CropAgeRenderer,
  'fj-bonitur-age': BoniturAgeRenderer,
  // NOTE: not needed for new boniturs but required for backward
  // compatibility/existing companies with 'fj-non-animated-input' enabled.
  'fj-non-animated-input': TextRenderer,
  'fj-math': MathCalculationRenderer,
  'fj-math-hectare': MathCalculationRenderer,
  'fj-label': LabelRenderer,
  'fj-amount': TextRenderer,
  'fj-textarea': TextRenderer,
  'fj-segmented-control': SegmentedControlRenderer,
  'fj-radio': RadioRenderer,
  'fj-unique-id': UniqueIdRenderer,
  'fj-field-size': FieldSizeRenderer,
  'fj-share-with': ShareWithRenderer,
};

export { default as NonSupportedRenderer } from './NonSupported';
