import React from 'react';
import RNFirebase from '../../../../data/firebase';
import {
  simpleStatus,
  SimpleStatus,
  Status,
} from 'farmerjoe-common/lib/constants/FileUpload';
import { FJAPPLERED, FJMUTEDLIGHT, FJNEWGREEN, FJMUTEDLIGHTER } from '../../../../styles/style';
import I18n from '../../../../language/i18n';
import Icon from '../../../Common/Icon';
import { ImageUploadProgress } from '../../../Common/ImageUploadProgress';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import Dropzone from 'react-dropzone';
import { processImage } from '../../../../utils/image';

const shortid = require('shortid32');

// TODO: improve typings
type Props = any;
type State = any;

class ImageComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    const { value } = props;
    const uri = get(value, 'uri', null);
    const ref = get(value, 'ref', null);

    this.state = {
      uri: uri,
      localUri: uri,
      ref: ref,
    };
  }

  uploadTask: any = null;

  upload = imagePath => {
    const { onUploaded, onUploading, openCompanyId, openFieldId } = this.props;
    const firebase = RNFirebase;

    onUploading();

    this.setState({
      localUri: imagePath,
      uploading: true,
      status: Status.UPLOADING,
    });
    this.uploadTask = firebase
      .storage()
      .ref()
      .child(
        `images/bonitur/${openCompanyId}/${openFieldId}/${shortid.generate()}`,
      )
      .put(imagePath.image);

    const unsubscribe = this.uploadTask.on(
      'state_changed',
      snap => {
        const progress = (snap.bytesTransferred / snap.totalBytes) * 100;

        this.setState({ progress: progress });
      },
      error => {
        this.setState({ status: SimpleStatus.FAILURE });
        unsubscribe();
      },
      () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          const refUrl = this.uploadTask.snapshot.ref.toString();
          this.setState({
            status: Status.SUCCESS,
            uri: downloadURL,
            ref: refUrl,
            uploading: false,
          });
          onUploaded({
            uri: downloadURL,
            ref: refUrl,
          });
        });

        unsubscribe();
      },
    );
  };

  onDrop = (files, rejectedFiles, e) => {
    // this.upload(files[0].filePath)

    const filePromises = files.map((file, i) => {
      // staged[i] = 'staged'
      return Promise.resolve(file)
        .then(file =>
          processImage(file, {
            maxWidth: 1920,
            maxHeight: 1920,
            imageQuality: 0.6,
          }),
        )
        .then(image => {
          this.setState({ localSource: URL.createObjectURL(file) }, () => {
            URL.revokeObjectURL(file);
          });
          this.upload(image);
        });
    });

    e.stopPropagation();
  };

  cancel() {
    const { onDelete } = this.props;

    try {
      this.uploadTask && this.uploadTask.cancel();
    } catch (e) {}

    this.setState({
      uploading: false,
      localUri: null,
      uri: null,
      ref: null,
    });

    onDelete();
  }

  render() {
    const { localUri } = this.state;

    const imageBlockStyle = {
      backgroundColor: 'rgba(0,0,0,0.1)',
      width: 300,
      height: 220,
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
    };

    return (
      <div
        className={
          'd-flex justify-content-center align-items-center ' +
          (localUri ? '' : 'fj-pointer')
        }>
        <div style={imageBlockStyle}>
          {localUri && this.renderImage()}
          {!localUri && (
            <Dropzone
              noClick={false}
              onDrop={this.onDrop}
              multiple={false}
              accept="image/*,.jpg,.jpeg,.png">
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div
                    {...getRootProps()}
                    className={
                      'd-flex justify-content-center align-items-center flex-column'
                    }
                    style={{
                      borderStyle: 'solid',
                      borderColor: isDragActive ? FJNEWGREEN : FJMUTEDLIGHTER,
                      borderRadius: 5,
                      borderWidth: 1,
                      padding: 10,
                      minHeight: '100%',
                    }}>
                    <Icon
                      name={'camera'}
                      iconType={'fal'}
                      style={{ fontSize: 140, color: FJMUTEDLIGHT }}
                    />
                    <input {...getInputProps()} />
                    <p className={'text-center'}>
                      {I18n.t('bonitur.dropFiles')}
                    </p>
                  </div>
                );
              }}
            </Dropzone>
          )}
        </div>
      </div>
    );
  }

  delete() {
    const { onDelete } = this.props;
    const { ref } = this.state;
    const firebase = RNFirebase;

    if (ref) {
      const fileRef = firebase.storage().refFromURL(ref);

      fileRef.delete();
    }

    this.setState({ uri: null, localUri: null });

    onDelete();
  }

  renderImage() {
    const { error } = this.props;
    const { localUri, uploading, progress, status } = this.state;
    let { localSource } = this.state;
    if (!localSource && localUri) {
      localSource = localUri;
    }
    const sStatus = simpleStatus(status);
    return (
      <div
        style={{
          position: 'relative',
          height: '100%',
          borderColor: error ? FJAPPLERED : FJMUTEDLIGHTER,
          borderWidth: 1,
          borderStyle: 'solid',
        }}
        className="d-flex justify-content-center align-items-center">
        {!uploading && (
          <div style={{ position: 'absolute', zIndex: 9999, right: 5, top: 5 }}>
            <button
              key={0}
              className={'btn btn-danger'}
              onClick={() => this.delete()}>
              {I18n.t('delete')}
            </button>
          </div>
        )}
        {localSource && (
          <img
            src={localSource}
            style={{ maxWidth: 300, maxHeight: 200 }}
            alt={'local source'}
          />
        )}
        {uploading && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
              borderRadius: 5,
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: 9999,
            }}>
            <ImageUploadProgress
              uploadKey={'boniturImage'}
              sStatus={sStatus}
              cancelImageUpload={() => {
                this.cancel();
              }}
              progress={progress === 0 ? 20 : progress}
              retryImageUpload={() => {
                this.upload(localUri);
              }}
              onPressDelete={() => this.delete()}
            />
          </div>
        )}
      </div>
    );
  }
}

const selector = state => {
  return {
    openCompanyId: selectors.getOpenCompanyId(state),
    openFieldId: selectors.getOpenFieldId(state),
  };
};

export default connect(
  selector,
  () => ({}),
)(ImageComponent);
