import React from 'react';
import { firebase } from 'react-redux-firebase';
import { firestoreRef } from 'redux-firestore/lib/utils/query';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { get, keyBy, map, orderBy } from 'lodash-es';

import { filters, displaySettings } from 'farmerjoe-common/lib/actions/actions';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { openField } from 'farmerjoe-common/lib/actions/field';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import { getLatestActivitiesFilter } from 'farmerjoe-common/lib/selectors/filters';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { OrderByDirection } from 'farmerjoe-common/lib/flow/types';
import { getLatestActivityQuery, getLatestActivityForFieldAndCropQuery, getLatestActivityForFieldQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Comments';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { getCompanyCollaboratorsQuery, getSharedFieldsQueries } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';
import { sharedFieldsSelector } from 'farmerjoe-common/lib/selectors/fields';
import { isAdmin, canDo } from 'farmerjoe-common/lib/utils/User';

import LatestActivitiesList from './LatestActivitiesList';
import ScrollableTabView from '../Common/ScrollableTabView';
import TabBarSearchSort from '../Common/TabBarSearchSort';
import withRouter from '../Router/withRouter';
import { Loading } from '../Loading/Loading';
import WithFieldCollaborators from '../../containers/HOC/WithFieldCollaborators';
import WithSharedFields from '../../containers/HOC/WithSharedFields';
import I18n from '../../language/i18n';
import type { Company as CompanyType, Comment } from '../../flowTypes';
import './style.css';


import DateRangeFilter from './DateRangeFilter';

const FIELDS_LIMIT = 10;
const SHARED_FIELDS_LIMIT = 1;

type Props = {
  firebase?: typeof firebase;
  company?: CompanyType;
  browsingGroup?: string;
  filters?: {
    periods: [Date, Date] | [] | any;
    onlyImages: boolean;
  };
  actions?: {
    filters: typeof filters;
    openField: (fieldId: any) => any;
    displaySettings: typeof displaySettings;
  };
  history?: any;
  openCompany?: any;
  sharedFields?: any;
  hasLoadedAll?: boolean;
  location?: any;
  myCompanyProfile?: any;
};
type State = {
  lastItem: null | Comment;
  hasMore: boolean;
  loading: boolean;
  loadingMore: boolean;
  itemsCache: Record<string, Comment>;
  items: Comment[];
  range?: any;
  period?: any;
  customDate?: boolean;
  modalVisible?: boolean;
};

class LatestActivitiesContainer extends React.Component<Props, State> {
  state: State = {
    lastItem: null,
    loading: true,
    loadingMore: false,
    items: [],
    range: null,
    period: null,
    modalVisible: false,
    hasMore: false,
    itemsCache: {},
  };

  listeners: any = [];

  componentDidMount() {
    const { company } = this.props;
    const { lastItem } = this.state;

    if (!lastItem && company) {
      this.loadMoreComments(true);
    }
  }

  componentWillUnmount() {
    this.clearListeners();
  }

  clearListeners() {
    this.listeners.forEach((listener: any) => {
      listener && listener();
    });
    this.listeners = [];
  }

  componentDidUpdate(prevProps, prevState) {
    const { company } = this.props;

    // XXX: Dirty hack to ensure that we are loading only when we are at the right
    // page (/companyId/latest). Otherwise the component is unmounted and causing
    // flooding of requests until it fetch all comments...
    // XXX: IT NEEDS REFACTORING!
    const isActivity = this.props.location && this.props.location.pathname.match(/latest/); 
    if (isActivity && company) {
      if (
        (prevProps.filters !== this.props.filters) || (prevProps.hasLoadedAll !== this.props.hasLoadedAll)
      ) {
        const reset = true;
        this.loadMoreComments(reset);
      }
    }
  }

  loadMoreComments(reset = false) {
    const { firebase, company, filters, browsingGroup, sharedFields, hasLoadedAll, myCompanyProfile } = this.props;
    const { lastItem } = this.state;
    const periods = get(filters, 'periods', []);
    const onlyImages = get(filters, 'onlyImages', false);

    const hasRestrictedFields = get(myCompanyProfile, 'fields', false);

    if (reset) {
      this.setState({ loading: true, items: [], itemsCache: {} });
      this.clearListeners();
    }

    if (!company) {
      return;
    }

    if (!hasLoadedAll) {
      return;
    }

    const callback = querySnap => {
      const docs = querySnap.docs.map(doc => doc.data());
      const docsObject = keyBy(docs, 'key');

      const itemsCache = { ...this.state.itemsCache, ...docsObject };

      querySnap.docChanges().forEach(function(change) {
        // Remove a deleted item from the object
        if (change.type === 'removed') {
          const removedDoc = change.doc.data();
          delete itemsCache[removedDoc.key];
        }
      });

      const items = orderBy(
        map(itemsCache, value => value),
        item => {
          try {
            return item.created.toDate();
          } catch(e) {
            // XXX: We need to refactor the whole component.
            // XXX: We have just added a comment
            return null;
          }
        },
        [OrderByDirection.Desc],
      );

      this.setState({
        hasMore: true,
        loading: false,
        loadingMore: false,
        itemsCache,
        items,
      });

      if (docs[docs.length - 1]) {
        this.setState({ lastItem: docs[docs.length - 1] });
      }

      if (docs.length < FIELDS_LIMIT) {
        this.setState({ hasMore: false });
      }
    };

    const onError = error => {
      this.setState({ loading: false });
    };

    if (sharedFields.length) {
      sharedFields.forEach(pair => {
        const { fieldId, companyId, cropId } = pair;
        if (fieldId && companyId && cropId) {
          const query = getLatestActivityForFieldAndCropQuery(companyId, fieldId, cropId, browsingGroup, periods, onlyImages, reset, lastItem, SHARED_FIELDS_LIMIT);
          const firestoreQuery = firestoreRef(firebase, query);
          this.listeners.push(firestoreQuery.onSnapshot(callback, onError));
        }
      });
    }

    if (hasRestrictedFields) {
      const fieldIds = Object.keys(hasRestrictedFields);
      fieldIds.forEach(fieldId => {
        const query = getLatestActivityForFieldQuery(company.key, fieldId, browsingGroup, periods, onlyImages, reset, lastItem, FIELDS_LIMIT, myCompanyProfile);
        const firestoreQuery = firestoreRef(firebase, query);
        this.listeners.push(firestoreQuery.onSnapshot(callback, onError));
      });
    } else {
      const query = getLatestActivityQuery(company.key, browsingGroup, periods, onlyImages, reset, lastItem, FIELDS_LIMIT, myCompanyProfile);
      const firestoreQuery = firestoreRef(firebase, query);
      this.listeners.push(firestoreQuery.onSnapshot(callback, onError));
    }
  }

  render() {
    const { items, loading, loadingMore } = this.state;
    const { company, hasLoadedAll } = this.props;

    if (!company || !hasLoadedAll) {
      return <Loading />;
    }

    const latestActivitiesListProps = {
      onEndReached: () => {
        if (
          this.state.hasMore &&
          !this.state.loading &&
          !this.state.loadingMore
        ) {
          this.setState(
            {
              loadingMore: true,
            },
            () => this.loadMoreComments(),
          );
        }
      },
      items: items,
      loadingMore: loadingMore,
      onCommentPress: comment => {
        this.props.actions?.openField(comment.field_id);
        this.props.history.push(
          `/company/${this.props.openCompany}/latest/${comment.field_id}`,
        );
      },
      showCropVariety: isAdmin(this.props.myCompanyProfile) || canDo('get.variety', 'crop', this.props.myCompanyProfile),
    };
    // eslint-disable-next-line no-script-url
    return (
      <div
        className="latest-activity-container"
        style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <ScrollableTabView
          onChangeTab={({ i }) => {
            if (i === 0) {
              this.props.actions?.filters(company?.key as any, {
                latestActivities: {
                  ...this.props.filters,
                  onlyImages: false,
                } as any,
              });
            } else if (i === 1) {
              this.props.actions?.filters(company?.key as any, {
                latestActivities: {
                  ...this.props.filters,
                  onlyImages: true,
                } as any,
              });
            }
          }}
          renderTabBar={() => (
            <TabBarSearchSort
              showSort={false}
              searchComponent={<DateRangeFilter 
                onSelect={(selectedRange) => {
                  const now = new Date();

                  const rangeOptions = {
                    'today': {
                      start: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0),
                      end: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59),
                    },
                    'yesterday': {
                      start: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()-1),
                      end: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()-1, 23, 59),
                    },
                    'last:7': {
                      start: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()-7),
                      end: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59),
                    },
                    'last:30': {
                      start: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()-30),
                      end: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59),
                    },
                    'last:90': {
                      start: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()-90),
                      end: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59),
                    },
                    'last:365': {
                      start: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()-365),
                      end: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59),
                    },
                    'customRange': (_selectedRange) => {
                      const isCustomRange = _selectedRange.match(/range/);
                      if (!isCustomRange) { // return "today" by default
                        return {
                          start: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()),
                          end: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59),
                        };
                      }
                      const range = _selectedRange.split(':')[1];
                      const [startRange, endRange] = range.split('#');
                      const _startDate = new Date(startRange);
                      const _endDate = new Date(endRange);
                      const _endDateAdjusted = _endDate.setHours(23);
                      return {
                        start: _startDate,
                        end: _endDateAdjusted,
                      };
                    },
                  };

                  const { start, end } = rangeOptions[selectedRange] || rangeOptions.customRange(selectedRange);

                  this.props.actions?.filters(company?.key as any, {
                    latestActivities: {
                      ...this.props.filters,
                      periods: [start, end],
                    } as any,
                  });
                  this.props.actions?.displaySettings(
                    company?.key,
                    'latestActivity',
                    {
                      date: selectedRange,
                    },
                    true,
                  );

                }}
              />}
            />
          )}>
          <LatestActivitiesListOrLoading
            loading={loading}
            tabLabel={I18n.t('latestActivities.all')}
            {...latestActivitiesListProps}
          />
          <LatestActivitiesListOrLoading
            loading={loading}
            tabLabel={I18n.t('latestActivities.onlyPhotos')}
            {...latestActivitiesListProps}
          />
        </ScrollableTabView>
      </div>
    );
  }
}

const LatestActivitiesListOrLoading = ({ loading, tabLabel, ...restProps }) => {
  return loading ? <Loading /> : <LatestActivitiesList {...restProps} />;
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const filters = getLatestActivitiesFilter(state, openCompany);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  const allCurrentSharedFields = sharedFieldsSelector(state, openCompany);
  const allCurrentSharedFieldsIds = allCurrentSharedFields.map(field => field.key);

  const sharedFields = allCurrentSharedFields.map(field => {
    return {
      fieldId: field.key,
      companyId: field.company_id,
      cropId: field.activeCrop.key,
    };
  });

  const queries = [
    getCompanyCollaboratorsQuery(openCompany),
    ...getSharedFieldsQueries(allCurrentSharedFieldsIds, openCompany),
  ];

  return {
    company,
    filters,
    openCompany,
    browsingGroup,
    myCompanyProfile,
    sharedFields,
    hasLoadedAll: hasLoaded(queries, state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      filters,
      openField,
      displaySettings,
    },
    dispatch),
  };
};

export default compose<typeof LatestActivitiesContainer>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  WithFieldCollaborators,
  WithSharedFields,
  withRouter,
)(LatestActivitiesContainer);
