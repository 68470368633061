import React from 'react';
import { debounce, get } from 'lodash-es';
import SearchInput from '../../Common/SearchInput';
import NoResults from '../../Common/NoResults';
import Icon from '../../Common/Icon';
import { Avatar } from '../../Common/Avatar';

import {  FJWHITE } from '../../../styles/style';

import I18n from '../../../language/i18n';

import { getAlgoliaInstance } from '../../../services/algolia';
import { ALGOLIA_INDEX_COMPANIES } from '../../../constants';

const algoliaInstance = getAlgoliaInstance(ALGOLIA_INDEX_COMPANIES);

const ModalBodySearchCompany = ({
  userCompany,
  companies,
  setCompanies,
  setSelectedCompany,
  setCurrentDialog,
  searchValue,
  setSearchValue,
  collaborators,
}) => {
  const searchForCompanies = debounce((value) => {
    if (!value) {
      setCompanies([]);
      return;
    }

    setSearchValue(value);

    return algoliaInstance.searchIndex(value).then((result) => {
      setCompanies(result.hits);
    });
  }, 1000);

  return (
    <div className='modal-body-content'>
      <SearchInput
        onChange={text => {
          searchForCompanies(text);
        }}
        search={searchValue}
        placeholder={I18n.t('fieldSharing.searchDialogueTitleInputPlaceholder')}
      />
      <div>
        {!companies.length && searchValue.length ? (
          <NoResults text={I18n.t('noSearchResults')} />
        ) : (
          <CompanyList userCompany={userCompany} companies={companies} setSelectedCompany={setSelectedCompany} setCurrentDialog={setCurrentDialog} collaborators={collaborators} />
        )
        }
      </div>
    </div>
  );
};

const CompanyList = ({ userCompany, companies, setSelectedCompany, setCurrentDialog, collaborators }) => {
  return companies.map((c) => {
    return <CompanyListItem userCompany={userCompany} company={c} key={c.uid} setSelectedCompany={setSelectedCompany} setCurrentDialog={setCurrentDialog} collaborators={collaborators} />;
  });
};

const CompanyListItem = ({ userCompany, company, setSelectedCompany, setCurrentDialog, collaborators }) => {
  const logoURL = get(company, 'company.logo.original.downloadURL', null);
  const canShare = company.uid !== userCompany.key;
  const isAlreadyShared = collaborators && collaborators.length && collaborators.includes(company.uid);
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '1em 0 1em'}}>

      <Avatar
        downloadUrl={logoURL}
        style={{ backgroundColor: '#EEE' }}
        isLoaded={false}
        size="medium"
      />

      <div style={{marginLeft: '1em'}}>
        <div>
          <span style={{fontWeight: 'bold'}}>{company.name}</span>
        </div>
        <div style={{fontSize: '16px'}}>
          <span>{`${company.street} ${company.city}`}</span>
        </div>
        <div style={{fontSize: '16px'}}>
          <span>{`${company.zip} ${company.country}`}</span>
        </div>
      </div>
      {canShare && !isAlreadyShared ? (
        <ButtonShare onClick={() => {
          const { uid, name } = company;
          setSelectedCompany({ uid, name });
          setCurrentDialog('CONFIRM');
        }}/>
      ) : null}
    </div>
  );
};

const ButtonShare = ({onClick}) => {
  return (
    <div style={{ marginLeft: 'auto' }}
      onClick={onClick}
    >
      <div className="btn btn-primary">
        <Icon
          name="md-share"
          iconType={'ion'}
          style={{ fontSize: 18, color: FJWHITE, marginRight: '5px' }}
        />
        <span
          style={{
            fontSize: 16,
            color: FJWHITE,
          }}
        >
          {I18n.t('fieldSharing.searchDialogueShareButtonLabel')}
        </span>
      </div>
    </div>
  );
};

export default ModalBodySearchCompany;
