import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { get } from 'lodash-es';

import { getSortedFields } from 'farmerjoe-common/lib/selectors/fields';
import { getFieldsQueries } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { filters } from 'farmerjoe-common/lib/actions/actions';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { FieldState } from 'farmerjoe-common/lib/flow/types';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';

import CropFieldsList from '../CropFieldsList';
import SingleField from '../SingleField';
import Icon from '../../Common/Icon';
import * as constants from '../../../styles/style';

import I18n from '../../../language/i18n';
import Dialog from '../../../components/Dialog/Dialog';
import SearchInput from '../../../components/Common/SearchInput';

import './style.css';

type fieldSelection = Record<string, boolean>;
type sortedFieldsItem = 'sections' | 'openFieldPath';
type sortedFieldsItemValue = Array<any> | any;

type Props = {
  openCompany: string;
  loading: boolean;
  filter: Record<string, any>;
  actions: {
    filters: typeof filters;
  };
  // navigation: navigation,
  selected: fieldSelection;
  sortedFields: Record<sortedFieldsItem, sortedFieldsItemValue>;
  onSave: (fields: fieldSelection) => {};
  onClose: () => {};
  show?: any;
};

type State = {
  selected: fieldSelection;
};

/**
 * A component that displays a screen on which the user is able to
 * select different fields
 */
class FieldsSelection extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected,
    };

    // $FlowFixMe
    this.onChangeText = this.onChangeText.bind(this);
    // $FlowFixMe
    this.renderField = this.renderField.bind(this);
  }

  onSave() {
    this.props.onSave(this.state.selected);

    this.close();
  }

  close() {
    const { onClose } = this.props;
    onClose && onClose();
  }

  onChangeText(text) {
    const { openCompany } = this.props;
    this.props.actions.filters(openCompany, {
      search: text,
    });
  }

  searchInput() {
    const { filter } = this.props;
    const search = get(filter, 'search', '');

    return (
      <SearchInput
        key={'searchField'}
        onChange={this.onChangeText}
        search={search}
      />
    );
  }

  render() {
    const { sortedFields, loading, show } = this.props;
    const { sections: fieldsBySections } = sortedFields;

    return (
      <Dialog
        show={show}
        title={I18n.t('select_field')}
        onClose={this.close.bind(this)}
        dialogClassName={'employee-select-fields'}
        footer={
          <div className="buttons">
            <button
              className="ml-auto btn btn-secondary"
              onClick={this.close.bind(this)}>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}>
              {I18n.t('done')}
            </button>
          </div>
        }>
        <div className={'d-flex flex-column'}>
          <div style={{ height: 50 }}>{this.searchInput()}</div>
          <CropFieldsList
            key={'test'}
            fields={fieldsBySections}
            renderField={this.renderField}
            loading={loading}
            filter={this.props.filter}
            extraData={this.state.selected}
            allFieldsOpen={true}
          />
        </div>
      </Dialog>
    );
  }

  renderField({ item }) {
    const field = item.item;
    const { selected } = this.state;
    return (
      <div key={'field' + field.key}>
        <div
          onClick={() => {
            this.setState({
              selected: {
                ...selected,
                [field.key]: !this.state.selected[field.key],
              },
            });
          }}
          className={
            'd-flex justify-content-between cursor-pointer list-item crop-fields-item'
          }>
          <div className={'d-flex'} style={{ width: '100%' }}>
            <SingleField
              field={field}
              crop={field.activeCrop}
              waitTimes={[]}
              lastComment={field.activeCrop.lastComment}
            />
          </div>
          <div
            style={{ width: 40 }}
            className={'d-flex justify-content-center'}>
            {selected[field.key]
              ? (
                <Icon
                  name={'ios-checkmark-circle'}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )
              : (
                <Icon
                  name={'ios-radio-button-off'}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}

const selector = (state, ownProps) => {
  const openCompany = getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  const loading = !hasLoaded(
    [
      ...getFieldsQueries(openCompany, myCompanyProfile, browsingGroup, FieldState.Active),
    ],
    state,
  );

  return {
    openCompany: openCompany,
    selected: ownProps.selected || {},
    // @ts-ignore
    sortedFields: getSortedFields(state, openCompany),
    filter: state.filtersByCompany[openCompany],
    loading,
    browsingGroup,
    myCompanyProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          filters,
        },
      ),
      dispatch,
    ),
  };
};

const wrappedFieldsSelection = firestoreConnect(props => {
  const { openCompany, browsingAsGroup, myCompanyProfile } = props;

  return [
    ...getFieldsQueries(openCompany, myCompanyProfile, browsingAsGroup, FieldState.Active),
    {
      collection: 'companies',
      doc: openCompany,
    },
  ];
})(FieldsSelection);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedFieldsSelection);

