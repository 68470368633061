import React, { createContext, useState, useContext, useEffect } from 'react';
import firebase from '../data/firebase';

type NewCropRatingsContextType = {
  newCropRatingsCount: number;
  setNewCropRatingsCount: (value: number) => void;
};

const NewCropRatingsContext = createContext<NewCropRatingsContextType>({
  newCropRatingsCount: 0,
  setNewCropRatingsCount: () => {},
});

export const useNewCropRatings = () => useContext(NewCropRatingsContext);

export const NewCropRatingsProvider = ({
  children,
  openCompany,
}) => {
  const [newCropRatingsCount, setNewCropRatingsCount] = useState(0);

  useEffect(() => {
    if (!openCompany) {
      return;
    }

    const unsubscribe = firebase.firestore()
      .collection('formSubmissions')
      .where('company_id', '==', openCompany)
      .where('read', '==', false)
      .onSnapshot((snapshot) => {
        setNewCropRatingsCount(snapshot.size);
      });

    return () => unsubscribe();
  }, [openCompany]);

  return (
    <NewCropRatingsContext.Provider
      value={{ newCropRatingsCount, setNewCropRatingsCount }}
    >
      {children}
    </NewCropRatingsContext.Provider>
  );
};
