import { GroupType } from 'farmerjoe-common/lib/flow/types';
import I18n from '../../language/i18n';
import moment from 'moment';
import sanitizeFilename from 'sanitize-filename';

export function getNoGroupItemsText(search: string | null, type: GroupType) {
  if (search) {
    return I18n.t('noSearchResults');
  }
  if (type === 'trader') {
    return I18n.t('traders.noTrader');
  } else if (type === 'producer') {
    return I18n.t('producers.noProducer');
  }
  console.warn('No translation for group type: ' + type);
  return '';
}


export function getExportFileName(type: GroupType) {
  return `farmerjoe-${sanitizeFilename(I18n.t(`${type}s.plural`))}-${moment().format('DD.MM.YYYY')}`;
}

export const GROUP_TYPE_PRODUCER = 'producer';
export const GROUP_TYPE_TRADER = 'trader';
type GROUP_TYPE = typeof GROUP_TYPE_PRODUCER | typeof GROUP_TYPE_TRADER;

export const getMapMarkers = (
  groups,
  groupType: GROUP_TYPE,
  onClick: (...args: any[]) => any,
) => {
  return groups.map(group => ({
    key: group.key,
    position: group.position,
    type: groupType,
    title: group.name,
    onClick: () => {
      onClick(group.key);
    },
  }));
};

export const getAverageGeoLocation = (groups) => {
  const coords = groups.map(group => group.position);
  if (coords.length === 1) {
    return coords[0];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (const coord of coords) {
    const latitude = coord.latitude * Math.PI / 180;
    const longitude = coord.longitude * Math.PI / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  const total = coords.length;

  x = x / total;
  y = y / total;
  z = z / total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    latitude: centralLatitude * 180 / Math.PI,
    longitude: centralLongitude * 180 / Math.PI,
  };
};

