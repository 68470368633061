import * as React from 'react';
import { Field } from 'formik';

type Props = {
  name: string;
  type?: string;
  className?: string;
  classNameContainer?: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  value: any;
  error?: string;
};

type State = {
  focused: boolean;
};

export default class FormikField extends React.PureComponent<Props, State> {
  state = {
    focused: false,
  };

  render() {
    const {
      name,
      type,
      className,
      classNameContainer,
      label,
      placeholder,
      value,
      error,
      disabled,
    } = this.props;
    const { focused } = this.state;

    return (
      <div
        className={`text-input-container form-group floating-labels
        ${focused || value ? 'focused' : ''} ${error ? 'has-error' : ''} ${
        classNameContainer || ''
      }`}>
        <Field
          type={type}
          name={name}
          className={className}
          disabled={disabled}
          onBlur={() => this.setState({ focused: false })}
          onFocus={() => this.setState({ focused: true })}
          placeholder={placeholder}
        />
        <span className="bar" />
        <label htmlFor={name}>{label}</label>
      </div>
    );
  }
}
