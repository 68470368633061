import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { includes, without, get } from 'lodash-es';

import { filters } from 'farmerjoe-common/lib/actions/actions';
import * as fieldActions from 'farmerjoe-common/lib/actions/field';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';

import I18n from '../../../language/i18n';
import { classes } from '../../../utils/dom';

const defaultFilter = {
  showCrops: [0, 1, 2],
};

// TODO: improve typings
type TableFilterProps = any;
type TableFilterState = any;

class TableFilter extends PureComponent<TableFilterProps, TableFilterState> {
  constructor(props) {
    super(props);

    const {
      filter: { showCrops },
    } = props;
    this.state = {
      showCrops: showCrops || defaultFilter.showCrops,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const showCrops = get(this.props.filter, 'showCrops');
    if (showCrops && showCrops !== prevState.showCrops) {
      this.setState({ showCrops });
    }
  }

  render() {
    return (
      <div className="d-flex align-items-center table-filter">
        <CheckboxGroup
          buttons={[
            { label: I18n.t('crop.without'), value: 1 },
            { label: I18n.t('crop.planned'), value: 2 },
            { label: I18n.t('crop.active'), value: 0 },
          ]}
          onClick={value => {
            const showCrops = includes(this.state.showCrops, value)
              ? without(this.state.showCrops, value)
              : (this.state.showCrops || []).concat([value]);

            this.setState({ showCrops });
            this.props.actions.filters(this.props.openCompany, {
              showCrops,
            });
          }}
          value={this.state.showCrops}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);

  return {
    openCompany: openCompany,
    filter: state.filtersByCompany[openCompany]
      ? state.filtersByCompany[openCompany]
      : defaultFilter,
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(TableFilter);

const CheckboxGroup = ({ value, buttons, ...restProps }) => {
  return (
    <ButtonGroup
      buttons={buttons.map(({ value: buttonValue, className, ...rest }) => ({
        ...rest,
        className: classes(
          className,
          includes(value, buttonValue) ? 'btn-primary' : 'btn-secondary',
        ),
        value: buttonValue,
      }))}
      {...restProps}
    />
  );
};

// TODO: improve typings
type ButtonGroupProps = any;

const ButtonGroup = ({ buttons, onClick }: ButtonGroupProps) => {
  return (
    <div className="btn-group" role="group">
      {buttons.map(({ value, label, className = 'btn-secondary' }, i) => (
        <button
          className={'btn ' + className}
          key={i}
          onClick={() => onClick(value)}>
          {label}
        </button>
      ))}
    </div>
  );
};
