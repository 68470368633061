import React from 'react';
import SingleField from './SingleField';
import CropHeader from './CropHeader';
import SectionList from '../Common/SectionList';
import { classes } from '../../utils/dom';
import './style.css';

export type CropFieldsListItemProps = {
  search?: any;
  onClick?: any;
  waitTimes?: any;
  myCompanyProfile?: any;
  renderField?: any;
  allFieldsOpen?: any;
  openFieldPath?: any;
  rowData?: any;
  expanded?: any;
  selected?: any;
};

export interface CropFieldsListItemState {
  open?: any;
  selected?: any;
}

export default class CropFieldsListItem extends React.PureComponent<CropFieldsListItemProps, CropFieldsListItemState> {
  constructor(props: CropFieldsListItemProps) {
    super(props);

    this.state = {
      open: {},
    };
  }

  checkExpanded=(section) => {
    const {
      search,
      allFieldsOpen,
    } = this.props;
    return (typeof this.isSectionExpanded(section) === 'undefined' && allFieldsOpen) || search || this.isSectionExpanded(section);
  };

  sectionHeader = ({ section: { title, color, data }, section }) => {
    const expanded = this.checkExpanded(section);
    return (
      <div
        className="crop-fields-list-section"
        /* testID={section.title} */
        onClick={() => {
          this.setState({
            open: {
              ...this.state.open,
              [`${title}${color}`]: !this.checkExpanded(section),
            },
          });
        }}>
        <CropHeader title={title} rowData={{ fields: data }} open={expanded} />
      </div>
    );
  };

  isSectionExpanded({ title, color }) {
    return this.state.open[`${title}${color}`] != null
      ? this.state.open[`${title}${color}`]
      : this.props.openFieldPath &&
          this.props.openFieldPath.cropGroup.color === color &&
          this.props.openFieldPath.cropGroup.crop === title;
  }

  sectionItem = (item) => {
    let {
      onClick,
      waitTimes,
      myCompanyProfile,
      renderField,
    } = this.props;
    const el = item.item;
    const expanded = this.checkExpanded(item.section);

    if (!expanded) {
      return null;
    }

    const openFieldPath = this.props.openFieldPath;
    const selected =
      openFieldPath &&
      openFieldPath.field.key === el.key &&
      (!openFieldPath.crop || openFieldPath.crop.key === el.activeCrop.key);

    waitTimes = waitTimes && waitTimes[el.key] ? waitTimes[el.key] : [];

    if (renderField) {
      return renderField({ item: item, ...this.props });
    }
    return (
      <div
        key={'field' + el.key + el.activeCrop.key}
        className={classes(
          'list-item crop-fields-item',
          expanded && 'expanded',
          expanded && selected && 'selected',
        )}
        onClick={() => onClick(el.key, el.activeCrop.key)}>
        <SingleField
          field={el}
          crop={el.activeCrop}
          waitTimes={waitTimes}
          myCompanyProfile={myCompanyProfile}
          lastComment={el.activeCrop.lastComment}
        />
      </div>
    );
  };

  render() {
    const { rowData, expanded } = this.props;
    const sections = rowData.item.map(row => {
      return {
        title: row.title,
        color: row.color,
        data: row.fields,
      };
    });

    return (
      <SectionList
        className={classes('crop-fields-list-item', expanded && 'expanded')}
        initialNumToRender={30}
        stickySectionHeadersEnabled={true}
        renderItem={this.sectionItem}
        renderSectionHeader={this.sectionHeader}
        sections={sections}
        keyExtractor={this.keyExtractor}
      />
    );
  }

  keyExtractor = (item, index) => {
    return `${item.key}-${item.activeCrop && item.activeCrop.key}`;
  };
}
