import React from 'react';
import I18n from '../../language/i18n';
import FieldsSelection from '../../components/Fields/Select/FieldsSelection';
import './style.css';

type fieldsObject = Record<string, boolean>;
type Props = {
  fields: fieldsObject;
  onChange: (fields: fieldsObject) => any;
  error: boolean;
};

type State = {
  fields: fieldsObject;
  showForm: boolean;
};

class FieldsSelectionInForm extends React.Component<Props, State> {
  static defaultProps = {
    fields: {},
  };

  constructor(props: Props, context) {
    super(props, context);

    // $FlowFixMe
    this.updateSelectedFields = this.updateSelectedFields.bind(this);

    this.state = {
      fields: props.fields,
      showForm: false,
    };
  }

  updateSelectedFields(fields: fieldsObject) {
    this.setState({ fields: fields });
    this.props.onChange(fields);
  }

  /**
   * The field selection might return {key: false}
   * we filter those and then return the length
   *
   * @returns {number}
   */
  getSelectedFieldsCount() {
    const { fields } = this.state;

    return Object.keys(fields)
      .map(key => {
        if (fields[key]) {
          return key;
        }
        return null;
      })
      .filter(key => key).length;
  }

  render() {
    const { fields, showForm } = this.state;
    const { error } = this.props;
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div>
            {I18n.t('employees.x_selectedFields', {
              count: this.getSelectedFieldsCount(),
            })}
          </div>
          <button
            type={'button'}
            className={
              'btn btn-secondary btn-fields-selection ' +
              (error ? 'outline-danger' : 'outline-primary')
            }
            onClick={() => this.setState({ showForm: !this.state.showForm })}>
            {I18n.t('employees.selectFields')}
          </button>
        </div>

        {showForm && (
          <FieldsSelection
            selected={fields}
            show={showForm}
            onSave={this.updateSelectedFields}
            onClose={() => this.setState({ showForm: false })}
          />
        )}
      </div>
    );
  }
}

export default FieldsSelectionInForm;
