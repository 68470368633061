import React from 'react';
import { FJWHITE } from '../../styles/style';
import I18n from '../../language/i18n';

const InviteButton = ({ onClick }) => {
  return (
    <div style={{ marginLeft: 'auto' }}
      onClick={onClick}
    >
      <div className="btn btn-primary">
        <span
          style={{
            fontSize: 16,
            color: FJWHITE,
          }}
        >
          {I18n.t('invites.invite')}
        </span>
      </div>
    </div>
  );
};

export default InviteButton;
