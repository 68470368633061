import React from 'react';
import t from 'tcomb-form';
import TextInput from './TextInput';
import './style.css';
import { get } from 'lodash-es';

const Textbox = t.form.Textbox;

class FormInput extends Textbox {
  getTemplate() {
    return function(locals) {
      if (get(locals, 'config.hidden')) {
        return null;
      }

      const { numberOfLines } = locals.config;

      return <TextInput {...locals} numberOfLines={numberOfLines} />;
    };
  }

  getLocals() {
    const locals = super.getLocals();
    ['errorPlaceholderTextColor'].forEach(
      name => (locals[name] = this.props.options[name]),
    );
    return locals;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
}

export default FormInput;
