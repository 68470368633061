import { useEffect, useState } from 'react';

import fetch from '../utils/fetch';
import { getUidToken } from '../utils/auth';
import { BASE_API_URL } from '../constants';

const API_PLANT_PROTECTION = `${BASE_API_URL}/plant-protection/get`;

const useSprayers = (companyId: string) => {
  const [sprayers, setSprayers] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchSprayers = async () => {
      try {
        const uidToken = await getUidToken();
        const response = await fetch(API_PLANT_PROTECTION, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${uidToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({companyId}),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const json = await response.json();
        setSprayers(json);
      } catch (e: any) {
        console.error('Unable to fetch sprayers: ', e);
      }
    };
    fetchSprayers();
    return () => {
      controller.abort();
    };
  }, [companyId]);

  return sprayers;
};

export default useSprayers;
