import React from 'react';
import t from 'tcomb-form';

import ModalDate from '../../../../../tcomb/templates/ModalDate';
import { dateTransformer } from '../../../../../tcomb/transformers/transformers';
import { defaultDateFormat } from '../../../../../tcomb/utils/options';
import I18n from '../../../../../language/i18n';

// Our harvest model
const model = () => {
  return t.struct({
    harvestedOn: t.Date,
  });
};

const options = () => {
  const minimumDate = new Date((new Date().getFullYear() - 3), 0, 1);
  return {
    fields: {
      harvestedOn: {
        label: I18n.t('field.when_was_the_field_harvested'),
        maximumDate: new Date(),
        transformer: dateTransformer,
        config: {
          mode: 'date',
          format: date => defaultDateFormat(date),
          maximumDate: new Date(),
          minimumDate: minimumDate,
        },
        template: ModalDate,
      },
    },
  };
};

export default { model, options };
