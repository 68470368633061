import React, { useState, useEffect, useContext } from 'react';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { bindActionCreators, compose } from 'redux';

import * as employeeCreators from 'farmerjoe-common/lib/actions/employee';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import {
  getCompanyGroupProfileForLoggedInUser,
  getCompanyProfile,
  getCompanyUser,
} from 'farmerjoe-common/lib/selectors/user';
import { isAdmin } from 'farmerjoe-common/lib/utils/User';
import { outputDate } from 'farmerjoe-common';
import { getUserQuery, getUsersQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Users';
import { getProfileQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Profiles';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';

import './style.css';
import LatestActivitiesContainer from './LatestActivitiesContainer';
import CreateEmployee from '../Employees/CreateEmployee';
import { Avatar as ComponentAvatar } from '../../components/Common/Avatar';
import CoverImage from '../../components/Common/CoverImage';
import Icon from '../../components/Common/Icon';
import FormTextRow from '../../components/Common/FormTextRow';
import CircleUrlButtonWithIcon from '../../components/Common/CircleUrlButtonWithIcon';
import NavbarBasic from '../../components/Common/NavbarBasic';
import ScrollableTabView, {
  DefaultTabBar,
} from '../../components/Common/ScrollableTabView';
import AccountEdit from '../../components/Settings/AccountEdit';
import Dialog from '../../components/Dialog/Dialog';
import { EditButton } from '../../components/Common/EditButton';
import withRouter, { FjRouteChildrenProps } from '../../components/Router/withRouter';
import { DataContext } from '../../contexts/CompanyEmployeesProvider';

import I18n from '../../language/i18n';
import * as constants from '../../styles/style';

/**
 * Typings for Props parsed from redux store
 */
type StateProps = {
  companyId: string;
  avatarUrl: string | null;
  coverUrl: string | null;
  requested?: boolean;
  auth: any;
  user: any;
  myCompanyProfile: any;
  isCompanyUser?: boolean;
  company?: any;
};

/**
 * Typings for reducers from the redux store
 */
type DispatchProps = {
  actions: typeof employeeCreators;
};

type OwnProps = {
  firebase?: any;
  uid: string;
  groupId: string;
  showInDialog?: boolean;
  onClose?: () => void;
  show?: boolean;
  isFloating?: boolean;
  isCompanyUser?: boolean;
  company?: any;
};

type ProfileProps = StateProps & DispatchProps & OwnProps & FjRouteChildrenProps;

/**
 * Display the user's profile
 */

const Profile = (props: ProfileProps) => {
  const [location, setLocation] = useState<{coords: {latitude: any, longitude: any}, timestamp: any}>();
  const [showCreateEmployeeDialog, setShowCreateEmployeeDialog] = useState(false);
  const [showAccountEdit, setShowAccountEdit] = useState(false);
  const [locRef, setLocRef] = useState<() => {}>();

  const { firebase, uid, companyId, user, auth, myCompanyProfile, isCompanyUser, actions, company } = props;
  const { avatarUrl, coverUrl, requested, showInDialog, onClose, show } = props;

  const { refreshData } = useContext(DataContext);

  useEffect(() => {
    if (uid && !uid.includes('@')) {
      const _locRef = firebase
        .firestore()
        .collection('userLocations')
        .doc(companyId)
        .collection('users')
        .doc(uid)
        .onSnapshot(
          (snap) => {
            const _location = snap.data();
            setLocation(_location);
          },
          (error) => console.error(error),
        );
      setLocRef(_locRef);
    }
    return () => {
      if (locRef) {
        locRef();
      }
    };
  }, [uid, companyId, firebase, locRef]);


  const canEdit = () => {
    if (!isCompanyUser) {
      return false;
    }

    return (
      auth.uid === user.key ||
      (auth.uid !== user.key && isAdmin(myCompanyProfile))
    );
  };

  const editable = canEdit();
  const invitationPending = user ? user.invitation : false;

  const onRight = () => {
    if (auth.uid === user.key) {
      setShowAccountEdit(true);
      return;
    }

    if (editable) {
      actions.editEmployee(companyId, user.key);
      setShowCreateEmployeeDialog(true);
    }
  };

  // In some situations the user is empty
  if (!user) {
    return null;
  }

  const tel = user.phoneNumber
    ? (
      <CircleUrlButtonWithIcon
        iconName={'ios-call'}
        url={`tel:${user.phoneNumber}`}
      />
    )
    : null;

  const renderCloseButtonIfNeeded = () => {
    if (!props.isFloating) return null;

    const onCloseHandler = () => {
      actions.openEmployee(null);
      props.history.push(`/company/${companyId}/employee`);
    };

    return (
      <div
        className="top-bar-button gray-text"
        onClick={onCloseHandler}
      >
        <Icon iconType="fa" name="times" style={{ fontSize: 25 }} />
      </div>
    );
  };

  const content = (
    <div className="profile">
      <ScrollableTabView
        renderTabBar={() => (
          <TabBar
            canEdit={editable && !invitationPending && !!showInDialog}
            onEdit={onRight}
          />
        )}>

        {isCompanyUser && !invitationPending ? (
          <LatestActivitiesContainer
            uid={user.key}
            companyId={companyId}
            tabLabel={{
              labelKey: 'activity',
              icon: 'ios-time',
              iconType: 'ion',
              text: I18n.t('latestActivities.tabLabel'),
            }}
          />
        ): null}

        <Div
          style={{ flex: 1, backgroundColor: constants.FJBACKGROUND }}
          tabLabel={{
            labelKey: 'info',
            icon: 'md-person',
            iconType: 'ion',
            text: I18n.t('info'),
          }}>
          <div style={{ backgroundColor: '#FFF' }}>
            <CoverImage cover={coverUrl} showUploadOption={false} />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
                marginTop: -50,
                flexDirection: 'column',
                position: 'relative',
              }}>
              <div
                style={{
                  padding: 2,
                  backgroundColor: '#FFF',
                  borderRadius: 5,
                  marginBottom: 5,
                }}>
                <ComponentAvatar
                  downloadUrl={avatarUrl}
                  style={{ width: 100, height: 100 }}
                  isLoaded={!!requested}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#FFF',
                  paddingBottom: 10,
                  flexDirection: 'column',
                }}>
                <span
                  style={{ fontSize: 22, marginTop: 10, marginBottom: 10 }}>
                  {user.name}
                </span>
                <span style={constants.styles.muted}>
                  {
                    {
                      admin: I18n.t('employees.admin'),
                      advisor: I18n.t('acl.role.advisor'),
                      standard: I18n.t('employee'),
                    }[user.role]
                  }
                </span>

                {invitationPending ? null : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginTop: 20,
                    }}>
                    <CircleUrlButtonWithIcon
                      url={`mailto:${user.email}`}
                      iconName={'ios-at-outline'}
                    />
                    {tel ? <div style={{ marginLeft: 40 }}>{tel}</div> : null}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 10,
              backgroundColor: '#FFF',
              paddingLeft: 20,
              paddingRight: 20,
            }}>
            {invitationPending ? (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1em',
              }}>
                <span
                  style={{
                    ...constants.styles.small,
                    ...constants.styles.muted,
                    ...constants.styles.italic,
                    ...{
                      marginTop: 5,
                      flex: 1,
                    },
                  }}>
                  {I18n.t('employees.invitationSentOn', {
                    date: outputDate(user.invited_on),
                  })}
                </span>

                <button
                  className="btn btn-danger"
                  onClick={() => {
                    actions.deleteEmployee(company.key, user);
                    actions.openEmployee(null);
                    refreshData();
                    props.history.push(`/company/${companyId}/employee`);
                  }}>
                  <Icon name="ios-trash-outline" /> {I18n.t('delete')}
                </button>
              </div>
            ) : (
              <>
                {location
                  ? (
                    <FormTextRow
                      label={`${I18n.t('position')} (${outputDate(
                        location.timestamp,
                        'DD.MM.YYYY HH:mm',
                      )})`}
                      value={
                        <a
                          href={`http://www.google.com/maps/place/${location.coords.latitude},${location.coords.longitude}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {location.coords.latitude.toFixed(4) +
                      ', ' +
                      location.coords.longitude.toFixed(4)}
                        </a>
                      }
                    />
                  )
                  : null}
                <FormTextRow
                  className="email"
                  label={I18n.t('email')}
                  value={<a href={`mailto:${user.email}`}>{user.email}</a>}
                />
                {user.phoneNumber
                  ? (
                    <FormTextRow
                      label={I18n.t('phone')}
                      value={
                        <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>
                      }
                    />
                  )
                  : null}
              </>
            )}
          </div>
        </Div>
      </ScrollableTabView>
      {showCreateEmployeeDialog
        ? (
          <CreateEmployee
            show={showCreateEmployeeDialog}
            groupId={user.group_id}
            onClose={() => setShowCreateEmployeeDialog(false)}
          />
        )
        : null}
      {showAccountEdit
        ? (
          <AccountEdit
            show={showAccountEdit}
            onClose={() => setShowAccountEdit(false)}
          />
        )
        : null}
    </div>
  );

  if (showInDialog) {
    return (
      <Dialog
        className="profile-dialog"
        title={user.name}
        show={!!show}
        onClose={onClose}>
        {content}
      </Dialog>
    );
  } else {
    return (
      <div className="profile-wrapper">
        <NavbarBasic
          title={user.name}
          leftText={
            <Icon
              iconType={'fj'}
              name="arrow_left"
              style={{ fontSize: 20, color: constants.FJNAVCOLOR }}
            />
          }
          leftButton={renderCloseButtonIfNeeded()}
          rightButton={
            editable && !invitationPending ? <EditButton onClick={onRight} /> : ''
          }
        />
        {content}
      </div>
    );
  }
};

function Div({ children, tabLabel, ...rest }) {
  return <div {...rest}>{children}</div>;
}

function TabBar(props) {
  return (
    <div className="profile-tab-bar">
      <DefaultTabBar {...props} key="tab-bar" />
      {props.canEdit
        ? (
          <span
            className="top-bar-button edit-button"
            onClick={props.onEdit}
            key="edit-button">
            <Icon
              name="ios-build"
              style={{
                fontSize: 25,
                color: constants.FJNAVCOLOR,
              }}
            />
          </span>
        )
        : null}
    </div>
  );
}

const selector = (state, ownProps: OwnProps): StateProps => {
  const { uid } = ownProps;
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const avatarUrl = get(
    state.firestore.data,
    ['profiles', uid, 'avatar', 'original', 'downloadURL'],
    null,
  );
  const coverUrl = get(
    state.firestore.data,
    ['profiles', uid, 'coverImage', 'original', 'downloadURL'],
    null,
  );
  const user = getCompanyProfile(state, uid, openCompany);
  const isCompanyUser = !!getCompanyUser(state, uid, openCompany);

  const queries = [
    getProfileQuery(uid),
    getUserQuery(openCompany, uid, ownProps.groupId),
    getUsersQuery(openCompany, ownProps.groupId),
  ];

  const loaded = hasLoaded(queries, state);

  return {
    companyId: openCompany,
    requested: loaded,
    auth: state.firebase.auth,
    avatarUrl,
    coverUrl,
    user,
    myCompanyProfile,
    isCompanyUser,
    company,
  };
};

const wrappedProfile = firestoreConnect(props => {
  if (!props.uid) {
    return [];
  }

  const { companyId, groupId, uid } = props;

  const queries = [
    getProfileQuery(uid),
    getUserQuery(companyId, uid, groupId),
    getUsersQuery(companyId, groupId),
  ];

  return queries;
})(Profile);

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...employeeCreators,
        },
      ),
      dispatch,
    ),
  };
};

export default compose<React.ComponentClass<OwnProps>>(
  connect<StateProps, DispatchProps, OwnProps>(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(wrappedProfile);

